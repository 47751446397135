import {Component, Input, Optional} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

  export interface BankData {
    beginningCashBalance: number;
    endingCashBalance: number;
    minimumCashBalance: number;
    newCashBalance: number;
    priorDayCashBalance: number;
  }
@Component({
  selector: 'app-bank-account-modal',
  templateUrl: './bank-account-modal.component.html'
})

@AutoUnsubscribe()
export class BankAccountModalComponent {
  @Input() public title!: string;
  private _data: any;

  @Input()
  set data(value: BankData) {
    this._data = value;
    this.getBankAccountInformation();
  }

  get data(): any {
    return this._data;
  }
  @Input() public isLoaderVisible: boolean = true;
  isLoading: boolean = true;

  constructor(
    private buildr: FormBuilder,
    @Optional() public dialogRef: MatDialogRef<BankAccountModalComponent>,
  ) { }

  getBankAccountInformation(){
    this.isLoading = false;
  }

  handleBankAccountModalCloseBtnClick() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  bankAccountModalForm = this.buildr.group({
    totalAti: this.buildr.control(''),
    totalAta: this.buildr.control(''),
    outstandingLiability: this.buildr.control(''),
    outstandingAuthorization: this.buildr.control(''),
    salesAllowance: this.buildr.control(''),
    overage: this.buildr.control(''),
    totalActivity: this.buildr.control(''),
    beginningCashBalance: this.buildr.control(''),
    endingCashBalance: this.buildr.control(''),
    minimumCashBalance: this.buildr.control(''),
    newCashBalance: this.buildr.control(''),
    priorDayCashBalance: this.buildr.control(''),
    creditChargebackAmount: this.buildr.control(''),
    creditReturnAmount: this.buildr.control(''),
    creditSaleAmount: this.buildr.control(''),
    creditVoidAmount: this.buildr.control(''),
    netDebitCreditToClient: this.buildr.control(''),
    purchaseCardAmount: this.buildr.control(''),
    purchaseCardReturnAmount: this.buildr.control(''),
    wireAchToFundCashAmount: this.buildr.control(''),
  });
}