import {Rule} from '../../terms-conditions/model/rule';

export class Template {
  id: number = -1;
  templateName: string = '';
  templateDescription: string = '';
  termsConditionsTypeId: number = -1;
  templateNotes: string = '';
  rules: Rule[] = []

  constructor(obj: any) {
    this.id = obj.id;
    this.templateName = obj.templateName;
    this.templateDescription = obj.templateDescription;
    this.templateNotes = obj.templateNotes;
    this.termsConditionsTypeId = obj.termsConditionsTypeId;
  }
}
