<mat-card id="widget-card" class="widget widget-card">
  <mat-card-content id="widget-card-content" class="widget-inner">
    <div id="widget-header" class="widget-header">
      <div id="widget-header-text" class="widget-header-text">
        <div id="widget-title" class="widget-title">
          {{ title }}
        </div>
        <p id="widget-text" class="widget-text">
          {{ subTitle }}
        </p>
      </div>

      <div id="widget-actions" class="widget-actions" *ngIf="actions">
        <button id="widget-button"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon id="widget-icon">more_vert</mat-icon>
        </button>
        <mat-menu id="widget-menu" #menu="matMenu">
          <ng-content select="[widgetActions]"> </ng-content>
        </mat-menu>
      </div>
    </div>

    <div id="widget-content" class="widget-content">
      <ng-content select="[widgetContent]"></ng-content>
    </div>
  </mat-card-content>
</mat-card>
