import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup,} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {FinanceReportFilterResult} from '../../model/finance-report-filter-result';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'finance-reports-filter-modal',
  templateUrl: './finance-reports-filter-modal.component.html'
})
export class FinanceReportsFilterModalComponent implements OnInit {
  @Input() config!: FinanceReportFilterResult;

  breakPoint$?:Subscription;
  isSmall: boolean = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<FinanceReportsFilterModalComponent>,
    breakpointObserver: BreakpointObserver,
  ) {
      this.breakPoint$ = breakpointObserver.observe([
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall
      ]).subscribe(result => {
          this.isSmall = result.matches;
      });
  }

  form: FormGroup = new FormGroup({
    bookingStartFrom: new FormControl(null),
    bookingStartTo: new FormControl(null),
    bookingEndFrom: new FormControl(null),
    bookingEndTo: new FormControl(null),
    dateCreatedFrom: new FormControl(null),
    dateCreatedTo: new FormControl(null),
    advisorName: new FormControl(null),
    tripReference: new FormControl(null)
  });

  handleClose() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  ngOnInit() {
    const { bookingStartFrom, bookingStartTo, bookingEndFrom, bookingEndTo, dateCreatedFrom, dateCreatedTo, advisorName, tripReference} = this.config ?? {};
      this.form.patchValue({
        bookingStartFrom, bookingStartTo, bookingEndFrom, bookingEndTo, dateCreatedFrom, dateCreatedTo, advisorName, tripReference
      });
  }


  applyFinanceReportFilters() {
    this.config.advisorName = this.config.advisorName?.trim();
    this.config.tripReference = this.config.tripReference?.trim();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }
    else if (this.dialogRef) {
      this.dialogRef.close(this.config);
    }
  }

  public clearFinanceReportFilters(propertyName: string): void {
    if (propertyName === "all") {
      this.config = {} as FinanceReportFilterResult;
    }
    else{
      let temp: any = this.config;
      temp[propertyName] = undefined;
      this.config = temp;
    }
  }
}
