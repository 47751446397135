<div id="sidebar-branding" class="sidebar-branding">
  <img id="sidebar-branding-logo" class="sidebar-branding-logo" src="../../assets/img/triparc-light.svg" alt="Trip Arc Brand Logo" />
</div>
<mat-divider id="sidebar-divider"></mat-divider>
<div id="sidebar-item-sep-1" class="sidebar-item-sep"></div>
<div id="sidebar-section" class="sidebar-section" *ngIf="hasMenuItems && !isNoAccessPage">
  <h2 id="sidebar-pages" matSubheader> Pages </h2>
  <div id="sidebar-item-sep-2" class="sidebar-item-sep"></div>
  <sidebar-menu id="{{navMenuItem.idTag}}-tab" *ngFor="let navMenuItem of sidenavMenu; let i = index" [item]="navMenuItem"></sidebar-menu>
</div>
<div id="sidebar-item-sep-3" class="sidebar-item-sep"></div>
