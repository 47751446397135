import {Component, Input} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'app-transaction-booking-viewer',
  templateUrl: './transaction-booking-viewer.component.html'
})

@AutoUnsubscribe()
export class TransactionBookingViewerComponent {
  @Input() public data: any;
  _booking: any;

  constructor(private buildr: FormBuilder) {
  }

  ngOnChanges() {
    this.getBookingInformation();
  }

  getBookingInformation() {
    if (this.data) {
      this._booking = this.data;

      if (this._booking.tasks && this._booking.tasks.length > 0) {
        this._booking.taskNumbers = this._booking.tasks.map((s: any) => s.externalNumber).join(',');
      }
    }
  }

  bookingViewerForm = this.buildr.group({
    action: this.buildr.control(''),
    templateName: this.buildr.control(''),
    templateNotes: this.buildr.control(''),
  })
}

