import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, throwError} from 'rxjs';
import {PaymentDetail} from '../models/payment-detail';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private resourcePath: string = 'dbov2/payment-detail';

  constructor(private http: HttpClient) { 
  }

  getPaymentByInvoiceId(invoiceId: number) {
    return this.http.get<PaymentDetail[]>(`${this.resourcePath}/invoice/${invoiceId}`)
    .pipe(catchError(this.errorHandler));
  }
  
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
}
