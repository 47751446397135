export class UploadNavReportFileModel {
  ArcAdjNo!: string;
  CreateDate!: Date;
  CreditAmount!: number;
  DebitAmount!: number;
  DtlRemarks!: string;
  GlBranchNo!: number;
  GlName!: string; 
  GlNo!: number;
  JeDetailNo!: number;
  JeRecordNo!: number;
  PayableNo!: number;
  PaymentNo!: number; 
  PostDate!: Date;
  Postings!: number;
  Remarks!: string;

  constructor(obj: any) {
    this.ArcAdjNo = obj.ARCADJNO;
    this.CreateDate = new Date((obj.CREATEDATE - 25569) * 86400000);
    this.CreditAmount = obj.CREDITAMOUNT;
    this.DebitAmount = obj.DEBITAMOUNT;
    this.DtlRemarks = '';
    this.GlBranchNo = obj.GLBRANCHNO;
    this.GlName = obj.GLNAME;
    this.GlNo = obj.GLNO;
    this.JeDetailNo = obj.JEDETAILNO;
    this.JeRecordNo = obj.JERECORDNO;
    this.PayableNo = obj.PAYABLENO;
    this.PaymentNo = obj.PAYMENTNO;
    this.PostDate = new Date((obj.POSTDATE - 25569) * 86400000);
    this.Postings = obj.POSTINGS;
    this.Remarks = obj.REMARKS;
  }
}

