export function AutoUnsubscribe() {
  return function (constructor: any) {
    const orig = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {
      for (const prop in this) {
        const property = this[prop];
        if (property && typeof property.unsubscribe === 'function') {
          property.unsubscribe();
        }
      }

      if (orig) {
        orig.apply(this);
      }
    }
  }
}
