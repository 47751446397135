import {ComponentType} from '@angular/cdk/overlay';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {Observable, catchError, throwError} from 'rxjs';

export const MODAL_DIALOG_CLASS = 'modal-dialog';

const DIALOG_CONFIG_DEFAULT: MatDialogConfig = {
  hasBackdrop: true,
  closeOnNavigation: true,
  panelClass: MODAL_DIALOG_CLASS,
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private resourcePath: string = 'help-spot';

  constructor(
    private matDialog: MatDialog,
    private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  open(
    component: ComponentType<any>,
    config: MatDialogConfig = DIALOG_CONFIG_DEFAULT
  ): MatDialogRef<any> {
    return this.matDialog.open(component, config);
  }

  public getHsPersonData(httpParams: HttpParams): Observable<any> {
    return this.httpClient
      .get<any>(`${this.resourcePath}/person/all`, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }
}
