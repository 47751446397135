<div class="grid-title-event">
    <h2 id="alerts-list-title">Alerts</h2>

    <div class="events-container">
        <button color="primary" id="new-alert-button" aria-label="New alert button" mat-stroked-button (click)="handleFinancialAlerts()">
            <mat-icon fontIcon="add_alert"></mat-icon>
            New Alert Rule
        </button>
    </div>
</div>
<mat-table id="alerts-table" [dataSource]="items">
    <ng-container matColumnDef="alert">
        <mat-header-cell id="alert-id-header" class="mat-header-cell" *matHeaderCellDef>
            Alert
        </mat-header-cell>
        <mat-cell id="alert-id-cell" *matCellDef="let element">
            {{element?.alert}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="enabled">
        <mat-header-cell id="enabled-id-header" class="mat-header-cell" *matHeaderCellDef>
            Enabled
        </mat-header-cell>
        <mat-cell id="enabled-id-cell" *matCellDef="let element">
            <mat-slide-toggle [(ngModel)]="element.isEnabled" (change)="handleToggleAlert(element)" [disabled]="isTogglePending">{{element?.isEnabled ? 'ON' : 'OFF'}}</mat-slide-toggle>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="criteria">
        <mat-header-cell id="criteria-id-header" class="mat-header-cell" *matHeaderCellDef>
            Criteria
        </mat-header-cell>
        <mat-cell id="criteria-id-cell" *matCellDef="let element">
            {{element?.criteria}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="notificationType">
        <mat-header-cell id="notification-type-id-header" class="mat-header-cell" *matHeaderCellDef>
            Notification Type
        </mat-header-cell>
        <mat-cell id="notification-type-id-cell" *matCellDef="let element">
            {{element?.currentAction}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
        <mat-header-cell id="action-id-header" class="mat-header-cell" *matHeaderCellDef>
            Action
        </mat-header-cell>
        <mat-cell id="action-id-cell" *matCellDef="let element">
            <button mat-icon-button aria-label="edit alert" (click)="handleFinancialAlerts(element?.workflowId)">
                <mat-icon fontIcon="edit"/>
            </button>
            <button mat-icon-button aria-label="delete alert">
                <mat-icon fontIcon="delete"/>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" />
    <mat-row *matRowDef="let row; columns: displayedColumns" />
</mat-table>
<mat-paginator id="booking-paginator" [length]="page?.totalItemCount" [hidePageSize]="true"
    [pageSize]="page?.pageSize" [pageIndex]="page?.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>