<mat-card id="widget-info-card" class="widget widget-info-card" [ngClass]="cssClass">
  <mat-card-content id="widget-info-card-content" class="widget-inner">
    <div id="widget-info-title" class="widget-title">
      {{ title }}
    </div>
    <div id="widget-info-content" class="widget-content">
      <p id="widget-info-text" class="widget-text">
        {{ text }}
      </p>
      <mat-divider id="widget-info-divider"></mat-divider>
      <p id="widget-info-textSub" class="widget-text-sub">{{ textSub }}</p>

      <div id="widget-info-icon-wrapper" class="widget-icon-wrapper">
        <mat-icon id="widget-info-icon">{{ icon }}</mat-icon>
      </div>
    </div>
  </mat-card-content>
</mat-card>
