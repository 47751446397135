import {Component, Input, Optional} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {VccViewerType} from 'src/app/virtual-credit-card/component/viewer/vcc-viewer.component';
import {AzureAuthService} from 'src/infrastructure/services/azure-auth.service';

export enum TransactionBookingViewerType {
  Booking,
  VendorPayment,
  CancellationRefund,
  AdxVirtualCreditCard,
  VirtualCreditCard,
  VendorInformation
}

@Component({
  selector: 'app-transaction-booking-viewer-modal',
  templateUrl: './transaction-booking-viewer-modal.component.html'
})

export class TransactionBookingViewerModalComponent {
  @Input() bookingData!: any;
  @Input() viewerType: TransactionBookingViewerType = TransactionBookingViewerType.Booking;
  @Input() title!: string;
  bookingViewerType = TransactionBookingViewerType;
  vccViewerType: VccViewerType;

  constructor(
    @Optional() public dialogRef: MatDialogRef<TransactionBookingViewerModalComponent>,
    private azureAuthService: AzureAuthService) {
      let loggedInUserHasManagerRole = this.azureAuthService.checkUserAzureTokenHasManagerRole();
      this.vccViewerType = loggedInUserHasManagerRole ? VccViewerType.Update : VccViewerType.Info;
    }

  handleClose() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }
}
