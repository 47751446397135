import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AdxInvoiceLineItemService {
  private resourcePath: string = 'dbov2/adx-invoice-line-item';
  constructor(private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public searchAdxInvoiceLineItems(adxInvoicesFilter: any): Observable<any> {
    let httpParams = new HttpParams();
    Object
      .keys(adxInvoicesFilter)
      .forEach((key) => {
        if (adxInvoicesFilter[key]) {
          httpParams = httpParams.append(key, adxInvoicesFilter[key]);
        }
      });

    return this.httpClient
      .get<any>(`${this.resourcePath}`, {params: httpParams})
  }
}