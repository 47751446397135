<div id="booking-dialog-confirmpopup" class="confirmpopup">
  <div id="booking-dialog-dialog-title" mat-dialog-title>
    <label id="booking-dialog-title-label" class="title">Booking: Confirmation</label>
  </div>
  <hr id="booking-dialog-first-hr">
  <div id="booking-dialog-dialog-content" mat-dialog-content>
    <p id="booking-dialog-content-p">Are you sure you want to save booking template information?</p>
  </div>
  <hr id="booking-dialog-second-hr">
  <div id="booking-dialog-dialog-actions" mat-dialog-actions align="end">
    <button
      id="booking-dialog-ok-button"
      type="submit" mat-raised-button
      (click)="okClick()"
      color="primary"
      cdkFocusInitial matTooltip="Save Booking Template"
      aria-label="Save Booking Template">
    <mat-spinner
      id="booking-dialog-spinner"
      color="accent"
      *ngIf="isLoadingSpinner"
      diameter="30">
    </mat-spinner>
      <span id="booking-dialog-ok-span" *ngIf="!isLoadingSpinner">Ok</span>
    </button>
    <button
      id="booking-dialog-no-button"
      mat-raised-button
      (click)="onNoClick()"
      color="warn">
      No Thanks
    </button>
  </div>
</div>
