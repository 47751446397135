<div id="template-confirmation-dialog-popup-div" class="confirmpopup">
  <div id="template-confirmation-dialog-title-div" mat-dialog-title>
    <label id="template-confirmation-dialog-title-label" class="title">Template: Confirmation</label>
  </div>
  <hr style="margin: 0;">
  <div id="template-confirmation-dialog-content-div" mat-dialog-content>
    <p id="template-confirmation-dialog-content-p">Are you sure you want to save <b>'{{data.template.templateName}}'</b> template information?</p>
  </div>
  <hr>
  <div id="template-confirmation-dialog-actions-div" mat-dialog-actions align="end">
    <button id="template-confirmation-dialog-ok-button" type="submit" mat-raised-button (click)="okClick()" color="primary" cdkFocusInitial matTooltip="Save Template" aria-label="Save Template">
      <mat-spinner id="template-confirmation-dialog-spinner" color="accent" *ngIf="isLoadingSpinner" diameter="30"></mat-spinner>
      <span id="template-confirmation-dialog-ok-span" *ngIf="!isLoadingSpinner">Ok</span>
    </button>
    <button id="template-confirmation-dialog-no-button" mat-raised-button (click)="onNoClick()" color="warn">No Thanks</button>
  </div>
</div>
