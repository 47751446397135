export class UploadNavMappingFileRecord {
  SourceId!: number;
  NavBranchId!: number;
  GlNo!: number;
  GlName!: string;
  NavGl!: number;
  NavGlDescription!: string;
  NavCompany!: string;
  AccountType!: string;
  TramsDataSource!: number;
  NavBranch!: string;
  Id!: number;
  IsDeleted!: boolean;
  TradingPartner!: string;
  constructor(obj: any) {
    this.SourceId = obj.sourceId;
    this.NavBranchId = obj.navBranchId;
    this.GlNo = Number(obj.glNo);
    this.GlName = obj.glName;
    this.NavGl = obj.navGl;
    this.NavGlDescription = obj.navGlDescription;
    this.NavCompany = obj.navCompany;
    this.AccountType = obj.accountType;
    this.TramsDataSource = obj.tramsDataSource;
    this.NavBranch = obj.navBranch;
    this.Id = obj.id;
    this.IsDeleted = obj.isDeleted;
    this.TradingPartner = obj.tradingPartner
  }
}

