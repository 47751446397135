import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {ChartDataSet, ChartOptions} from 'src/app/shared/components/chart/chart.component';
import {DashboardService} from '../../service/dashboard.service';
import {UtilizationWeeklyCalculation} from '../../model/utilization-weekly-calculation';
import {HttpParams} from '@angular/common/http';
import 'chartjs-adapter-moment';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {FilterResult} from 'src/app/shared/modals/filter-modal/filter-modal.component';
import {DateService} from 'src/app/shared/service/date/date.service';
import {ApplicationConstants} from 'src/app/shared/constants/application.constants';
import {AzureAuthService, UserAzureProfileDetails} from '../../../../infrastructure/services/azure-auth.service';

@Component({
  selector: 'app-utilization-weekly-calculation',
  templateUrl: './utilization-weekly-calculation.component.html'
})

@AutoUnsubscribe()
export class UtilizationWeeklyCalculationComponent implements OnInit {
  @Input() filter!: FilterResult;
  filterDateFrom: Date;
  filterDateTo: Date;
  filterEmail: string;
  userAzureProfileDetails: UserAzureProfileDetails = {};
  utilizationWeeklyCalculation: UtilizationWeeklyCalculation[] = []
  IsLoading: boolean = false;
  public utilizationLineChartData!: ChartDataSet | null;
  public utilizationLineChartOptions: ChartOptions;
  private appConstants: ApplicationConstants = new ApplicationConstants;

  constructor(
    private azureAuthService: AzureAuthService,
    private dashboardService: DashboardService,
    private date: DateService,
  ) {
    this.filterDateFrom = this.date.subtractDays(new Date(), 30);
    this.filterDateTo = new Date();
    this.filterEmail = this.appConstants.AllUser;
  }

  ngOnInit(): void {
    const isDirector = this.azureAuthService.checkUserAzureTokenHasDirectorRole();
    if (!isDirector) {
      this.azureAuthService.loggedInUserEmail.subscribe(x => {
        this.filterEmail = x;
      });
    }

    this.azureAuthService.getUserAuth$()
      .subscribe((user) => {
        if (user?.profile) {
          this.handle(this.filterDateFrom.toDateString(), this.filterDateTo.toDateString(), this.filterEmail, user.profile);
        }
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter'] && !changes['filter'].firstChange) {
      this.filterDateFrom = this.filter.from ? this.filter.from : this.filterDateFrom;
      this.filterDateTo = this.filter.to ? this.filter.to : this.filterDateTo;
      this.filterEmail = this.filter.email?.toString() ?? this.filterEmail;
      this.getUtilizationChart(this.filterDateFrom.toDateString(), this.filterDateTo.toDateString(), this.filterEmail);
    }
  }

  // because of the promise returned by azure service the code should run like that in order not to had undefined values
  // once we don't have the need to call azure auth service to get the user, the code can be split
  public handle(fromDate: string, toDate: string, email: string, profile: UserAzureProfileDetails) {
    this.IsLoading = true;
    if (profile) {
      if (this.azureAuthService.checkUserAzureTokenHasDirectorRole()) {
        email = this.filterEmail || '';
      } else if (profile.mail) {
        email = profile.mail;
      }

      this.getUtilizationChart(fromDate, toDate, email);
    }
    this.userAzureProfileDetails = profile;
    this.IsLoading = false;
  }

  public getUtilizationChart(fromDate: string, toDate: string, email: string) {
    this.utilizationLineChartData = null;

    if (email == this.appConstants.AllUser) {
      email = '';
    }

    let httpParams = new HttpParams({fromObject: {Email: email ?? '', DateFrom: fromDate, DateTo: toDate}});

    this.dashboardService.searchUtilizationWeeklyCalculation(httpParams)
      .subscribe((data: any) => {
        this.utilizationWeeklyCalculation = data as UtilizationWeeklyCalculation[];

        const labels = this.utilizationWeeklyCalculation.map((d) => `${d.weekNumber}`);
        const dateRanges = this.utilizationWeeklyCalculation.map((d) => this.date.getDateRangeForWeek(d.weekNumber));
        const utilizationData = this.utilizationWeeklyCalculation.map((d) => d.utilization);

        this.createUtilizationLineChart(labels, dateRanges, utilizationData);
      });
  }

  createUtilizationLineChart(labels: string[], dateRanges: string[], utilizationData: number[]) {
    this.utilizationLineChartData = {
      datasets: [
        {
          data: utilizationData,
          label: 'Utilization',
          backgroundColor: '#494368',
          borderColor: '#494368',
          pointBackgroundColor: '#fc60a8',
          pointBorderColor: '#fc60a8',
        },
      ],
      labels: labels,
    };

    this.utilizationLineChartOptions = {
      scales: {
        y: {
          title: {
            display: true,
            text: 'Utilization',
          },
          beginAtZero: true
        },
        x: {
          title: {
            display: true,
            text: 'Week',
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context: any) => {
              const index = context[0].dataIndex;
              return `Date Range: ${dateRanges[index]}`;
            },
          },
        },
      }
    };
  }
}
