<div id="template-viewer-form-div" class="template-form" *ngIf="_template.id > 0">
  <div>
    <label id="template-viewer-title-label" *ngIf="data != undefined" class="title">{{data.title}}</label>
  </div>
  <hr>
  <div>
    <mat-form-field id="template-viewer-template-name-field">
      <mat-label id="template-viewer-template-name-label">Template Name</mat-label>
      <input id="template-viewer-template-name-input" type="text" #templateName matInput [(ngModel)]="_template.templateName" readonly>
    </mat-form-field>
    <mat-form-field id="template-viewer-template-description-field">
      <mat-label id="template-viewer-template-description-label">Template Description</mat-label>
      <input id="template-viewer-template-description-input" type="text" #templateDescription matInput [(ngModel)]="_template.templateDescription" readonly>
    </mat-form-field>
    <mat-form-field id="template-viewer-template-notes-field">
      <mat-label id="template-viewer-template-notes-label">Template Notes</mat-label>
      <input id="template-viewer-template-notes-input" type="text" #templateNotes matInput [(ngModel)]="_template.templateNotes" readonly>
    </mat-form-field>
  </div>
</div>

