<div id="trip-profitability-container" class="trip-profitability-container" >
  <div
    id="trip-profitability-pie-chart-container"
    class="trip-profitability-pie-chart"
    *ngIf="this.tripProfitabilityReportData.length !== 0">
    <ng-container id="trip-profitability-pie-chart-data-container">
      <chart
        id="trip-profitability-pie-chart"
        [dataSet]="tripProfitabilityPieChartData"
        [options]="tripProfitabilityPieChartOptions"
        [chartType]="'pie'"></chart>
    </ng-container>
  </div>
  <br>
  <div id="trip-profitability-table-feed" class="table-feed" *ngIf="this.tripProfitabilityReportData.length === 0">
    <div id="trip-profitability-table-feed-text" class="table-feed-text" style="margin-top: 2%;">
      There are no records to display.
    </div>
  </div>
  <div class="grid-title-event">
    <h2 data-testid="trip-profitability-list-title">Trip Profitability</h2>

    <div class="events-container">
        @if(dataSource.filteredData.length){
            <button  data-testid="trip-profitability-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getTripProfitabilityReport()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" data-testid="download-trip-profitability-button" aria-label="Download Adx Vcc File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
                <mat-icon>download</mat-icon>
            </button>
        }
    </div>
  </div>
  <div id="trip-profitability-grid" class="trip-profitability-grid mat-elevation-z8">
    <mat-table id="trip-profitability-mat-table"
      [dataSource]="dataSource"
      (matSortChange)="handleSortChange($event)"
      matSort>
      <ng-container id="trip-profitability-no-data-row" *matNoDataRow>
        <mat-cell id="trip-profitability-empty-data-cell" [attr.colspan]="displayedColumns.length">Empty data</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-trip-reference-column" matColumnDef="tripReference">
        <mat-header-cell id="trip-profitability-trip-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Trip Reference</mat-header-cell>
        <mat-cell id="trip-profitability-trip-reference-cell" *matCellDef="let row">{{row.tripReference}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-date-created-column" matColumnDef="dateCreated">
          <mat-header-cell id="trip-profitability-date-created-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
          <mat-cell id="trip-profitability-date-created-cell" *matCellDef="let element">{{element.dateCreated  | date: 'M/d/yyyy'}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-advisor-name-column" matColumnDef="advisorName">
          <mat-header-cell id="trip-profitability-advisor-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Advisor Name</mat-header-cell>
          <mat-cell id="trip-profitability-advisor-name-cell" *matCellDef="let row">{{row.advisorName}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-customer-paid-column" matColumnDef="customerPaid">
        <mat-header-cell id="trip-profitability-customer-paid-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Customer Paid</mat-header-cell>
        <mat-cell id="trip-profitability-customer-paid-cell" *matCellDef="let row">{{row.customerPaid}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-vendor-paid-column" matColumnDef="vendorPaid">
          <mat-header-cell id="trip-profitability-vendor-paid-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Vendor Paid</mat-header-cell>
          <mat-cell id="trip-profitability-vendor-paid-cell" *matCellDef="let row">{{row.vendorPaid}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-commission-column" matColumnDef="commission">
          <mat-header-cell id="trip-profitability-commission-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Commission</mat-header-cell>
          <mat-cell id="trip-profitability-commission-cell" *matCellDef="let row">{{row.commission}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-agent-split-column" matColumnDef="agentSplit">
          <mat-header-cell id="trip-profitability-agent-split-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Agent Split</mat-header-cell>
          <mat-cell id="trip-profitability-agent-split-cell" *matCellDef="let row">{{row.agentSplit}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-margin-amount-column" matColumnDef="marginAmount">
          <mat-header-cell id="trip-profitability-margin-amount-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header disabled>Margin Amount</mat-header-cell>
          <mat-cell id="trip-profitability-margin-amount-cell" *matCellDef="let row">{{row.marginAmount}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-margin-percentage-column" matColumnDef="marginPercentage">
          <mat-header-cell id="trip-profitability-margin-percentage-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header disabled>Margin Percentage</mat-header-cell>
          <mat-cell id="trip-profitability-margin-percentage-cell" *matCellDef="let row">{{row.marginPercentage.toFixed(2)}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-adx-booking-number-column" matColumnDef="adxBookingNumber">
        <mat-header-cell id="trip-profitability-adx-booking-number-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Link To Trip Details</mat-header-cell>
        <mat-cell id="trip-profitability-adx-booking-number-cell" *matCellDef="let row">
            <a id="trip-profitability-link-to-trip-details" href={{row.linkToTripDetails}} target="_blank" rel="noopener noreferrer">
                <div id="trip-profitability-adxBookingNumber-div">
                    {{row.adxBookingNumber}}
                </div>
            </a>
        </mat-cell>
      </ng-container>

      <mat-header-row data-testid="trip-profitability-header-row"
        *matHeaderRowDef="displayedColumns; sticky: true">
      </mat-header-row>
      <mat-row id="trip-profitability-row"
        *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  </div>
</div>
<mat-paginator
  id="trip-profitability-paginator"
  [length]="this.pagination.totalItemCount"
  [hidePageSize]="true"
  [pageSize]="this.pagination.pageSize"
  (page)="pageChanged($event)">
</mat-paginator>
