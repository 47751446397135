<mat-dialog-content style="overflow: inherit;">
    <div id="transaction-history-container" style="display: flex; justify-content: center; align-items: center;">
    @if(transactions){
            @if (transactions.length) {
                <mat-tab-group style="min-width: 100%;">
                @for (transaction of transactions; track transaction) {
                    <mat-tab label="{{transaction?.amount}} - {{transaction.status | capitalize}}">
                      <form id="transaction-history-viewer-form" class="template-form">
                        <div id="transaction-history-main-container" class="container">
                          <mat-form-field id="transaction-history-timestamp-mat-form-field">
                            <mat-label id="transaction-history-timestamp-field-title">Date: </mat-label>
                            <input id="transaction-history-timestamp-input-field" matInput value="{{transaction?.timestamp | date:'M/d/yyyy'}} {{transaction?.timestamp | date:'shortTime'}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-amount-mat-form-field">
                            <mat-label id="transaction-history-amount-field-title">Amount: </mat-label>
                            <input id="transaction-history-amount-input-field" matInput value="{{transaction?.amount}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-status-mat-form-field">
                            <mat-label id="transaction-history-status-field-title">Status: </mat-label>
                            <input id="transaction-history-status-input-field" matInput value="{{transaction?.status}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-description-mat-form-field">
                            <mat-label id="transaction-history-description-field-title">Description: </mat-label>
                            <input id="transaction-history-description-input-field" matInput value="{{transaction?.description}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-currency-mat-form-field">
                            <mat-label id="transaction-history-currency-field-title">Currency: </mat-label>
                            <input id="transaction-history-currency-input-field" matInput value="{{transaction?.currencyCode}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-card-guid-mat-form-field">
                            <mat-label id="transaction-history-card-guid-field-title">Card Guid: </mat-label>
                            <input id="transaction-history-card-guid-input-field" matInput value="{{transaction?.cardGuid}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-merchant-name-mat-form-field">
                            <mat-label id="transaction-history-merchant-name-field-title">Merchant Name: </mat-label>
                            <input id="transaction-history-merchant-name-input-field" matInput value="{{transaction?.merchantName}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-merchant-mcc-mat-form-field">
                            <mat-label id="transaction-history-merchant-mcc-field-title">Merchant Mcc: </mat-label>
                            <input id="transaction-history-merchant-mcc-input-field" matInput value="{{transaction?.merchantMcc}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-approval-code-mat-form-field">
                            <mat-label id="transaction-history-approval-code-field-title">Approval Code: </mat-label>
                            <input id="transaction-history-approval-code-input-field" matInput value="{{transaction?.approvalCode}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-card-acceptor-mid-mat-form-field">
                            <mat-label id="transaction-history-card-acceptor-mid-field-title">Card Acceptor: </mat-label>
                            <input id="transaction-history-card-acceptor-mid-input-field" matInput value="{{transaction?.cardAcceptorMid}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-transaction-guid-mat-form-field">
                            <mat-label id="transaction-history-transaction-guid-field-title">Transaction Guid: </mat-label>
                            <input id="transaction-history-transaction-guid-input-field" matInput value="{{transaction?.transactionGuid}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-net-work-mat-form-field">
                            <mat-label id="transaction-history-net-work-field-title">Network: </mat-label>
                            <input id="transaction-history-net-work-input-field" matInput value="{{transaction?.netWork}}">
                          </mat-form-field>
                          <mat-form-field id="transaction-history-type-mat-form-field">
                            <mat-label id="transaction-history-type-field-title">Type: </mat-label>
                            <input id="transaction-history-type-input-field" matInput value="{{transaction?.type}}">
                          </mat-form-field>

                          @if (transaction?.precedingRelatedToken) {
                            <mat-form-field id="transaction-history-preceding-related-token-mat-form-field">
                              <mat-label id="transaction-history-preceding-related-token-field-title">Preceding Related Token: </mat-label>
                              <input id="transaction-history-preceding-related-token-input-field" matInput value="{{transaction?.precedingRelatedToken}}">
                            </mat-form-field>
                          }

                          @if (transaction?.merchantAlias) {
                            <mat-form-field id="transaction-history-merchant-alias-mat-form-field">
                              <mat-label id="transaction-history-merchant-alias-field-title">Merchant Alias: </mat-label>
                              <input id="transaction-history-merchant-alias-input-field" matInput value="{{transaction?.merchantAlias}}">
                            </mat-form-field>
                          }

                          
                        </div>
                      </form>
                    </mat-tab>
                }
                </mat-tab-group>
            } @else {
                
                <span>There are no transactions to display.</span>
            }
    } @else {
            @if (isLoading) {
                <div class="loading-spinner">
                    <mat-spinner></mat-spinner>
                </div>
            } @else {
                <button mat-raised-button color="primary" (click)="loadHistory()">History</button>
            }
    }
    </div>
</mat-dialog-content>