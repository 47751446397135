import {Component, Input, OnInit} from '@angular/core';
import {AzureAuthService} from '../../../../infrastructure/services/azure-auth.service';

@Component({
  selector: 'app-transaction-booking-layout',
  templateUrl: './transaction-booking-layout.component.html'
})
export class TransactionBookingLayoutComponent implements OnInit {
  @Input() public data: any;
  bookingData: any;
  flag: number = 1;
  isUserLoggedIn: boolean = false;

  constructor(private azureAuthService: AzureAuthService) {
  }

  public ngOnInit(): void {
    this.azureAuthService.isUserLoggedIn.subscribe(x => {
      this.isUserLoggedIn = x;
    })
  }

  getSelectedBookingInfo(data: any) {
    this.bookingData = data;
  }
}
