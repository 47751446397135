<form [formGroup]="ruleForm" id="terms-conditions-rule-form-form" class="template-form">
  <div id="terms-conditions-rule-form-div" class="rule-form">
    <mat-form-field id="terms-conditions-amount-field" class="amt">
      <mat-label id="terms-conditions-amount-label">Amount</mat-label>
      <input id="terms-conditions-amount-input" matInput [(ngModel)]="rule.ruleAmt" #ruleAmtInput (change)="ruleAmtChanged($event)" placeholder="Amount" formControlName="ruleAmt">
      <mat-error id="terms-conditions-amount-required-error" *ngIf="this.ruleForm.controls['ruleAmt'].hasError('required')">Rule Amount is required</mat-error>
      <mat-error id="terms-conditions-amount-pattern-error" *ngIf="this.ruleForm.controls['ruleAmt'].hasError('pattern')">Only postive decimals are allowed.</mat-error>
      <mat-error id="terms-conditions-amount-max-error" *ngIf="this.ruleForm.controls['ruleAmt'].hasError('max')">Maximum number is 1000000 for amount and 100 for percent.</mat-error>
      <mat-error id="terms-conditions-amount-min-error" *ngIf="this.ruleForm.controls['ruleAmt'].hasError('min')">Minimum number is 0.</mat-error>
    </mat-form-field>
    <div class="divider"></div>
    <mat-form-field id="terms-conditions-proramt-field" class="proramt">
      <mat-select id="terms-conditions-proramt-select" [value]="templatePrcAmtSelected" formControlName="isPrOrAmt" (selectionChange)="onPrOrAmtChange($event.value)">
        <mat-option id="terms-conditions-proramt-option-{{i}}" *ngFor="let item of isPrOrAmt; let i = index" [value]="item.value">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="divider"></div>
    <mat-form-field id="terms-conditions-rule-period-field" class="amt">
      <mat-label id="terms-conditions-rule-period-label">Period</mat-label>
      <input id="terms-conditions-rule-period-input" matInput [(ngModel)]="rule.termsConditionsRuleTypeNumber" #rulePeriodInput (change)="rulePeriodChanged($event)" placeholder="Period" formControlName="rulePeriod">
      <mat-error id="terms-conditions-rule-period-required-error" *ngIf="this.ruleForm.controls['rulePeriod'].hasError('required')">Rule Amount is required</mat-error>
      <mat-error id="terms-conditions-rule-period-pattern-error" *ngIf="this.ruleForm.controls['rulePeriod'].hasError('pattern')">Only postive numbers are allowed.</mat-error>
      <mat-error id="terms-conditions-rule-period-max-error" *ngIf="this.ruleForm.controls['rulePeriod'].hasError('max')">Maximum number is 100.</mat-error>
      <mat-error id="terms-conditions-rule-period-min-error" *ngIf="this.ruleForm.controls['rulePeriod'].hasError('min')">Minimum number is 0.</mat-error>
    </mat-form-field>
    <div class="divider"></div>
    <mat-form-field id="terms-conditions-rule-period-type-field" class="proramt">
      <mat-select id="terms-conditions-rule-period-type-select" [(ngModel)]="rule.termsConditionsRuleTypeId" formControlName="rulePeriodType" (selectionChange)="onRulePeriodType($event.value)">
        <mat-option *ngFor="let item of rulePeriodTypes; let i = index" [value]="item.ruleTypePeriodId" id="terms-conditions-rule-period-type-option-{{i}}">
          {{item.termsConditionsRuleTypeName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="divider"></div>
    <mat-form-field id="terms-conditions-rule-condition-field" class="condition">
      <mat-label id="terms-conditions-rule-condition-label">Condition</mat-label>
      <mat-select id="terms-conditions-rule-condition-select" [(ngModel)]="rule.ruleConditionId" formControlName="ruleCondition" (selectionChange)="onRuleCondition($event.value)">
        <mat-option *ngFor="let item of ruleConditions; let i = index" [value]="item.ruleConditionId" id="terms-conditions-rule-condition-option-{{i}}">
          {{item.ruleConditionName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>


