import {Component, Input} from '@angular/core';
import {AutoUnsubscribe} from '../../../../../infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-vendor-viewer',
  templateUrl: './vendor-viewer.component.html'
})
export class VendorViewerComponent {
  @Input()
  public data: any;

  @Input()
  public update: any;

  public vendorId: any;

  public _vendor: any;

  public ngOnInit(): void {
    if (this.data != undefined) {
      this.getVendorInfo();
    }
  }

  public ngOnChanges(): void {
    this.getVendorInfo();
  }

  public getVendorInfo(): void {
    this._vendor = this.data;
  }
}
