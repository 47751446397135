import {Component, Input} from '@angular/core';
import {Template} from '../../model/template';
import {TemplateService} from '../../service/template.service';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-template-viewer',
  templateUrl: './template-viewer.component.html'
})
export class TemplateViewerComponent {
  @Input() public data: any;
  @Input() public update: any;
  public _template = {} as Template;
  vendorId: any;

  constructor(private templateService: TemplateService) {
  }

  ngOnChanges(): void {
    if (this.data?.templateId > 0) {
      this.getTemplateInfo();
    } else {
      this._template = {} as Template;
    }
  }

  public getTemplateInfo() {
    this.templateService.GetTermsConditionsTemplateById(this.data.templateId)
      .subscribe({
        next: (response: Template) => {
          this._template = response as Template;
        }
      });
  }
}
