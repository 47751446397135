<ng-container *ngIf="!isLoading; else loadingView">
    <form [formGroup]="adxVccViewerForm" id="adx-vcc-viewer-form" class="template-form adx-vcc-viewer-form"> 
      <div id="adx-vcc-main-container" class="container">
        <mat-tab-group dynamicHeight>
          <mat-tab *ngFor="let adxVcc of _adxVccs; let index = index" [label]= "adxVcc.id + ''">
            <mat-form-field id="adx-vcc-payment-transaction-id-field" *ngIf="adxVcc.paymentTransactionId != undefined && adxVcc.paymentTransactionId != null">
              <mat-label id="adx-vcc-payment-transaction-id-field-title">Payment Transaction ID: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.paymentTransactionId}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-travel-service-booking-id-field" *ngIf="adxVcc.travelServiceBookingId != undefined && adxVcc.travelServiceBookingId != null">
              <mat-label id="adx-vcc-travel-service-booking-transaction-id-field-title">Travel Service Booking ID: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.travelServiceBookingId}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-amt-date-guid-field" *ngIf="adxVcc.amount != undefined && adxVcc.amount != null">
              <mat-label id="adx-vcc-amt-date-guid-field-title">Amount: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.amount}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-amt-currency-field" *ngIf="adxVcc.amountCurrency != undefined && adxVcc.amountCurrency != null">
              <mat-label id="adx-vcc-amt-currenty-field-title">Amount Currency: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.amountCurrency}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-masked-number-field" *ngIf="adxVcc.maskedCreditCardNumber != undefined && adxVcc.maskedCreditCardNumber != null">
              <mat-label id="adx-vcc-masked-number-field-title">Masked Number: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.maskedCreditCardNumber}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-date-created-field" *ngIf="adxVcc.dateCreated != undefined && adxVcc.dateCreated != null">
              <mat-label id="adx-vcc-date-created-field-title">Date Created: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.dateCreated | date: 'M/d/yyyy'}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-expiration-month-field" *ngIf="adxVcc.expirationMonth != undefined && adxVcc.expirationMonth != null">
              <mat-label id="adx-vcc-expiration-month-field-title">Expiration Month: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.expirationMonth}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-expiration-year-field" *ngIf="adxVcc.expirationYear != undefined && adxVcc.expirationYear != null">
              <mat-label id="adx-vcc-expiration-year-field-title">Expiration Year: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.expirationYear}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-expiration-date-field" *ngIf="adxVcc.expirationDate != undefined && adxVcc.expirationDate != null">
              <mat-label id="adx-vcc-expiration-date-field-title">Expiration Date: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.expirationDate | date: 'M/d/yyyy'}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-credit-limit-field" *ngIf="adxVcc.creditLimit != undefined && adxVcc.creditLimit != null">
              <mat-label id="adx-vcc-credit-limit-field-title">Credit Limit: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.creditLimit}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-provider-unique-id-field" *ngIf="adxVcc.providerUniqueId != undefined && adxVcc.providerUniqueId != null">
              <mat-label id="adx-vcc-provider-unique-id-field-title">Provider Unique ID: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.providerUniqueId}}">
            </mat-form-field>
            <mat-form-field id="adx-vcc-record-locator-field" *ngIf="adxVcc.recordLocator != undefined && adxVcc.recordLocator != null">
              <mat-label id="adx-vcc-record-locator-field-title">Record Locator: </mat-label>
              <input id="adx-vcc-input-field" matInput value="{{adxVcc.recordLocator}}">
            </mat-form-field>
            <ng-container *ngIf="adxVcc.paymentProviderId == 19; else noConnexPayAlert">
              <button id="adx-vcc-show-more-button" mat-raised-button color="primary" (click)="showMore(index)" [hidden]="adxVcc.isVccPaymentGatewayInfoLoaded">Show more</button>
              <app-vcc-payment-gateway-viewer [data]="this._adxVccs[index]" [isLoaderVisible]="this.isLoaderVisible" *ngIf="adxVcc.isVccPaymentGatewayInfoLoaded"></app-vcc-payment-gateway-viewer>
            </ng-container>
            <ng-template #noConnexPayAlert>
              <div id="adx-vcc-no-connexpay-alert" class="adx-vcc-no-connexpay-alert">
                <mat-icon id="adx-vcc-no-connexpay-alert-icon" class="adx-vcc-no-connexpay-alert__icon" aria-hidden="false" aria-label="Alert">warning</mat-icon>
                <mat-label id="adx-vcc-no-connexpay-alert-label" class="adx-vcc-no-connexpay-alert__label">Not possible to show more information as this virtual credit card was not issued by ConnexPay.</mat-label>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
        <div *ngIf="this._adxVccs.length === 0">
          <div id="no-adx-vcc-issued-table-feed-text" class="table-feed-text col-md-12" style="margin-top: 2%;">
            <mat-label>
              There is no virtual credit card (VCC) issued so far for the selected transaction booking.
            </mat-label>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
  
  <ng-template #loadingView>
    <div class="loading-spinner">
      <mat-spinner *ngIf="isLoaderVisible"></mat-spinner>
    </div>
  </ng-template>
  