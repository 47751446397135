<div *ngIf="!(loading$ | async)" id="search-vendor-loading-div">
  <form [formGroup]="vendorForm" id="search-vendor-vendor-search-form" class="flex flex-wrap">
    <div>
      <mat-label class="title" id="search-vendor-title-label">Vendors:</mat-label>
    </div>
    <mat-form-field id="search-vendor-mat-form-field">
      <input #inputAutoComplete
             placeholder="Search..."
             [formControl]="inputControl"
             [matAutocomplete]="auto"
             #trigger="matAutocompleteTrigger"
             matInput
             type="text"
             id="search-vendor-input"/>
      <div matSuffix style="display:flex" id="search-vendor-button-div">
        <button (click)="clearInput($event)"
                *ngIf="inputControl?.value!==null && inputControl?.value!==''"
                aria-label="Clear"
                mat-icon-button
                type="button"
                id="search-vendor-clear-button">
          <mat-icon id="search-vendor-clear-icon">clear</mat-icon>
        </button>
        <button (click)="openOrClosePanel($event, trigger)"
                aria-label="Clear"
                mat-icon-button
                type="button"
                id="search-vendor-panel-button">
          <mat-icon id="search-vendor-arrow-icon">{{arrowIconSubject.getValue()}}</mat-icon>
        </button>
      </div>
      <mat-autocomplete #auto="matAutocomplete"
                        (closed)="arrowIconSubject.next('arrow_drop_down')"
                        (opened)="arrowIconSubject.next('arrow_drop_up')"
                        (optionSelected)="arrowIconSubject.next('arrow_drop_down')"
                        id="search-vendor-mat-autocomplete">
        <mat-option *ngFor="let vendor of vendorGroupOptions | async; let i = index "
                    [value]="vendor.VendorName" (onSelectionChange)="setSelectedVendor(vendor.VendorId,$event)"
                    id="search-vendor-mat-option-{{i}}">
          {{vendor.VendorName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  </div>
