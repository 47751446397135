<form class="filter" [formGroup]="adxFilterForm" (submit)="applyAdxFilters()">
<modal-panel
    id="adx-vcc-filter-modal-panel"
    class="adx-vcc-filter-modal-panel-cls filter-panel"
    [title]="'Filter'"
    (close)="handleBookingFiltersFormCloseEvent()">
        <div id="adx-vcc-filter-modal-div-2" class="filter__fields">
            <mat-grid-list [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Date created from</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="dateCreatedFrom">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker #picker1></mat-datepicker>
                        @if(adxFilterForm.controls['dateCreatedFrom'].value){
                            <button 
                                id="adx-vcc-start-date-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('dateCreatedFrom')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Date created to</mat-label>
                        <input matInput [matDatepicker]="picker2" formControlName="dateCreatedTo">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker #picker2></mat-datepicker>
                        @if(adxFilterForm.controls['dateCreatedTo'].value){
                            <button 
                                id="adx-vcc-end-date-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('dateCreatedTo')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Payment provider</mat-label>
                        <mat-select formControlName="paymentProvider">
                            @if(paymentProviders$ | async; as paymentProviders){
                                @for (provider of paymentProviders; track provider) {
                                    <mat-option [value]="provider?.id">{{provider?.paymentProviderName}}</mat-option>
                                }
                            }
                        </mat-select>
                        @if(adxFilterForm.controls['paymentProvider'].value){
                            <button 
                                id="adx-vcc-payment-provider-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('paymentProvider')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field class="example-full-width">
                        <mat-label>Masked Credit Card Number</mat-label>
                        <input matInput placeholder="Card Number" value="" formControlName="vccLastFourDigits" maxlength="4">
                        @if(adxFilterForm.controls['vccLastFourDigits'].value){
                            <button 
                                id="adx-vcc-digits-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('vccLastFourDigits')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>

            </mat-grid-list>
            <mat-grid-list cols="1" gutterSize="10px" rowHeight="85">
                <mat-grid-tile>
                    <mat-form-field class="example-full-width">
                        <mat-label>Trip Reference</mat-label>
                        <input matInput placeholder="Trip Reference" value="" formControlName="tripReference">
                        @if(adxFilterForm.controls['tripReference'].value){
                            <button 
                                id="adx-vcc-reference-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('tripReference')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Merchant name</mat-label>
                        <mat-select [disableOptionCentering]="true"  formControlName="merchantGuid">
                            @if(merchantGuids){
                                @for (merchant of merchantGuids; track merchant) {
                                    <mat-option [value]="merchant?.id">{{merchant?.name}}</mat-option>
                                }
                            }
                        </mat-select>
                        @if(adxFilterForm.controls['merchantGuid'].value){
                            <button 
                                id="adx-vcc-merchant-guid-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('merchantGuid')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Vcc status</mat-label>
                        <mat-select formControlName="cardStatus">
                            <mat-option value="Card - Active">Card - Active</mat-option>
                            <mat-option value="Card - Deactivated">Card - Deactivated</mat-option>
                        </mat-select>
                        @if(adxFilterForm.controls['cardStatus'].value){
                            <button 
                                id="adx-vcc-vcc-status-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('cardStatus')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <ng-container id="adx-vcc-modal-footer-container" modalPanelFooter>
            <div class="modal-footer">
                <button id="adx-vcc-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearAdxFields()">Clear All
                    <mat-icon id="adx-vcc-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
                </button>
                <button id="adx-vcc-modal-button" mat-raised-button color="primary" type="submit">Apply</button>
            </div>
        </ng-container>
</modal-panel>
</form>
