import {Component, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-adx-invoice-details',
  templateUrl: './adx-invoice-details.component.html'
})

@AutoUnsubscribe()
export class AdxInvoiceDetailsComponent implements OnInit {
  data: any;

  constructor(private route: ActivatedRoute) {
  }

  public ngOnInit(): void{
    this.route.paramMap.subscribe(params => {
      this.data = {
        adxInvoiceId: params.get('id'),
        pageSize: 25
      }
    });
  }
}