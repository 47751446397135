import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TermsConditionsRuleComponent} from '../../../terms-conditions/components/terms-conditions-rule.component';
import {RuleCondition} from '../../../terms-conditions/model/rule-condition';
import {RulePeriodType} from '../../../terms-conditions/model/rule-period-type';
import {TemplateType} from '../../../terms-conditions/model/template-type';
import {Rule} from '../../../terms-conditions/model/rule';
import {RuleModel} from '../../../terms-conditions/model/rule-model';
import {TermsConditionsService} from '../../../terms-conditions/service/terms-conditions.service';
import {Template} from '../../model/template';
import {
  TemplateConfirmationDialogComponent
} from '../../popup/confirmation-dialog/template-confirmation-dialog.component';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-template-layout',
  templateUrl: './template-layout.component.html'
})
export class TemplateLayoutComponent implements OnInit{
  selected: any;
  closed$ = new Subject<void>();
  @ViewChild('container', {read: ViewContainerRef})
  container!: ViewContainerRef;
  private _counter: number = 1;
  components: any = [];
  templateForm!: FormGroup;
  termsContitionsTypes: TemplateType[] = [];
  rule: Rule = {
    id: -1,
    templateId: -1,
    ruleAmt: 100,
    isAmt: false,
    isPrc: true,
    termsConditionsRuleTypeId: 1,
    termsConditionsRuleTypeNumber: 0,
    ruleConditionId: 1
  }
  rulesModels: RuleModel[] = [];
  template: Template = {
    id: -1,
    templateName: 'Template 1',
    templateDescription: '',
    termsConditionsTypeId: 1,
    templateNotes: '',
    rules: []
  };
  public rulePeriodTypes!: RulePeriodType[];
  public ruleConditions!: RuleCondition[];
  public prcSum: number = 100;
  public amtSum: number = 100;
  public prdSum: number = 0;

  constructor(private buildr: FormBuilder, private termsConditionsService: TermsConditionsService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.setFormBuilder();
    this.getTemplateTypes();
  }

  ngAfterViewInit() {
    if (this._counter === 1) {
      this.add();
    }
  }

  setFormBuilder() {
    this.templateForm = this.buildr.group({
      templateType: new FormControl('templateType'),
      templateName: new FormControl('templateName', [Validators.required, Validators.maxLength(250)]),
      templateNotes: new FormControl('templateNotes', [Validators.maxLength(2500)])
    })
    this.termsConditionsService.GetTermsConditionsRuleTypes().subscribe(data => {
      if (data) {
        this.rulePeriodTypes = data;
      }
    })

    this.termsConditionsService.GetTermsConditionsRuleConditionTypes().subscribe(data => {
      if (data) {
        this.ruleConditions = data;
      }
    })
  }

  getTemplateTypes() {
    this.termsConditionsService.GetTermsConditionsTypes().subscribe(data => {
      if (data) {
        this.termsContitionsTypes = data;
      }
    })
  }

  add(): void {
    if (this.rulesModels.length < 5) {
      const viewContainerRef = this.container.createComponent(TermsConditionsRuleComponent);
      let newModel = new RuleModel(this.rule);
      viewContainerRef.instance.index = newModel.internalId = this._counter++;

      viewContainerRef.instance.emitter
        .pipe(takeUntil(this.closed$))
        .subscribe((event: RuleModel) => this.clickHandler(event));

      fromEvent(viewContainerRef.location.nativeElement, 'click')
        .pipe(takeUntil(this.closed$))
        .subscribe((event: any) => this.clickHandler(event));

      this.components.push(viewContainerRef);
      this.rulesModels.push(newModel);
      this.clickHandler(newModel);
    }
  }

  createDescription() {
    this.template.templateDescription = '';
    this.rulesModels.forEach((item) => {
      let ruleDescription =
        [item.rule?.ruleAmt?.toString(),
          item.rule?.isPrc ? '%' : 'Amt',
          item.rule?.termsConditionsRuleTypeNumber.toString(),
          this.rulePeriodTypes == undefined ? 'Days' : this.rulePeriodTypes.find(s => s.ruleTypePeriodId === item.rule?.termsConditionsRuleTypeId)?.termsConditionsRuleTypeName,
          this.ruleConditions == undefined ? 'before booking start date' : this.ruleConditions.find(s => s.ruleConditionId === item.rule?.ruleConditionId)?.ruleConditionName
        ].join(' ');
      this.template.templateDescription = [this.template.templateDescription, ruleDescription, '; '].join('');
    });
  }

  assignRules() {
    this.template.rules = [];
    this.rulesModels.forEach((item) => {
      this.template.rules.push(item.rule!);
    });
  }

  clickHandler(ruleModel: RuleModel) {
    if (ruleModel.internalId) {
      let item = this.rulesModels.find(s => s.internalId == ruleModel.internalId)
      if (item == undefined) {
        this.rulesModels.push(ruleModel);
      } else {
        let indexToUpdate = this.rulesModels.findIndex(item => item.internalId === ruleModel.internalId);
        ruleModel.isvalid = true;
        this.rulesModels[indexToUpdate] = ruleModel;
      }
      this.createDescription();
      this.assignRules();
    }
  }

  reset(): void {
    const components = this.components.filter((component: {
      instance: any;
    }) => component.instance instanceof TermsConditionsRuleComponent);
    let i = components.length;
    while (i--) {
      this.container.remove(i);
      this.components.splice(i, 1);
    }
    this._counter = 1;
    this.rulesModels = [];
    this.add();
    this.createDescription();
    this.assignRules();
    this.prcSum = 100;
    this.amtSum = 100;
    this.templateForm.updateValueAndValidity();
  }

  onTemplateTypeId(selection: any) {
    this.template.termsConditionsTypeId = selection;
    this.reset();
  }

  templateNameChanged(selection: any) {
    this.template.templateName = selection.target.value;
  }

  templateNotesChanged(ev: any) {
    try {
      this.template.templateNotes = ev.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }
  }

  validate() {
    if (this.templateForm.invalid) {
      return false;
    }

    this.prcSum = -1;
    this.prcSum = this.rulesModels.reduce((acc: number, val: any) => {
      if (val.rule.isPrc) return (+acc + +(val.rule.ruleAmt));
      return acc;
    }, 0);

    this.amtSum = -1;
    this.amtSum = this.rulesModels.reduce((acc: number, val: any) => {
      if (val.rule.isAmt) return (+acc + +(val.rule.ruleAmt));
      return acc;
    }, 0);

    this.prdSum = -1;
    this.prdSum = this.rulesModels.reduce((acc: number, val: any) => {
      return (+acc + +(val.rule.termsConditionsRuleTypeNumber));
    }, 0);

    if (Number.isNaN(this.prdSum) || this.prdSum < 0) {
      return false;
    }

    if (Number.isNaN(this.prcSum) || Number.isNaN(this.amtSum) || this.prcSum < 0 || this.amtSum < 0) {
      return false;
    }

    if (this.template.termsConditionsTypeId == 1) {
      if (this.prcSum > 100) {
        return false;
      }
      else if (this.amtSum >= 0 && this.prcSum == 0) {
        this.prcSum = -1;
        return true;
      }
      else if (this.prcSum >= 0 && this.prcSum < 100) {
        return false;
      }
    }
    return true;
  }

  opentemplateConfirmationDialog(): void {
    if (this.validate() && this.rulesModels.find(s => !s.isvalid) == undefined) {
      this.dialog.open(TemplateConfirmationDialogComponent, {
        data: {template: this.template},
      });
    }
  }
}

