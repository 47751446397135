import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subject, delay, filter, takeUntil} from 'rxjs';
import {SideNavMenuItem} from '../../model/nav.model';

@Component({
  selector: 'sidebar-menu',
  templateUrl: './sidebar-menu.component.html'
})
export class SidebarMenuComponent implements OnInit, OnDestroy {
  @Input() isSubMenu: boolean = false;
  @Input() item!: SideNavMenuItem;
  private destroy$ = new Subject<void>();

  constructor(private router: Router) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    if (this.item.subMenu) {
      this.router.events.pipe(
        takeUntil(this.destroy$),
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        delay(0),
      ).subscribe((_: NavigationEnd) => {
        this.item.isExpanded = this.isActive(this.item.path);
      });
    }
  }

  isActive(link: string): boolean {
    return this.router.url.includes(link);
    ;
  }
}
