import {Component, Inject, Optional} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApplyVccData} from "../../model/match-vcc";
import {MatchingVccService} from "../../service/matching-vcc.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-confirmation-dialog-matching-vcc',
    templateUrl: './confirmation-dialog-matching-vcc.component.html'
})
export class ConfirmationDialogMatchingVccComnponent {
    isLoadingSpinner = false;

    resolvedElements = 0;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: { toSave: ApplyVccData[], noSave: ApplyVccData[] },
        @Optional() public confirmationDialogRef: MatDialogRef<ConfirmationDialogMatchingVccComnponent>,
        private _snackBar: MatSnackBar,
        private matchingVccService: MatchingVccService,
    ){
        this.resolvedElements = this.dialogData.toSave.filter((element)=> (element.isResolved && !element.adjustedCommission)).length;
    }

    okClick(){
        this.isLoadingSpinner = true;
        if (this.dialogData && this.dialogData.toSave.length) {
            this.matchingVccService.applyUpdate(this.dialogData?.toSave).subscribe(()=>{
                this._snackBar.open(
                    `Suggested commission applied to ${this.dialogData.toSave.length} ${this.dialogData?.toSave.length > 1 ? 'elements' : 'element'}`, 'Dismiss', {
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                });

                this.confirmationDialogRef.close(true);
            });
        } else {
            this.confirmationDialogRef.close();
        }
    }

    onNoClick(){
        this.confirmationDialogRef.close();
    }
}