
<ng-container id="summary-list-by-service-type-main-container" *ngIf="isTableView">
  <div id="summary-list-by-service-type-table-feed" class="table-feed" *ngIf="!hasSummaries">
    <div id="summary-list-by-service-type-table-feed-text" class="table-feed-text" style="margin-top: 2%;">
      There are no records to display.
    </div>
  </div>
  <mat-table id="summary-list-by-service-type-mat-table" [dataSource]="dataSource" matSort>
    <ng-container id="summary-list-by-service-type-no-data-row" *matNoDataRow>
      <mat-cell id="summary-list-by-service-type-empty-data-cell" [attr.colspan]="displayedColumns.length"> Empty data </mat-cell>
    </ng-container>
    <ng-container id="summary-list-by-service-type-name-column" matColumnDef="name">
      <mat-header-cell id="summary-list-by-service-type-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell id="summary-list-by-service-type-name-cell" *matCellDef="let row"> {{row.name}} </mat-cell>
    </ng-container>
    <ng-container id="summary-list-by-service-type-serviceType-column" matColumnDef="serviceType">
      <mat-header-cell id="summary-list-by-service-type-serviceType-header" class="mat-header-cell" *matHeaderCellDef>Service Type</mat-header-cell>
      <mat-cell id="summary-list-by-service-type-serviceType-cell" *matCellDef="let row"> {{row.serviceType}} </mat-cell>
    </ng-container>
    <ng-container id="summary-list-by-service-type-total-tickets-column" matColumnDef="totalTickets">
      <mat-header-cell id="summary-list-by-service-type-total-tickets-header" class="mat-header-cell" *matHeaderCellDef>Total tickets</mat-header-cell>
      <mat-cell id="summary-list-by-service-type-total-tickets-cell" *matCellDef="let row"> {{row.totalTickets}} </mat-cell>
    </ng-container>
    <mat-header-row id="summary-list-by-service-type-header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row id="summary-list-by-service-type-row" *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
</ng-container>
<ng-container id="summary-list-by-service-type-chart-container" *ngIf="isChartView">
  <chart id="summary-list-by-service-type-chart" *ngIf="summaryBarChartData" [dataSet]="summaryBarChartData" [options]="summaryBarChartOptions"
    [chartType]="'bar'"></chart>
</ng-container>
<mat-paginator
  id="summary-list-by-service-type-paginator"
  *ngIf="isTableView"
  [length]="totalPages"
  [hidePageSize]="true"
  [pageSize]="pageSize"
  (page)="PageChanged($event)">
</mat-paginator>
