import {Component, Input, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {FinanceReportsService} from '../../service/finance-reports.service';
import {ChartOptions} from 'chart.js';
import {ChartOptionsService} from 'src/app/shared/components/chart/chart-options.service';
import {ChartDataSet} from 'src/app/shared/components/chart/chart.component';
import {Pagination} from 'src/infrastructure/pagination/model/pagination';
import {AzureAuthService} from 'src/infrastructure/services/azure-auth.service';
import {FilterService} from 'src/app/shared/service/filter/filter.service';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {TripProfitability} from '../../model/trip-profitability';
import {TripProfitabilityResponse} from '../../model/trip-profitability-response';
import {FinanceReportFilterResult} from 'src/app/shared/model/finance-report-filter-result';
import * as moment from 'moment';
import {ExportGridService} from '../../../shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-trip-profitability-with-agency',
    templateUrl: './trip-profitability-with-agency.component.html'
  })

@AutoUnsubscribe()
export class TripProfitabilityWithAgencyComponent {
  @Input() filter!: FinanceReportFilterResult;
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  public pagination: Pagination = {} as Pagination;

  @ViewChild(MatSort, {static: false}) public sort!: MatSort;
  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSourceDirector.sort = this.sort;
  }

  tripProfitabilityPieChartOptions: ChartOptions;
  tripProfitabilityPieChartData!: ChartDataSet;
  public isUserHasDirectorRole: boolean = false;
  public tripProfitabilityDirectorReportFilter: TripProfitability = {} as TripProfitability;
  public dataSourceDirector: MatTableDataSource<TripProfitability> = new MatTableDataSource<TripProfitability>([]);
  displayedColumnsDirector = [
    'tripReference',
    'dateCreated',
    'advisorName',
    'agentSplit',
    'agencySplit',
    'marginPercentage',
    'adxBookingNumber'
  ];
  tripProfitabilityDirectorReportData: TripProfitability[] = [];
  constructor(
    private azureAuthService: AzureAuthService,
    private financeReportsService: FinanceReportsService,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe,
    private chartOptions: ChartOptionsService,
    private filters: FilterService
    ) {
      this.isUserHasDirectorRole = this.azureAuthService.checkUserAzureTokenHasDirectorRole();
      this.tripProfitabilityPieChartOptions = this.chartOptions.getChartOptions('pie', {
        responsive: true,
        plugins: {
          datalabels:{
            display: false
          },
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Number Of Trips'
          },
        },
        elements: {
          arc: {
            borderWidth: 1
          },
        },
        scales: {
          x: {
            display: false
          },
          y: {
            display: false
          },
        },
      });
    }

  ngOnInit(): void {
    if(this.isUserHasDirectorRole){
      this.setDefaultFilterValues();
      this.filters.getFinanceReportFilter().subscribe((filter: FinanceReportFilterResult) => {
        this.tripProfitabilityDirectorReportFilter.agentName = filter.advisorName;
        this.tripProfitabilityDirectorReportFilter.tripReference = filter.tripReference;
        
        this.tripProfitabilityDirectorReportFilter.bookingStartFrom = filter.bookingStartFrom ? moment(filter.bookingStartFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
        this.tripProfitabilityDirectorReportFilter.bookingStartTo = filter.bookingStartTo ? moment(filter.bookingStartTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
  
        this.tripProfitabilityDirectorReportFilter.bookingEndFrom = filter.bookingEndFrom ? moment(filter.bookingEndFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
        this.tripProfitabilityDirectorReportFilter.bookingEndTo = filter.bookingEndTo ? moment(filter.bookingEndTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
        
        this.tripProfitabilityDirectorReportFilter.dateCreatedFrom = filter.dateCreatedFrom ? moment(filter.dateCreatedFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
        this.tripProfitabilityDirectorReportFilter.dateCreatedTo = filter.dateCreatedTo ? moment(filter.dateCreatedTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
  
        this.getTripProfitabilityReport();
      });
    }
  }

  private setDefaultFilterValues(): void {
    this.tripProfitabilityDirectorReportFilter.pageSize = 25;
    this.tripProfitabilityDirectorReportFilter.orderKey = 'Desc';
    this.tripProfitabilityDirectorReportFilter.propertyName = 'dateCreated';
  }

  public ngAfterViewInit(): void {
    if(this.isUserHasDirectorRole){
      this.dataSourceDirector.sort = this.sort;
    }
  }

  getTripProfitabilityReport() {
    if(this.isUserHasDirectorRole){
      this.financeReportsService.getTripProfitabilityReport(this.tripProfitabilityDirectorReportFilter)
      .subscribe((data: TripProfitabilityResponse) => {
        if (data) {
          this.pagination = data.pagination;
          this.tripProfitabilityDirectorReportData = data.items;
          this.tripProfitabilityDirectorReportFilter.pageNumber = data.pagination.pageNumber;
          this.dataSourceDirector = new MatTableDataSource(this.tripProfitabilityDirectorReportData);
          const below25MarginPercentNumbrOfTripsCount = this.tripProfitabilityDirectorReportData.filter(i => i.marginPercentage <= 25).length;
          const above25MarginPercentNumbrOfTripsCount = this.tripProfitabilityDirectorReportData.filter(i => i.marginPercentage > 25).length;
          this.tripProfitabilityPieChartData = {
            labels: [
              'Below 25% Margin Percentage',
              'Above 25% Margin Percentage'
            ],
            datasets: [{
              label: 'Trip Profitability Report',
              data: [below25MarginPercentNumbrOfTripsCount, above25MarginPercentNumbrOfTripsCount],
              backgroundColor: [
                '#708090',
                '#a2798f'
              ],
              hoverOffset: 0,
              spacing: 0
            }]
          };
        }
      });
    }
  }

  pageChanged(event: PageEvent): void  {
    if(this.isUserHasDirectorRole){
      this.tripProfitabilityDirectorReportFilter.pageNumber = event.pageIndex + 1;
    }
    this.getTripProfitabilityReport();
  }

  handleSortChange(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }
    if(this.isUserHasDirectorRole){
      this.tripProfitabilityDirectorReportFilter.propertyName = this.getMappedBookingTblPropertyName(sort.active);
      this.tripProfitabilityDirectorReportFilter.orderKey = sort.direction.toUpperCase();
    }
    this.getTripProfitabilityReport();
  }

  downloadGridToFile(): void {
    if(this.isUserHasDirectorRole){
      const downloadSubs$ = this.financeReportsService.getTripProfitabilityReport(this.tripProfitabilityDirectorReportFilter)
        .subscribe((data: TripProfitabilityResponse) => {
          this.exportGridService.getByDataSource(
            'finance-reports/trip-profitability',
            'trip-profitability-with-agency',
            {
              ...this.tripProfitabilityDirectorReportFilter,
              pageSize: data?.pagination?.totalItemCount || 100,
            },
            (data)=>{
              const record = data as TripProfitability;
              return {
                'Trip Reference': record?.tripReference,
                'Date Created': this.datePipe.transform(record?.dateCreated, 'M/d/yyyy'),
                'Advisor Name': record?.advisorName,
                'Agent Split': record?.agentSplit,
                'Agency Split': record?.agencySplit,
                'Margin Percentage': record?.marginPercentage,
                'ADX Booking Number': record?.adxBookingNumber
              }
            }
          );
          downloadSubs$?.unsubscribe();
        });
    }
  }

  getMappedBookingTblPropertyName(propertyName: string): string {
    switch (propertyName) {
      case 'tripReference':
        return propertyName;
      case 'dateCreated':
        return propertyName;
      case 'advisorName':
        return 'agent.agentName';
      case 'agentSplit':
        return 'agent.splitRate';
      case 'adxBookingNumber':
        return 'externalBookingNumber';
      default:
        return '';
    }
  }
}
