<div id="modal-panel-header" class="modal-panel__header">
  <ng-container id="modal-panel-header-container" *ngIf="panelHeader; else defaultHeader">
    <ng-content select="[modalPanelHeader]"></ng-content>
  </ng-container>
  <ng-template id="modal-panel-default-header" #defaultHeader>
    <div id="modal-panel-title" class="modal-panel__title mat-headline-6">
      {{ title }}
    </div>
    <div id="modal-panel-actions" class="modal-panel__actions ms-16">
      <button id="modal-panel-close-button" class="modal-panel__close" mat-icon-button disableRipple="true" type="button" (click)="handleClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </ng-template>
</div>
<mat-divider id="modal-panel-divider-1"></mat-divider>
<div id="modal-panel-scroll-feed-up" class="modal-panel__scroll-feed modal-panel__scroll-feed--up"></div>
<div id="modal-panel-body" class="modal-panel__body">
  <div id="modal-panel-body-inner" class="modal-panel__body-inner">
    <ng-content></ng-content>
  </div>
</div>
<div id="modal-panel-scroll-feed-down" class="modal-panel__scroll-feed modal-panel__scroll-feed--down"></div>
<ng-container id="modal-panel-footer-container" *ngIf="panelFooter">
  <mat-divider id="modal-panel-divider-2"></mat-divider>
  <div id="modal-panel-footer" class="modal-panel__footer">
    <ng-content select="[modalPanelFooter]"></ng-content>
  </div>
</ng-container>
