import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DateService {

  constructor(private datePipe: DatePipe) {
  }

  subtractDays(date: Date, days: number) {
    const customFilterDate = new Date(date);
    customFilterDate.setDate(customFilterDate.getDate() - days);
    return customFilterDate;
  }

  getDateRangeForWeek(weekNumber: number): string {
    const currentYear = new Date().getFullYear();
    const firstDayOfYear = new Date(currentYear, 0, 1);
    const firstMondayOfYear = new Date(firstDayOfYear);
    firstMondayOfYear.setDate(
      firstDayOfYear.getDate() + ((1 - firstDayOfYear.getDay() + 7) % 7)
    );

    const startDate = new Date(firstMondayOfYear);
    startDate.setDate(firstMondayOfYear.getDate() + (weekNumber - 1) * 7);

    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    const formattedStartDate = this.datePipe.transform(startDate, 'MMM d');
    const formattedEndDate = this.datePipe.transform(endDate, 'MMM d');

    return `${formattedStartDate} - ${formattedEndDate}`;
  }
}
