import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-booking-udid-info',
  templateUrl: './booking-udid-info.component.html'
})
export class BookingUdidInfoComponent {
  bookingNo!: string;
  bookingUdids: any;
  constructor(
    public dialogRef: MatDialogRef<BookingUdidInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.bookingNo = this.data.bookingNo;
    this.bookingUdids = this.data.bookingUdids;
  }
}
