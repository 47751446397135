<div class="template-form" *ngIf="!(loading$ | async)" id="vendor-template-addedit-template-form">
  <form [formGroup]="vendorTemplateForm" id="vendor-template-addedit-form">
      <div class="title" id="vendor-template-addedit-title-div">
          <label id="vendor-template-addedit-title-label">{{this.title}}</label>
      </div>
      <div id="vendor-template-addedit-content-div">
          <mat-spinner *ngIf="isPageLoadingSpinnerVisible" diameter="50" mode="indeterminate" id="vendor-template-addedit-spinner"></mat-spinner>
          <mat-form-field id="vendor-template-addedit-tc-type-form-field">
              <mat-label id="vendor-template-addedit-tc-type-label">T&C type</mat-label>
              <input matInput disabled value="{{_createVendorTemplate.termsConditionsType}}" id="vendor-template-addedit-tc-type-input">
          </mat-form-field>
          <mat-form-field id="vendor-template-addedit-template-form-field">
              <mat-label id="vendor-template-addedit-template-label">Select one template</mat-label>
              <mat-select name="template" [(value)]="this.vendorTemplateSelected" [formControl]="termConditionsTemplateValidator"
                  [errorStateMatcher]="vendorTemplateErrorStateMatcher" matInput [(ngModel)]="_createVendorTemplate.templateId" required
                  (selectionChange)="onSelectTermConditionTemplate($event.value)" id="vendor-template-addedit-template-select">
                  <mat-option
                  id="vendor-template-addedit-template-option-{{i}}"
                  *ngFor="let termConditionTemplate of this.termsConditionsTemplates; let i = index"
                  [value]="termConditionTemplate.id">
                  {{termConditionTemplate.templateName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="termConditionsTemplateValidator.hasError('required')" id="vendor-template-addedit-template-error">Template is required</mat-error>
            </mat-form-field>
          <mat-form-field id="vendor-template-addedit-description-form-field">
              <mat-label id="vendor-template-addedit-description-label">Description</mat-label>
              <input matInput disabled value="{{_createVendorTemplate.description}}" id="vendor-template-addedit-description-input">
          </mat-form-field>
          <mat-form-field class="example-full-width" id="vendor-template-addedit-notes-form-field">
              <mat-label id="vendor-template-addedit-notes-label">Notes</mat-label>
              <textarea matInput value="{{_createVendorTemplate.vendorTemplateNotes}}" (input)="onNotesChange($event)"
                  cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" #message maxlength="1000" placeholder="Type here your note." id="vendor-template-addedit-notes-textarea"></textarea>
              <mat-hint align="end" id="vendor-template-addedit-notes-hint">{{message.value.length}} / 1000</mat-hint>
              <mat-error *ngIf="notesValidator.hasError('maxlength')" id="vendor-template-addedit-notes-error">Notes cannot exceed 1000 characters</mat-error>
          </mat-form-field>
      </div>
      <div id="vendor-template-addedit-submit-div">
        <button (click)="openVendorConfirmationDialog()" mat-raised-button color="primary" class="pull-right" id="vendor-template-addedit-submit-button">Submit</button>
    </div>
    </form>
</div>
