import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup,} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {VendorFilterResult} from '../../model/vendor-filter-result';

@AutoUnsubscribe()
@Component({
  selector: 'vendors-filter-modal',
  templateUrl: './vendors-filter-modal.component.html'
})
export class VendorsFilterModalComponent implements OnInit {
  @Input() config!: VendorFilterResult;

  constructor(
    @Optional() public dialogRef: MatDialogRef<VendorsFilterModalComponent>,
  ) {}

  form: FormGroup = new FormGroup({
    vendorName: new FormControl(null),
    vendorCity: new FormControl(null),
    vendorCountry: new FormControl(null)
  });

  handleVendorFiltersFormCloseEvent() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  ngOnInit() {
    const {vendorName, vendorCity, vendorCountry} = this.config ?? {};
      this.form.patchValue({
        vendorName, vendorCity, vendorCountry
      });
  }

  applyVendorFilters() {
    this.config.vendorName = this.config.vendorName?.trim();
    this.config.vendorCity = this.config.vendorCity?.trim();
    this.config.vendorCountry = this.config.vendorCountry?.trim();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }
    else if (this.dialogRef) {
      this.dialogRef.close(this.config);
    }
  }

  public clearVendorFilters(propertyName: string): void {
    if (propertyName === 'all') {
      this.config = {} as VendorFilterResult;
    }else{
      let temp: any = this.config;
      temp[propertyName] = undefined;
      this.config = temp;
    }
  }
}
