<div id="copy-adxbooking-dialog-confirmpopup" class="confirmpopup">
  <div id="copy-adxbooking-dialog-dialog-title" mat-dialog-title>
    <label id="copy-adxbooking-dialog-title-label" class="title">Copy Booking: Confirmation</label>
  </div>
  <hr id="copy-adxbooking-dialog-first-hr">
  <div id="copy-adxbooking-dialog-dialog-content" mat-dialog-content>
    <p id="copy-adxbooking-dialog-content-p">Are you sure you want to copy Booking number '{{this.adxBookingId}}'?</p>
  </div>
  <hr id="copy-adxbooking-dialog-second-hr">
  <div id="copy-adxbooking-dialog-dialog-actions" mat-dialog-actions style="float: right;">
    <button
      id="copy-adxbooking-dialog-ok-button"
      type="submit" mat-raised-button
      (click)="okClick()"
      color="primary"
      cdkFocusInitial matTooltip="Copy Booking"
      aria-label="Copy Booking">
    <mat-spinner
      id="copy-adxbooking-dialog-spinner"
      color="accent"
      *ngIf="isLoadingSpinner"
      diameter="30">
    </mat-spinner>
      <span id="copy-adxbooking-dialog-ok-span" *ngIf="!isLoadingSpinner">Ok</span>
    </button>
    <button
      id="copy-adxbooking-dialog-no-button"
      mat-raised-button
      (click)="onNoClick()"
      color="warn">
      No Thanks
    </button>
  </div>
</div>
