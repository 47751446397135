import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html'
})
export class VersionComponent {
  public readonly environment = environment;
  public readonly showVersion: boolean;
  private readonly testEnvironments = ['Local', 'Development', 'QA', 'UAT'];

  constructor() {
    this.showVersion = this.IsTestEnvironment();
  }

  private IsTestEnvironment(): boolean {
    return this.testEnvironments.includes(environment.environmentName);
  }
}
