import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {CreateVendor} from 'src/app/vendor/model/create-vendor';
import {VendorService} from 'src/app/vendor/service/vendor.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmationDialogComponent} from '../../../popup/confirmation-dialog/confirmation-dialog.component';
import {Country} from 'src/app/vendor/model/country';
import {ActivatedRoute} from '@angular/router';
import {AutoUnsubscribe} from '../../../../../infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-addedit-vendor',
  templateUrl: './addedit-vendor.component.html'
})
export class AddeditVendorComponent implements OnInit {
  @Input() public data: any;
  countries: Country[] = [];
  vendorContactPhoneValidator!: FormControl;
  vendorContactEmailValidator!: FormControl;
  vendorNameValidator!: FormControl;
  vendorZipCodeValidator!: FormControl;
  vendorCountryValidator!: FormControl;
  vendorFullAddressValidator!: FormControl;
  vendorCityValidator!: FormControl;
  vendorProvinceValidator!: FormControl;
  vendorErrorStateMatcher!: ErrorStateMatcher;
  vendorCountrySelected: number = 1;
  isPageLoadingSpinnerVisible: boolean = false;
  isInputDisabled: boolean = false;
  transactionBookingId: string | null = null;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  public _vendor: CreateVendor = {
    vendorName: '',
    vendorFullAddress: '',
    vendorCity: '',
    vendorProvince: '',
    vendorCountry: '',
    vendorZipCode: '',
    vendorContactPerson: '',
    vendorContactPhone: '',
    vendorContactEmail: '',
    vendorWebSite: ''
  };

  constructor(
    public dialog: MatDialog,
    private buildr: FormBuilder,
    private vendorService: VendorService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.transactionBookingId = this.route.snapshot.paramMap.get('id');
    this.setFormControls();
    this.isPageLoadingSpinnerVisible = false;
    this.getCountries();
    if (this.data.vendorId != undefined && this.data.vendorId != -1) {
      this.setVendorDataForEditOperation(this.data.vendorId);
    }
  }

  setFormControls() {
    if (this.data.vendorId != undefined && this.data.vendorId != -1) {
      this.isInputDisabled = true;
    }
    this.vendorNameValidator = new FormControl(
      {value: '', disabled: this.isInputDisabled}, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(250),
        Validators.pattern(/^[a-zA-Z0-9\s-]+$/)
      ]);
    this.vendorContactPhoneValidator = new FormControl('', [
      Validators.maxLength(50),
      Validators.pattern(/^[\d()-]*(?: [\d()-]+[\d()-]*)?$/)
    ]);
    this.vendorZipCodeValidator = new FormControl(
      {value: '', disabled: this.isInputDisabled}, [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^[a-zA-Z0-9]{1,10}$/)
      ]);
    this.vendorCountryValidator = new FormControl(
      {value: '', disabled: this.isInputDisabled}, [
        Validators.required
      ]);
    this.vendorFullAddressValidator = new FormControl({value: '', disabled: this.isInputDisabled});
    this.vendorCityValidator = new FormControl({value: '', disabled: this.isInputDisabled});
    this.vendorProvinceValidator = new FormControl({value: '', disabled: this.isInputDisabled});

    this.vendorContactEmailValidator = new FormControl('', [Validators.email]);
    this.vendorErrorStateMatcher = new VendorErrorStateMatcher();

  }

  setVendorDataForEditOperation(request: any) {
    if (request) {
      this.vendorService.getVendorById(request)
        .subscribe({
          next: (response) => {
            this._vendor.vendorName = response.vendorName;
            this._vendor.vendorFullAddress = response.addressLine1;
            this._vendor.vendorCity = response.vendorCity;
            this._vendor.vendorProvince = response.vendorProvince;
            this._vendor.vendorCountry = response.vendorCountry;
            this.vendorCountrySelected = parseInt(response.vendorCountryId);
            this._vendor.vendorZipCode = response.vendorZipCode;
            this._vendor.vendorContactPerson = response.vendorContactPerson;
            this._vendor.vendorContactPhone = response.vendorContactPhone;
            this._vendor.vendorContactEmail = response.vendorContactEmail;
            this._vendor.vendorWebSite = response.vendorWebSite;
            this.isPageLoadingSpinnerVisible = false;
          },
          error: () => {
            this.isPageLoadingSpinnerVisible = false;
          }
        });
    } else {
      this._snackBar.open('This Booking has no vendor information \n', 'Dismiss', {
        panelClass: ['message-position', 'app-notification-error']
      });
    }
  }

  getCountries() {
    this.vendorService.getCountries()
      .subscribe((data: any) => {
        if (data) {
          data.forEach((myObject: any) => {
            const item = new Country(myObject);
            return this.countries.push(item);
          });
        }
      });
  }

  openVendorConfirmationDialog(): void {
    var addvendorValidation = this.vendorNameValidator.value !== '' && this.vendorNameValidator.value.length >= 3 && this.vendorZipCodeValidator.value !== '' && this.vendorCountryValidator.value !== '';
    if ((this.isInputDisabled == false && addvendorValidation) || this.isInputDisabled == true) {
      this._vendor.transactionBookingId = Number(this.transactionBookingId);
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          vendor: this._vendor,
          vendorId: this.data.vendorId,
        },
      });
      dialogRef.afterClosed().subscribe(() => {});
    }
  }

  myform = this.buildr.group({
    VendorName: this.buildr.control(''),
    vendorFullAddress: this.buildr.control(''),
    VendorCity: this.buildr.control(''),
    VendorProvince: this.buildr.control(''),
    VendorZipCode: this.buildr.control(''),
    VendorCountry: this.buildr.control(''),
    VendorContactPerson: this.buildr.control(''),
    VendorContactEmail: this.buildr.control(''),
    VendorContactPhone: this.buildr.control(''),
    vendorWebSite: this.buildr.control('')
  });
}

export class VendorErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
