<modal-panel id="adx-invoice-line-item-filter-modal-panel" class="adx-invoice-line-item-filter-modal-panel-cls filter-panel" [title]="'Filter'" (close)="handleAdxInvoiceLineItemFiltersFormCloseEvent()">
    <div id="adx-invoice-line-item-filter-modal-div-1" class="filter" [formGroup]="form">
      <div id="adx-invoice-line-item-filter-modal-div-2" class="filter__fields">
        <mat-grid-list cols="1" gutterSize="10px" rowHeight="80">
            <mat-grid-tile id="adx-invoice-line-item-filter-quote-id-grid-tile">
              <mat-form-field id="adx-invoice-line-item-filter-quote-id-form-field">
                <mat-label id="adx-invoice-line-item-filter-quote-id-label">Quote Id</mat-label>
                <input
                  matInput
                  [(ngModel)]="config.quoteId"
                  formControlName="quoteId"
                  id="adx-invoice-line-item-filter-quote-id-input">
                  @if(form.controls['quoteId'].value){
                    <button 
                        id="adx-invoice-line-item-filter-quote-id-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearFilters('quoteId')">
                            <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile id="adx-invoice-line-item-filter-invoice-record-locator-grid-tile">
              <mat-form-field id="adx-invoice-line-item-filter-form-field">
                <mat-label id="adx-invoice-line-item-filter-invoice-record-locator-label">Invoice Record Locator</mat-label>
                <input
                  matInput
                  [(ngModel)]="config.invoiceRecordLocator"
                  formControlName="invoiceRecordLocator"
                  id="adx-invoice-line-item-filter-invoice-record-locator-input">
                  @if(form.controls['invoiceRecordLocator'].value){
                    <button 
                        id="adx-invoice-line-item-filter-invoice-record-locator-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearFilters('invoiceRecordLocator')">
                            <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile id="adx-invoice-line-item-filter-invoice-type-grid-tile">
              <mat-form-field id="adx-invoice-line-item-filter-invoice-type-form-field">
                <mat-label id="adx-invoice-line-item-filter-invoice-type-label">Invoice Type</mat-label>
                <input
                  matInput
                  [(ngModel)]="config.invoiceType"
                  formControlName="invoiceType"
                  id="adx-invoice-line-item-filter-invoice-type-input">
                  @if(form.controls['invoiceType'].value){
                    <button 
                        id="adx-invoice-line-item-filter-invoice-type-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearFilters('invoiceType')">
                            <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="80">
            <mat-grid-tile>
                <mat-form-field>
                  <mat-label id="adx-invoice-line-item-filter-issue-start-date-field-title">Issue Start Date</mat-label>
                  <input
                    id="adx-invoice-line-item-filter-issue-start-date-input-field"
                    matInput
                    [matDatepicker]="picker1" readonly
                    [(ngModel)]="config.issueStartDate"
                    formControlName="issueStartDate">
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker #picker1 id="adx-invoice-line-item-filter-issue-start-date-datepicker"></mat-datepicker>
                  @if(form.controls['issueStartDate'].value){
                    <button 
                        id="adx-invoice-line-item-filter-issue-start-date-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearFilters('issueStartDate')">
                            <mat-icon>close</mat-icon>
                    </button>
                  } @else {
                      <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                  }
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="adx-invoice-line-item-filter-issue-end-date-field-title">Issue End Date</mat-label>
                <input
                  id="adx-invoice-line-item-filter-issue-end-date-input-field"
                  matInput
                  [matDatepicker]="picker2" readonly
                  [(ngModel)]="config.issueEndDate"
                  formControlName="issueEndDate">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #picker2 id="adx-invoice-line-item-issue-end-date-datepicker"></mat-datepicker>
                @if(form.controls['issueEndDate'].value){
                  <button 
                      id="adx-invoice-line-item-filter-issue-end-date-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearFilters('issueEndDate')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>


            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="adx-invoice-line-item-filter-created-start-date-field-title">Created Start Date</mat-label>
                <input
                  id="adx-invoice-line-item-filter-created-start-date-input-field"
                  matInput
                  [matDatepicker]="picker3" readonly
                  [(ngModel)]="config.createdStartDate"
                  formControlName="createdStartDate">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #picker3 id="adx-invoice-line-item-filter-created-start-date-datepicker"></mat-datepicker>
                @if(form.controls['createdStartDate'].value){
                  <button 
                      id="adx-invoice-line-item-filter-created-start-date-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearFilters('createdStartDate')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="adx-invoice-line-item-filter-created-end-date-field-title">Created End Date</mat-label>
                <input
                  id="adx-invoice-line-item-filter-created-end-date-input-field"
                  matInput
                  [matDatepicker]="picker4" readonly
                  [(ngModel)]="config.createdEndDate"
                  formControlName="createdEndDate">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #picker4 id="adx-invoice-line-item-created-end-date-datepicker"></mat-datepicker>
                @if(form.controls['createdEndDate'].value){
                  <button 
                      id="adx-invoice-line-item-filter-created-end-date-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearFilters('createdEndDate')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="1" gutterSize="20px" rowHeight="80">
          <mat-grid-tile id="adx-invoice-line-item-filter-client-profile-no-grid-tile">
            <mat-form-field id="adx-invoice-line-item-filter-client-profile-no-form-field">
                <mat-label id="adx-invoice-line-item-filter-client-profile-no-label">Client Profile No</mat-label>
                <input
                matInput
                [(ngModel)]="config.clientProfileNo"
                formControlName="clientProfileNo"
                id="adx-invoice-line-item-filter-client-profile-no-input">
                @if(form.controls['clientProfileNo'].value){
                  <button 
                      id="adx-invoice-line-item-filter-client-profile-no-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearFilters('clientProfileNo')">
                          <mat-icon>close</mat-icon>
                  </button>
                }
            </mat-form-field>
        </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
    <ng-container id="adx-invoice-line-item-filter-modal-footer-container" modalPanelFooter>
      <div class="modal-footer">
        <button id="adx-invoice-line-item-filter-modal-button-clear-all" mat-stroked-button color="primary" (click)="clearFilters('all')">Clear All
          <mat-icon id="adx-invoice-line-item-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
        </button>
        <button id="adx-invoice-line-item-filter-modal-button" mat-raised-button color="primary" (click)="applyAdxInvoiceLineItemFilters()">Apply</button>
      </div>
    </ng-container>
  </modal-panel>
  