import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {BookingTemplateService} from '../../../service/booking-template.service';
import {AutoUnsubscribe} from '../../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {UpdateBookingTemplate} from 'src/app/booking-template/model/update-booking-template';

@AutoUnsubscribe()
@Component({
  selector: 'app-confirmation-dialog-booking-template',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogBookingTemplateComponent {
  isLoadingSpinner: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  public updateBookingTemplate: UpdateBookingTemplate = {
    id: 0,
    transactionBookingId: 0,
    templateId: 0,
    termsConditionsTypeId: 0,
    bookingTemplateNotes: '',
  };
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogBookingTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bookingTemplateService: BookingTemplateService,
    private _snackBar: MatSnackBar
  ) {
    this.updateBookingTemplate.transactionBookingId = this.data.bookingTemplate.transactionBookingId;
    this.updateBookingTemplate.templateId = this.data.bookingTemplate.templateId;
    this.updateBookingTemplate.termsConditionsTypeId = this.data.bookingTemplate.termsConditionsTypeId;
    this.updateBookingTemplate.bookingTemplateNotes = this.data.bookingTemplate.bookingTemplateNotes;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  okClick(): void {
    this.isLoadingSpinner = true;
    if (!this.data.bookingTemplate.bookingTemplateExists) {
      this.CreateBookingTemplate();
      this.data.bookingTemplate.bookingTemplateExists = true;
    } else {
      this.updateBookingTemplate.id = this.data.bookingTemplate.id;
      this.UpdateBookingTemplate();
    }
  }

  public CreateBookingTemplate() {
    this.bookingTemplateService.createBookingTemplate(this.data.bookingTemplate)
      .subscribe({
        next: (_) => {
          this.data.bookingTemplate.id = _.bookingTemplateId;
          if(this.data.bookingTemplate.bookingTemplateNotes !== '')
            this.data.bookingTemplate.bookingTemplateNoteExists = true;
          this.isLoadingSpinner = false;
          this.dialogRef.close();
          this._snackBar.open('Successfully booking template information updated!!', 'Dismiss', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['message-position', 'app-notification-success']
          });
          this.data.bookingTemplateAddEditDialogRef.close();
        },
        error: () => {
          this.isLoadingSpinner = false;
          this.dialogRef.close();
          this.data.bookingTemplateAddEditDialogRef.close();
        }
      });
  }

  public UpdateBookingTemplate() {
    if(!this.data.bookingTemplate.bookingTemplateNoteExists){
      this.bookingTemplateService.updateBookingTemplateService(this.updateBookingTemplate)
      .subscribe({
        next: () => {
          if(this.data.bookingTemplate.bookingTemplateNotes !== '')
            this.data.bookingTemplate.bookingTemplateNoteExists = true;
          this.isLoadingSpinner = false;
          this.dialogRef.close();
          this._snackBar.open('Successfully booking template information updated!!', 'Dismiss', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['message-position', 'app-notification-success']
          });
          this.data.bookingTemplateAddEditDialogRef.close();
        },
        error: () => {
          this.isLoadingSpinner = false;
          this.dialogRef.close();
          this.data.bookingTemplateAddEditDialogRef.close();
        }
      });
    }
    else{
      this.bookingTemplateService.deleteBookingTemplate(this.data.bookingTemplate)
        .subscribe({
          next: () => {
            this.CreateBookingTemplate();
          },
          error: () => {
            this.isLoadingSpinner = false;
            this.dialogRef.close();
            this.data.bookingTemplateAddEditDialogRef.close();
          }
        });
    }
  }
}
