<form id="template-layout-form" [formGroup]="templateForm" class="template-form">
  <div class="col-md-9">
    <mat-label id="template-layout-title-label" class="title center">Terms and Conditions Template</mat-label>
  </div>
  <div>
    <div class="row">
      <div class="col-md-9">
        <mat-error id="template-layout-form-error" *ngIf="templateForm.invalid">Form is invalid</mat-error>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <mat-label id="template-layout-template-type-label">Template Type:</mat-label>
        <mat-form-field id="template-layout-template-type-field" class="template-input">
          <mat-select id="template-layout-template-type-select" [(ngModel)]="template.termsConditionsTypeId" formControlName="templateType" (selectionChange)="onTemplateTypeId($event.value)">
            <mat-option id="template-layout-template-type-option-{{i}}" *ngFor="let item of termsContitionsTypes; let i = index" [value]="item.termConditionId">
              {{item.termsConditionsTypeName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <mat-label id="template-layout-template-name-label">Template Name:</mat-label>
        <mat-form-field id="template-layout-template-name-field" class="template-input">
          <input id="template-layout-template-name-input" matInput [(ngModel)]="template.templateName" #templateNameInput (change)="templateNameChanged($event)" placeholder="Template 1" formControlName="templateName">
          <mat-error id="template-layout-template-name-required-error" *ngIf="this.templateForm.controls['templateName'].hasError('required')">Template Name is required.</mat-error>
          <mat-error id="template-layout-template-name-max-length-error" *ngIf="this.templateForm.controls['templateName'].hasError('maxLength')">Maximum number is 250.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <mat-label id="template-layout-description-label">Description:</mat-label>
        <mat-label id="template-layout-template-description-label">{{template.templateDescription}}</mat-label>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-9">
        <mat-label id="template-layout-template-notes-label">Template Notes:</mat-label>
        <mat-form-field id="template-layout-template-notes-field" class="template-input">
          <textarea id="template-layout-template-notes-textarea" matInput name="templateNotes" cols="35" rows="5" placeholder="Notes" [(ngModel)]="template.templateNotes" (change)="templateNotesChanged($event)" maxlength="1000"></textarea>
          <mat-error id="template-layout-template-notes-max-length-error" *ngIf="this.templateForm.controls['templateNotes'].hasError('maxLength')">Maximum number is 1000.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <mat-label id="template-layout-rules-label">Rules:</mat-label><br />
        <button id="template-layout-reset-button" mat-button color="default" (click)="reset()">
          <mat-icon id="template-layout-reset-icon">replay</mat-icon>
          Reset
        </button>
        <div class="row">
          <ng-container id="template-layout-container" #container></ng-container>
        </div>
        <div class="row">
          <div class="col-md-9">
            <mat-error id="template-layout-rules-error" *ngIf="template.termsConditionsTypeId == 1 && ((this.prcSum >= 0 && this.prcSum < 100) || this.prcSum > 100)">Sum of percentaged rules cannot be greater or less than 100 </mat-error>
          </div>
        </div>
        <div class="row-fluid">
          <button id="template-layout-add-button" mat-button color="default" (click)="add()">
            <mat-icon id="template-layout-add-icon">add_circle</mat-icon>
            Add Rule
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div id="template-layout-center-div" class="center">
          <button id="template-layout-submit-button" (click)="opentemplateConfirmationDialog()" mat-raised-button color="primary">Submit</button>
        </div>
      </div>
    </div>
  </div>
</form>
