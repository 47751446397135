<div id="no-access-mainbox-div" class="mainbox">
  <h1 id="no-access-header-1">
      <span id="no-access-exclamation-icon" class="fa fa-exclamation-circle" aria-hidden="true"></span>  Not Authorized
  </h1>
  <h2 id="no-access-not-authorized-header">You are not authorized to view this site.</h2>
  <p id="no-access-access-not-authorized-info">For access to this Application, you will need to request access from one of the following persons:</p>
  <mat-table id="no-access-data-table" [dataSource]="dataSource" matSort>
      <!-- Name Column -->
      <ng-container id="no-access-full-name-container" matColumnDef="fullName">
          <mat-header-cell id="no-access-name-header" class="mat-header-cell" *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell id="no-access-name-cell" *matCellDef="let row"> {{row.fullName}} </mat-cell>
      </ng-container>

      <!-- Job Title Column -->
      <ng-container id="no-access-job-title-container" matColumnDef="jobTitle">
          <mat-header-cell id="no-access-job-title-header" class="mat-header-cell" *matHeaderCellDef>Job Title</mat-header-cell>
          <mat-cell id="no-access-job-title-cell" *matCellDef="let row"> {{row.jobTitle}} </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container id="no-access-email-container" matColumnDef="email">
          <mat-header-cell id="no-access-email-header" class="mat-header-cell" *matHeaderCellDef>Contact Email</mat-header-cell>
          <mat-cell id="no-access-email-cell" *matCellDef="let row"> {{row.email}} </mat-cell>
      </ng-container>

      <mat-header-row id="no-access-header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row id="no-access-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <br/>
  <p id="no-access-logout-info">
      If you believe that you should have access, and are a TripArc user, please request access from your supervisor or FMS Admin Team.
      Please click here to <b><a id="no-access-logout-link" (click)="azureAccountLogout()">logout</a></b> from your account. Thank you!
  </p>
</div>
