import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TripProfitabilityResponse} from '../model/trip-profitability-response';
  
@Injectable({
    providedIn: 'root'
})
  
export class FinanceReportsService {
    private resourcePath: string = 'finance-reports';
    constructor(private httpClient: HttpClient) {
    }
  
    public getTripProfitabilityReport(tripProfitabilityReportFilter: any): Observable<TripProfitabilityResponse> {
      let httpParams = new HttpParams();
      Object
        .keys(tripProfitabilityReportFilter)
        .forEach((key) => {
          if (tripProfitabilityReportFilter[key]) {
            httpParams = httpParams.append(key, tripProfitabilityReportFilter[key]);
          }
        });
      return this.httpClient
        .get<TripProfitabilityResponse>(`${this.resourcePath}/trip-profitability`, {params: httpParams})
    }
}