import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, catchError, throwError, BehaviorSubject} from 'rxjs';
import {SearchTramsInvoiceResponse} from '../model/search-trams-invoice-response';
import {InvoiceDetailResponse} from '../model/invoice-detail-model';
import {TramsInvoiceFilterResult} from '../model/trams-invoice-filter-result';

@Injectable({
  providedIn: 'root'
})

export class InvoiceService {
  private resourcePath: string = 'dbov2/invoice';
  private _tramsInvoiceFilterSubject: BehaviorSubject<TramsInvoiceFilterResult> = new BehaviorSubject<TramsInvoiceFilterResult>({});
  constructor(private httpClient: HttpClient) {
  }

  getTramsInvoicesFilter(): Observable<TramsInvoiceFilterResult> {
    return this._tramsInvoiceFilterSubject.asObservable();
  }

  setTramsInvoicesFilter(value: TramsInvoiceFilterResult): void {
    this._tramsInvoiceFilterSubject.next(value);
  }

  searchTramsInvoices(tramsInvoiceFilter: any): Observable<SearchTramsInvoiceResponse> {
    let httpParams = new HttpParams();
    Object
      .keys(tramsInvoiceFilter)
      .forEach((key) => {
        if (tramsInvoiceFilter[key]) {
          httpParams = httpParams.append(key, tramsInvoiceFilter[key]);
        }
      });
      return this.httpClient
      .get<SearchTramsInvoiceResponse>(`${this.resourcePath}`, {params: httpParams})
      
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  getInvoiceById(id: string): Observable<InvoiceDetailResponse> {
    return this.httpClient
      .get<InvoiceDetailResponse>(`${this.resourcePath}/${id}`)
      .pipe(catchError(this.errorHandler));
  }
}