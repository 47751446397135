import {Component, Input, OnInit, Optional} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormGroup, FormControl} from '@angular/forms';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {VccChartFilterResult} from '../../../../model/funding-vcc-chart-filter';
import {AzureAppConfigurationService} from '../../../../../../infrastructure/services/azure-app-configuration.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Merchant} from '../../../../model/merchant-guid';
import {from} from 'rxjs/internal/observable/from';
import {Subscription} from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'vcc-chart-filter-modal',
  templateUrl: './vcc-chart-filter-modal.component.html'
})

export class VccChartFilterModalComponent implements OnInit {
  @Input() config!: VccChartFilterResult;

  VccChartFilterForm!:FormGroup;
  formBuilder: any;
  merchantGuids: Merchant[] = [];

  breakPoint$?:Subscription;
  isSmall: boolean = false;

  constructor(
      @Optional() public dialogRef: MatDialogRef<VccChartFilterModalComponent>,
      private azureAppConfigService: AzureAppConfigurationService,
      breakpointObserver: BreakpointObserver,
  ) {
    this.breakPoint$ = breakpointObserver.observe([
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall
    ]).subscribe(result => {
        this.isSmall = result.matches;
    });}
    
  form: FormGroup = new FormGroup({
    merchantGuid: new FormControl(null),
    releasedDateFrom: new FormControl(null),
    releasedDateTo: new FormControl(null)
  });

  handleVccChartFiltersFormCloseEvent() {
    if (!this.dialogRef) {
        return;
    }
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.form.patchValue(this.config || {});
    this.setMerchantNameFilter();
  }
  
  applyVccChartFilters() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }
    else if (this.dialogRef) {
      this.dialogRef.close(this.config);
    }
  }

  public clearVccChartFilters(propertyName: string): void {
    if (propertyName === 'all') {
      this.config = {} as VccChartFilterResult;
    }else{
      let temp: any = this.config;
      temp[propertyName] = undefined;
      this.config = temp;
    }
  }

  setMerchantNameFilter(){
    const subscription$ = from(this.azureAppConfigService.getByKey('MerchantGuid')).subscribe((result)=>{
        const merchantGuidsString = result?.value;
        if (merchantGuidsString) {
            const parsedMerchantGuids = JSON.parse(merchantGuidsString);
            this.merchantGuids = parsedMerchantGuids.merchantGuid;
        }
        subscription$.unsubscribe();
    });    
  }
}