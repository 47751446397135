import {NgModule} from "@angular/core";
import {MaskedCardPipe} from "./masked-card.pipe";
import {CapitalizePipe} from "./capitalize.card.pipe";


@NgModule({
    imports: [
        MaskedCardPipe,
        CapitalizePipe,
    ],
    exports: [
        MaskedCardPipe,
        CapitalizePipe,
    ],
    providers: [
        MaskedCardPipe,
        CapitalizePipe,
    ]
})
export class PipesModule {}
