import {Component, Input} from '@angular/core';
import {DatePipe} from '@angular/common';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'app-adx-booking-info',
  templateUrl: './adx-booking-info.component.html'
})

@AutoUnsubscribe()
export class AdxBookingInfoComponent {
  @Input() public data: any;
  _adxBooking : any;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(){
    this.getAdxBookingInfo();
  }

  getAdxBookingInfo() {
    if (this.data != null){
      this._adxBooking = this.data;
    }
  } 

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM/dd/yyyy') || '';
  }
}