<form id="financial-alerts-form" class="modal-panel side-panel-modal-form"  [formGroup]="form">
  <modal-panel id="financial-alerts-modal-panel" [title]="'Financial Alerts and Thresholds'" (close)="handleClose()">

    <div id="financial-alerts-detail-main-container">
      <div>
        <div id="financial-alerts-name">
          <mat-form-field id="financial-alerts-name-form-field">
            <mat-label id="financial-alerts-name-label">Alert Name</mat-label>
            <input
              matInput
              formControlName="alertName"
              id="financial-alerts-name-input">
          </mat-form-field>
        </div>

        <div id="financial-alerts-description">
          <mat-form-field id="financial-alerts-description-form-field">
            <mat-label id="financial-alerts-description-label">Description</mat-label>
            <textarea id="financial-alerts-description-input" matInput formControlName="description"></textarea>
          </mat-form-field>
        </div>

        <div id="financial-alerts-select-alert-criteria">
          <mat-form-field id="financial-alerts-alert-criteria-form-field">
            <mat-label id="financial-alerts-alert-logic-label">Alert Criteria</mat-label>
            <mat-select formControlName="alertCriteria" id="financial-alerts-alert-criteria-input">
              @for (criteria of alertCriteria; track criteria) {
                <mat-option [value]="criteria?.id">{{ criteria?.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div id="financial-alerts-select-merchant">
          @if(merchantGuids.length){
            <mat-form-field id="financial-alerts-merchant-name-form-field" class="">
              <mat-label id="financial-alerts-merchant-name-label">Merchant Name</mat-label>
              <mat-select formControlName="merchantGuid"
                          id="financial-alerts-merchant-name-input"
                          multiple>
                          <mat-select-trigger>
                            {{getMerchantNameById(form.controls['merchantGuid'].value?.[0]) || ''}}
                            @if ((form.controls['merchantGuid'].value?.length || 0) > 1) {
                              <span class="example-additional-selection">
                                (+{{(form.controls['merchantGuid'].value?.length || 0) - 1}} {{form.controls['merchantGuid'].value?.length === 2 ? 'other' : 'others'}})
                              </span>
                            }
                          </mat-select-trigger>
                
                          <mat-option value="select-all" selectAll [allValues]="merchantGuidsIds">Select all</mat-option>
                          @for (merchant of merchantGuids; track merchant) {
                            <mat-option [value]="merchant.id">{{ merchant.name }}</mat-option>
                          }
              </mat-select>
            </mat-form-field>
          }
        </div>
        
        @if(form.controls['alertCriteria'].value){
          <div id="financial-alerts-select-alert-condition">
            <mat-form-field id="financial-alerts-alert-condition-form-field">
              <mat-label id="financial-alerts-alert-condition-label">Alert Condition</mat-label>
              <mat-select formControlName="alertCondition" id="financial-alerts-alert-condition-input">
                @for (condition of alertCondition; track condition) {
                  <mat-option [value]="condition?.id">{{ condition?.displayName }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
      
          <div id="financial-alerts-amount">
            <mat-form-field id="financial-alerts-amount-form-field">
              <mat-label id="financial-alerts-amount-label">Amount</mat-label>
              <input
                matInput
                formControlName="amount"
                id="financial-alerts-amount-input">
            </mat-form-field>
          </div>

          <div id="financial-enabled-description" class="mb-24">
            <mat-label id="financial-alerts-enabled-label">Alert State</mat-label>
            <br/>
            <mat-slide-toggle formControlName="enabled" class="mt-8">{{form.controls['enabled'].value ? 'ON' : 'OFF'}}</mat-slide-toggle>
          </div>
      
          <div id="financial-alerts-add-email">
            <mat-form-field id="financial-alerts-email-form-field"class="items-space-between">
              <mat-label id="financial-alerts-email-label">Add emails</mat-label>
              <input matInput [formControl]="newEmailControl" placeholder="example@email.com">
              <button matSuffix mat-icon-button color="primary" (click)="addEmail()"><mat-icon>add</mat-icon></button>
            </mat-form-field>
          </div>
      
          <mat-accordion class="scrollable-accordion">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Emails ({{emails.length}})</mat-panel-title>
              </mat-expansion-panel-header>
              @if (emails.length) {
                <div id="financial-alerts-show-emails" formArrayName="emailsForm">
                  @for (email of emails.controls; track $index) {
                    <div id="financial-alerts-email-{{$index}}" [formGroupName]="$index" class="items-space-between">
                      <label>{{ email.get('email')?.value }}</label>
                      <button mat-icon-button color="warn" (click)="removeEmail($index)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  }
                </div>
              } @else {
                <p>Email required</p>
              }
            </mat-expansion-panel>
          </mat-accordion>
        }

      </div>
    </div>
    <ng-container id="financial-alerts-footer-container" modalPanelFooter>
      <div id="financial-alerts-footer-container-buttons" class="items-space-between">
          <button class="col-md-3" id="financial-alerts-footer-button-cancel" mat-raised-button color="warn" type="button" (click)="handleClose()">Cancel</button>  
          <button class="col-md-3" id="financial-alerts-footer-button-apply" mat-raised-button color="primary" type="submit" (click)="applyAlerts()" [disabled]="form.invalid">Apply</button>
      </div>
    </ng-container>
  </modal-panel>
</form>