import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {SummaryService} from '../../service/summary.service';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {ITicketsSummary as TicketsSummary} from '../../model/tickets-summary';
import {MatTableDataSource} from '@angular/material/table';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {DateService} from 'src/app/shared/service/date/date.service';
import {FilterResult} from 'src/app/shared/modals/filter-modal/filter-modal.component';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ApplicationConstants} from 'src/app/shared/constants/application.constants';
import {AzureAuthService} from '../../../../infrastructure/services/azure-auth.service';
import {ExportGridService} from '../../../shared/service/files/export-grid.service';

@Component({
  selector: 'tickets-summary-list',
  templateUrl: './tickets-summary-list.component.html'
})

@AutoUnsubscribe()
export class TicketsSummaryListComponent implements OnInit {
  @Input() filter!: FilterResult;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  ticketsSummaryChartsFilterFromDate: Date;
  ticketsSummaryChartsFilterToDate: Date;
  ticketsSummaries: TicketsSummary[] = [];
  loading$: any;
  dataSource!: MatTableDataSource<TicketsSummary>;
  displayedColumns = ['adxRefNumber', 'assigneeName', 'transactions', 'serviceType','isOpen', 'handlingTime'];
  totalPages: number = 0;
  pageSize = 5;
  pageNumber: number = 1;
  private appConstants: ApplicationConstants = new ApplicationConstants;
  filterEmail: string = this.appConstants.AllUser;
  httpParams: HttpParams = new HttpParams();

  constructor(
    private summaryService: SummaryService,
    private exportGridService: ExportGridService,
    public loader: LoadingService,
    private date: DateService,
    private azureAuthService: AzureAuthService
  ) {
    this.ticketsSummaryChartsFilterFromDate = this.date.subtractDays(new Date(), 30);
    this.ticketsSummaryChartsFilterToDate = new Date();
  }

  ngOnInit(): void {
    const isDirector = this.azureAuthService.checkUserAzureTokenHasDirectorRole();
    if (!isDirector) {
      this.azureAuthService.loggedInUserEmail.subscribe(x => {
        this.filterEmail = x;
      });
    }
    this.getTicketsSummary(this.ticketsSummaryChartsFilterFromDate.toDateString(), this.ticketsSummaryChartsFilterToDate.toDateString(), this.filterEmail);
    this.loading$ = this.loader.loading$;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter'] && !changes['filter'].firstChange) {
      this.pageNumber = 1;
      this.ticketsSummaryChartsFilterFromDate = this.filter.from ? this.filter.from : this.ticketsSummaryChartsFilterFromDate;
      this.ticketsSummaryChartsFilterToDate = this.filter.to ? this.filter.to : this.ticketsSummaryChartsFilterToDate;
      this.filterEmail = this.filter.email?.toString() ?? this.filterEmail;
      this.getTicketsSummary(this.ticketsSummaryChartsFilterFromDate.toDateString(), this.ticketsSummaryChartsFilterToDate.toDateString(), this.filterEmail);
    }
  }

  getTicketsSummary(fromDate: string, toDate: string, email: string) {
    if (email == this.appConstants.AllUser) {
      email = '';
    }
    this.httpParams = new HttpParams({
      fromObject: {
        dateFrom: fromDate,
        dateTo: toDate,
        email: email,
        PageSize: this.pageSize,
        PageNumber: this.pageNumber == 0 ? 1 : this.pageNumber
      }
    });
    this.summaryService.getHsFiltersData(this.httpParams)
      .subscribe((data: any) => {
        if (data) {
          this.ticketsSummaries = data.items as TicketsSummary[];
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.length = data.pagination.totalItemCount;
          this.dataSource = new MatTableDataSource(this.ticketsSummaries);
        }
      });
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.summaryService.getHsFiltersData(this.httpParams)
      .subscribe(() => {
        this.exportGridService.getByDataSource(
          'help-spot/request/filter',
          'tickets-summary',
          {
            dateFrom: this.ticketsSummaryChartsFilterFromDate.toDateString(),
            dateTo: this.ticketsSummaryChartsFilterToDate.toDateString(),
            email: this.filterEmail == this.appConstants.AllUser ? '' : this.filterEmail,
            PageSize: this.pageSize || 100,
            PageNumber: this.pageNumber == 0 ? 1 : this.pageNumber
          },
          (data) => {
            const record = data as TicketsSummary;
            return {
              'Ticket List ': record?.adxRefNumber,
              'Assignee Name': record?.assigneeName,
              'Transaction Types': record?.transactions.join(', '),
              'Service Type': record?.serviceType,
              'Status': record?.isOpen == 1 ? 'Open' : 'Closed',
              'Handling Time': record?.handlingTime
            };
          }
        );
        downloadSubs$?.unsubscribe();
      });
  }

  replayGrid (){
    this.getTicketsSummary(this.ticketsSummaryChartsFilterFromDate.toDateString(), this.ticketsSummaryChartsFilterToDate.toDateString(), this.filterEmail);
  }

  PageChanged(event: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.getTicketsSummary(this.ticketsSummaryChartsFilterFromDate.toDateString(), this.ticketsSummaryChartsFilterToDate.toDateString(), this.filterEmail);
  }
}
