<form [formGroup]="templateForm" id="search-template-form" class="flex flex-wrap" *ngIf="!(loading$ | async)">
  <div id="search-template-templates-div">
    <mat-label id="search-template-templates-label" class="title">Templates:</mat-label>
  </div>
  <br/>
  <div id="search-template-row-div" class="row">
    <mat-form-field id="search-template-form-field">
      <mat-label id="search-template-template-type-label">Template Type:</mat-label>
      <mat-select id="search-template-template-type-select" formControlName="templateType" (selectionChange)="onTemplateTypeId()">
        <mat-option id="search-template-template-type-option-{{i}}" *ngFor="let item of termsContitionsTypes; let i = index" [value]="item.termConditionId">
          {{item.termsConditionsTypeName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-form-field id="search-template-form-field">
    <input id="search-template-input-auto-complete" #inputAutoComplete
           placeholder="Search..."
           [formControl]="inputControl"
           [matAutocomplete]="auto"
           #trigger="matAutocompleteTrigger"
           matInput
           type="text" />
    <div id="search-template-button-div" matSuffix style="display:flex">
      <button id="search-template-clear-button" (click)="clearInput($event)"
              *ngIf="inputControl?.value!==null && inputControl?.value!==''"
              aria-label="Clear"
              mat-icon-button
              type="button">
        <mat-icon id="search-template-clear-icon">clear</mat-icon>
      </button>
      <button id="search-template-open-or-close-panel-button" (click)="openOrClosePanel($event, trigger)"
              aria-label="Clear"
              mat-icon-button
              type="button">
        <mat-icon id="search-template-arrowIcon">{{arrowIconSubject.getValue()}}</mat-icon>
      </button>
    </div>
    <mat-autocomplete id="search-template-auto-complete" #auto="matAutocomplete"
                      (closed)="arrowIconSubject.next('arrow_drop_down')"
                      (opened)="arrowIconSubject.next('arrow_drop_up')"
                      (optionSelected)="arrowIconSubject.next('arrow_drop_down')">
      <mat-option id="search-template-template-option-{{i}}" *ngFor="let template of templateGroupOptions | async; let i = index "
                  [value]="template.templateName" (onSelectionChange)="setSelectedTemplate(template.id,$event)">
        {{template.templateName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

