<modal-panel [title]="title" (close)="closeDialog()" id="agent-info-modal-panel">
  <ng-container id="agent-viewer-modal-agent-container">
    <div class="agent-info-content" id="agent-info-modal-content">
      <mat-form-field appearance="fill" class="agent-info-field">
        <mat-label>Agent Name</mat-label>
        <input matInput readonly [value]="agentData.agentName || ''">
      </mat-form-field>
      <mat-form-field appearance="fill" class="agent-info-field">
        <mat-label>Agent Code</mat-label>
        <input matInput readonly [value]="agentData.interfaceId || ''">
      </mat-form-field>
      <mat-form-field appearance="fill" class="agent-info-field">
        <mat-label>Email</mat-label>
        <input matInput readonly [value]="agentData.email || ''">
      </mat-form-field>
      <mat-form-field appearance="fill" class="agent-info-field">
        <mat-label>Data Source Name</mat-label>
        <input matInput readonly [value]="agentData.tramsDataSource.displayName || ''">
      </mat-form-field>
      <mat-form-field appearance="fill" class="agent-info-field">
        <mat-label>Trams Central Document Id</mat-label>
        <input matInput readonly [value]="agentData.tramsCentralDocumentId || ''">
      </mat-form-field>
      <mat-form-field appearance="fill" class="agent-info-field">
        <mat-label>Address</mat-label>
        <input matInput readonly [value]="formatAddress(agentData.address)">
      </mat-form-field>
      <mat-form-field appearance="fill" class="agent-info-field">
        <mat-label>Phone</mat-label>
        <input matInput readonly [value]="agentData.phone || ''">
      </mat-form-field>
      <mat-form-field appearance="fill" class="agent-info-field">
        <mat-label>Split Rate</mat-label>
        <input matInput readonly [value]="agentData.splitRate !== undefined ? agentData.splitRate + '%' : ''">
      </mat-form-field>
    </div>
  </ng-container>
  <ng-container modalPanelFooter id="agent-info-modal-footer">
    <button mat-raised-button color="primary" (click)="closeDialog()" id="agent-info-modal-close-button">Close</button>
  </ng-container>
</modal-panel>
