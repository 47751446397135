import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TermsConditionsService {
  constructor(
    private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public GetTermsConditionsTypes(): Observable<any> {
    return this.httpClient
      .get<any>('terms-conditions-type', {params: new HttpParams()})
      .pipe(catchError(this.errorHandler));
  }

  public GetTermsConditionsRuleTypes(): Observable<any> {
    return this.httpClient
      .get<any>('terms-conditions-rule-type', {params: new HttpParams()})
      .pipe(catchError(this.errorHandler));
  }

  public GetTermsConditionsRuleConditionTypes(): Observable<any> {
    return this.httpClient
      .get<any>('rule-condition', {params: new HttpParams()})
      .pipe(catchError(this.errorHandler));
  }

  public AddTemplateFormDetails(template: any): Observable<any> {
    return this.httpClient
      .post<any>('terms-conditions-template/form', JSON.stringify(template))
      .pipe(catchError(this.errorHandler));
  }

  public GetPaymentTermsTemplate(): Observable<any> {
    let httpParams = new HttpParams({fromObject: {TermsConditionsTypeId: 1, All: false}});
    return this.httpClient
      .get<any>('terms-conditions-template', {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  public GetCancellationTermsTemplate(): Observable<any> {
    let httpParams = new HttpParams({fromObject: {TermsConditionsTypeId: 2, All: false}});
    return this.httpClient
      .get<any>('terms-conditions-template', {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }
}
