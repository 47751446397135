<div id="matching-vcc-revert-dialog-confirmpopup" class="confirmpopup">
    <div id="-matching-vcc-revert-dialog-dialog-title" mat-dialog-title>
      <label id="matching-vcc-revert-dialog-title-label" class="title">Apply Revert VCC:</label>
    </div>
    <hr id="matching-vcc-revert-dialog-first-hr">
    <div id="matching-vcc-revert-dialog-dialog-content" mat-dialog-content>
      <p id="matching-vcc-revert-dialog-content-p">Are you sure you want to revert virtual credit card suggested commission?</p>
    </div>
    <hr id="matching-vcc-revert-dialog-second-hr">
    <div id="matching-vcc-revert-dialog-dialog-actions" mat-dialog-actions style="float: right;">
      <button
        id="matching-vcc-revert-dialog-ok-button"
        type="submit" mat-raised-button
        (click)="okClick()"
        color="primary"
        cdkFocusInitial matTooltip="Revert Matching Virtual Credit Card"
        aria-label="Revert Matching Virtual Credit Card">
          <span id="matching-vcc-revert-dialog-ok-span">Ok</span>
      </button>
      <button
        id="matching-vcc-revert-dialog-no-button"
        mat-raised-button
        (click)="onNoClick()"
        color="warn">
        No
      </button>
    </div>
  </div>
  