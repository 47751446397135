import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ServiceStatus} from '../model/service-status';
import {CacheableQuery} from '../../../infrastructure/abstractrions/cacheable-query';
import {HttpParamHelper} from '../../../infrastructure/helper/http-param-helper';

@Injectable({
  providedIn: 'root'
})
export class ServiceStatusService {

  constructor(private httpClient: HttpClient) {
  }

  public getServiceStatuses(query: CacheableQuery): Observable<Array<string>> {
    return this.httpClient
      .get<Array<ServiceStatus>>('service-status', {params: HttpParamHelper.toQuery(query)})
      .pipe(map(bss => bss.map(bs => bs.serviceStatusName)));
  }
}
