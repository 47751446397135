import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import {VendorService} from '../../service/vendor.service';
import {Vendor} from '../../model/vendor';
import {VendorInputModel} from '../../model/vendor-input-model';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {HttpParams} from '@angular/common/http';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'search-vendor',
  templateUrl: './search-vendor.component.html',
})
export class SearchVendorComponent implements OnInit {
  @Input() public data: any;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;

  vendorForm: FormGroup = this._formBuilder.group({});

  vendors: Vendor[] = [];
  vendorGroupOptions!: Observable<Vendor[]>;

  selectedVendorId!: number;
  inputControl = new FormControl();
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  loading$: any;
  @Output() selectedVendor: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _formBuilder: FormBuilder,
              private vendorService: VendorService,
              public loader: LoadingService
  ) {
  }

  ngOnInit() {
    this.loading$ = this.loader.loading$;
    this.LoadForm();
  }

  LoadForm() {
    this.vendorForm.reset();
    this.getVendors();

  }

  getVendors() {
    let httpParams = new HttpParams({fromObject: {IsDeleted: false}});
    this.vendorService.getVendors(httpParams)
      .subscribe((data: any) => {
        if (data.items) {
          data.items.forEach((myObject: any) => {
            const item = new Vendor(myObject);
            return this.vendors.push(item);
          });
          this.vendorGroupOptions = this.inputControl.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string'
                ? value
                : value?.VendorName),
              map(name => (name
                ? this.vendors.filter(vendors => vendors.VendorName.toLowerCase().startsWith(name.toLowerCase()))
                : this.vendors.slice()))
            );
        }
      });
  }

  setSelectedVendor(vendorId: number, event: any) {
    if (event.isUserInput) {
      this.loading$ = this.loader.show();
      this.selectedVendorId = vendorId;
      const data = new VendorInputModel();
      data.title = '';
      data.vendorId = vendorId;
      this.selectedVendor.emit(data);
    }
  }

  clearInput(evt: any): void {
    evt.stopPropagation();
    this.inputControl?.reset();
    this.inputAutoComplete?.nativeElement.focus();
  }

  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }
}
