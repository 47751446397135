import {Component, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-trams-invoice-component',
  templateUrl: './trams-invoice.component.html'
})
@AutoUnsubscribe()
export class TramsInvoiceComponent implements OnInit {
  data: any;
  constructor(private route: ActivatedRoute) {
  }

  public ngOnInit(): void{
    this.route.paramMap.subscribe(params => {
      this.data = {
        invoiceId: params.get('invoiceId')
      }
    });
  }
}
