import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {TraceService} from '../service/trace-service';
import {TraceFilter} from '../model/trace-filter';
import {TraceModel} from '../model/trace.model';
import {PaginatedResponse} from '../../../infrastructure/pagination/model/paginated-response';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Pagination} from '../../../infrastructure/pagination/model/pagination';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'app-trace',
  templateUrl: './trace.component.html',
  styleUrls: ['./trace.component.scss']
})

@AutoUnsubscribe()
export class TraceComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort, {static: false})
  public sort!: MatSort;

  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  public pagination: Pagination = {} as Pagination;
  public isLoading: boolean = false;
  public dataSource: MatTableDataSource<TraceModel> = new MatTableDataSource<TraceModel>([]);
  public traceFilter: TraceFilter = {} as TraceFilter;
  public displayedColumns: Array<string> = [
    'id',
    'started',
    'finished',
    'elapsed',
    'correlationId',
    'httpMethod',
    'schema',
    'host',
    'path',
    // 'queryString',
    // 'requestHeaders',
    // 'requestBody',
    'responseStatus',
    // 'responseHeaders',
    // 'responseBody',
    'localIp',
    'localPort',
    'remoteIp',
    'remotePort',
    // 'exception',
    'dateCreated',
    'userCreated',
    'dateUpdated',
    'userUpdated',
    'isDeleted'
  ];

  constructor(private traceService: TraceService) {
  }

  public ngOnInit(): void {
    this.getManyPaginated(new PageEvent());
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public getManyPaginated(pageEvent: PageEvent): void {
    this.setPaginationAfterPageChanged(pageEvent);
    this.isLoading = true;
    this.traceService.getManyPaginated(this.traceFilter)
      .subscribe((data: PaginatedResponse<TraceModel>) => {
        if (data) {
          this.pagination = data.paginationContainer.pagination;
          this.traceFilter.pageNumber = data.paginationContainer.pagination.pageNumber - 1;
          this.dataSource = new MatTableDataSource<TraceModel>(data.paginationContainer.items);
        }
        this.isLoading = false;
      });
  }

  public handleSortChange(sort: any): void {
    this.traceFilter.propertyName = sort.active;
    this.traceFilter.orderKey = sort.direction.toUpperCase();

    this.getManyPaginated(new PageEvent());
  }

  public setPaginationAfterPageChanged(pageEvent: PageEvent): void {
    this.traceFilter.pageNumber = pageEvent.pageIndex + 1;
    this.traceFilter.pageSize = 25;

    const isStepForward = pageEvent.pageIndex > pageEvent.previousPageIndex!;

    if (isStepForward) {
      this.traceFilter.pageNumber += 1;
    } else {
      this.traceFilter.pageNumber -= 1;
    }
  }

  public pageChanged(event: PageEvent): void {
    this.getManyPaginated(event);
  }
}
