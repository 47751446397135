import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {
  Observable,
  finalize,
  first,
  map,
  mergeMap,
  of,
  switchMap,
  throwError,
  catchError,
  tap,
  filter,
  iif,
  concatMap
} from 'rxjs';
import {environment} from '../../environments/environment';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {MsalAzureTokenConstants} from 'src/app/shared/model/msal-azure-token.constants';
import {AzureAuthService} from '../services/azure-auth.service';

const waitUntil =
  <TSignal>(signal$: Observable<TSignal>) =>
    <TSource>(source$: Observable<TSource>) =>
      source$.pipe(
        mergeMap((value) =>
          signal$.pipe(
            first(),
            map(() => value)
          )
        )
      );

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private loader: LoadingService,
    private azureAuthService: AzureAuthService) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.url == 'trx/health') {
      let _url = environment.apiUrl + request.url;
      return next.handle(request.clone({
        url: _url
      }))
    }

    else if (request.url == 'dbov2/health') {
      let _url = environment.dboV2ApiUrl + request.url;
      return next.handle(request.clone({
        url: _url
      }))
    }

    const isTokenReady$ = this.azureAuthService.isTokenReady$.pipe(filter(tokenReady => tokenReady));

    return of(request)
      .pipe(waitUntil(isTokenReady$),
        concatMap(req => {
          const tokenType = req.url === environment.graphApiUrl
            ? MsalAzureTokenConstants.GraphApiToken
            : MsalAzureTokenConstants.FmsApiToken;

          return iif(
            () => this.azureAuthService.checkAzureTokenIsExpired(tokenType),
            this._refreshAndSaveToken(tokenType),
            of(localStorage.getItem(tokenType) ?? '')
          ).pipe(
            map(accessToken => ({accessToken, req}))
          );
        }),
        switchMap(({accessToken, req}) => this._prepareRequest(req, accessToken)),
        switchMap(preparedRequest => this._executeHttpRequest(preparedRequest, next)),
        catchError(err => this._handleError(err))
      )
  }

  private _refreshAndSaveToken(tokenType: string): Observable<string> {
    return this._refreshTokens(tokenType).pipe(
      tap(token => this.azureAuthService.saveAccessTokenToCache(tokenType, token))
    );
  }

  private _prepareRequest(request: HttpRequest<unknown>, accessToken: string): Observable<HttpRequest<unknown>> {
    let url;
    if(request.url === environment.graphApiUrl){
      url = request.url;
    }else if (request.url.includes('dbov2/')){
      url = environment.dboV2ApiUrl + request.url;
    }else{
      url = environment.apiUrl + request.url;
    }
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return of(request.clone({url, headers}));
  }

  private _executeHttpRequest(preparedRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (preparedRequest.headers.has('Authorization') && preparedRequest.url !== environment.graphApiUrl) {
      this.loader.show();
    }
    return next.handle(preparedRequest).pipe(
      finalize(() => {
        if (preparedRequest.url !== environment.graphApiUrl) {
          this.loader.hide();
        }
      })
    );
  }

  private _refreshTokens(tokenType: string): Observable<string> {
    return tokenType === MsalAzureTokenConstants.GraphApiToken
      ? this.azureAuthService.getGraphAzureToken()
      : this.azureAuthService.getFMSAzureToken();
  }

  private _handleError(err: any): Observable<never> {
    // Global error handle logic
    return throwError(() => new Error(err));
  }

}
