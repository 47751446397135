<modal-panel id="trams-invoice-filter-modal-panel" class="trams-invoice-filter-modal-panel-cls filter-panel" [title]="'Filter'" (close)="handleTramsInvoiceFiltersFormCloseEvent()">
    <div id="trams-invoice-filter-modal-div-1" class="filter" [formGroup]="form">
      <div id="trams-invoice-filter-modal-div-2" class="filter__fields">
        <mat-grid-list [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85" id="trams-invoice-list-grid-list">
            <mat-grid-tile id="trams-invoice-filter-quote-id-grid-tile">
              <mat-form-field id="trams-invoice-filter-quote-id-form-field">
                <mat-label id="trams-invoice-filter-quote-id-label">Invoice Number</mat-label>
                <input
                  matInput
                  [(ngModel)]="config.tramsInvoiceNo"
                  formControlName="tramsInvoiceNo"
                  id="trams-invoice-filter-quote-id-input">
                @if(form.controls['tramsInvoiceNo'].value){
                  <button 
                    id="trams-invoice-filter-quote-id-button"
                    matSuffix mat-icon-button
                    type="button"
                    (click)="clearTramsInvoiceFilters('tramsInvoiceNo')">
                        <mat-icon>close</mat-icon>
                  </button>
                }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile id="trams-invoice-filter-invoice-record-locator-grid-tile">
              <mat-form-field id="trams-invoice-filter-form-field">
                <mat-label id="trams-invoice-filter-invoice-record-locator-label">Invoice Record Locator</mat-label>
                <input
                  matInput
                  [(ngModel)]="config.invoiceRecordLocator"
                  formControlName="invoiceRecordLocator"
                  id="trams-invoice-filter-invoice-record-locator-input">
                  @if(form.controls['invoiceRecordLocator'].value){
                    <button 
                      id="trams-invoice-filter-invoice-record-locator-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearTramsInvoiceFilters('invoiceRecordLocator')">
                          <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field>
                  <mat-label id="trams-invoice-filter-issue-start-date-field-title">Issue Start Date</mat-label>
                  <input
                    id="trams-invoice-filter-issue-start-date-input-field"
                    matInput
                    [matDatepicker]="picker1" readonly
                    [(ngModel)]="config.issueStartDate"
                    formControlName="issueStartDate">
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker #picker1 id="trams-invoice-filter-issue-start-date-datepicker"></mat-datepicker>
                  @if(form.controls['issueStartDate'].value){
                    <button 
                        id="trams-invoice-filter-issue-start-date-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearTramsInvoiceFilters('issueStartDate')">
                            <mat-icon>close</mat-icon>
                    </button>
                  } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                  }
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="trams-invoice-filter-issue-end-date-field-title">Issue End Date</mat-label>
                <input
                  id="trams-invoice-filter-issue-end-date-input-field"
                  matInput
                  [matDatepicker]="picker2" readonly
                  [(ngModel)]="config.issueEndDate"
                  formControlName="issueEndDate">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #picker2 id="trams-invoice-issue-end-date-datepicker"></mat-datepicker>
                @if(form.controls['issueEndDate'].value){
                  <button 
                      id="trams-invoice-filter-issue-end-date-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearTramsInvoiceFilters('issueEndDate')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                  <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>
            
            <mat-grid-tile>
                <mat-form-field>
                  <mat-label id="trams-invoice-filter-created-start-date-field-title">Created Start Date</mat-label>
                  <input
                    id="trams-invoice-filter-created-start-date-input-field"
                    matInput
                    [matDatepicker]="picker3" readonly
                    [(ngModel)]="config.createdStartDate"
                    formControlName="createdStartDate">
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker #picker3 id="trams-invoice-filter-created-start-date-datepicker"></mat-datepicker>
                  @if(form.controls['createdStartDate'].value){
                    <button 
                        id="trams-invoice-filter-created-start-date-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearTramsInvoiceFilters('createdStartDate')">
                            <mat-icon>close</mat-icon>
                    </button>
                  } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                  }
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="trams-invoice-filter-created-end-date-field-title">Created End Date</mat-label>
                <input
                  id="trams-invoice-filter-created-end-date-input-field"
                  matInput
                  [matDatepicker]="picker4" readonly
                  [(ngModel)]="config.createdEndDate"
                  formControlName="createdEndDate">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #picker4 id="trams-invoice-created-end-date-datepicker"></mat-datepicker>
                @if(form.controls['createdEndDate'].value){
                  <button 
                      id="trams-invoice-filter-created-end-date-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearTramsInvoiceFilters('createdEndDate')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                  <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
      </div>
    </div>
    <ng-container id="trams-invoice-filter-modal-footer-container" modalPanelFooter>
      <div class="modal-footer">
        <button id="trams-invoice-filter-modal-button-clear-all" mat-stroked-button color="primary" (click)="clearTramsInvoiceFilters('all')">Clear All
          <mat-icon id="trams-invoice-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
        </button>
        <button id="trams-invoice-filter-modal-button" mat-raised-button color="primary" (click)="applyTramsInvoiceFilters()">Apply</button>
    </div>

    </ng-container>
  </modal-panel>
  