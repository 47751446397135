import {Component, EventEmitter, Input, Output} from '@angular/core';
import { AdxVccFilter } from '../../../finance-resource/model/adx-data';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'refresh-list',
  templateUrl: './refresh-list.component.html'
})
export class RefreshListComponent {

  @Input() filtersAdx!: AdxVccFilter;
  @Input() page!: PageEvent;
  @Output() keepFilters = new EventEmitter<{ filters: AdxVccFilter, page: PageEvent }>();

  handleAdxClick() {
    this.keepFilters.emit({ filters: this.filtersAdx, page: this.page });
  }
}
