
<ng-container>
    <form id="trams-invoice-detail-form" class="template-form trams-invoice-detail-form" *ngIf="!(loading$ | async)">  
      <div id="trams-invoice-detail-main-container" class="container">
        <mat-tab-group dynamicHeight disablePagination="true" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Invoice">
                <mat-card class="mat-elevation-z8">
                  <mat-card-content>
                    <mat-form-field id="client-name-field" *ngIf="invoiceData.clientName != undefined && invoiceData.clientName != null">
                      <mat-label id="client-name-field-title">Client Name: </mat-label>
                      <input id="client-name-input-field" matInput value="{{invoiceData.clientName}}">
                    </mat-form-field>
                    <mat-form-field id="client-id-field" *ngIf="invoiceData.clientCentralDocumentId != undefined && invoiceData.clientCentralDocumentId != null">
                      <mat-label id="client-id-field-title">Client Id: </mat-label>
                      <input id="client-id-input-field" matInput value="{{invoiceData.clientCentralDocumentId}}">
                    </mat-form-field>
                    <div *ngFor="let booking of invoiceData.bookings">
                      <mat-form-field id="main-passenger-name-field-2" *ngIf="booking.mainPassengerName != undefined && booking.mainPassengerName != null">
                        <mat-label id="main-passenger-name-field-title-2">Main Passenger Name: </mat-label>
                        <input id="main-passenger-name-input-field-2" matInput value="{{booking.mainPassengerName}}">
                      </mat-form-field>
                    </div>
                    <mat-form-field id="record-locator-field" *ngIf="invoiceData.recordLocator != undefined && invoiceData.recordLocator != null">
                      <mat-label id="record-locator-field-title">Invoice Record Locator: </mat-label>
                      <input id="record-locator-input-field" matInput value="{{invoiceData.recordLocator}}">
                    </mat-form-field>
                    <mat-form-field id="invoice-type-code-field" *ngIf="invoiceData.invoiceTypeCode != undefined && invoiceData.invoiceTypeCode != null">
                      <mat-label id="invoice-type-code-field-title">Invoice Type: </mat-label>
                      <input id="invoice-type-code-input-field" matInput value="{{invoiceData.invoiceTypeCode}}">
                    </mat-form-field>
                    <mat-form-field id="invoice-no-field" *ngIf="invoiceData.tramsInvoiceNumber != undefined && invoiceData.tramsInvoiceNumber != null  && invoiceData.tramsInvoiceNumber != 0">
                      <mat-label id="invoice-no-field-title">Invoice Number: </mat-label>
                      <input id="invoice-no-input-field" matInput value="{{invoiceData.tramsInvoiceNumber}}">
                    </mat-form-field>
                    <mat-form-field id="invoice-group-field" *ngIf="invoiceData.invoiceGroup != undefined && invoiceData.invoiceGroup != null">
                      <mat-label id="invoice-group-field-title">Invoice Group: </mat-label>
                      <input id="invoice-group-input-field" matInput value="{{invoiceData.invoiceGroup}}">
                    </mat-form-field>
                    <mat-form-field id="branch-no-field" *ngIf="invoiceData.branchNo != undefined && invoiceData.branchNo != null">
                      <mat-label id="branch-no-field-title">Branch: </mat-label>
                      <input id="branch-no-input-field" matInput value="{{invoiceData.branchNo}}">
                    </mat-form-field>
                    <mat-form-field id="issue-date-field" *ngIf="invoiceData.issueDate != undefined && invoiceData.issueDate != null">
                      <mat-label id="issue-date-field-title">Issue Date: </mat-label>
                      <input id="issue-date-input-field" matInput [value]="formatDate(invoiceData.issueDate)">
                    </mat-form-field>
                    <mat-form-field id="data-source-id-field" *ngIf="invoiceData.dataSourceId != undefined && invoiceData.dataSourceId != null">
                      <mat-label id="data-source-id-field-title">Data Source Id: </mat-label>
                      <input id="data-source-id-input-field" matInput value="{{invoiceData.dataSourceId}}">
                    </mat-form-field>
                    <mat-form-field id="data-source-name-field" *ngIf="invoiceData.dataSourceName != undefined && invoiceData.dataSourceName != null">
                      <mat-label id="data-source-name-field-title">Data Source Name: </mat-label>
                      <input id="data-source-name-input-field" matInput value="{{invoiceData.dataSourceName}}">
                    </mat-form-field>
                  </mat-card-content>
                </mat-card>
            </mat-tab>
            @for (booking of invoiceData.bookings; track booking) {
            <mat-tab [label]="booking.tramsBookingNo">
              <mat-form-field id="vendor-id-field" *ngIf="booking.vendorCentralDocumentId != undefined && booking.vendorCentralDocumentId != null">
                <mat-label id="vendor-id-field-title">Vendor Id: </mat-label>
                <input id="vendor-id-input-field" matInput value="{{booking.vendorCentralDocumentId}}">
              </mat-form-field>
              <mat-form-field id="vendor-name-field" *ngIf="booking.vendorName != undefined && booking.vendorName != null">
                <mat-label id="vendor-name-field-title">Vendor Name: </mat-label>
                <input id="vendor-name-input-field" matInput value="{{booking.vendorName}}">
              </mat-form-field>
              <mat-form-field id="trams-booking-no-field" *ngIf="booking.tramsBookingNo != undefined && booking.tramsBookingNo != null">
                <mat-label id="trams-booking-no-field-title">Trams Booking No: </mat-label>
                <input id="trams-booking-no-input-field" matInput value="{{booking.tramsBookingNo}}">
              </mat-form-field>
              <mat-form-field id="depart-date-field" *ngIf="booking?.departDate != undefined && booking?.departDate != null">
                <mat-label id="depart-date-field-title">Depart Date: </mat-label>
                <input id="depart-date-input-field" matInput [value]="formatDate(booking?.departDate)">
              </mat-form-field>
              <mat-form-field id="arrival-date-field" *ngIf="booking.returnDate != undefined && booking.returnDate != null">
                <mat-label id="arrival-date-field-title">Arrival Date: </mat-label>
                <input id="arrival-date-input-field" matInput [value]="formatDate(booking.returnDate)">
              </mat-form-field>
              <mat-form-field id="total-fare-field" *ngIf="booking.totalFare != undefined && booking.totalFare != null">
                <mat-label id="total-fare-field-title">Booking Total: </mat-label>
                <input id="total-fare-input-field" matInput value="{{booking.totalFare}}">
              </mat-form-field>
              <mat-form-field id="booking-taxes-field" *ngIf="areTaxesDefined(booking)">
                <mat-label id="tbooking-taxes-field-title">Booking Taxes: </mat-label>
                <input id="booking-taxes-input-field" matInput [value]="getTotalTaxes(booking)">
              </mat-form-field>
              <mat-form-field id="comm-amt-field" *ngIf="booking.commAmt != undefined && booking.commAmt != null">
                <mat-label id="comm-amt-field-title">Commission Amount: </mat-label>
                <input id="comm-amt-input-field" matInput value="{{booking.commAmt}}">
              </mat-form-field>
              <mat-form-field id="cc-no-field" *ngIf="booking.clientPaidByCc != undefined && booking.clientPaidByCc != null && booking.clientPaidByCc != 'N'">
                <mat-label id="cc-no-field-title">CC Number: </mat-label>
                <input id="cc-no-input-field" matInput value="{{booking.clientPaidByCc}}">
              </mat-form-field>
              <mat-form-field id="confirmation-number-field" *ngIf="booking.confirmationNumber != undefined && booking.confirmationNumber != null">
                <mat-label id="confirmation-number-field-title">Confirmation Number: </mat-label>
                <input id="confirmation-number-input-field" matInput value="{{booking.confirmationNumber}}">
              </mat-form-field>
              <mat-form-field id="currency-field" *ngIf="booking.currencyName != undefined && booking.currencyName != null && booking.currencyName != ''">
                <mat-label id="currency-field-title">Currency: </mat-label>
                <input id="currency-input-field" matInput value="{{booking.currencyName}}">
              </mat-form-field>
              <mat-form-field id="booking-record-locator-field" *ngIf="booking.recordLocator != undefined && booking.recordLocator != null">
                <mat-label id="booking-record-locator-field-title">Booking Record Locator: </mat-label>
                <input id="booking-record-locator-input-field" matInput value="{{booking.recordLocator}}">
              </mat-form-field>
              <mat-form-field id="submit-to-code-field" *ngIf="booking.submitToCode != undefined && booking.submitToCode != null">
                <mat-label id="submit-to-code-field-title">Submit To Code: </mat-label>
                <input id="submit-to-code-input-field" matInput value="{{booking.submitToCode}}">
              </mat-form-field>
              <mat-form-field id="gst-amt-field" *ngIf="booking.gstAmt != undefined && booking.gstAmt != null">
                <mat-label id="gst-amt-field-title">GST Amount: </mat-label>
                <input id="gst-amt-input-field" matInput value="{{booking.gstAmt}}">
              </mat-form-field>
              <mat-form-field id="qst-amt-field" *ngIf="booking.qstAmt != undefined && booking.qstAmt != null">
                <mat-label id="qst-amt-field-title">QST Amount: </mat-label>
                <input id="qst-amt-input-field" matInput value="{{booking.qstAmt}}">
              </mat-form-field>
              <mat-form-field id="tax1-amt-field" *ngIf="booking.tax1Amt != undefined && booking.tax1Amt != null">
                <mat-label id="tax1-amt-field-title">Tax1 Amount: </mat-label>
                <input id="tax1-amt-input-field" matInput value="{{booking.tax1Amt}}">
              </mat-form-field>
              <mat-form-field id="tax2-amt-field" *ngIf="booking.tax2Amt != undefined && booking.tax2Amt != null">
                <mat-label id="tax2-amt-field-title">Tax2 Amount: </mat-label>
                <input id="tax2-amt-input-field" matInput value="{{booking.tax2Amt}}">
              </mat-form-field>
              <mat-form-field id="tax3-amt-field" *ngIf="booking.tax3Amt != undefined && booking.tax3Amt != null">
                <mat-label id="tax3-amt-field-title">Tax3 Amount: </mat-label>
                <input id="tax3-amt-input-field" matInput value="{{booking.tax3Amt}}">
              </mat-form-field>
              <mat-form-field id="tax4-amt-field" *ngIf="booking.tax4Amt != undefined && booking.tax4Amt != null">
                <mat-label id="tax4-amt-field-title">Tax4 Amount: </mat-label>
                <input id="tax4-amt-input-field" matInput value="{{booking.tax4Amt}}">
              </mat-form-field>
              <mat-form-field id="client-gst-amt-field" *ngIf="booking.clientGstAmt != undefined && booking.clientGstAmt != null">
                <mat-label id="client-gst-amt-field-title">Client GST Amount: </mat-label>
                <input id="client-gst-amt-input-field" matInput value="{{booking.clientGstAmt}}">
              </mat-form-field>
              <mat-form-field id="travel-type-field" *ngIf="booking.travelTypeId != undefined && booking.travelTypeId != null">
                <mat-label id="travel-type-field-title">Travel Type: </mat-label>
                <input id="travel-type-input-field" matInput value="{{booking.travelTypeId}}">
              </mat-form-field>
              <mat-form-field id="booking-udids-field" *ngIf="booking.bookingUdids?.length >= 1" (keypress)="showMoreAboutBookingUdidsInfo()" (click)="showMoreAboutBookingUdidsInfo()">
                <mat-label id="booking-udids-field-title">List of UDIDs: </mat-label>
                <input id="booking-udids-input-field" matInput [value]="getUdidValues(booking.bookingUdids)">
              </mat-form-field>
              <mat-form-field id="agent-ids-field" *ngIf="booking.agentBookings?.length >= 1" (keypress)="showMoreAboutAgentsInfo()" (click)="showMoreAboutAgentsInfo()">
                <mat-label id="agent-ids-field-title">List of agents: </mat-label>
                <input id="agent-ids-input-field" matInput [value]="getAgentValues(booking.agentBookings)">
              </mat-form-field>
            </mat-tab>
            }
        </mat-tab-group>
      </div>
    </form>
  </ng-container>
