import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, catchError} from 'rxjs';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';
import {ApplyVccData, MatchedVccFilter, MatchedVccResponse, MatchingVccDetails, SerachMatchedVccParams} from '../model/match-vcc';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class MatchingVccService {
  private readonly url = 'dbov2/connex-pay-accounting-daily-detail';

  private _dataSource$ = new BehaviorSubject<MatchedVccFilter | {}>({});

  constructor(private http: HttpClient) {}

  getMatchingFilter (): Observable<MatchedVccFilter | {}>{
    return this._dataSource$.asObservable();
  }

  setMatchingFilter(value:MatchedVccFilter){
    this._dataSource$.next(value || null);
  }

  getOrganizedFilters(filters?:MatchedVccFilter) {
    return {
      releasedDateFrom: filters?.releasedDateFrom? moment(filters?.releasedDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      releasedDateTo: filters?.releasedDateTo? moment(filters?.releasedDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      tramsBookingStartDateFrom: filters?.tramsBookingStartDateFrom? moment(filters?.tramsBookingStartDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      tramsBookingStartDateTo: filters?.tramsBookingStartDateTo? moment(filters?.tramsBookingStartDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      tramsBookingEndDateFrom: filters?.tramsBookingEndDateFrom? moment(filters?.tramsBookingEndDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      tramsBookingEndDateTo: filters?.tramsBookingEndDateTo? moment(filters?.tramsBookingEndDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      orderNumber: filters?.orderNumber ?? '',
      tramsInvoiceNumber: filters?.tramsInvoiceNumber ?? '',
      merchantName: filters?.merchantName ?? '',
      cardLastFour: filters?.cardLastFour ?? '',
      merchantGuid: filters?.merchantGuid ?? '',
      isMapped: filters?.isMapped ?? '',
      isAmountMatches: filters?.isAmountMatches ?? '',
      isResolved: filters?.isResolved ?? '',
    }
  }

  searchMatching({filters, page, sort} : SerachMatchedVccParams): Observable<MatchedVccResponse>  {

    const params:Record<string, string | number | boolean> = {
      pageSize:  page?.pageSize || 25,
      pageNumber : (page?.pageIndex || 0) + 1,
      propertyName: sort?.active || '',
      orderKey: sort?.direction || '',

      ...this.getOrganizedFilters(filters),
    };

    removeEmptyParams(params);
    
    return this.http.get<MatchedVccResponse>(`${this.url}`, {
      params: {...params}
    }).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  applyUpdate(vccs:ApplyVccData[]) {
    return this.http.put(`${this.url}/update-adjustments`, {
      items: vccs
    }).pipe(catchError((error:HttpErrorResponse)=>{
      throw new Error(error?.message || 'Server error');
    }));
  }

  getMatchingDetails(id: string) {
    return this.http.get<MatchingVccDetails>(`${this.url}/matching-details?id=${id}`)
    .pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }
}
