import {Injectable, Inject} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  filter,
  from,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
  throwError
} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {environment} from 'src/environments/environment';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  RedirectRequest
} from '@azure/msal-browser';
import {UserRoleConstants} from '../../app/shared/model/user-role.constants';
import {MsalAzureTokenConstants} from '../../app/shared/model/msal-azure-token.constants';

export interface UserAuth {
  email: string;
  profile: UserAzureProfileDetails | null
}

@Injectable({
  providedIn: 'root'
})
export class AzureAuthService {

  private _userAuth$: BehaviorSubject<UserAuth | null> = new BehaviorSubject<UserAuth | null>(null);
  isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isTokenReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loggedInUserEmail: BehaviorSubject<string> = new BehaviorSubject<string>('');
  loggedInUserName: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private msalService: MsalService,
    private httpClient: HttpClient,
    private msalBroadCastService: MsalBroadcastService,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {
  }

  getUserProfile(): Observable<UserAzureProfileDetails> {
    return this.httpClient.get(environment.graphApiUrl).pipe(
      catchError((error: any) => {
        if (error.status === 401 && error.headers.get('www-authenticate')) {
          this.handleClaimsChallenge(error);
        }
        return throwError(() => new Error(error));
      })
    );
  }

  handleClaimsChallenge(response: any): void {
    const authenticateHeader: string = response.headers.get('www-authenticate');
    const claimsChallenge: any = authenticateHeader
      ?.split(' ')
      ?.find((entry) => entry.includes('claims='))
      ?.split('claims="')[1]
      ?.split('",')[0];
    sessionStorage.setItem(`claimsChallenge`, claimsChallenge);
    this.msalService.instance.acquireTokenRedirect({
      account: this.msalService.instance.getActiveAccount()!,
      scopes: [environment.graphApiScope, environment.fmsApiScope],
      claims: window.atob(claimsChallenge),
    });
  }

  getGraphAzureToken(): Observable<string> {
    const account = this.msalService.instance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: [environment.graphApiScope],
      account: account,
    };

    return from(this.msalService.instance.acquireTokenSilent(accessTokenRequest)).pipe(
      map(({accessToken}: AuthenticationResult) => accessToken),
      catchError((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          this.msalService.instance.acquireTokenRedirect(accessTokenRequest);
        }
        return of('Unable to fetch FMS Graph Library token from Azure');
      })
    );
  }


  getFMSAzureToken(): Observable<string> {
    const account = this.msalService.instance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: [environment.fmsApiScope],
      account: account,
    };

    return from(this.msalService.instance.acquireTokenSilent(accessTokenRequest)).pipe(
      map(accessTokenResponse => accessTokenResponse.accessToken),
      catchError((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          this.msalService.instance.acquireTokenRedirect(accessTokenRequest);
        }
        return of('Unable to fetch FMS Read token from Azure');
      })
    );
  }

  saveAccessTokenToCache(tokenName: string, accessToken: string): void {
    localStorage.setItem(tokenName, accessToken);
  }

  checkAzureTokenIsExpired(tokenName: string): boolean {
    let isExpired = false;
    const jwt = localStorage.getItem(tokenName);
    if (jwt) {
      const jwtPayload = JSON.parse(window.atob(jwt.split('.')[1]))
      isExpired = Date.now() >= jwtPayload.exp * 1000;
    }
    return isExpired;
  }

  checkUserAzureTokenHasAnyRole(): boolean {
    let hasRole = false;
    const jwt = localStorage.getItem(MsalAzureTokenConstants.FmsApiToken);
    if (jwt) {
      const jwtPayload = JSON.parse(window.atob(jwt.split('.')[1]))
      if (jwtPayload.roles && this.checkUserAzureTokenHasAnyFmsRole()) {
        hasRole = true;
      }
    }
    return hasRole;
  }

  checkUserAzureTokenHasAnyFmsRole(): boolean {
    return this.checkUserAzureTokenHasRole(UserRoleConstants.Director)
      || this.checkUserAzureTokenHasRole(UserRoleConstants.Manager)
      || this.checkUserAzureTokenHasRole(UserRoleConstants.Finance)
      || this.checkUserAzureTokenHasRole(UserRoleConstants.User);
  }

  checkUserAzureTokenHasDirectorRole(): boolean {
    return this.checkUserAzureTokenHasRole(UserRoleConstants.Director);
  }

  checkUserAzureTokenHasManagerRole(): boolean {
    return this.checkUserAzureTokenHasRole(UserRoleConstants.Manager);
  }

  checkUserAzureTokenHasFinanceRole(): boolean {
    return this.checkUserAzureTokenHasRole(UserRoleConstants.Finance);
  }

  checkUserAzureTokenHasUserRole(): boolean {
    return this.checkUserAzureTokenHasRole(UserRoleConstants.User);
  }

  checkUserAzureTokenHasAdminRole(): boolean {
    return this.checkUserAzureTokenHasRole(UserRoleConstants.Admin);
  }

  checkUserAzureTokenHasRole(roleName: string): boolean {
    let hasRole = false;
    const jwt = localStorage.getItem(MsalAzureTokenConstants.FmsApiToken);
    if (jwt) {
      const jwtPayload = JSON.parse(window.atob(jwt.split('.')[1]))
      if (jwtPayload.roles) {
        hasRole = jwtPayload.roles.includes(roleName);
      }
    }
    return hasRole;
  }

  fetchAndSaveTokens(): Observable<[string, string]> {
    return combineLatest([
      this.getFMSAzureToken(),
      this.getGraphAzureToken()
    ]).pipe(
      tap(([readToken, graphToken]) => {
        this.saveAccessTokenToCache(MsalAzureTokenConstants.FmsApiToken, readToken);
        this.saveAccessTokenToCache(MsalAzureTokenConstants.GraphApiToken, graphToken);
        this.isTokenReady$.next(true);
      })
    );
  }

  fetchUserProfile(): Observable<UserAzureProfileDetails> {
    return this.fetchAndSaveTokens().pipe(
      switchMap(() => this.getUserProfile())
    );
  }

  updateUserAuthData(userProfile: UserAzureProfileDetails | null): void {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      activeAccount = this.authService.instance.getAllAccounts()[0];
    }

    this._userAuth$.next({
      email: activeAccount ? activeAccount.username : '',
      profile: userProfile
    } as UserAuth);
  }

  registerAuth(destroy$: Observable<void>) {
    this.msalBroadCastService.msalSubject$.pipe(
      filter((message: EventMessage) => message.eventType === EventType.LOGIN_SUCCESS),
      takeUntil(destroy$))
      .subscribe((message: EventMessage) => {
        const authResult = message.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(authResult.account);
      });

    const userLoggedIn$: Observable<boolean> = this.msalBroadCastService.inProgress$.pipe(
      filter(interactionStatus => interactionStatus === InteractionStatus.None),
      map(() => this.authService.instance.getAllAccounts().length > 0)
    );

    userLoggedIn$.pipe(
      switchMap((isUserLoggedIn): Observable<[boolean, UserAzureProfileDetails | null]> => {
        if (isUserLoggedIn) {
          let activeAccount = this.authService.instance.getActiveAccount();
          this.loggedInUserEmail.next(activeAccount ? activeAccount.username : '');
          this.loggedInUserName.next(activeAccount?.name ? activeAccount.name : '');

          return this.fetchUserProfile().pipe(
            map(userProfile => ([isUserLoggedIn, userProfile]))
          );
        } else {
          return of([false, null]);
        }
      }),
      takeUntil(destroy$),
      tap(([isUserLoggedIn, userProfile]) => {
        this.isUserLoggedIn.next(isUserLoggedIn);

        if (!isUserLoggedIn) {
          this.login();
          return;
        }

        this.updateUserAuthData(userProfile)
      })
    ).subscribe();
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest)
    } else {
      this.authService.loginRedirect();
    }
  }

  getUserAuth$(): Observable<UserAuth | null> {
    return this._userAuth$.asObservable();
  }
}

export type UserAzureProfileDetails = {
  id?: string,
  userPrincipalName?: string,
  businessPhones?: Array<string>,
  displayName?: string,
  givenName?: string,
  jobTitle?: string,
  mail?: string,
  mobilePhone?: string,
  officeLocation?: string,
  preferredLanguage?: string,
  surname?: string
};
