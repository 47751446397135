import {Inject} from '@angular/core';
import {Component} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-addedit-vendor-modal',
  templateUrl: './addedit-vendor-modal.component.html'
})
export class AddEditVendorModalComponent {
  vendorData: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditVendorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnChanges() {
    this.vendorData = this.data;
  }

  ngOnInit(): void {
    this.vendorData = this.data;
  }

  closeVendorPopUpDialog() {
    this.dialogRef.close();
  }

  getSelectedVendorInfo(data: any) {
    this.vendorData = data;
  }
}
