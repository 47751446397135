<form class="filter" [formGroup]="hsProcessingFilterForm" (submit)="applyMatchingFilters()">
<modal-panel
    data-testid="hs-processing-filter-modal-panel"
    class="matching-vcc-filter-modal-panel-cls filter-panel"
    [title]="'Filter'"
    (close)="handleBookingFiltersFormCloseEvent();"
    >
        <div data-testid="hs-processing-filter-modal-div-2" class="filter__fields">
            <mat-grid-list class="modal-filter-col-flex" [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Date created from</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="createdDateFrom">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        @if(hsProcessingFilterForm.controls['createdDateFrom'].value){
                            <button 
                                data-testid="hs-processing-date-created-from-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('createdDateFrom')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        }
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Date created to</mat-label>
                        <input matInput [matDatepicker]="picker2" formControlName="createdDateTo">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker #picker2></mat-datepicker>
                        @if(hsProcessingFilterForm.controls['createdDateTo'].value){
                            <button 
                                data-testid="hs-processing-date-created-to-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('createdDateTo')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Adx reference</mat-label>
                        <input matInput placeholder="Adx Reference" value="" formControlName="adxReference">
                        @if(hsProcessingFilterForm.controls['adxReference'].value){
                            <button 
                                data-testid="hs-processing-adx-reference-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('adxReference')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>HS ticket</mat-label>
                        <input matInput placeholder="HS ticket" value="" formControlName="hsTicketNumber">
                        @if(hsProcessingFilterForm.controls['hsTicketNumber'].value){
                            <button 
                                data-testid="hs-processing-hs-ticket-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('hsTicketNumber')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="1" gutterSize="10px" rowHeight="85">
                <mat-grid-tile>
                    <p class="w-25-vw ">Invalid</p>
                    <mat-radio-group class="w-100" formControlName="invalid" aria-label="is-invalid" [color]="'primary'">
                        <mat-radio-button value="true">Yes</mat-radio-button>
                        <mat-radio-button value="false">No</mat-radio-button>
                    </mat-radio-group>
                    <button 
                        data-testid="hs-processing-is-invalid-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearMatchingFields('invalid')">
                            <mat-icon>close</mat-icon>
                    </button>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <ng-container data-testid="hs-processing-modal-footer-container" modalPanelFooter>
            <div class="modal-footer">
                <button data-testid="hs-processing-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearMatchingFields()">
                    <mat-icon data-testid="hs-processing-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
                    Clear All
                </button>
                <button data-testid="hs-processing-modal-button" mat-raised-button color="primary" type="submit">Apply</button>
            </div>
        </ng-container>
</modal-panel>
</form>