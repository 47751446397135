<widget data-testid="aggregated-vcc-widget" title="Total VCCs Charged" [subTitle]="'Shows the aggregated charged card data.'+ (dateFilterText | async)">
  <div data-testid="aggregated-vcc-chart-wrap" class="widget-chart-wrap" widgetContent>
    <chart data-testid="aggregated-vcc-chart"
      [dataSet]="dataAggregated"
      [chartType]="'bar'"
      [useZoom]="true"
      (chartClick)="navigateToReport($event)"
      [options]="aggregatedVccChartOptions">
    </chart>
  </div>
</widget>