<form id="request-action-form" [formGroup]="requestActionForm" class="template-form">
  <div id="request-action-loading-div" *ngIf="!(loading$ | async)">
    <div>
      <mat-label id="request-action-booking-label" class="title center" *ngIf="_booking!=undefined">Request action for booking '{{_booking.bookingName}}'</mat-label>
    </div>
    <div>
      <div class="row">
        <div class="col-md-9">
          <mat-error id="request-action-form-error" *ngIf="requestActionForm.invalid">Form is invalid</mat-error>
        </div>
      </div>
      <div id="request-action-row" class="row" *ngIf="_booking!=undefined">
        <div class="col-md-9">
          <mat-label id="request-action-booking-details-label" class="title">Booking Details: </mat-label>
          <mat-form-field id="request-action-booking-details-field">
            <input id="request-action-booking-details-input" matInput disabled value="{{_booking.bookingDescription !== undefined ? this._booking.bookingDescription : this._booking.bookingName }}">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <div >
            <mat-label id="request-action-action-label" class="title">Action:</mat-label>
          </div>
          <br/>
          <mat-form-field id="request-action-action-field">
            <mat-select id="request-action-action-select" [formControl]="actionSelectControl" (selectionChange)="onSelectAction($event.value)">
              <mat-option id="request-action-default-option">--Select an action--</mat-option>
              <mat-option id="request-action-action-option-{{i}}" *ngFor="let action of actionsList; let i = index"
                          [value]="action.id" (click)="submitAction()">
                {{action.name}}
              </mat-option>
            </mat-select>
            <mat-error id="request-action-action-error" *ngIf="actionSelectControl.hasError('required')">Please choose an action</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="center">
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <ng-container id="request-action-container" #container></ng-container>
  </div>
</form>
