<modal-panel 
    id="fms-vcc-pay-modal-panel"
    class="fms-vcc-pay-modal-panel-cls"
    [title]="'Pay'"
    (close)="handleFmsPayFiltersFormCloseEvent()">
        <form [formGroup]="fmsPayForm" (submit)="submitAmount()">
            <mat-grid-list cols="1" gutterSize="10px" rowHeight="85">
                <mat-grid-tile>
                    <mat-form-field>
                      <mat-label id="fms-vcc-pay-amount-field-title">Amount</mat-label>
                      <input
                        id="fms-vcc-pay-amount-to-input-field"
                        matInput
                        formControlName="amount"
                        >
                        @if(fmsPayForm.controls['amount'].value){
                            <button 
                                id="fms-vcc-pay-amount-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAmountFilter()">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                        @if (fmsPayForm.invalid) {
                            <mat-error>{{errorMessage}}</mat-error>
                        }
                    </mat-form-field>
                  </mat-grid-tile>
            </mat-grid-list>
            <ng-container id="booking-filter-modal-footer-container" modalPanelFooter>
                <button id="booking-filter-modal-button" mat-raised-button color="primary" type="submit" [disabled]="disabledSubmit">Apply</button>
            </ng-container>
        </form>
</modal-panel>