<div class="table-responsive">
  <mat-table
    [dataSource]="dataSource"
    (matSortChange)="handleSortChange($event)"
    matSort>
    <ng-container matColumnDef="id">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.id}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="started">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Started</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.started  | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="finished">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Finished</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.finished  | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="elapsed">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Elapsed</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.elapsed}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="correlationId">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Correlation Id</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.correlationId}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="httpMethod">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Http Method</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.httpMethod}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="schema">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Schema</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.schema}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="host">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Host</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.host}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="path">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Path</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.path}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="queryString">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Query String</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.queryString}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="requestHeaders">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Request Headers</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.requestHeaders}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="requestBody">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Request Body</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.requestBody}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="responseStatus">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Response Status</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.responseStatus}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="responseHeaders">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Response Headers
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.responseHeaders}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="responseBody">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Response Body</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.responseBody}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="localIp">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Local Ip</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.localIp}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="localPort">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Local Port</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.localPort}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="remoteIp">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Remote Ip</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.remoteIp}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="remotePort">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Remote Port</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.remotePort}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="exception">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Exception</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.exception}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="dateCreated">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.dateCreated  | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="userCreated">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>User Created</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.userCreated}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="userUpdated">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>User Updated</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.userUpdated}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="dateUpdated">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Date Updated</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.dateUpdated  | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="isDeleted">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Is Deleted</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.isDeleted}}</mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumns">
    </mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
  <mat-paginator
    [length]="pagination.totalItemCount"
    [hidePageSize]="true"
    [pageSize]="pagination.pageSize"
    (page)="pageChanged($event)">
  </mat-paginator>
</div>
