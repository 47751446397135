import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {MatSort} from '@angular/material/sort';
import {Pagination} from '../../../../infrastructure/pagination/model/pagination';
import {MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {Trip} from '../../model/trip';
import {TripService} from '../../service/trip.service';
import {TripFilter} from '../../model/trip-filter';
import {TripFilterResult} from '../../model/trip-filter-result';
import {SearchTripResponse} from '../../model/search-trip-response';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'trip-list',
  templateUrl: './trip-list.component.html',
})
@AutoUnsubscribe()
export class TripListComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort, {static: false})
  public sort!: MatSort;

  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  public dataSource: MatTableDataSource<Trip> = new MatTableDataSource<Trip>([]);

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  public displayedColumns: Array<string> = [
    'id',
    'tripName',
    'externalNumber',
    'externalReference',
    'tripExternalStatusType',
    'tripStartDate',
    'tripEndDate',
    'dateCreated',
    'isCancelled',
    'isArchived',
    'invoicesCount'
  ];

  isLoading: boolean = false;

  public pagination: Pagination = {} as Pagination;
  public tripFilter: TripFilter = {} as TripFilter;

  constructor(
    private tripService: TripService,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe)
    {  }

  public ngOnInit(): void {
    this.tripService.getTripFilter().subscribe((filter: TripFilterResult) => {
      if (this.paginator !== undefined) {
        this.paginator.pageIndex = 0;
        this.tripFilter.pageNumber = 1;
      }
      this.tripFilter.propertyName = 'dateCreated';
      this.tripFilter.orderKey = 'DESC';
      this.tripFilter.withInvoice = filter.withInvoice;
      this.tripFilter.tripName = filter.tripName;
      this.tripFilter.externalNumber = filter.externalNumber;
      this.tripFilter.externalReference = filter.externalReference;
      this.tripFilter.externalStatusTypeId = filter.externalStatusTypeId;
      this.tripFilter.tripStartDateFrom = filter.tripStartDateFrom ? moment(filter.tripStartDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.tripFilter.tripStartDateTo = filter.tripStartDateTo ? moment(filter.tripStartDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.tripFilter.tripEndDateFrom = filter.tripEndDateFrom ? moment(filter.tripEndDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.tripFilter.tripEndDateTo = filter.tripEndDateTo ? moment(filter.tripEndDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.tripFilter.isCancelled = filter.isCancelled;
      this.tripFilter.isArchived = filter.isArchived;

      this.searchTrips(new PageEvent(), false);
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public searchTrips(pageEvent: PageEvent, skipPager: boolean): void {
    if (!skipPager) {
      this.setPaginationAfterPageChanged(pageEvent);
    }
    this.tripService
      .searchTrips(this.tripFilter)
      .subscribe((data: SearchTripResponse) => {
        if (data) {
          this.pagination = data.pagination;
          this.tripFilter.pageNumber = data.pagination.pageNumber;
          this.dataSource = new MatTableDataSource<Trip>(data.items);
          this.isLoading = false;
        }
      });
  }

  public pageChanged(event: PageEvent): void {
    this.searchTrips(event, false);
  }

  public setPaginationAfterPageChanged(pageEvent: PageEvent): void {
    this.tripFilter.pageSize = 25;

    const isStepForward = pageEvent.pageIndex > pageEvent.previousPageIndex!;

    if (isStepForward) {
      this.tripFilter.pageNumber += 1;
    } else {
      this.tripFilter.pageNumber = this.tripFilter.pageNumber !== undefined &&  this.tripFilter.pageNumber > 1 ? this.tripFilter.pageNumber-1 : 1;
    }
  }

  public handleSortChange(sort: any): void {
    this.tripFilter.propertyName = sort.active;
    this.tripFilter.orderKey = sort.direction.toUpperCase();

    this.searchTrips(new PageEvent(), false);
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.tripService
      .searchTrips(this.tripFilter).subscribe((data: SearchTripResponse) => {
        this.exportGridService.getByDataSource(
          'dbov2/trip/pagination',
          'trips',
          {
            ... this.tripFilter,
            pageSize: data?.pagination?.totalItemCount || 100,
          },
          (data)=>{
            const record = data as Trip;
            return {
              'Trip Name': record?.tripName,
              'External Number': record?.externalNumber,
              'External Reference': record?.externalReference,
              'External Status Type': record?.tripExternalStatusType?.displayName,
              'Trip Start Date': this.datePipe.transform(record?.tripStartDate, 'M/d/yyyy'),
              'Trip End Date': this.datePipe.transform(record?.tripEndDate, 'M/d/yyyy'),
              'Date Created': this.datePipe.transform(record?.dateCreated, 'M/d/yyyy'),
              'Cancelled': record?.isCancelled ? "Yes" : "No",
              'Archived': record?.isArchived ? "Yes" : "No",
              'Invoice Count': record?.adxInvoices?.length
            };
          }
        );
        downloadSubs$?.unsubscribe();
      });
  }

  replayGrid (){
    this.searchTrips(new PageEvent(), false);
  }

  invoicesCount(adxInvoices: any): number {
    return adxInvoices?.length;
  }
}
