import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VendorService} from 'src/app/vendor/service/vendor.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {UpdateVendor} from 'src/app/vendor/model/update-vendor';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {
  isLoadingSpinner: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  confirmationVendorMsg: string = '';
  public _updateVendor: UpdateVendor = {
    vendorId: 0,
    vendorContactPerson: '',
    vendorContactPhone: '',
    vendorContactEmail: '',
    vendorWebSite: ''
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private vendorService: VendorService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public _location: Location
  ) {
  }

  ngOnInit() {
    if (this.data.vendor.transactionBookingId !== undefined && this.data.vendor.transactionBookingId !== null && this.data.vendor.transactionBookingId > 0) {
      this.confirmationVendorMsg = 'Are you sure you want to save \'' + this.data.vendor.vendorName + '\' vendor information and assign it to the booking no. \'' + this.data.vendor.transactionBookingId + '\'?';
    } else {
      this.confirmationVendorMsg = 'Are you sure you want to save \'' + this.data.vendor.vendorName + '\' vendor information?';
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  okClick(): void {
    this.isLoadingSpinner = true;
    if (this.data.vendorId === undefined || this.data.vendorId <= 0) {
      this.vendorService.createVendor(this.data.vendor)
        .subscribe({
          next: (response) => {
            this.isLoadingSpinner = false;
            this.dialogRef.close();
            if (this.data.vendor.transactionBookingId !== undefined && this.data.vendor.transactionBookingId !== null && this.data.vendor.transactionBookingId > 0) {
              this._router.routeReuseStrategy.shouldReuseRoute = () => false;
              this._router.navigate([decodeURI(this._location.path())]);
            }
            this._snackBar.open('Successfully new vendor information saved in the system with the vendor id: ' + response.vendorId, 'Dismiss', {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['message-position', 'app-notification-success']
            });
            this.data.addEditVendorDialogRef.close();
          },
          error: () => {
            this.isLoadingSpinner = false;
            this.dialogRef.close();
            this.data.addEditVendorDialogRef.close();
          }
        });
    } else if (this.data.vendorId > 0) {
      this._updateVendor.vendorId = this.data.vendorId;
      this._updateVendor.vendorContactPerson = this.data.vendor.vendorContactPerson;
      this._updateVendor.vendorContactEmail = this.data.vendor.vendorContactEmail;
      this._updateVendor.vendorContactPhone = this.data.vendor.vendorContactPhone;
      this._updateVendor.vendorWebSite = this.data.vendor.vendorWebSite;
      this.vendorService.updateVendor(this._updateVendor)
        .subscribe({
          next: (response) => {
            this.isLoadingSpinner = false;
            this.dialogRef.close();
            this._snackBar.open('Successfully vendor information have been updated in the system with the vendor id: ' + response.vendorId, 'Dismiss', {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['message-position', 'app-notification-success']
            });
            this.data.addEditVendorDialogRef.close();
          },
          error: () => {
            this.isLoadingSpinner = false;
            this.dialogRef.close();
            this.data.addEditVendorDialogRef.close();
          }
        });
    }
  }
}
