import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {AzureAppConfigurationService} from './infrastructure/services/azure-app-configuration.service';
import {AppInsightsService} from './infrastructure/services/app-insights.service';

// This is entrypoint for application.
// Before bootstrapping our application we have to get configurations for it from Azure.
new AzureAppConfigurationService()
  .InitializeAsync()
  .then(_ => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => new AppInsightsService().logException(err));
  })

