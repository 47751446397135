/**
 * This file contains authentication parameters. Contents of this file is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in app.module.ts file.
 */

import {LogLevel, Configuration, BrowserCacheLocation} from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
//Configuration object to be passed to MSAL instance on creation.
export let msalConfig: Configuration = {
  auth: {
    clientId: '',
    authority: '',
    redirectUri: '',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. sessionStorage is more secure, but localStorage gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to true if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback() {
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

/*
 * Scopes you add here will be prompted for user consent during sign-in.
 * For more information about scopes, visit: https://learn.microsoft.com/en-us/azure/active-directory/develop/scopes-oidc
 */
export const loginRequest = {
  scopes: ['email'],
};
