import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Optional, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {ModalService} from '../../service/modal/modal.service';
import {IPerson} from '../../model/person';
import {ApplicationConstants} from '../../constants/application.constants';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {AzureAuthService} from '../../../../infrastructure/services/azure-auth.service';
import {Observable, map, startWith} from 'rxjs';

export interface FilterConfig {
  from?: Date | null;
  to?: Date | null;
  email?: string | null;
}

export interface FilterResult {
  from?: Date | null;
  to?: Date | null;
  email?: string | null;
  name?: string | null;
}

@AutoUnsubscribe()
@Component({
  selector: 'filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit {
  @Input() config!: FilterConfig;
  @Output() applyFilter: EventEmitter<FilterResult> =
    new EventEmitter<FilterResult>();
  persons: IPerson[] = [];
  selectedPersonEmail: string = '';
  selectedPersonFullName: string = '';
  public isLoggedInUserDirector: boolean = false;
  private appConstants: ApplicationConstants = new ApplicationConstants;
  personListFilteredOptions: Observable<IPerson[]> | undefined;

  constructor(
    @Optional() public dialogRef: MatDialogRef<FilterModalComponent>,
    private modalService: ModalService,
    private azureAuthService: AzureAuthService
  ) {
  }

  form: FormGroup = new FormGroup({
    from: new FormControl(null, [Validators.required]),
    to: new FormControl(null, [Validators.required]),
    email: new FormControl<string | IPerson>('', [Validators.required])
  });

  handleClose() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  ngOnInit() {
    this.isLoggedInUserDirector = this.azureAuthService.checkUserAzureTokenHasDirectorRole();
    if (this.isLoggedInUserDirector) {
      this.getHsPersonListData();
    } else {
      const {from, to} = this.config ?? {};
      this.azureAuthService.loggedInUserEmail.subscribe(x => {
        this.selectedPersonEmail = x;
        this.form.patchValue({
          from,
          to,
          x
        });
      });
      this.azureAuthService.loggedInUserName.subscribe(x => {
        this.selectedPersonFullName = x;
      });
      this.form.get('email')?.setValidators(null);
    }
  }

  patchFilterForm() {
    const {from, to} = this.config ?? {};
    this.form.patchValue({
      from,
      to
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['config']) {
      this.patchFilterForm();
    }
  }

  apply() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    else if(this.isLoggedInUserDirector && this.personEmailControl.value.email !== "AllUser" && !this.selectedPersonEmail && !this.isValidEmail(this.personEmailControl.value.email)){
      this.form.controls['email'].markAsTouched();
      return;
    }

    let {from, to, email} = this.form.value;
    const name = this.selectedPersonFullName;
    email = typeof email === 'object' ? email.email : this.selectedPersonEmail;
    const result = {from, to, email, name};
    if (this.dialogRef) {
      this.dialogRef.close(result);
      return;
    }

    this.applyFilter.emit(result);
  }

  get fromDateControl() {
    return this.form.get('from') as FormControl;
  }

  get toDateControl() {
    return this.form.get('to') as FormControl;
  }

  get personEmailControl() {
    return this.form.get('email') as FormControl;
  }

  getHsPersonListData() {
    let httpParams = new HttpParams({fromObject: {All: false, PropertyName: 'FirstName', PageSize: 1000}});
    this.modalService.getHsPersonData(httpParams)
      .subscribe((data: any) => {
        if (data) {
          this.persons = data.items as IPerson[];
        }
        const allUserItemNode: IPerson = {
          personId: 0,
          firstName: 'All',
          lastName: 'User',
          email: this.appConstants.AllUser
        };
        this.persons.unshift(allUserItemNode);
        this.personListFilteredOptions = this.form.controls['email'].valueChanges.pipe(
          startWith(''),
          map(value => {
            const userName = typeof value === 'string' ? value : value?.firstName + " " + value?.lastName;
            this.selectedPersonEmail = (typeof value !== 'string' && value?.email) ? value.email : '';
            return userName ? this._filter(userName) : this.persons.slice();
          }),
        );
        const selectedPerson = this.persons.find(option => option.email.toLowerCase().includes((this.config.email ?? "").toLowerCase()));
        this.form.controls['email'].setValue(selectedPerson ?? null);
        this.patchFilterForm();
      });
  }

  displaySelectedPersonFullName(selectedPerson: IPerson): string {
    return typeof selectedPerson !== 'string' ? selectedPerson.firstName + " " + selectedPerson.lastName : "";
  }

  private _filter(userName: string): IPerson[] {
    return this.persons.filter(option => (option.firstName + " " + option.lastName).toLowerCase().includes(userName.toLowerCase()));
  }

  isValidEmail(searchedUserEmail:string):boolean
  {
    const regexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regexp.test(searchedUserEmail);
  }

  onPersonSelection(selectedPerson: IPerson) {
    this.selectedPersonEmail = selectedPerson.email;
    let person = this.persons.find(option => option.email === selectedPerson.email);
    this.selectedPersonFullName = person?.firstName + ' ' + person?.lastName;
  }
}
