<form class="filter" [formGroup]="form" (submit)="applyTripFilters()">
  <modal-panel data-testid="trip-filter-modal-panel" class="trip-filter-modal-panel-cls filter-panel" [title]="'Filter'" (close)="handleTripFiltersFormCloseEvent()">
    <div data-testid="trip-filter-modal-div-2" class="filter__fields">
      <mat-grid-list cols="1" gutterSize="10px" rowHeight="85">
        <mat-grid-tile>
          <p style="width: 25vw;">With invoice</p>
          <mat-radio-group formControlName="withInvoice" aria-label="matching-mode" [color]="'primary'" style="width: 100%;">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
          <button 
              id="matching-is-mapped-clear-filter-button"
              matSuffix mat-icon-button
              type="button"
              (click)="clearTripFilters('withInvoice')">
                  <mat-icon>close</mat-icon>
          </button>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list data-testid="trip-list-grid-list" [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
        <mat-grid-tile data-testid="trip-filter-name-grid-tile">
          <mat-form-field data-testid="trip-filter-name-form-field">
            <mat-label data-testid="trip-filter-name-label">Trip Name</mat-label>
            <input
              matInput
              [(ngModel)]="config.tripName"
              formControlName="tripName"
              data-testid="trip-filter-name-input">
              @if(form.controls['tripName'].value){
                <button 
                    data-testid="trip-filter-name-clear-filter-button"
                    matSuffix mat-icon-button
                    type="button"
                    (click)="clearTripFilters('tripName')">
                    <mat-icon>close</mat-icon>
                </button>
              }
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile data-testid="trip-filter-external-number-grid-tile">
          <mat-form-field data-testid="trip-filter-form-field">
            <mat-label data-testid="trip-filter-external-number-label">External Number</mat-label>
            <input
              matInput [(ngModel)]="config.externalNumber"
              formControlName="externalNumber"
              data-testid="trip-filter-external-number-input">
              @if(form.controls['externalNumber'].value){
                <button 
                    data-testid="trip-filter-external-number-clear-filter-button"
                    matSuffix mat-icon-button
                    type="button"
                    (click)="clearTripFilters('externalNumber')">
                    <mat-icon>close</mat-icon>
                </button>
              }
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile data-testid="trip-filter-external-reference-grid-tile">
          <mat-form-field data-testid="trip-filter-external-reference-form-field">
            <mat-label data-testid="trip-filter-external-reference-label">External Reference</mat-label>
            <input
              matInput [(ngModel)]="config.externalReference"
              formControlName="externalReference"
              data-testid="trip-filter-external-reference-input">
              @if(form.controls['externalReference'].value){
                <button 
                    data-testid="trip-filter-external-reference-clear-filter-button"
                    matSuffix mat-icon-button
                    type="button"
                    (click)="clearTripFilters('externalReference')">
                    <mat-icon>close</mat-icon>
                </button>
              }
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile data-testid="trip-filter-external-status-type-grid-tile">
          <mat-form-field data-testid="trip-filter-external-status-type-form-field">
            <mat-label data-testid="trip-filter-external-status-type-label">External Status Type</mat-label>
            <mat-select data-testid="trip-filter-external-status-type-select" formControlName="externalStatusTypeId">
              <mat-option *ngFor="let status of tripExternalStatusTypes" [value]="status.id">
                {{ status.displayName }}
              </mat-option>
            </mat-select>
            @if(form.controls['externalStatusTypeId'].value){
              <button
                  data-testid="trip-filter-external-status-type-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearTripFilters('externalStatusTypeId')">
                  <mat-icon>close</mat-icon>
              </button>
            }
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <mat-label data-testid="trip-filter-start-date-from-field-title">Trip start from</mat-label>
            <input
              data-testid="trip-filter-start-date-from-input-field"
              matInput [matDatepicker]="picker1"
              formControlName="tripStartDateFrom">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker #picker1 data-testid="trip-filter-start-date-from-datepicker"></mat-datepicker>
            @if(form.controls['tripStartDateFrom'].value){
              <button 
                  data-testid="trip-filter-start-date-from-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearTripFilters('tripStartDateFrom')">
                      <mat-icon>close</mat-icon>
              </button>
            } @else {
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
            }
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <mat-label data-testid="trip-filter-start-date-to-field-title">Trip start to</mat-label>
            <input
              data-testid="trip-filter-start-date-to-input-field"
              matInput [matDatepicker]="picker2"
              formControlName="tripStartDateTo">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker #picker2 data-testid="trip-filter-start-date-to-datepicker"></mat-datepicker>
            @if(form.controls['tripStartDateTo'].value){
              <button 
                  data-testid="trip-filter-start-date-to-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearTripFilters('tripStartDateTo')">
                      <mat-icon>close</mat-icon>
              </button>
            } @else {
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            }
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <mat-label data-testid="trip-filter-end-date-from-field-title">Trip end from</mat-label>
            <input
              data-testid="trip-filter-end-date-from-input-field"
              matInput [matDatepicker]="picker3"
              formControlName="tripEndDateFrom">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker #picker3 data-testid="trip-filter-end-date-from-datepicker"></mat-datepicker>
            @if(form.controls['tripEndDateFrom'].value){
              <button 
                  data-testid="trip-filter-end-date-from-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearTripFilters('tripEndDateFrom')">
                      <mat-icon>close</mat-icon>
              </button>
            } @else {
                <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
            }
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <mat-label data-testid="trip-filter-end-date-to-field-title">Trip end to</mat-label>
            <input
              data-testid="trip-filter-end-date-to-input-field"
              matInput [matDatepicker]="picker4"
              formControlName="tripEndDateTo">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker #picker4 data-testid="trip-end-date-to-datepicker"></mat-datepicker>
            @if(form.controls['tripEndDateTo'].value){
              <button 
                  data-testid="trip-filter-end-date-to-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearTripFilters('tripEndDateTo')">
                      <mat-icon>close</mat-icon>
              </button>
            } @else {
                <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
            }
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile data-testid="trip-filter-cancelled-grid-tile">
          <mat-form-field data-testid="trip-filter-cancelled-form-field">
            <mat-label data-testid="trip-filter-cancelled-label">Cancelled</mat-label>
            <mat-select data-testid="trip-filter-cancelled-select" formControlName="isCancelled">
              <mat-option value=""></mat-option>
              <mat-option value="true">Yes</mat-option>
              <mat-option value="false">No</mat-option>
            </mat-select>
            @if(form.controls['isCancelled'].value){
              <button 
                  data-testid="trip-filter-cancelled-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearTripFilters('isCancelled')">
                  <mat-icon>close</mat-icon>
              </button>
            }
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile data-testid="trip-filter-archived-grid-tile">
          <mat-form-field data-testid="trip-filter-archived-form-field">
            <mat-label data-testid="trip-filter-archived-label">Archived</mat-label>
            <mat-select data-testid="trip-filter-archived-select" formControlName="isArchived">
              <mat-option value=""></mat-option>
              <mat-option value="true">Yes</mat-option>
              <mat-option value="false">No</mat-option>
            </mat-select>
            @if(form.controls['isArchived'].value){
              <button
                  data-testid="trip-filter-archived-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearTripFilters('isArchived')">
                  <mat-icon>close</mat-icon>
              </button>
            }
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <ng-container data-testid="trip-filter-modal-footer-container" modalPanelFooter>
      <div class="modal-footer">
          <button data-testid="trip-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearTripFilters()">
              <mat-icon data-testid="trip-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
              Clear All
          </button>
          <button data-testid="trip-modal-button" mat-raised-button color="primary" type="submit">Apply</button>
      </div>
    </ng-container>
  </modal-panel>
</form>