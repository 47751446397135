<form data-testid="adx-booking-info-form" class="template-form adx-booking-info-form">  
    <div data-testid="adx-booking-info-main-div" class="filter__fields">
        <mat-card class="mat-elevation-z8">
            <mat-card-content>
                <mat-grid-list data-testid="adx-booking-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile data-testid="adx-booking-info-vendor-id-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-vendor-id-field">
                            <mat-label data-testid="adx-booking-info-vendor-id-field-title">Vendor Id: </mat-label>
                            <input data-testid="adx-booking-info-vendor-id-input-field" matInput value="{{_adxBooking.vendor?.id}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-vendor-name-tile">
                        <mat-form-field data-testid="adx-booking-info-vendor-name-field">
                            <mat-label data-testid="adx-booking-info-vendor-name-field-title">Vendor Name: </mat-label>
                            <input data-testid="adx-booking-info-vendor-name-input-field" matInput value="{{_adxBooking.vendor?.vendorName}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-record-locator-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-record-locator-field">
                            <mat-label data-testid="adx-booking-info-record-locator-field-title">Record Locator: </mat-label>
                            <input data-testid="adx-booking-info-record-locator-input-field" matInput value="{{_adxBooking.recordLocator}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-reservation-number-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-reservation-number-field">
                            <mat-label data-testid="adx-booking-info-reservation-number-field-title">Reservation Number: </mat-label>
                            <input data-testid="adx-booking-info-reservation-number-input-field" matInput value="{{_adxBooking.reservationNo}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-invoice-id-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-invoice-id-field">
                            <mat-label data-testid="adx-booking-info-invoice-id-field-title">Invoice Id: </mat-label>
                            <input data-testid="adx-booking-info-invoice-id-input-field" matInput value="{{_adxBooking.adxInvoiceId}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-submit-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-submit-field">
                            <mat-label data-testid="adx-booking-info-submit-field-title">Submit to code: </mat-label>
                            <input data-testid="adx-booking-info-submit-input-field" matInput value="{{_adxBooking.submitToCode}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-depart-date-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-depart-date-field">
                            <mat-label data-testid="adx-booking-info-depart-date-field-title">Depart Date: </mat-label>
                            <input data-testid="adx-booking-info-depart-date-input-field" matInput [value]="formatDate(_adxBooking.departDate)">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-return-date-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-return-date-field">
                            <mat-label data-testid="adx-booking-info-return-date-field-title">Return Date: </mat-label>
                            <input data-testid="adx-booking-info-return-date-input-field" matInput [value]="formatDate(_adxBooking.returnDate)">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-confirmation-number-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-confirmation-number-field">
                            <mat-label data-testid="adx-booking-info-confirmation-number-field-title">Confirmation Number: </mat-label>
                            <input data-testid="adx-booking-info-confirmation-number-input-field" matInput value="{{_adxBooking.confirmationNumber}}">
                        </mat-form-field>
                    </mat-grid-tile>            
                    <mat-grid-tile data-testid="adx-booking-info-data-source-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-data-source-field">
                            <mat-label data-testid="adx-booking-info-data-source-field-title">Data Source: </mat-label>
                            <input data-testid="adx-booking-info-data-source-input-field" matInput value="{{_adxBooking.tramsDataSource?.sourceName}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-total-fare-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-total-fare-field">
                            <mat-label data-testid="adx-booking-info-total-fare-field-title">Total Fare: </mat-label>
                            <input data-testid="adx-booking-info-total-fare-input-field" matInput value="{{_adxBooking.totalFare}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-currency-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-currency-field">
                            <mat-label data-testid="adx-booking-info-currency-field-title">Currency: </mat-label>
                            <input data-testid="adx-booking-info-currency-input-field" matInput value="{{_adxBooking.currency?.currencyName}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-tax1-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-tax1-field">
                            <mat-label data-testid="adx-booking-info-tax1-field-title">Tax 1: </mat-label>
                            <input data-testid="adx-booking-info-tax1-input-field" matInput value="{{_adxBooking.tax1Amt}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-tax2-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-tax2-field">
                            <mat-label data-testid="adx-booking-info-tax2-field-title">Tax 2: </mat-label>
                            <input data-testid="adx-booking-info-tax2-input-field" matInput value="{{_adxBooking.tax2Amt}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-tax3-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-tax3-field">
                            <mat-label data-testid="adx-booking-info-tax3-field-title">Tax 3: </mat-label>
                            <input data-testid="adx-booking-info-tax3-input-field" matInput value="{{_adxBooking.tax3Amt}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-tax4-grid-tile">
                        <mat-form-field data-testid="adx-booking-info-tax4-field">
                            <mat-label data-testid="adx-booking-info-tax4-field-title">Tax 4: </mat-label>
                            <input data-testid="adx-booking-info-tax4-input-field" matInput value="{{_adxBooking.tax4Amt}}">
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-card-content>
        </mat-card>
    </div>
</form>