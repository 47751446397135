import {Injectable} from "@angular/core";
import {ISummary} from '../../../summary/model/summary';
import {Driver} from '../../../summary/model/driver';
import {ChartDataSet} from '../../components/chart/chart.component';

@Injectable({
    providedIn: 'root'
  })
  
  export class ChartService {
    summaryBarChartData!: ChartDataSet;
    
    public createBarChartOptions(data: ISummary[], driver: Driver[] = []) {
        let summaryChartLabels: string[] = [];
        let summaryChartData: number[] = [];
        let summaryChartDataSet: any[] = [];
        if (driver) {
          summaryChartLabels = driver.map(item => item.driverName);
        }
        let barColors = ['#003f5c', '#7a5195', '#36a2eb','#fc60a8', '#ffa600'];
        data.forEach((obj: ISummary, index) => {
          summaryChartData = driver.map((_) => 0);
          obj.drivers.forEach(childObj => {
          let driverIndex = driver.findIndex(x => x.driverId == childObj.driverId);
          summaryChartData[driverIndex] = childObj.timeInMins ? childObj.timeInMins : childObj.value!;
        });
    
        summaryChartDataSet.push({
          data: summaryChartData,
          label: obj.name,
          backgroundColor: barColors[index % barColors.length]
          });
        });

        this.summaryBarChartData = {
          labels: summaryChartLabels,
          datasets: summaryChartDataSet
        };
        return this.summaryBarChartData
    }
    
  }