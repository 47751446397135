import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { ClientFilterModalComponent } from '../filter-modal/client-filter-modal.component';
import { ClientService } from '../../service/client.service';
import { ClientFilterResult } from 'src/app/client/component/filter-modal/client-filter-result';
import { Client, ClientData } from 'src/app/client/service/client.model';
import { ExportGridService } from 'src/app/shared/service/files/export-grid.service';
import { FilterService } from 'src/app/shared/service/filter/filter.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html'
})
export class ClientListComponent implements OnInit {
  dataSource = new MatTableDataSource<Client>([]);
  displayedColumns: string[] = ['firstName', 'middleName', 'lastName', 'primaryAgentName', 'dataSourceName', 'tramsProfileNumber'];
  pagination = { totalItemCount: 0, pageSize: 25, pageIndex: 0 };
  isLoading = false;
  config: ClientFilterResult = {};

  constructor(
    private dialog: MatDialog,
    private filterService: FilterService,
    private clientService: ClientService,
    @Inject(ExportGridService) private exportGridService: ExportGridService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.filterService.clientFilter$.subscribe((filter: ClientFilterResult) => {
      console.log('Filter applied from navbar:', filter);
      this.config = filter;
      this.clientService.setClientFilter(this.config);
      this.pagination.pageIndex = 0;
      this.loadClients();
    });
    this.loadClients();
  }

  openFilterModal(): void {
    const dialogRef = this.dialog.open(ClientFilterModalComponent, {
      width: '400px',
      data: { config: this.config }
    });

    dialogRef.afterClosed().subscribe((result: ClientFilterResult | undefined) => {
      if (result) {
        console.log('Filter applied from modal:', result);
        this.config = result;
        this.clientService.setClientFilter(this.config);
        this.pagination.pageIndex = 0;
        this.loadClients();
      }
    });
  }

  loadClients(): void {
    this.isLoading = true;
    const filters = this.clientService.getClientFilter();
    console.log('Loading clients with filters:', filters);
    this.clientService.getClients(this.pagination.pageIndex + 1, this.pagination.pageSize, filters).subscribe({
      next: (data: ClientData) => {
        console.log('API Response:', data);
        this.dataSource.data = data.items.map((client: Client) => {
          return {
            ...client,
            primaryAgentName: client.agent?.agentName ?? '',
            tramsProfileNumber: client.tramsDataSource?.id ?? '',
            dataSourceName: client.tramsDataSource?.sourceName ?? ''
          };
        });
        this.pagination.totalItemCount = data.pagination.totalItemCount;
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      error: (error: any) => {
        console.error('Error loading clients', error);
        this.isLoading = false;
      }
    });
  }

  handleSortChange(event: Sort): void {
    const data = this.dataSource.data.slice();
    if (!event.active || event.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a: Client, b: Client) => {
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'firstName': return this.compare(a.firstName ?? '', b.firstName ?? '', isAsc);
        case 'middleName': return this.compare(a.middleName ?? '', b.middleName ?? '', isAsc);
        case 'lastName': return this.compare(a.lastName ?? '', b.lastName ?? '', isAsc);
        case 'primaryAgentName': return this.compare(a.primaryAgentName ?? '', b.primaryAgentName ?? '', isAsc);
        case 'dataSourceName': return this.compare(a.dataSourceName ?? '', b.dataSourceName ?? '', isAsc);
        case 'tramsProfileNumber': return this.compare(a.tramsProfileNumber ?? '', b.tramsProfileNumber ?? '', isAsc);
        default: return 0;
      }
    });
  }

  compare(a: string, b: string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  searchClients(event: any): void {
    this.pagination.pageIndex = event.pageIndex;
    this.loadClients();
  }

  replayGrid(): void {
    this.loadClients();
  }

  downloadGridToFile(): void {
    const totalPages = Math.ceil(this.pagination.totalItemCount / this.pagination.pageSize);
    const allData: Client[] = [];
    let currentPage = 1;

    const fetchData = () => {
      if (currentPage > totalPages) {
        this.exportGridService.downloadDataSource(
          {
            items: allData.map(client => ({
              'First Name': client.firstName,
              'Middle Name': client.middleName,
              'Last Name': client.lastName,
              'Primary Agent Name': client.agent?.agentName ?? '',
              'Trams Profile Number': client.tramsDataSource?.id ?? '',
              'Data Source Name': client.tramsDataSource?.sourceName ?? ''
            }))
          },
          'ClientData'
        );
        return;
      }

      this.clientService.getClients(currentPage, this.pagination.pageSize, this.config).subscribe({
        next: (data: ClientData) => {
          allData.push(...data.items);
          currentPage++;
          fetchData();
        },
        error: (error: any) => {
          console.error('Error loading clients for download', error);
        }
      });
    };

    fetchData();
  }
}
