import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder, FormArray} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {AlertCondition, AlertCriteria, AlertDetail} from '../../../shared/model/financial-alerts-result';
import {ChartsVccService} from '../../../dashboard/service/charts-vcc.service';
import {Merchant} from '../../../shared/model/merchant';
import {ParsedMguid} from '../../../dashboard/model/merchant-guid';
import {FinancialAlertsService} from '../../service/financial-alerts.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@AutoUnsubscribe()
@Component({
  selector: 'financial-alerts-modal',
  templateUrl: './financial-alerts-modal.component.html'
})
export class FinancialAlertsModalComponent implements OnInit {
  merchantGuids: Merchant[] = [];
  merchantGuidsIds: string[] = [];
  alertCriteria: AlertCriteria[] = [];
  alertCondition: AlertCondition[] = [];
  alertDetails?: AlertDetail;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: string,
    @Optional() public dialogRef: MatDialogRef<FinancialAlertsModalComponent>,
    private chartsVccService: ChartsVccService,
    private fb: FormBuilder,
    private financialAlertsService: FinancialAlertsService,
    private _snackBar: MatSnackBar,
  ) {  }

  newEmailControl: FormControl = new FormControl('', [Validators.email]);

  form: FormGroup = new FormGroup({
    alertName: new FormControl(null,[Validators.required]),
    description: new FormControl(null),
    merchantGuid: new FormControl(null,[Validators.required]),
    alertCriteria: new FormControl(null,[Validators.required]),
    alertCondition: new FormControl(null,[Validators.required]),
    emailsForm: new FormArray([], [Validators.required]),
    amount: new FormControl('',[Validators.pattern(/^[1-9]\d*$/), Validators.min(1), Validators.required]),
    enabled: new FormControl(true, [Validators.required]),
  });

  ngOnInit(): void {
    this.chartsVccService.getMerchantGuidData().subscribe((mGuid: ParsedMguid) => {
      this.merchantGuids = mGuid.merchantGuid;
      this.merchantGuidsIds = this.merchantGuids.map(merchant => merchant.id);
    });

    this.financialAlertsService.getAlertCriteria().subscribe((alertCriteria)=>{
      this.alertCriteria = alertCriteria;
    });

    this.financialAlertsService.getAlertConditions().subscribe((alertCondition)=>{
      this.alertCondition = alertCondition;
    });

    if (this.dialogData) {
      this.financialAlertsService.getAlertDetails(this.dialogData).subscribe((data)=>{

        const selectedMerchants = data?.workflow?.workflowRules?.filter((rule)=> rule?.isEnabled)
        this.alertDetails = data;
        this.form.patchValue({
          alertName: `${data?.workflow?.name}`,
          description: data?.workflow?.description,
          merchantGuid: selectedMerchants?.map((rule)=> (rule?.workflowRuleConditions[0]?.workflowRuleConditionValue)),
          alertCriteria: `${data?.workflow?.criteriaId}`,
          alertCondition: data?.workflow?.workflowRules[0]?.workflowRuleConditions[1].workflowLogicOperatorId,
          amount: data?.workflow?.workflowRules[0]?.workflowRuleConditions[1].workflowRuleConditionValue,
          enabled: data?.workflow?.isEnabled,
        });

        data?.workflow.workflowNotification.notificationValue.split(';').forEach((email)=>{
          this.emails.push(this.fb.group({email}));
        });
        
        this.form.controls['alertCriteria'].disable();
      });
    }
  }

  applyAlerts() {
    if(this.alertDetails) {
      this.financialAlertsService.updateAlert(this.form.getRawValue(), this.alertDetails).subscribe(()=>{
        this.dialogRef.close(true);
        this._snackBar.open('Alert updated successfully', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        }); 
      });
    } else {
      this.financialAlertsService.setAlert(this.form.getRawValue()).subscribe(()=>{
          this.dialogRef.close(true);
          this._snackBar.open('Alert created successfully', 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });  
      });
    }
  }

  handleClose(): void {
    this.dialogRef?.close();
  }

  get emails(): FormArray {
    return this.form.get('emailsForm') as FormArray;
  }

  addEmail(): void {
    if (this.newEmailControl.valid && this.newEmailControl.value) {
      this.emails.push(this.fb.group({
        email: [this.newEmailControl.value]
      }));

      this.newEmailControl.reset();
    }
  }

  removeEmail(index: number): void {
    this.emails.removeAt(index);
  }

  getMerchantNameById(id?: string): string {
    return this.merchantGuids.find((merchant)=> merchant.id === id)?.name || '';
  }

}
