import {Component, Input} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'app-adx-vcc-viewer',
  templateUrl: './adx-vcc-viewer.component.html'
})

@AutoUnsubscribe()
export class AdxVccViewerComponent {
  @Input() public data: any;
  @Input() public isLoaderVisible: boolean = true;
  _adxVccs: any;
  isLoading: boolean = true;

  constructor(
    private buildr: FormBuilder,
    public dialog: MatDialog) {
  }

  ngOnInit(){
    this._adxVccs = this.data.adxVirtualCreditCards;
    this.isLoading = false;
  }

  adxVccViewerForm = this.buildr.group({
    action: this.buildr.control(''),
    purchaseLogUniqueID: this.buildr.control(''),
    cardGuid: this.buildr.control(''),
  })

  showMore(tabIndex: number){
    this._adxVccs[tabIndex].isVccPaymentGatewayInfoLoaded = true;
  }

}