import {Component} from '@angular/core';

interface Card {
  title: string,
  image: string,
  text: string,
  showText: boolean
}

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html'
})

export class HomeLayoutComponent {
  cards: Card [] = [
    {
      title: 'FinanceFunctionality ',
      image: '../../assets/img/FinanceFunctionality.jpg',
      text: 'Finance Management System provides wide spectre of Finance activities for Trip-Arc Platform- generating Financial Profitability Report, VCC Report, monitor transactions under Finance Reports and manage vendors across TAP tool.',
      showText: false
    },
    {
      title: 'TransactionTeam',
      image: '../../assets/img/TransactionTeam.jpg',
      text: 'FMS provides automation of HelpSpot ticket creation for Transaction Team to work on eligible bookings. Transaction Team can also use the Transactions Dashboard for process summaries and payment reminders.',
      showText: false
    },
    {
      title: 'LifestylyTeam',
      image: '../../assets/img/LifestyleTeam.jpg',
      text: 'Lifestyle Team uses FMS generated reports and Dashboard for daily processes monitoring. FMS provides Lifestyle team with access to actions that can improve work of the team, and the TAP processes in general.',
      showText: false
    },
    {
      title: 'Navision(NAV)Integration.',
      image: '../../assets/img/nav.jpg',
      text: 'FMS provides automation for loading payment transactions to Navision by transforming data records to templates and sending data to NAV journals. FMS also provides tools to map reports with TRAMS General Ledgers to corresponding NAV General Ledgers and generates templates that are ready to be uploaded to NAV.',
      showText: false
    }
  ];

  ngOnInit() {
    this.cards.forEach(card => {
      card.showText = false;
    });
  }

  toggleText(card: Card) {
    card.showText = !card.showText;
  }
}
