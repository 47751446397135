import {Component, EventEmitter, Input, OnInit, ViewChild, Output} from '@angular/core';
import {ChartConfiguration, ChartType} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ChartOptionsService} from './chart-options.service';
import {legendSpacePlugin} from './chart.plugin';

export type ChartDataSet = ChartConfiguration['data'];
export type ChartOptions = ChartConfiguration['options'];

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  host: {
    'class': 'chart'
  }
})
export class ChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  @Input() dataSet!: ChartDataSet;
  @Input() options!: ChartOptions;
  @Input() chartType: ChartType = 'line';
  @Input() useZoom: boolean = false;
  @Output() chartClick: EventEmitter<any> = new EventEmitter<any>();
  public optionsLocal: ChartOptions;

  public plugins = [ChartDataLabels, legendSpacePlugin];


  constructor(private chartOptions: ChartOptionsService) {
  }

  ngOnInit() {
    this.optionsLocal = this.chartOptions.getDefaultOptions(this.chartType);

    if (this.options) {
      if(!this.useZoom) {
          this.optionsLocal = {
            ...this.optionsLocal,
            ...this.options,
          };
      } else {
        this.optionsLocal = {
          ...this.optionsLocal,
          ...this.options,
          onResize: (chart, size) => {
            let resetButton = document.getElementById('resetButton');
            if (!resetButton) {
              resetButton = document.createElement('button');
              resetButton.id = 'resetButton';
              resetButton.innerHTML = '<i class="fa fa-refresh btn-primary"></i>';
              resetButton.classList.add('chart-icon-reseet');
              if (chart.canvas.parentNode) {
                chart.canvas.parentNode.appendChild(resetButton);
              }
            }
            const xPosition = size.width - 40;
            const yPosition = size.height - 10;
            resetButton.style.bottom = `${yPosition}px`;
            resetButton.style.left = `${xPosition}px`;
          }
        };
      }
    }
  }

  ngAfterViewInit() {
    if (this.chart?.chart) {
      this.chart.chart.canvas.onclick = (event) => {
        const chartArea = this.chart?.chart?.chartArea;
        const clickX = event.offsetX;
        const clickY = event.offsetY;
  
        if (chartArea &&
          (clickX < chartArea.left || clickX > chartArea.right ||
          clickY < chartArea.top  - 20 || clickY > chartArea.bottom)
        ) {
          return;
        }
        const activePoints = this.chart?.chart?.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
        if (activePoints?.length) {
          const firstPoint = activePoints[0];
          const label = this.chart?.chart?.data.labels?.[firstPoint.index] ?? [];
          const value = this.chart?.chart?.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
          // TO DO: Validate if more data is needed
          this.chartClick.emit({ label, value });
        }
        if (this.useZoom) {
          this.zoomClick(event, this.chart?.chart);
        }
      };
      document.getElementById('resetButton')?.addEventListener('click', () => {
        this.reset();
      });
    }
  }
  
  zoomClick( event: MouseEvent, chart : any ) { 
    const {scales: {x, y}} = chart;
    const xCenter = (x.min + x.max) / 2;
    const yCenter = (y.min + y.max) / 2;

    if (event.offsetX < chart.width / 2) {
      x.min = x.min;
      x.max = xCenter;
    } else {
      x.min = xCenter;
      x.max = x.max;
    }

    if (event.offsetY < chart.height / 2) {
      y.min = y.min;
      y.max = yCenter;
    } else {
      y.min = yCenter;
      y.max = y.max;
    }

    chart.options.scales.x.min = x.min;
    chart.options.scales.x.max = x.max;
    chart.update();
  }
  
  reset() { 
    if (this.chart?.chart?.config.options?.scales && this.chart?.chart?.config.options?.scales['x']) {
      this.chart.chart.config.options.scales['x'].min = 0;
      this.chart.chart.config.options.scales['x'].max = 14;
      this.chart.chart.update();
    }    
  }
}
