<modal-panel [title]="title" (close)="handleClose()" id="vendor-viewer-modal-panel">
  <div class="vendorview-form" id="vendor-viewer-modal-vendorview-form">
    <ng-container *ngIf="viewerType == vendorViewerType.Vendor; else tcViewer" id="vendor-viewer-modal-vendor-container">
      <app-vendor-viewer [data]="vendorData" id="vendor-viewer-modal-vendor-viewer"></app-vendor-viewer>
    </ng-container>
    <ng-template #tcViewer id="vendor-viewer-modal-tc-viewer-template">
      <app-vendor-tc-viewer [data]="vendorData" [tcType]="viewerType" id="vendor-viewer-modal-tc-viewer"></app-vendor-tc-viewer>
    </ng-template>
  </div>
  <ng-container modalPanelFooter id="vendor-viewer-modal-footer-container">
    <button mat-raised-button color="primary" (click)="handleClose()" id="vendor-viewer-modal-close-button">Close</button>
  </ng-container>
</modal-panel>
