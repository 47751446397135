import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {map} from 'rxjs/operators';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {TransactionBookingService} from 'src/app/transaction-booking/service/transaction-booking.service';
import {TransactionBooking} from 'src/app/transaction-booking/model/transaction-booking';
import {TransactionBookingFilter} from 'src/app/transaction-booking/model/transaction-booking-filter';


@Component({
  selector: 'trip-reference-modal',
  templateUrl: './trip-reference-modal.component.html'
})

@AutoUnsubscribe()
export class TripReferenceComponent {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  @Input() public title!: string;
  private _data: any;

  @Input()
  set data(value: any) {
    this._data = value;
    this.getTripReferenceDetails();
  }

  get data(): any {
    return this._data;
  }
  @Input() public isLoaderVisible: boolean = true;
  _vcc: any;
  isLoading: boolean = true;

  @Output() innerLoadState = new EventEmitter<boolean>(true);

  bookingFilter: TransactionBookingFilter = {} as TransactionBookingFilter;

  constructor(
    private buildr: FormBuilder,
    private _snackBar: MatSnackBar,
    @Optional() public dialogRef: MatDialogRef<TripReferenceComponent>,
    private transactionBookingService: TransactionBookingService) {

  }

  ngOnChanges() {
    this.getTripReferenceDetails();
  }

  getTripReferenceDetails() {
    this.bookingFilter.travelServiceId = this.data.travelServiceId;
    const bookingsObservable = this.transactionBookingService.searchBookings(this.bookingFilter).pipe(
      map(response => response.items[0] as TransactionBooking)
    );

    bookingsObservable.subscribe(transactionBookingRecord => {
      if(transactionBookingRecord)
      {
        this._vcc = transactionBookingRecord;
        this.isLoading = false;
        this.innerLoadState.emit(false);
      }else {
        const message = "No related records found.";
        this._snackBar.open(message, 'Dismiss', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['message-position', 'app-notification-success']
        });
        this.dialogRef.close();
      }
    });
  }

  handleTripReferenceCloseBtnClick() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  tripReferenceForm = this.buildr.group({
    action: this.buildr.control(''),
    purchaseLogUniqueID: this.buildr.control(''),
    cardGuid: this.buildr.control(''),
  })
}
