<div class="confirmpopup" id="vendor-template-confirmation-dialog-confirm-popup">
  <div mat-dialog-title id="vendor-template-confirmation-dialog-title-div">
    <label class="title" id="vendor-template-confirmation-dialog-title-label">Vendor: Confirmation</label>
  </div>
  <hr>
  <div mat-dialog-content id="vendor-template-confirmation-dialog-content-div">
    <p id="vendor-template-confirmation-dialog-content-p">Are you sure you want to save vendor template information?</p>
  </div>
  <hr>
  <div mat-dialog-actions text-align="end" id="vendor-template-confirmation-dialog-actions-div">
    <button type="submit" mat-raised-button (click)="okClick()" color="primary" cdkFocusInitial matTooltip="Save Vendor Template" aria-label="Save Vendor Template" id="vendor-template-confirmation-dialog-ok-button">
      <mat-spinner color="accent" *ngIf="isLoadingSpinner" diameter="30" id="vendor-template-confirmation-dialog-spinner"></mat-spinner>
      <span *ngIf="!isLoadingSpinner" id="vendor-template-confirmation-dialog-ok-span">Ok</span>
    </button>
    <button mat-raised-button (click)="onNoClick()" color="warn" id="vendor-template-confirmation-dialog-no-button">No Thanks</button>
  </div>
</div>
