import {Component, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {Subscription} from 'rxjs';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {HsProcessingModel, HsProcessingFilter} from '../../models/hs-process.component';
import {HsProcessingService} from '../../services/hs-processing.service';
import {ExportGridService} from '../../../shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'hs-processing',
  templateUrl: './hs-processing.component.html',
})
export class HsProcessingComponent implements OnInit {

  displayedColumns: Array<string> = [
    'ServiceId',
    'notificationId',
    'adxReference',
    'agentName',
    'dateCreated',
    'hsTicketNumber',
    'processed',
    'invalid',
    'urgent',
  ];
  hsProcessingFilter$?: Subscription;

  filters: HsProcessingFilter = {};
  items: HsProcessingModel[] = [];
  pagination: Partial<PageEvent & {totalItemCount?: number}> = {};
  sorting: Partial<Sort & { propertyName?: string, orderKey?: string }> = {};

  constructor(
    private hsProcessingService: HsProcessingService,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe,
  ){}

  ngOnInit(): void {
    this.hsProcessingFilter$ = this.hsProcessingService.getHsProcessingFilter().subscribe((filter: HsProcessingFilter)=>{
      this.filters = filter;
      this.pagination = {
        pageSize: this.pagination.pageSize || 25,
        pageIndex: 0,
        totalItemCount: 0,
      };
      this.sorting = {active: 'dateCreated', direction: 'desc'} as Sort;
        this.getItems();
    });
  }

  handleSortChange(sort: Sort): void { 
    this.sorting = sort;

    if(!this.sorting?.active || !this.sorting?.direction) {
      this.sorting = {};
    } else {
      this.sorting.propertyName = this.getMappedFmsVccTblPropertyName(sort.active);
      this.sorting.active = this.getMappedFmsVccTblPropertyName(sort.active);
    }
  
    this.getItems();
  }

  handlePageChanged(page: PageEvent): void {
    this.pagination = {
      ...this.pagination,
      ...page,
    };

    this.getItems();
  }

  getItems(){
    this.hsProcessingService.searchHsProcessing({
      filters: this.filters,
      page: this.pagination,
      sort: this.sorting,
    }).subscribe((response) => {
      this.items = response.items;
      this.pagination = {
        ... this.pagination,
        pageIndex: response.pagination.pageNumber - 1,
        totalItemCount: response.pagination.totalItemCount,
      };
    });
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.hsProcessingService.searchHsProcessing({
      filters: this.filters,
      page: this.pagination,
      sort: this.sorting,
    }).subscribe((response) => {
      this.exportGridService.downloadDataSource(
        response.items,
        'hs-processing',
        (data) => {
          const record = data as HsProcessingModel;
          return {
            'Service Id': record?.serviceId,
            'Notification Id': record?.notificationId,
            'Adx Reference': record?.adxReference,
            'Agent Name': record?.agentName,
            'Date Created': this.datePipe.transform(record?.dateCreated, 'M/d/yyyy'),
            'Hs Ticket Number': record?.hsTicketNumber,
            'Processed': record?.processed ? 'True': 'False',
            'Invalid': record?.invalid ? 'True': 'False',
            'Urgent': record?.urgent ? 'True': 'False',
          }
        }
      );
      downloadSubs$?.unsubscribe();
    });
  }

  getMappedFmsVccTblPropertyName(propertyName: string): string {
    switch (propertyName) {
      case 'serviceId':
        return propertyName;
      case 'notificationId':
        return propertyName;
      case 'adxReference':
        return 'transactionBooking.tripReference';
      case 'agentName':
        return 'transactionBooking.agent.agentName';
      case 'dateCreated':
        return propertyName;
      case 'hsTicketNumber':
        return 'transactionBooking.tasks';
      case 'processed':
        return 'isSynced';
      case 'invalid':
        return 'isInvalid';
      case 'urgent':
        return 'isUrgent';
      default:
        return '';
    }
  }
}
