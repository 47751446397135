import {Injectable} from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {environment} from '../../environments/environment';

// https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {

  private applicationInsights: ApplicationInsights | undefined;

  constructor() {
    if (!environment.instrumentationKey){
      return;
    }

    this.applicationInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
        enableAutoRouteTracking: true
      }
    });
    this.applicationInsights.loadAppInsights();
  }

  public logPageView(name?: string, url?: string): void {
    if (!environment.instrumentationKey){
      return;
    }
    this.applicationInsights?.trackPageView({
      name: name,
      uri: url
    });
  }

  public logEvent(name: string, properties?: { [key: string]: any }): void {
    if (!environment.instrumentationKey){
      return;
    }
    this.applicationInsights?.trackEvent({name: name}, properties);
  }

  public logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    if (!environment.instrumentationKey){
      return;
    }
    this.applicationInsights?.trackMetric({name: name, average: average}, properties);
  }

  public logException(exception: Error, severityLevel?: number): void {
    if (!environment.instrumentationKey){
      console.log(exception);
      return;
    }
    this.applicationInsights?.trackException({exception: exception, severityLevel: severityLevel});
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    if (!environment.instrumentationKey){
      return;
    }
    this.applicationInsights?.trackTrace({message: message}, properties);
  }
}
