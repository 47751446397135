import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, catchError,throwError} from 'rxjs';
import { AdxBookingCopyResponse } from '../model/adx-booking-copy-response';

@Injectable({
  providedIn: 'root'
})

export class AdxBookingService {
  private resourcePath: string = 'dbov2/adx-booking';

  constructor(private httpClient: HttpClient) {
  }

  copyAdxBooking(adxBookingId: number): Observable<AdxBookingCopyResponse> {
    return this.httpClient
      .post<AdxBookingCopyResponse>(`${this.resourcePath}/copy`, { adxBookingId })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message || 'Server Error'));
  }
}
