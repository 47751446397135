<div class="grid-title-event">
    <h2 style="text-align:center" id="matching-vcc-list-title">VCC Matching Report</h2>
    @if (items.length){
        <div class="events-container">
            <button  id="matching-vcc-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getItems()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" id="apply-update-matching-vcc-button" aria-label="Apply Update Matching Vcc" mat-stroked-button (click)="applyUpdate()">Apply Update</button>
            <button color="primary" id="download-matching-vcc-button" aria-label="Download Matching Vcc File" mat-stroked-button (click)="downloadGridToFile()" style="justify-content:flex-end">
                <mat-icon>download</mat-icon>
            </button>
        </div>
    }
</div>
<mat-table id="matching-vcc-table" class="compact-mat-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>
    <ng-container matColumnDef="clientName">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Merchant Name
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.clientName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderNumber">
        <mat-header-cell id="order-number-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            VCC Provider Reference
        </mat-header-cell>
        <mat-cell id="order-number-cell" class="mat-cell" *matCellDef="let element">
            <span>
                <a id="matching-vcc-order-number-cell-link" href="FinanceResources/MatchingVcc/{{element?.connexPayAccountingDailyDetailId}}" target="_blank">
                    {{element?.orderNumber}}
                </a>
            </span>

        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cardLastFour">
        <mat-header-cell id="card-last-four-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            VCC Number
        </mat-header-cell>
        <mat-cell id="card-last-four-cell" *matCellDef="let element">
            <span>{{element?.cardLastFour}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="merchantName">
        <mat-header-cell id="merchant-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            VCC Provider Vendor Name
        </mat-header-cell>
        <mat-cell id="merchant-name-cell" *matCellDef="let element">
            <span>{{element?.merchantName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="releasedDate">
        <mat-header-cell id="released-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            VCC Provider Released Date
        </mat-header-cell>
        <mat-cell id="released-date-cell" *matCellDef="let element">
            <span>{{element?.releasedDate | date:'M/d/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="connexPayAccountingDailyDetailAmount">
        <mat-header-cell id="connexpay-total-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            VCC Provider Total
        </mat-header-cell>
        <mat-cell id="total-cell" *matCellDef="let element">
            <span>{{element?.connexPayAccountingDailyDetailAmount}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="adxCCAmount">
        <mat-header-cell id="adx-cc-amount-total-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Adx Total
        </mat-header-cell>
        <mat-cell id="total-cell" *matCellDef="let element">
            <span>{{element?.adxCCAmount}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tramsBookingTotalFare">
        <mat-header-cell id="trams-total-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Trams Total
        </mat-header-cell>
        <mat-cell id="total-cell" *matCellDef="let element">
            <span>{{element?.tramsBookingTotalFare}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tramsBookingCommAmt">
        <mat-header-cell id="comm-amt-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Agent Commission
        </mat-header-cell>
        <mat-cell id="comm-amt-cell" *matCellDef="let element">
            <span>{{element?.tramsBookingCommAmt}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cogs">
        <mat-header-cell id="cogs-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            COGS
        </mat-header-cell>
        <mat-cell id="cogs-cell" *matCellDef="let element">
            <span>{{element?.cogs}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="adxTripReference">
        <mat-header-cell id="adx-trip-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Adx Trip Reference
        </mat-header-cell>
        <mat-cell id="adx-trip-reference-cell" *matCellDef="let element">
            <span>{{element?.adxTripReference}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tramsInvoiceNumber">
        <mat-header-cell id="invoice-number-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Trams Invoice Number
        </mat-header-cell>
        <mat-cell id="invoice-number-cell" *matCellDef="let element">
            <span>{{element?.tramsInvoiceNumber}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="agentName">
        <mat-header-cell id="agent-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Agent Name
        </mat-header-cell>
        <mat-cell id="agent-name-cell" *matCellDef="let element">
            <span>{{element?.agentName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="adxCardIssueDate">
        <mat-header-cell id="adx-issued-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Adx Issued Date
        </mat-header-cell>
        <mat-cell id="adx-issued-date-cell" *matCellDef="let element">
            <span>{{element?.adxCardIssueDate | date:'M/d/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="travelerName">
        <mat-header-cell id="traveler-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Traveler Name
        </mat-header-cell>
        <mat-cell id="traveler-name-cell" *matCellDef="let element">
            <span>{{element?.travelerName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tramsBookingStartDate">
        <mat-header-cell id="start-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Booking Start Date
        </mat-header-cell>
        <mat-cell id="invoice-number-cell" *matCellDef="let element">
            <span>{{element?.tramsBookingStartDate | date:'M/d/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tramsBookingEndDate">
        <mat-header-cell id="end-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Booking End Date
        </mat-header-cell>
        <mat-cell id="end-date-cell" *matCellDef="let element">
            <span>{{element?.tramsBookingEndDate | date:'M/d/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userApproved">
        <mat-header-cell id="user-approved-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            User Approved
        </mat-header-cell>
        <mat-cell id="user-approved-cell" *matCellDef="let element">
            <span>{{element?.userApproved}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateApproved">
        <mat-header-cell id="datef-approved-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Date Approved
        </mat-header-cell>
        <mat-cell id="date-approved-cell" *matCellDef="let element">
            <span>{{element?.dateApproved | date:'M/d/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="suggestedAdjustmentToCommAmt">
        <mat-header-cell id="suggested-comm-amt-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Suggested Adjustment Commission
        </mat-header-cell>

        <mat-cell 
            id="suggested-comm-amt-cell"
            *matCellDef="let element"
            [ngStyle]="{'background-color': itemColorStyle(element)}">
                    @if(element.isAmountMatches){
                        <span>
                            {{element?.suggestedAdjustmentToCommAmt}}
                        </span>
                    } @else {
                        <span>
                            {{element?.varianceAmount}}
                        </span>
                    }

        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="revertButton">
        <mat-header-cell id="revert-button-header" class="mat-header-cell" *matHeaderCellDef>
            Actions
        </mat-header-cell>
        <mat-cell id="revert-button-cell" *matCellDef="let element">
            <div class="flexible-content">
            @if(element?.isAmountMatches && !element.userApproved && !element.dateApproved){
                <button mat-icon-button (click)="$event.stopPropagation(); applyRevert(element)" matTooltip="{{element?.revert ? 'Undo' : 'Revert'}}"  [matTooltipPosition]="'above'" [disabled]="element?.shouldResolve">
                    <mat-icon>
                        @if(element?.revert){
                            undo
                        } @else {
                            redo
                        }
                    </mat-icon>
                </button>
            }
            @if(element?.isAmountMatches){
                @if(!element?.userApproved && !element?.dateApproved){
                    <mat-checkbox  [checked]="element?.isResolved"  [disabled]="!element?.revert" (change)="setResolve(element)" matTooltip="Resolve" [matTooltipPosition]="'below'"></mat-checkbox>
                }
            } @else {
                <mat-checkbox  [checked]="element?.isResolved"  [disabled]="element?.isResolved" (change)="setResolve(element)" matTooltip="Resolve" [matTooltipPosition]="'below'"></mat-checkbox>
            }

            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

    <mat-row
    *matRowDef="let row; columns: displayedColumns; let i = index">
    </mat-row>
</mat-table>
<mat-paginator id="matching-vcc-paginator" [length]="pagination.totalItemCount" [pageSizeOptions]="[25, 50, 75, 100]"
    [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>