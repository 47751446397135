<mat-toolbar id="navbar-toolbar" class="appbar">
  <mat-toolbar-row id="navbar-toolbar-row">
    <button id="navbar-menu-button" (click)="menuToggled.emit()" mat-icon-button *ngIf="!hideMenuToggle">
      <mat-icon id="navbar-menu-icon">menu</mat-icon>
    </button>
    <button id="navbar-more-button" mat-icon-button *ngIf="isMenuItemsVisibleIfUserHasAnyRole"
            [matMenuTriggerFor]="clickmenu">
      <mat-icon id="navbar-more-icon">more_vert</mat-icon>
    </button>
    <div id="navbar-spacer" class="appbar-spacer"></div>
    <mat-menu id="navbar-click-menu" #clickmenu="matMenu">
      <button id="navbar-menu-{{item.menuLinkId}}-button" mat-menu-item
        *ngFor="let item of menuListItems; let i = index"
        (click)="clickMenuItem(item)"
        [disabled]="item.isDisabled">
        <mat-icon id="navbar-menu-{{item.menuLinkId}}-icon">{{ item.menuIcon }}</mat-icon>
        <span id="navbar-menu-{{item.menuLinkId}}-text"> {{ item.menuLinkText }}</span>
      </button>
    </mat-menu>
    <div id="navbar-full-title" class="full-name">Finance Management System</div>
    <span class="navbar-spacer"></span>
    <span flex></span>
    <span class="navbar-spacer"></span>
    <button id="navbar-filter-button" mat-icon-button (click)="openFilter()" *ngIf="!hideToggleFilterMenuIcon">
      <mat-icon id="navbar-filter-icon">filter_list</mat-icon>
    </button>
    <span id="navbar-login" class="login" *ngIf="!(isUserLoggedIn$ | async); else loggedInView">
      <button id="navbar-login-button" mat-mini-fab matTooltip="Login FMS" [matMenuTriggerFor]="loginMenu" color="accent">
        <mat-icon id="navbar-login-icon">perm_identity</mat-icon>
      </button>
      <mat-menu id="navbar-login-menu" #loginMenu="matMenu">
        <button id="navbar-login-menu-button" mat-menu-item (click)="azureLogin()">
          <mat-icon id="navbar-login-menu-icon">power_settings_new</mat-icon>
          <span id="navbar-login-menu-text">Login</span>
        </button>
      </mat-menu>
    </span>
    <ng-template id="navbar-logged-in-view" #loggedInView>
      <span id="navbar-logout" class="logout" *ngIf="userAzureProfileDetails$ | async as userAzureProfileDetails">
        <button id="navbar-logout-button" mat-icon-button matTooltip="{{userAzureProfileDetails.givenName != undefined ? userAzureProfileDetails.givenName + ', ': ''}}Click here to Logout" [matMenuTriggerFor]="logoutMenu" color="primary">
          <mat-icon id="navbar-logout-icon">perm_identity</mat-icon>
        </button>
        <mat-menu id="navbar-logout-menu" #logoutMenu="matMenu">
          <div id="navbar-logout-menu-div" style="text-align:center;" *ngIf="userAzureProfileDetails.displayName != undefined">
            <mat-label id="navbar-logout-menu-label" style="font-size: 15px;"><b>{{userAzureProfileDetails.displayName}}</b></mat-label><br>
            <span id="navbar-logout-menu-span" *ngIf="userAzureProfileDetails.jobTitle != undefined">
              <mat-label id="navbar-logout-menu-job-title" class="mat-title" style="font-size: 10px;">({{userAzureProfileDetails.jobTitle}})</mat-label><br>
            </span>
            <mat-label id="navbar-logout-menu-user-principal-name" style="font-size: 10px;"><i>{{userAzureProfileDetails.userPrincipalName || userAzureProfileDetails.mail}}</i></mat-label><br>
            <span id="navbar-logout-menu-office-location" *ngIf="userAzureProfileDetails.officeLocation != undefined">
              <mat-label id="navbar-logoutMenu-office-location-label" style="font-size: 10px;">{{userAzureProfileDetails.officeLocation}}</mat-label><br>
            </span>
            <mat-divider id="navbar-logout-menu-divider"></mat-divider>
          </div>
          <button id="navbar-logout-menu-button" mat-menu-item (click)="azureLogout()">
            <mat-icon id="navbar-logout-menu-button-icon">exit_to_app</mat-icon>
            <span id="navbar-logout-menu-button-text">Logout</span>
          </button>
        </mat-menu>
      </span>
    </ng-template>
  </mat-toolbar-row>
</mat-toolbar>
