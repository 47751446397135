import {Component, OnInit} from '@angular/core';
import {ChartsVccService} from '../../../service/charts-vcc.service';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';
import {Observable} from 'rxjs';
import {VccChartFilterResult} from '../../../model/funding-vcc-chart-filter';
 
@Component({
    selector: 'app-vcc-component',
    templateUrl: './vcc.component.html'
})

export class VccComponent implements OnInit {

    public formattedTodayDate: string = '';
    public releasedDateFrom: string = '';
    public totalDays: number = 0;
    public titleWidget: string = 'Selected Date Range Interval';
    public latestReleasedDate$: Observable<VccChartFilterResult>  = new Observable();  
    constructor(private chartsVccService: ChartsVccService,
                private datePipe: DatePipe
    ) {}

    ngOnInit() {
        const today = moment();
        this.latestReleasedDate$ = this.chartsVccService.getVccChartFilter();
        this.latestReleasedDate$.subscribe((releasedDate) => {
            if(releasedDate.releasedDateFrom) {
                this.updateTotalDays(releasedDate.releasedDateFrom);
            }
        })
        this.chartsVccService.getLatestDate().subscribe((latestReleasedDate) => {
            this.releasedDateFrom = this.datePipe.transform(latestReleasedDate, 'EEE MMM dd yyyy') || '';
            this.updateTotalDays(latestReleasedDate);
        })
        this.formattedTodayDate = this.datePipe.transform(today.toDate(), 'EEE MMM dd yyyy') || '';
    }

    private updateTotalDays(latestReleasedDate: string  | Date) {
        const today = moment();
        const startDate = moment(latestReleasedDate);
        this.totalDays = today.diff(startDate, 'days') + 1;
    }

    get dateFilterText() {
        let dateFilterText = '';
        this.chartsVccService.getDateSubTitle().subscribe((subTitle) => {
            dateFilterText = subTitle.replace(/\(|\)/g, '');
        });
        return dateFilterText;
    }
}