import {Component, OnInit, AfterViewInit, ViewChild, Input} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {FmsVccModel} from '../../model/fms-vcc-model';
import {MatSort, Sort} from '@angular/material/sort';
import {FmsVccFilter} from '../../model/fms-vcc-filter';
import {Pagination} from '../../../../infrastructure/pagination/model/pagination';
import {PageEvent, MatPaginator} from '@angular/material/paginator';
import {FmsVccService} from '../../service/fms-vcc.service';
import {FilterResult} from '../../../shared/modals/filter-modal/filter-modal.component';
import {ModalService} from 'src/app/shared/service/modal/modal.service';
import {FmsVccPayComponent} from '../fms-vcc-pay/fms-vcc-pay.component';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {TransactionHistoryDialog} from '../transaction-history-dialog/transaction-history-dialog.component';
import * as moment from 'moment';
import {ExportGridService} from '../../../shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-fms-vcc',
  templateUrl: './fms-vcc.component.html'
})
export class FmsVccComponent implements OnInit, AfterViewInit{
  @Input() filter!: FilterResult;

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;
  @ViewChild(MatSort, {static: false})
  public sort!: MatSort;

  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  
  public dataSource: MatTableDataSource<FmsVccModel> = new MatTableDataSource<FmsVccModel>([]);

  public displayedColumns: Array<string> = [
    'TripReference',
    'VccNumber',
    'VirtualCreditCardIssueDate',
    'VirtualCreditCardTerminateDate',
    'VirtualCreditCardStartActiveDate',
    'VirtualCreditCardEndActiveDate',
    'VirtualCreditCardCurrentAmt',
    'virtualCreditCardProvider.paymentProviderName',
    'currency.code'
  ];

  public readonly showPay: boolean = false;
  private readonly testEnvironments = ['Local', 'Development', 'QA', 'UAT'];

  public isLoading: boolean = false;

  public pagination: Pagination = {} as Pagination;
  public fmsVccFilter: FmsVccFilter = {} as FmsVccFilter;

  payClosed$?: Subscription;

  constructor(
    private fmsVccService: FmsVccService,
    private modalService: ModalService,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe,
  ) { 
    this.showPay = this.testEnvironments.includes(environment.environmentName);
    if(this.showPay){
      this.displayedColumns.push('Pay')
    }
  }

  ngOnInit(): void {
    this.fmsVccFilter.propertyName = 'DateCreated';
    this.fmsVccFilter.orderKey = 'DESC';
    this.fmsVccService.getFmsVccFilter().subscribe((filter) => {
    if (this.paginator !== undefined) {
      this.paginator.pageIndex = 0;
      this.fmsVccFilter.pageNumber = 1;
    }
    this.fmsVccFilter.vccNumber = filter.vccNumber;
    this.fmsVccFilter.issueDateFrom = filter.virtualCreditCardIssueDateFrom ? moment(filter.virtualCreditCardIssueDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
    this.fmsVccFilter.issueDateTo = filter.virtualCreditCardIssueDateTo ? moment(filter.virtualCreditCardIssueDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
    this.fmsVccFilter.providerId = filter.providerId;
    this.fmsVccFilter.tripReference = filter.tripReference;
    this.getFmsVcc(new PageEvent());
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  clearFilters(propertyName: string): void {
    let temp: any = this.fmsVccFilter;
    temp[propertyName] = undefined;
    this.fmsVccFilter = temp;

    if (propertyName === 'all') {
      this.fmsVccFilter = {} as FmsVccFilter;
    }

    this.getFmsVcc(new PageEvent());
  }

  getFmsVcc( pageEvent: PageEvent ): void {
    // Set Pagination After page changed
    this.setPagination(pageEvent);

    this.fmsVccService.searchFmsVcc(this.fmsVccFilter)
    .subscribe((data: any) => {
      if (data){
        this.pagination = data.pagination;
        this.fmsVccFilter.pageNumber= data.pagination.pageNumber;
        this.dataSource = new MatTableDataSource<FmsVccModel>(data.items);
      }
    });
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.fmsVccService.searchFmsVcc(this.fmsVccFilter)
    .subscribe((data) => {
      this.exportGridService.getByDataSource(
        'virtual-credit-card/details',
        'fms-vcc',
        {
          ... this.fmsVccFilter,
          pageSize: data.pagination?.totalItemCount || 100,
        },
        (data)=>{
          const record = data as FmsVccModel;
          return {
            'Adx Reference': record?.tripReference,
            'Card Number': record?.vccNumber,
            'Issue Date':  this.datePipe.transform(record?.virtualCreditCardIssueDate, 'M/d/yyyy'),
            'Terminate Date':  this.datePipe.transform(record?.virtualCreditCardTerminateDate, 'M/d/yyyy'),
            'Activation Active Date':  this.datePipe.transform(record?.virtualCreditCardStartActiveDate, 'M/d/yyyy'),
            'Activation End Date':  this.datePipe.transform(record?.virtualCreditCardEndActiveDate, 'M/d/yyyy'),
            'Available Balance': record?.virtualCreditCardCurrentAmt,
            'Provider Name': record?.virtualCreditCardProvider?.paymentProviderName,
            'Currency Name': record?.currency.code,
          };
        }
      );
      downloadSubs$?.unsubscribe();
    });
  }

  replayGrid (){
    this.getFmsVcc(new PageEvent());
  }

  public handleSortChange(sort: Sort): void {
    if(!sort?.active || !sort?.direction) {
      this.fmsVccFilter.propertyName = 'DateCreated';
      this.fmsVccFilter.orderKey = 'DESC';
    } else {
      this.fmsVccFilter.propertyName = this.getMappedFmsVccTblPropertyName(sort.active);
      this.fmsVccFilter.orderKey = sort.direction.toUpperCase();
    }
  
    this.getFmsVcc(new PageEvent());
  }

  getMappedFmsVccTblPropertyName(propertyName: string): string {
    switch (propertyName) {
      case 'VirtualCreditCardUrl':
        return propertyName;
      case 'VccNumber':
        return propertyName;
      case 'PurchasedCardGuid':
        return propertyName;
      case 'VirtualCreditCardIssueDate':
        return propertyName;
      case 'VirtualCreditCardTerminateDate':
        return propertyName;
      case 'VirtualCreditCardStartActiveDate':
        return propertyName;
      case 'VirtualCreditCardEndActiveDate':
        return propertyName;
      case 'VirtualCreditCardCurrentAmt':
        return propertyName;
      case 'virtualCreditCardProvider.paymentProviderName':
        return propertyName;
      case 'currency.code':
        return propertyName;
      case 'TripReference':
        return propertyName;
      default:
        return '';
    }
  }
  
  setPagination(pageEvent: PageEvent):void {
    this.fmsVccFilter.pageSize = 25;
    const isStepForward = pageEvent.pageIndex > pageEvent.previousPageIndex!;
    
    if (isStepForward) {
      this.fmsVccFilter.pageNumber += 1;
    } else {
      this.fmsVccFilter.pageNumber = this.fmsVccFilter.pageNumber !== undefined && this.fmsVccFilter.pageNumber > 1 ? this.fmsVccFilter.pageNumber-1 : 1;
    }
  }

  openAmountModal(fmsVcc:FmsVccModel):void {
    this.payClosed$ = this.modalService.open(
      FmsVccPayComponent,
      {
        disableClose: true,
        data: fmsVcc,
      }).afterClosed().subscribe((data)=>{
        if(data?.amount){
          this.getFmsVcc(new PageEvent());
        }
      });
  }

  openDialogShowMoreDetailsOfFmsVcc(record:FmsVccModel):void {
    const modalRef = this.modalService.open(TransactionHistoryDialog, { width: '55%', height: '90%', panelClass: 'overflowAuto' })
    modalRef.componentInstance.data = record;
    modalRef.componentInstance.title = 'Virtual Credit Card';
    modalRef.componentInstance.guid = record?.purchasedCardGuid;
  }
}
