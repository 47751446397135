import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicComponent} from './dynamic/dynamic.component';
import {
  ModalPanelComponent,
  ModalPanelFooterDirective,
  ModalPanelHeaderDirective
} from './components/modal-panel/modal-panel.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ChartComponent} from './components/chart/chart.component';
import {NgChartsModule} from 'ng2-charts';
import {WidgetInfoComponent} from './components/widget-info/widget-info.component';
import {WidgetActionsDirective, WidgetComponent} from './components/widget/widget.component';
import {SidebarMenuComponent} from './components/sidebar-menu/sidebar-menu.component';
import {RouterModule} from '@angular/router';
import {FilterModalComponent} from './modals/filter-modal/filter-modal.component';
import {AngularMaterialDesignModule} from '../angular-material-design/module/angular-material-design.module';
import {FinanceReportsFilterModalComponent} from './modals/finance-reports-filter-modal/finance-reports-filter-modal.component';
import {VccDailySummaryReportFilterModalComponent} from './modals/vcc-daily-summary-reports-filter-modal/vcc-daily-summary-reports-filter-modal.component';
import {VendorsFilterModalComponent} from '../vendor/component/vendors-filter-modal/vendors-filter-modal.component';
import {TransactionBookingsFilterModalComponent} from '../transaction-booking/components/transaction-bookings-filter-modal/transaction-bookings-filter-modal.component';
import {AdxInvoiceLineItemFilterModalComponent} from '../invoices/component/adx-invoice-line-items-filter-modal/adx-invoice-line-items-filter-modal.component';
import {TramsInvoiceFilterModalComponent} from '../invoices/component/trams-invoices-filter-modal/trams-invoices-filter-modal.component';
import {FmsVccFilterModalComponent} from '../finance-resource/component/fms-vccs-filter-modal/fms-vccs-filter-modal.component';
import {AdxVccFilterComponent} from '../finance-resource/component/adx-vcc-filter/adx-vcc-filter.component';
import {RefreshListComponent} from './components/refresh/refresh-list.component';
import {MatchingVccFilterComponent} from '../finance-resource/component/matching-vcc-filter/matching-vcc-filter.component';
import {VccChartFilterModalComponent} from '../dashboard/layout/vcc/components/vcc-chart-filter-modal/vcc-chart-filter-modal.component';
import {FinancialAlertsModalComponent} from '../finance-resource/component/financial-alerts/financial-alerts-modal.component';
import {TripReferenceComponent} from '../finance-resource/component/trip-reference-modal/trip-reference-modal.component';
import {TripFilterModalComponent} from '../trip/components/trip-filter-modal/trip-filter-modal.component';
import {SelectAllDirective} from './directives/select-all.directive';

const components = [
  DynamicComponent,
  ModalPanelComponent,
  FilterModalComponent,
  FinanceReportsFilterModalComponent,
  VccDailySummaryReportFilterModalComponent,
  VendorsFilterModalComponent,
  TransactionBookingsFilterModalComponent,
  AdxInvoiceLineItemFilterModalComponent,
  TramsInvoiceFilterModalComponent,
  FmsVccFilterModalComponent,
  VccChartFilterModalComponent,
  ChartComponent,
  WidgetInfoComponent,
  WidgetComponent,
  SidebarMenuComponent,
  AdxVccFilterComponent,
  RefreshListComponent,
  MatchingVccFilterComponent,
  FinancialAlertsModalComponent,
  TripReferenceComponent,
  TripFilterModalComponent,
]

const directives = [
  ModalPanelFooterDirective,
  ModalPanelHeaderDirective,
  WidgetActionsDirective,
  SelectAllDirective,
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialDesignModule,
    NgChartsModule,
    RouterModule,
  ],
  declarations: [
    ...components,

    ...directives
  ],
  exports: [
    ...components,

    ...directives
  ]
})
export class SharedModule {
}
