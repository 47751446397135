<div class="row">
  <div *ngFor="let infoDate of infoData; let i = index" id="finance-infoData-{{i}}" class="col-12 col-md-6 col-lg-3">
    <widget-info id="finance-widget-info-{{i}}" [text]="infoDate.text" [title]="infoDate.title" [textSub]="infoDate.overLine" [icon]="infoDate.icon"
      [color]="infoDate.color">
    </widget-info>
  </div>
</div>
<div class="row mt-24">
  <div id="finance-revenue-widget-container" class="col-md-6 mt-24">
    <widget id="finance-revenue-widget" title="[MOCK]: Monthly Revenue Growth" subTitle="Tracking the monthly increase in revenue over the past year">
      <div id="finance-revenue-chart-wrap" class="widget-chart-wrap" widgetContent>
        <chart id="finance-revenue-chart" [dataSet]="data.revenue" [chartType]="'line'">
        </chart>
      </div>
    </widget>
  </div>
  <div class="col-md-6 mt-24">
    <widget id="finance-cAcq-widget" title="[MOCK]: Customer Acquisition Trends" subTitle="Monthly comparison of new customers acquired">
      <div id="finance-cAcq-chart-wrap" class="widget-chart-wrap" widgetContent>
        <chart id="finance-cAcq-chart" [dataSet]="data.cAcq" [chartType]="'pie'">
        </chart>
      </div>
    </widget>
  </div>
  <div class="col-md-6 mt-24">
    <widget id="finance-pMargin-widget" title="[MOCK]: Profit Margin Analysis"
      subTitle="Quarterly profit margins and their fluctuations throughout the fiscal year">
      <div id="finance-pMargin-widget-content" widgetContent>
        <div id="finance-pMargin-chart-wrap" class="widget-chart-wrap">
          <chart id="finance-pMargin-chart" [dataSet]="data.pMargin" [chartType]="'line'">
          </chart>
        </div>
      </div>
    </widget>
  </div>
  <div class="col-md-6 mt-24">
    <widget id="finance-subRet-widget" title="[MOCK]: Subscription Retention Rates" subTitle="Analyzing monthly subscription retention and churn rates">
      <div id="finance-subRet-widget-content" widgetContent>
        <div id="finance-subRet-chart-wrap" class="widget-chart-wrap">
          <chart id="finance-subRet-chart" [dataSet]="data.subRet" [chartType]="'line'">
          </chart>
        </div>
      </div>
    </widget>
  </div>
</div>
