import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup, Validators,} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {AdxInvoiceLineItemFilterResult} from '../../model/adx-invoice-line-item-filter-result';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'adx-invoice-line-items-filter-modal',
  templateUrl: './adx-invoice-line-items-filter-modal.component.html'
})
export class AdxInvoiceLineItemFilterModalComponent implements OnInit {
  @Input() config!: AdxInvoiceLineItemFilterResult;
  
  breakPoint$?:Subscription;
  isSmall: boolean = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<AdxInvoiceLineItemFilterModalComponent>,
    breakpointObserver: BreakpointObserver,
  ) {
      this.breakPoint$ = breakpointObserver.observe([
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall
      ]).subscribe(result => {
          this.isSmall = result.matches;
      });
  }

  form: FormGroup = new FormGroup({
    quoteId: new FormControl('',[Validators.pattern(/^[1-9]\d*$/), Validators.min(1)]),
    invoiceRecordLocator: new FormControl('', Validators.pattern(/^\S*$/)),
    invoiceType: new FormControl(null),
    clientProfileNo: new FormControl(null),
    issueStartDate: new FormControl(null),
    issueEndDate: new FormControl(null),
    createdStartDate: new FormControl(null),
    createdEndDate: new FormControl(null)
  });

  handleAdxInvoiceLineItemFiltersFormCloseEvent() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  ngOnInit() {
    const {quoteId, invoiceRecordLocator, invoiceType, clientProfileNo, issueStartDate, issueEndDate, createdStartDate, createdEndDate} = this.config ?? {};
      this.form.patchValue({
        quoteId, invoiceRecordLocator, invoiceType, clientProfileNo, issueStartDate, issueEndDate, createdStartDate, createdEndDate
      });
  }

  applyAdxInvoiceLineItemFilters() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }
    else if (this.dialogRef) {
      this.dialogRef.close(this.config);
    }
  }


  public clearFilters(propertyName: string): void {
    if (propertyName === 'all') {
      this.config = {} as AdxInvoiceLineItemFilterResult;
    }else{
      let temp: any = this.config;
      temp[propertyName] = undefined;
      this.config = temp;
    }
  }
}
