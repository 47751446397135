<div class="row">
  <div
    *ngFor="let infoDate of infoData; let i = index"
    id="transactions-infoData-{{i}}"
    class="col-12 col-md-6 col-lg-3">
    <widget-info
      id="transactions-widget-info-{{i}}"
      [text]="infoDate.text"
      [title]="infoDate.title"
      [textSub]="infoDate.overLine"
      [icon]="infoDate.icon"
      [color]="infoDate.color">
    </widget-info>
 </div>
</div>
<div id="transactions-row" class="row mt-24">
  <div
    id="transactions-assigned-tickets-container"
    class="col-md-6 mt-24">
    <widget
      id="transactions-assigned-tickets-widget"
      title="Assigned Tickets"
      [subTitle]="'Weekly Average Handling Time '+ (dateFilterText$ | async)">
      <ng-container id="transactions-assigned-tickets-actions" widgetActions>
        <button id="transactions-assigned-tickets-minutes-button" mat-menu-item (click)="ahtChartType = AhtTypeEnum.Minutes">
          <mat-icon>hourglass_empty</mat-icon>
          <span>Minutes</span>
        </button>
        <button
          id="transactions-assigned-tickets-percentage-button"
          mat-menu-item
          (click)="ahtChartType = AhtTypeEnum.Percentage">
          <mat-icon>percent</mat-icon>
          <span>Percentage</span>
        </button>
      </ng-container>
      <div
        id="transactions-assigned-tickets-chart-wrap"
        class="widget-chart-wrap" widgetContent>
        <app-aht-weekly-calculation
          id="transactions-assigned-tickets-calculation"
          [type]="ahtChartType"
          [filter]="(filters$ | async)!">
        </app-aht-weekly-calculation>
      </div>
    </widget>
  </div>
  <div id="transactions-closed-tickets-container" class="col-md-6 mt-24">
    <widget id="transactions-closed-tickets-widget" title="Closed Tickets" [subTitle]="'Utilization Weekly '+ (dateFilterText$ | async)">
      <div id="transactions-closed-tickets-chart-wrap" class="widget-chart-wrap" widgetContent>
        <app-utilization-weekly-calculation id="transactions-closed-tickets-calculation" [filter]="(filters$ | async)!"></app-utilization-weekly-calculation>
      </div>
    </widget>
  </div>
  <div id="transactions-team-summary-container" class="col-md-6 mt-24">
    <widget id="transactions-team-summary-widget" title="Transaction Team Summary" [subTitle]="'Summary of Transaction team helpspot drivers with timing'+ (dateFilterText$ | async)">
      <div id="transactions-team-summary-content" widgetContent>
        <div id="transactions-team-summary-chart-wrap" class="widget-chart-wrap">
          <summary-list id="transactions-team-summary-list" [type]="'chart'" [filter]="(filters$ | async)!"></summary-list>
        </div>
      </div>
    </widget>
  </div>
  <div id="transactions-service-type-summary-container" class="col-md-6 mt-24">
    <widget
      id="transactions-service-type-summary-widget"
      title="Summary By Service Type"
      [subTitle]="'Summary of Transaction team tickets by service type with timing'+ (dateFilterText$ | async)">
      <div id="transactions-service-type-summary-content" widgetContent>
        <div id="transactions-service-type-summary-chart-wrap" class="widget-chart-wrap">
          <summary-list-by-service-type
            id="transactions-service-type-summary-list"
            [type]="'chart'"
            [filter]="(filters$ | async)!">
          </summary-list-by-service-type>
        </div>
      </div>
    </widget>
  </div>
</div>
<div class="row mt-24">
  <div id="transactions-team-summary-container" class="col-12">
    <widget id="transactions-team-summary-widget" title="Transaction Team Summary" [subTitle]="'Summary of Transaction team helpspot drivers with timing' + (dateFilterText$ | async)">
      <div id="transactions-team-summary-content" widgetContent>
        <div id="transactions-team-summary-table-wrap" class="widget-table-wrap">
          <summary-list id="transactions-team-summary-list" [filter]="(filters$ | async)!"></summary-list>
        </div>
      </div>
    </widget>
  </div>
</div>
<div class="row mt-24">
  <div id="transactions-tickets-summary-container" class="col-12">
    <widget id="transactions-tickets-summary-widget" title="Tickets Summary" [subTitle]="'Summary for Tickets and Transaction Types ' + (dateFilterText$ | async)">
      <div id="transactions-tickets-summary-content" widgetContent>
        <div id="transactions-tickets-summary-table-wrap" class="widget-table-wrap">
          <tickets-summary-list id="transactions-tickets-summary-list" [filter]="(filters$ | async)!"></tickets-summary-list>
        </div>
      </div>
    </widget>
  </div>
</div>
