<div class="grid-title-event">
  <h2 data-testid="trip-list-title">Trips</h2>
  <div class="events-container">
      @if(dataSource.filteredData.length){
          <button data-testid="trip-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="replayGrid()">
              <mat-icon fontIcon="replay"></mat-icon>
          </button>
          <button color="primary" data-testid="trip-download-button" aria-label="Download Trip File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
              <mat-icon>download</mat-icon>
          </button>
      }
  </div>
</div>
<div class="trips-container mat-elevation-z8" *ngIf="!this.isLoading">
  <mat-table
    data-testid="trip-table"
    [dataSource]="dataSource"
    (matSortChange)="handleSortChange($event)"
    matSort>

    <ng-container matColumnDef="id">
      <mat-header-cell data-testid="trip-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
      <mat-cell data-testid="trip-id-cell" class="mat-cell" *matCellDef="let element">
        <span class="trip-id-cell-text" matTooltip={{element.id}}  [matTooltipPosition]="'above'">
          <a data-testid="trip-id-cell-link" href="Trip/{{element?.id}}" target="_blank">{{element.id}}</a>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tripName">
      <mat-header-cell data-testid="trip-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Trip Name</mat-header-cell>
      <mat-cell data-testid="trip-name-cell" *matCellDef="let element">{{element.tripName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="externalNumber">
      <mat-header-cell data-testid="trip-external-number-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>External Number</mat-header-cell>
      <mat-cell data-testid="trip-external-number-cell" *matCellDef="let element">{{element.externalNumber}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="externalReference">
      <mat-header-cell data-testid="trip-external-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>External Reference</mat-header-cell>
      <mat-cell data-testid="trip-external-reference-cell" *matCellDef="let element">{{element.externalReference}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="tripExternalStatusType">
      <mat-header-cell data-testid="trip-external-status-type-header" class="mat-header-cell" *matHeaderCellDef>External Status Type</mat-header-cell>
      <mat-cell data-testid="trip-external-status-type-cell" *matCellDef="let element">{{element.tripExternalStatusType?.displayName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="tripStartDate">
      <mat-header-cell data-testid="trip-start-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
      <mat-cell  data-testid="trip-start-date-cell" *matCellDef="let element">{{element.tripStartDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="tripEndDate">
      <mat-header-cell data-testid="trip-end-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>End Date</mat-header-cell>
      <mat-cell data-testid="trip-end-date-cell" *matCellDef="let element">{{element.tripEndDate  | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateCreated">
      <mat-header-cell data-testid="trip-date-created-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
      <mat-cell data-testid="trip-date-created-cell" *matCellDef="let element">{{element.dateCreated  | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="isCancelled">
      <mat-header-cell data-testid="trip-cancelled-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Cancelled</mat-header-cell>
      <mat-cell data-testid="trip-cancelled-date-cell" *matCellDef="let element">{{ element.isCancelled ? 'Yes' : 'No' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="isArchived">
      <mat-header-cell data-testid="trip-archived-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Archived</mat-header-cell>
      <mat-cell data-testid="trip-archived-date-cell" *matCellDef="let element">{{ element.isArchived ? 'Yes' : 'No' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="invoicesCount">
      <mat-header-cell data-testid="trip-invoices-count-header" class="mat-header-cell" *matHeaderCellDef>Invoices Count</mat-header-cell>
      <mat-cell data-testid="trip-invoices-count-cell" *matCellDef="let element">{{ invoicesCount(element.adxInvoices) }}</mat-cell>
    </ng-container>

    <mat-header-row data-testid="trip-list-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row data-testid="trip-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-paginator
    data-testid="trip-paginator"
    [length]="pagination.totalItemCount"
    [hidePageSize]="true"
    [pageSize]="pagination.pageSize"
    (page)="pageChanged($event)">
  </mat-paginator>
</div>
