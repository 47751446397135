import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, catchError} from 'rxjs';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';
import * as moment from 'moment';
import {HsProcessingFilter, SearchProcessingParams, HsProcessingResponse} from '../models/hs-process.component';

@Injectable({
  providedIn: 'root',
})
export class HsProcessingService {
    private readonly resourcePath = 'transaction-booking-notification/search';

    private _dataSource$ = new BehaviorSubject<HsProcessingFilter | {}>({});

    constructor(private http: HttpClient) {}

    getHsProcessingFilter (): Observable<HsProcessingFilter | {}>{
        return this._dataSource$.asObservable();
    }

    setHsProcessingFilter(value:HsProcessingFilter){
        this._dataSource$.next(value || null);
    }

    getOrganizedFilters(filters?:HsProcessingFilter) {
        return {
            createdDateFrom: filters?.createdDateFrom? moment(filters?.createdDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
            createdDateTo: filters?.createdDateTo? moment(filters?.createdDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
            adxReference: filters?.adxReference ?? '',
            hsTicketNumber: filters?.hsTicketNumber ?? '',
            invalid: filters?.invalid ?? '',
        }
    }

    searchHsProcessing({filters, page, sort} : SearchProcessingParams): Observable<HsProcessingResponse>  {

        const params:Record<string, string | number | boolean> = {
            pageSize:  page?.pageSize || 25,
            pageNumber : (page?.pageIndex || 0) + 1,
            propertyName: sort?.active || '',
            orderKey: sort?.direction || '',
    
            ...this.getOrganizedFilters(filters),
        };

        removeEmptyParams(params);
        return this.http.get<HsProcessingResponse>(`${this.resourcePath}`, {
            params: {...params}
        }).pipe(catchError((error:HttpErrorResponse)=>{
                throw new Error(error?.message || 'Server error');
        }));
    }

}
