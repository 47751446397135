import {MatSnackBarConfig} from '@angular/material/snack-bar';

export class SnackBarConstants {
  public static defaultSnackBarDuration = 5000;
  public static defaultSnackBarAction = 'Dismiss';
  public static defaultMatSnackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    duration: this.defaultSnackBarDuration
  };
}
