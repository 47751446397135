import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Trip} from '../model/trip';
import {TripFilterResult} from '../model/trip-filter-result';

@Injectable({
  providedIn: 'root'
})
export class TripService {
  private resourcePath: string = 'dbov2/trip';
  private _tripFilterSubject: BehaviorSubject<TripFilterResult> = new BehaviorSubject<TripFilterResult>({});
  
  constructor(
    private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public getTripById(id: number): Observable<Trip> {
    return this.httpClient
      .get<any>(`${this.resourcePath}/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  public getAllTrips(): Observable<Trip[]> {
    return this.httpClient
      .get<any>(this.resourcePath, {params: new HttpParams()})
      .pipe(catchError(this.errorHandler));
  } 
  
  public searchTrips(bookingFilter: any): Observable<any> {
    let httpParams = new HttpParams();
    Object
      .keys(bookingFilter)
      .forEach((key) => {
        if (bookingFilter[key] !== null && bookingFilter[key] !== undefined && bookingFilter[key] !== '') {
          httpParams = httpParams.append(key, bookingFilter[key]);
        }
      });

    return this.httpClient
      .get<any>(`${this.resourcePath}/pagination`, {params: httpParams})
  }

  getTripFilter(): Observable<TripFilterResult> {
    return this._tripFilterSubject.asObservable();
  }

  setTripFilter(value: TripFilterResult): void {
    this._tripFilterSubject.next(value);
  }
}
