import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookingVirtualCreditCardService {
  private resourcePath: string = 'booking-virtual-credit-card';

  constructor(private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public getBookingVirtualCreditCardQuery(transactionBookingId: number): Observable<any> {
    let httpParams = new HttpParams({fromObject: {TransactionBookingId: transactionBookingId}});
    return this.httpClient
      .get<any>(this.resourcePath, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }
}
