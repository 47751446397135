import {Component, Optional} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-confirmation-dialog-revert-matching-vcc',
    templateUrl: './confirmation-dialog-revert-matching-vcc.component.html'
})
export class ConfirmationDialogRevertMatchingVccComnponent {

    constructor(
        @Optional() public confirmationDialogRef: MatDialogRef<ConfirmationDialogRevertMatchingVccComnponent>,
    ){}

    okClick(){
        this.confirmationDialogRef.close(true);
    }

    onNoClick(){
        this.confirmationDialogRef.close(false);
    }
}