import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BYPASS_ERROR_INTERCEPTOR, ProblemDetailsResponse} from 'src/app/shared/model/problem-details.response';
import {SnackBarConstants} from '../constants/snack-bar/snack-bar.constants';

@Injectable()
export class ProblemDetailsResponseInterceptor implements HttpInterceptor {

  constructor(private _snackBar: MatSnackBar) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const bypassErrorInterceptor = req.context.get(BYPASS_ERROR_INTERCEPTOR);
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!bypassErrorInterceptor && error.status >= 400 && error.status <= 510) {
          const errorMessage = this.constructErrorMessage(error);
          this.displayErrorMessage(errorMessage);
        }
        return throwError(() => new Error(error.message));
      })
    );
  }
  
  private constructErrorMessage(error: HttpErrorResponse): string {
    const errorMessage: ProblemDetailsResponse = error.error;
    const detail = errorMessage.detail ? `Detail: ${errorMessage.detail}\n` : '';
    let description = errorMessage.errors !== undefined && Object.keys(errorMessage.errors).length > 0
      ? `Description: ${Object.values(errorMessage.errors).join('\n')}`
      : '';
    description = description == '' && error.status == 404 ? "Description: No records found for selected criteria" : "";
    let message = '';
    if (errorMessage?.title) {
      message += `${errorMessage.title}\n`;
    }
    message += `${detail}`;
    if (errorMessage?.status) {
      message += `Error: ${errorMessage.status}\n`;
    }
    message += `${description}`;
    if (error.error && !error.error.detail && typeof error.error.errors === 'object') {
      message += Object.values(error.error.errors).join('\n');
    }
    return message;
  }
  
  private displayErrorMessage(errorMessage: string): void {
    this._snackBar.open(errorMessage, SnackBarConstants.defaultSnackBarAction, SnackBarConstants.defaultMatSnackBarConfig);
  }
}
