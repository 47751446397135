<div *ngIf="!isLoaded">
  Loading...
</div>
<div *ngIf="isLoaded && !isError">
  <div class="grid-title-event">
    <h2 data-testid="vcc-summary-report-list-title">VCC Daily Summary Report</h2>

    <div class="events-container">
        @if(dataSource.filteredData.length){
            <button  data-testid="vcc-summary-report-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getVccDailySummaryReport()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" data-testid="download-vcc-summary-report-button" aria-label="Download Adx Vcc File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
                <mat-icon>download</mat-icon>
            </button>
        }
    </div>
  </div>
  <div *ngFor="let field of objectFields">
    <mat-list>
      <mat-list-item class="data-row">
        <span class="data-key">{{ field.displayName }} : </span>
        <span>
          <mat-chip class="data-value" style="background-color: rgb(255, 124, 67)">{{ dataSource.data[0][field.key]}}</mat-chip>
        </span>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </div>
</div>
<div *ngIf="isLoaded && isError">
  An error occurred while loading the data.
</div>
<div *ngIf="isLoaded && !isError && (!dataSource || !dataSource.data || dataSource.data.length === 0)">
  There are no records to display.
</div>
