<form [formGroup]="bookingViewerForm" *ngIf="_booking != undefined && _booking != null && _booking.transactionBookingId > 0" class="template-form">
  <div class="container">
    <div id="booking-error" *ngIf="bookingViewerForm.invalid">
      <mat-error>Form is invalid</mat-error>
    </div>   
      <mat-form-field id="booking-number-field" *ngIf="_booking.transactionBookingId != undefined && _booking.transactionBookingId != null">
        <mat-label id="booking-number-field-title">Service number: </mat-label>
        <input id="booking-number-input-field" matInput value="{{_booking.transactionBookingId}}">
      </mat-form-field>
        <mat-form-field id="booking-service-type-name-field" *ngIf="_booking.serviceTypeName != undefined && _booking.serviceTypeName != null">
          <mat-label id="booking-service-type-name-field-title">Service type: </mat-label>
          <input id="booking-service-type-name-input-field" matInput value="{{_booking.serviceTypeName}}">
        </mat-form-field>
      <mat-form-field id="booking-total-field" *ngIf="_booking.bookingTotal != undefined && _booking.bookingTotal != null">
        <mat-label id="booking-total-field-title">Service total: </mat-label>
        <input id="booking-total-input-field" matInput value="{{_booking.bookingTotal | number : '1.2-2'}}">
      </mat-form-field>
      <mat-form-field id="booking-start-date-fiel" *ngIf="_booking.bookingStartDate != undefined && _booking.bookingStartDate != null">
        <mat-label id="booking-start-date-field-title">Service start date: </mat-label>
        <input id="booking-start-date-input-field" matInput value="{{_booking.bookingStartDate  | date: 'MM/dd/yyyy'}}">
      </mat-form-field>
      <mat-form-field id="booking-end-date-field" *ngIf="_booking.bookingEndDate != undefined && _booking.bookingEndDate != null">
        <mat-label id="booking-end-date-field-title">Service end date: </mat-label>
        <input id="booking-end-date-input-field" matInput value="{{_booking.bookingEndDate  | date: 'MM/dd/yyyy'}}">
      </mat-form-field>
      <mat-form-field id="booking-credit-card-field" *ngIf="_booking.virtualCreditCardUrl != undefined && _booking.virtualCreditCardUrl != null">
        <mat-label id="booking-credit-card-field-title">VCC URL: </mat-label>
        <input id="booking-credit-card-input-field" matInput value="{{_booking.virtualCreditCardUrl}}">
      </mat-form-field>
      <mat-form-field id="booking-status-name-field" *ngIf="_booking.bookingStatusName != undefined && _booking.bookingStatusName != null">
        <mat-label id="booking-status-name-field-title">Fms status: </mat-label>
        <input id="booking-status-name-input-field" matInput value="{{_booking.bookingStatusName}}">
      </mat-form-field>
      <mat-form-field id="booking-vendor-name-field" *ngIf="_booking.vendorName != undefined && _booking.vendorName != null">
        <mat-label id="booking-vendor-name-field-title">Vendor: </mat-label>
        <input id="booking-vendor-name-input-field" matInput value="{{_booking.vendor.vendorName}}">
      </mat-form-field>
      <mat-form-field id="booking-main-passenger-name-field" *ngIf="_booking.mainPassengerName != undefined && _booking.mainPassengerName != null">
        <mat-label id="booking-main-passenger-name-field-title">Passenger: </mat-label>
        <input id="booking-main-passenger-name-input-field" matInput value="{{_booking.mainPassengerName}}">
      </mat-form-field>
      <mat-form-field id="booking-task-number-field" *ngIf="_booking.taskNumbers != undefined && _booking.taskNumbers != null">
        <mat-label id="booking-task-number-field-title">Tasks: </mat-label>
        <input id="booking-task-number-input-field" matInput value="{{_booking.taskNumbers}}">
      </mat-form-field>
    </div>
  <br>
  <div class="row">
    <ng-container #container></ng-container>
  </div>
</form>
