<form class="filter" [formGroup]="matchingFilterForm" (submit)="applyMatchingFilters()">
<modal-panel
    id="matching-vcc-filter-modal-panel"
    class="matching-vcc-filter-modal-panel-cls filter-panel"
    [title]="'Filter'"
    (close)="handleBookingFiltersFormCloseEvent();"
    >
        <div id="matching-vcc-filter-modal-div-2" class="filter__fields">
            <mat-grid-list class="modal-filter-col-flex" [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Release date from</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="releasedDateFrom">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        @if(matchingFilterForm.controls['releasedDateFrom'].value){
                            <button 
                                id="matching-vcc-start-date-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('releasedDateFrom')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        }
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Release date to</mat-label>
                        <input matInput [matDatepicker]="picker2" formControlName="releasedDateTo">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker #picker2></mat-datepicker>
                        @if(matchingFilterForm.controls['releasedDateTo'].value){
                            <button 
                                id="matching-vcc-to-date-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('releasedDateTo')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Booking start date from</mat-label>
                        <input matInput [matDatepicker]="picker3" formControlName="tramsBookingStartDateFrom">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker #picker3></mat-datepicker>
                        @if(matchingFilterForm.controls['tramsBookingStartDateFrom'].value){
                            <button 
                                id="matching-vcc-booking-start-date-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('tramsBookingStartDateFrom')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Booking start date to</mat-label>
                        <input matInput [matDatepicker]="picker4" formControlName="tramsBookingStartDateTo">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker #picker4></mat-datepicker>
                        @if(matchingFilterForm.controls['tramsBookingStartDateTo'].value){
                            <button 
                                id="matching-vcc-service-to-date-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('tramsBookingStartDateTo')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Booking end date from</mat-label>
                        <input matInput [matDatepicker]="picker5" formControlName="tramsBookingEndDateFrom">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker #picker5></mat-datepicker>
                        @if(matchingFilterForm.controls['tramsBookingEndDateFrom'].value){
                            <button 
                                id="matching-vcc-booking-end-date-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('tramsBookingEndDateFrom')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle matIconSuffix [for]="picker5"></mat-datepicker-toggle>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Booking end date to</mat-label>
                        <input matInput [matDatepicker]="picker6" formControlName="tramsBookingEndDateTo">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker #picker6></mat-datepicker>
                        @if(matchingFilterForm.controls['tramsBookingEndDateTo'].value){
                            <button 
                                id="matching-vcc-booking-to-date-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('tramsBookingEndDateTo')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle matIconSuffix [for]="picker6"></mat-datepicker-toggle>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Trip reference</mat-label>
                        <input matInput placeholder="Trip Reference" value="" formControlName="orderNumber">
                        @if(matchingFilterForm.controls['orderNumber'].value){
                            <button 
                                id="matching-trip-reference-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('orderNumber')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Trams invoice number</mat-label>
                        <input matInput placeholder="TRAMS invoice number" value="" formControlName="tramsInvoiceNumber">
                        @if(matchingFilterForm.controls['tramsInvoiceNumber'].value){
                            <button 
                                id="matching-trams-invoice-number-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('tramsInvoiceNumber')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Vendor name</mat-label>
                        <input matInput placeholder="Vendor name" value="" formControlName="merchantName">
                        @if(matchingFilterForm.controls['merchantName'].value){
                            <button 
                                id="matching-merchant-name-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('merchantName')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>VCC number</mat-label>
                        <input matInput placeholder="VCC Number" value="" formControlName="cardLastFour">
                        @if(matchingFilterForm.controls['cardLastFour'].value){
                            <button 
                                id="matching-card-last-four-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('cardLastFour')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="1" gutterSize="10px" rowHeight="85">
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Merchant name</mat-label>
                        <mat-select formControlName="merchantGuid">
                            @if(merchantGuids){
                                @for (merchant of merchantGuids; track merchant) {
                                    <mat-option [value]="merchant?.id">{{merchant?.name}}</mat-option>
                                }
                            }
                        </mat-select>
                        @if(matchingFilterForm.controls['merchantGuid'].value){
                            <button 
                                id="matching-merchant-guid-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearMatchingFields('merchantGuid')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="1" gutterSize="10px" rowHeight="85">
                <mat-grid-tile>
                    <p style="width: 25vw;">Mapped to TRAMS data</p>
                    <mat-radio-group formControlName="isMapped" aria-label="matching-mode" [color]="'primary'" style="width: 100%;">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                    </mat-radio-group>
                    <button 
                        id="matching-is-mapped-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearMatchingFields('isMapped')">
                            <mat-icon>close</mat-icon>
                    </button>
                </mat-grid-tile>
                <mat-grid-tile>
                    <p style="width: 25vw;">Matched VCC's</p>
                    <mat-radio-group formControlName="isAmountMatches" aria-label="is-matched" [color]="'primary'" style="width: 100%;">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                    </mat-radio-group>
                    <button 
                        id="matching-is-matches-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearMatchingFields('isAmountMatches')">
                            <mat-icon>close</mat-icon>
                    </button>
                </mat-grid-tile>
                <mat-grid-tile>
                    <p style="width: 25vw;">Resolved</p>
                    <mat-radio-group formControlName="isResolved" aria-label="is-resolved" [color]="'primary'" style="width: 100%;">
                        <mat-radio-button value="true">Yes</mat-radio-button>
                        <mat-radio-button value="false">No</mat-radio-button>
                    </mat-radio-group>
                    <button 
                        id="matching-is-matches-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearMatchingFields('isResolved')">
                            <mat-icon>close</mat-icon>
                    </button>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <ng-container id="matching-vcc-modal-footer-container" modalPanelFooter>
            <div class="modal-footer">
                <button id="matching-vcc-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearMatchingFields()">
                    <mat-icon id="matching-vcc-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
                    Clear All
                </button>
                <button id="matching-vcc-modal-button" mat-raised-button color="primary" type="submit">Apply</button>
            </div>
        </ng-container>
</modal-panel>
</form>