import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FmsAllComponent } from './home/components/fms-all.component';
import { TemplateLayoutComponent } from './template/components/template-layout/template-layout.component';
import { VendorLayoutComponent } from './vendor/component/layout/vendor-layout/vendor-layout.component';
import { RequestActionLayoutComponent } from './request-action/components/layout/request-action-layout.component';
import { TransactionBookingViewerComponent } from './transaction-booking/components/viewer/transaction-booking-viewer.component';
import { MsalGuard } from '@azure/msal-angular';
import { VendorTemplateLayoutComponent } from './vendor-template/component/layout/vendor-template.layout/vendor-template.layout.component';
import { TransactionBookingLayoutComponent } from './transaction-booking/components/layout/transaction-booking-layout.component';
import { VendorListComponent } from './vendor/component/list/vendor-list.component';
import { TransactionBookingListComponent } from './transaction-booking/components/list/transaction-booking-list.component';
import { FileUploadComponent } from './nav/upload/components/file-upload.component';
import { DashboardComponent } from './dashboard/layout/dashboard/dashboard.component';
import { TransactionsComponent } from './dashboard/layout/transactions/transactions.component';
import { FinanceReportComponent } from './finance-report/layout/finance-report/finance-report.component';
import { CommissionReportComponent } from './finance-report/components/commission-report/commission-report.component';
import { CommissionQueueComponent } from './finance-report/components/commission-queue/commission-queue.component';
import { PaymentQueueComponent } from './finance-report/components/payment-queue/payment-queue.component';
import { TripProfitabilityComponent } from './finance-report/components/trip-profitability/trip-profitability.component';
import { VccDailySummaryReportComponent } from './finance-report/components/vcc-daily-summary-report/vcc-daily-summary-report.component';
import { NoAccessComponent } from './unauthorized-access/component/no-access/no-access.component';
import { HomeLayoutComponent } from './home/components/layout/home-layout.component';
import { UserRoleConstants } from './shared/model/user-role.constants';
import { AuthRoleGuard } from 'src/infrastructure/guards/auth-role.guard';
import { FinanceComponent } from './dashboard/layout/finance/finance.component';
import { TripProfitabilityWithAgencyComponent } from './finance-report/components/trip-profitability-with-agency/trip-profitability-with-agency.component';
import { TraceComponent } from './trace/component/trace.component';
import { InvoicesComponent } from './invoices/layout/invoices/invoices.component';
import { AdxInvoiceLineItemsComponent } from './invoices/component/adx-invoice-line-items/adx-invoice-line-items.component';
import { TramsInvoicesComponent } from './invoices/component/trams-invoices/trams-invoices.component';
import { PaymentsComponent } from './payments/layout/payments/payments.component';
import { AdxVccComponent } from './finance-resource/component/adx-vcc/adx-vcc.component';
import { FmsVccComponent } from './finance-resource/component/fms-vcc/fms-vcc.component';
import { InvoiceDetailComponent } from './invoices/layout/invoice-detail/invoice-detail.component';
import { VccComponent } from './dashboard/layout/vcc/components/vcc.component';
import { VccReportComponent } from './dashboard/layout/vcc/components/vcc-report/vcc-report.component';
import { MatchingVccComponent } from './finance-resource/component/matching-vcc/matching-vcc.component';
import { MatchingVccDetailComponent } from './finance-resource/component/matching-vcc-detail/matching-vcc-detail.component';
import { AlertsGridComponent } from './finance-resource/component/alerts/alerts.component';
import { TripListComponent } from './trip/components/trip-list/trip-list.component';
import { TripDetailsComponent } from './trip/components/trip-details/trip-details.component';
import { AdxInvoiceDetailComponent } from './adx-invoice/components/adx-invoice-detail/adx-invoice-detail.component';
import { ClientListComponent } from './client/component/list/client-list.component';
import { AgentListComponent } from './agent/component/list/agent-list.component';
import { TramsInvoiceComponent } from './invoices/component/layout/trams-invoice.component';
import { unsavedChangesGuard } from 'src/infrastructure/guards/unsaved-changes.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
  },
  {
    path: 'trace',
    component: TraceComponent,
    canActivate: [MsalGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    }
  },
  {
    path: 'Actions',
    component: FmsAllComponent,
    canActivate: [MsalGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    }
  },
  {
    path: 'Clients',
    component: ClientListComponent
  },
  {
    path: 'Agents',
    component: AgentListComponent
  },
  {
    path: 'Dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User,
        UserRoleConstants.Director
      ]
    },
    children: [
      {
        path: 'Transactions',
        component: TransactionsComponent
      },
      {
        path: 'Finance',
        component: FinanceComponent
      },
      {
        path: 'Vcc',
        component: VccComponent
      },
      {
        path: 'VccReport',
        component: VccReportComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Transactions'
      }
    ]
  },
  {
    path: 'FinanceReports',
    component: FinanceReportComponent,
    children: [
      {
        path: 'CommissionReports',
        component: CommissionReportComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'CommissionQueue',
        component: CommissionQueueComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'PaymentQueue',
        component: PaymentQueueComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'TripProfitability',
        component: TripProfitabilityComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'TripProfitabilityWithAgency',
        component: TripProfitabilityWithAgencyComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Director
          ]
        },
      },
      {
        path: 'VccDailySummaryReport',
        component: VccDailySummaryReportComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'CommissionReports'
      }
    ]
  },
  {
    path: 'NoAccess',
    component: NoAccessComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'VendorSearch',
    component: VendorLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'Vendors',
    component: VendorListComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'Templates',
    component: VendorTemplateLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'Rules',
    component: TemplateLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'RequestAction/:id', component: RequestActionLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'RequestAction',
    component: RequestActionLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'Services',
    component: TransactionBookingListComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'BookingsSearch',
    component: TransactionBookingLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'Services/:id',
    component: TransactionBookingViewerComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'FileUpload',
    component: FileUploadComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'Invoices',
    component: InvoicesComponent,
    children: [
      {
        path: 'AdxInvoices',
        component: AdxInvoiceLineItemsComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'TramsInvoices',
        component: TramsInvoicesComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'AdxInvoices'
      }
    ]
  },
  {
    path: 'Invoices/TramsInvoices/:invoiceId',
    component: TramsInvoiceComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'Invoices/AdxInvoices/:id',
    component: AdxInvoiceDetailComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'Itinerary/:invoiceLineItemId',
    component: InvoiceDetailComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'Payments',
    component: PaymentsComponent,
    children: [
      {
        path: 'Received',
        component: PaymentsComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'Made',
        component: PaymentsComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Received'
      }
    ]
  },
  {
    path: 'FinanceResources',
    component: PaymentsComponent,
    children: [
      {
        path: 'AdxVcc',
        component: AdxVccComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'FmsVcc',
        component: FmsVccComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'MatchingVcc',
        component: MatchingVccComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        canDeactivate: [unsavedChangesGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'MatchingVcc/:id',
        component: MatchingVccDetailComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'Alerts',
        component: AlertsGridComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Manager,
            UserRoleConstants.Director
          ]
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'AdxVcc'
      }
    ]
  },
  {
    path: 'Trips',
    component: TripListComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    }
  },
  {
    path: 'Trip/:id',
    component: TripDetailsComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    }       
  },
  {
    path: '**',
    redirectTo: '/'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
