export class NavReport {
  'Posting Date'!: string;
  'Document Type'!: string;
  'Document Date'!: string;
  'Document No'!: string;
  'Account Type'!: string;
  'Account No'!: number;
  'External Document No'!: string;
  'Description'!: string;
  'Currency Code'!: string;
  'Amount'!: number;
  'Region'!: number;
  'Exchange Rate'!: number;
  'Dept'!: string;
  'Trip Id'!: string;
  'Segment Code'!: string;
  'Bal Account No'!: string;
  'Bal Account Type'!: string;
  'Trading Partner'!: string;

  constructor(obj: any) {
    this['Posting Date'] = obj.postingDate;
    this['Document Type'] = obj.documentType;;
    this['Document Date'] = obj.documentDate;
    this['Document No'] = obj.documentNo;
    this['Account Type'] = obj.accountType;
    this['Account No'] = obj.accountNo;
    this['External Document No'] = obj.externalDocumentNo;
    this.Description = obj.description;
    this['Currency Code'] = obj.currencyCode;
    this.Amount = obj.amount;
    this.Region = obj.region;
    this['Exchange Rate'] = obj.exchangeRate;
    this.Dept = obj.dept;
    this['Trip Id'] = obj.tripId;
    this['Segment Code'] = obj.segmentCode;
    this['Bal Account No'] = obj.balAccountNo;
    this['Bal Account Type'] = obj.balAccountType;
    this['Trading Partner'] = obj.tradingPartner;
  }
}

