import {Component, Input} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {TransactionBookingViewerType} from './transaction-booking-viewer-modal/transaction-booking-viewer-modal.component';

@Component({
  selector: 'app-transaction-booking-tc-viewer',
  templateUrl: './transaction-booking-tc-viewer.component.html'
})

@AutoUnsubscribe()
export class TransactionBookingTCViewerComponent {
  @Input() public data: any;
  @Input() public tcType!: TransactionBookingViewerType;
  _booking: any;
  _bookingTermsAndConditions: any;

  constructor(private buildr: FormBuilder) {
  }

  ngOnChanges() {
    this.getBookingTCInformation();
  }
   
  getBookingTCInformation() {
    if (this.data) {
      this._booking = this.data;
      
      if (this._booking.bookingTemplates.length > 0) {
        if (this.tcType == TransactionBookingViewerType.VendorPayment){
          this._bookingTermsAndConditions = this._booking.bookingTemplates.find((x: any) => !x.isDeleted &&
            x.termsConditionsTypeId == Number(TransactionBookingViewerType.VendorPayment));
        }
        else{
          this._bookingTermsAndConditions = this._booking.bookingTemplates.find((x: any) => !x.isDeleted &&
            x.termsConditionsTypeId == Number(TransactionBookingViewerType.CancellationRefund));
        }
      }
    }
  }

  bookingTCViewerForm = this.buildr.group({
    action: this.buildr.control(''),
    templateName: this.buildr.control(''),
    templateNotes: this.buildr.control(''),
  })
}

