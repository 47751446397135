<modal-panel id="booking-filter-modal-panel" class="booking-filter-modal-panel-cls filter-panel" [title]="'Filter'" (close)="handleBookingFiltersFormCloseEvent()">
    <div id="booking-filter-modal-div-1" class="filter" [formGroup]="form">
      <div id="booking-filter-modal-div-2" class="filter__fields">
        <mat-grid-list [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="booking-filter-statuses-field-title">Fms Statuses</mat-label>
                <mat-select
                  id="booking-filter-statuses-drop-down-list"
                  [(ngModel)]="config.bookingStatusName"
                  formControlName="bookingStatusName">
                  <mat-option
                    *ngFor="let bs of bookingStatuses; let i = index"
                    [value]="bs"
                    id="booking-filter-status-option-{{i}}">{{bs}}
                  </mat-option>
                </mat-select>
                @if(form.controls['bookingStatusName'].value){
                  <button 
                      id="booking-filter-statuses-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearBookingFilters('bookingStatusName')">
                          <mat-icon>close</mat-icon>
                  </button>
                }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="booking-service-statuses-field-title">Service Statuses</mat-label>
                <mat-select
                  id="booking-filter-service-statuses-drop-down-list"
                  [(ngModel)]="config.serviceStatusName"
                  formControlName="serviceStatusName">
                  <mat-option
                    *ngFor="let ss of serviceStatuses; let i = index"
                    [value]="ss"
                    id="booking-filter-service-status-option-{{i}}">{{ss}}
                  </mat-option>
                </mat-select>
                @if(form.controls['serviceStatusName'].value){
                  <button 
                      id="booking-service-statuses-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearBookingFilters('serviceStatusName')">
                          <mat-icon>close</mat-icon>
                  </button>
                }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="booking-filter-start-from-field-title">Service start from</mat-label>
                <input
                  id="booking-filter-start-from-input-field"
                  matInput
                  [matDatepicker]="picker1" readonly
                  [(ngModel)]="config.bookingStartFrom"
                  formControlName="bookingStartFrom">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #picker1 id="booking-filter-start-from-datepicker"></mat-datepicker>
                @if(form.controls['bookingStartFrom'].value){
                  <button 
                      id="booking-filter-start-from-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearBookingFilters('bookingStartFrom')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="booking-filter-start-to-field-title">Service start to</mat-label>
                <input
                  id="booking-filter-start-to-input-field"
                  matInput
                  [matDatepicker]="picker2" readonly
                  [(ngModel)]="config.bookingStartTo"
                  formControlName="bookingStartTo">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #picker2 id="booking-start-to-datepicker"></mat-datepicker>
                @if(form.controls['bookingStartTo'].value){
                  <button 
                      id="booking-filter-start-to-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearBookingFilters('bookingStartTo')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="booking-filter-end-from-field-title">Service end from</mat-label>
                <input
                  id="booking-filter-end-from-input-field"
                  matInput
                  [matDatepicker]="picker3" readonly
                  [(ngModel)]="config.bookingEndFrom"
                  formControlName="bookingEndFrom">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #picker3 id="booking-filter-end-from-datepicker"></mat-datepicker>
                @if(form.controls['bookingEndFrom'].value){
                  <button 
                      id="booking-filter-end-from-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearBookingFilters('bookingEndFrom')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="booking-filter-end-to-field-title">Service end to</mat-label>
                <input
                  id="booking-filter-end-to-input-field"
                  matInput
                  [matDatepicker]="picker4" readonly
                  [(ngModel)]="config.bookingEndTo"
                  formControlName="bookingEndTo">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #picker4 id="booking-end-to-datepicker"></mat-datepicker>
                @if(form.controls['bookingEndTo'].value){
                  <button 
                      id="booking-filter-end-to-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearBookingFilters('bookingEndTo')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="booking-filter-date-created-from-field-title">Date created from</mat-label>
                <input
                  id="booking-filter-date-created-from-input-field"
                  matInput
                  [matDatepicker]="dateCreatedFrom" readonly
                  [(ngModel)]="config.dateCreatedFrom"
                  formControlName="dateCreatedFrom">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #dateCreatedFrom id="booking-date-create-from-datepicker"></mat-datepicker>
                @if(form.controls['dateCreatedFrom'].value){
                  <button 
                      id="booking-filter-date-create-from-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearBookingFilters('dateCreatedFrom')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="dateCreatedFrom"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="booking-filter-date-created-to-field-title">Date created to</mat-label>
                <input
                  id="booking-filter-date-created-to-input-field"
                  matInput
                  [matDatepicker]="dateCreatedTo" readonly
                  [(ngModel)]="config.dateCreatedTo"
                  formControlName="dateCreatedTo">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #dateCreatedTo id="booking-filter-date-create-to-datepicker"></mat-datepicker>
                @if(form.controls['dateCreatedTo'].value){
                  <button 
                      id="booking-filter-date-create-to-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearBookingFilters('dateCreatedTo')">
                          <mat-icon>close</mat-icon>
                  </button>
                } @else {
                    <mat-datepicker-toggle matIconSuffix [for]="dateCreatedTo"></mat-datepicker-toggle>
                }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label id="booking-filter-agent-name-field-title">Agent Name</mat-label>
                <input
                  id="booking-filter-agent-name-to-input-field"
                  matInput
                  [(ngModel)]="config.agentName"
                  formControlName="agentName">
                  @if(form.controls['agentName'].value){
                    <button 
                        id="booking-filter-agent-name-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearBookingFilters('agentName')">
                            <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label  id="booking-filter-name-field-title">Booking Name</mat-label>
                <input
                  id="booking-filter-name-to-input-field"
                  matInput
                  [(ngModel)]="config.bookingName"
                  formControlName="bookingName">
                  @if(form.controls['bookingName'].value){
                    <button 
                        id="booking-filter-name-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearBookingFilters('bookingName')">
                            <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label  id="helpspot-ticket-number-filter-field-title">Helpspot Ticket No.</mat-label>
                <input
                  id="helpspot-ticket-number-filter-to-input-field"
                  matInput
                  [(ngModel)]="config.externalNumber"
                  formControlName="externalNumber">
                  @if(form.controls['externalNumber'].value){
                    <button 
                        id="helpspot-ticket-number-filter-name-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearBookingFilters('externalNumber')">
                            <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label  id="trip-reference-ticket-number-filter-field-title">Trip Reference No.</mat-label>
                <input
                  id="trip-reference-number-filter-to-input-field"
                  matInput
                  [(ngModel)]="config.tripReference"
                  formControlName="tripReference">
                  @if(form.controls['tripReference'].value){
                    <button 
                        id="trip-reference-number-filter-name-clear-filter-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearBookingFilters('tripReference')">
                            <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
      </div>
    </div>
    <ng-container id="booking-filter-modal-footer-container" modalPanelFooter>
      <div class="modal-footer">
        <button id="booking-filter-modal-button-clear-all" mat-stroked-button color="primary" (click)="clearBookingFilters('all')">Clear All
          <mat-icon id="booking-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
        </button>
        <button id="booking-filter-modal-button" mat-raised-button color="primary" (click)="applyBookingFilters()">Apply</button>
      </div>
    </ng-container>
  </modal-panel>
