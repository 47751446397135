<modal-panel id="finance-report-filter-modal-panel" class="finance-report-filter-modal-panel-cls filter-panel" [title]="'Filter'" (close)="handleClose()">
  <div id="finance-report-filter-modal-div-1" class="filter" [formGroup]="form">
    <div id="finance-report-filter-modal-div-2" class="filter__fields">
      <mat-grid-list [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
        <mat-grid-tile>
            <mat-form-field>
              <mat-label id="finance-report-filter-booking-start-from-field-title">Booking start from</mat-label>
              <input
                id="finance-report-filter-booking-start-from-input-field"
                matInput
                [matDatepicker]="picker1" readonly
                [(ngModel)]="config.bookingStartFrom"
                formControlName="bookingStartFrom">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker #picker1 id="finance-report-filter-booking-start-from-datepicker"></mat-datepicker>
              @if(form.controls['bookingStartFrom'].value){
                <button 
                    id="finance-report-filter-booking-start-from-clear-filter-button"
                    matSuffix mat-icon-button
                    type="button"
                    (click)="clearFinanceReportFilters('bookingStartFrom')">
                        <mat-icon>close</mat-icon>
                </button>
              } @else {
                  <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
              }
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-form-field>
            <mat-label id="finance-report-filter-booking-start-to-field-title">Booking start to</mat-label>
            <input
              id="finance-report-filter-booking-start-to-input-field"
              matInput
              [matDatepicker]="picker2" readonly
              [(ngModel)]="config.bookingStartTo"
              formControlName="bookingStartTo">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker #picker2 id="finance-report-filter-booking-start-to-datepicker"></mat-datepicker>
            @if(form.controls['bookingStartTo'].value){
              <button 
                  id="finance-report-filter-booking-start-to-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearFinanceReportFilters('bookingStartTo')">
                      <mat-icon>close</mat-icon>
              </button>
            } @else {
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            }
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-form-field>
            <mat-label id="finance-report-filter-booking-end-from-field-title">Booking end from</mat-label>
            <input
              id="finance-report-filter-booking-end-from-input-field"
              matInput
              [matDatepicker]="picker3" readonly
              [(ngModel)]="config.bookingEndFrom"
              formControlName="bookingEndFrom">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker #picker3 id="finance-report-filter-booking-end-from-datepicker"></mat-datepicker>
            @if(form.controls['bookingEndFrom'].value){
              <button 
                  id="finance-report-filter-booking-end-from-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearFinanceReportFilters('bookingEndFrom')">
                      <mat-icon>close</mat-icon>
              </button>
            } @else {
                <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
            }
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-form-field>
            <mat-label id="finance-report-filter-booking-end-to-field-title">Booking end to</mat-label>
            <input
              id="finance-report-filter-booking-end-to-input-field"
              matInput
              [matDatepicker]="picker4" readonly
              [(ngModel)]="config.bookingEndTo"
              formControlName="bookingEndTo">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker #picker4 id="finance-report-filter-booking-end-to-datepicker"></mat-datepicker>
            @if(form.controls['bookingEndTo'].value){
              <button 
                  id="finance-report-filter-booking-end-to-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearFinanceReportFilters('bookingEndTo')">
                      <mat-icon>close</mat-icon>
              </button>
            } @else {
                <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
            }
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-form-field>
            <mat-label id="finance-report-filter-booking-date-created-from-field-title">Date created from</mat-label>
            <input
              id="finance-report-filter-booking-date-created-from-input-field"
              matInput
              [matDatepicker]="dateCreatedFrom" readonly
              [(ngModel)]="config.dateCreatedFrom"
              formControlName="dateCreatedFrom">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker #dateCreatedFrom id="finance-report-filter-booking-date-create-from-datepicker"></mat-datepicker>
            @if(form.controls['dateCreatedFrom'].value){
              <button 
                  id="finance-report-filter-booking-date-create-from-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearFinanceReportFilters('dateCreatedFrom')">
                      <mat-icon>close</mat-icon>
              </button>
            } @else {
                <mat-datepicker-toggle matIconSuffix [for]="dateCreatedFrom"></mat-datepicker-toggle>
            }
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-form-field>
            <mat-label id="finance-report-filter-booking-date-created-to-field-title">Date created to</mat-label>
            <input
              id="finance-report-filter-booking-date-created-to-input-field"
              matInput
              [matDatepicker]="dateCreatedTo" readonly
              [(ngModel)]="config.dateCreatedTo"
              formControlName="dateCreatedTo">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker #dateCreatedTo id="finance-report-filter-booking-date-create-to-datepicker"></mat-datepicker>
            @if(form.controls['dateCreatedTo'].value){
              <button 
                  id="finance-report-filter-booking-date-create-to-clear-filter-button"
                  matSuffix mat-icon-button
                  type="button"
                  (click)="clearFinanceReportFilters('dateCreatedTo')">
                      <mat-icon>close</mat-icon>
              </button>
            } @else {
                <mat-datepicker-toggle matIconSuffix [for]="dateCreatedTo"></mat-datepicker-toggle>
            }
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="1" gutterSize="10px" rowHeight="85">
      <mat-grid-tile>
        <mat-form-field id="finance-report-filter-advisor-name-form-field">
          <mat-label id="finance-report-filter-advisor-name-label">Advisor Name</mat-label>
          <input type="text" matInput #advisorName formControlName="advisorName" [(ngModel)]="config.advisorName" id="finance-report-filter-advisor-name-input">
          @if(form.controls['advisorName'].value){
            <button 
                id="finance-report-filter-booking-advisor-name-clear-filter-button"
                matSuffix mat-icon-button
                type="button"
                (click)="clearFinanceReportFilters('advisorName')">
                    <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field id="finance-report-filter-trip-reference-form-field">
          <mat-label id="finance-report-filter-trip-reference-label">Trip Reference</mat-label>
          <input type="text" matInput #tripReference formControlName="tripReference" [(ngModel)]="config.tripReference" id="finance-report-filter-trip-reference-input">
          @if(form.controls['tripReference'].value){
            <button 
                id="finance-report-filter-booking-tripReference-clear-filter-button"
                matSuffix mat-icon-button
                type="button"
                (click)="clearFinanceReportFilters('tripReference')">
                    <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    </div>
  </div>
  <ng-container id="finance-report-filter-modal-footer-container" modalPanelFooter>
    <div class="modal-footer">
      <button id="finance-report-filter-modal-button-clear-all" mat-stroked-button color="primary" (click)="clearFinanceReportFilters('all')">Clear All
        <mat-icon id="finance-report-filter-clear-filter-button-icon">delete_outline</mat-icon>
      </button>
      <button id="finance-report-filter-modal-button" mat-raised-button color="primary" (click)="applyFinanceReportFilters()">Apply</button>
    </div>
  </ng-container>
</modal-panel>
