import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Task} from '../model/task';
import {CreateTask} from '../model/create-task';
import {UpdateHelpSpot} from '../model/update-help-spot';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  public task = {} as Task;
  private resourcePath: string = 'task';

  constructor(private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public createTask(createTask: CreateTask): Observable<any> {
    return this.httpClient
      .post<any>(this.resourcePath, JSON.stringify(createTask))
      .pipe(catchError(this.errorHandler));
  }

  public updateHelpSpot(updateHelpSpot: UpdateHelpSpot): Observable<any> {
    return this.httpClient
      .put<any>('help-spot', JSON.stringify(updateHelpSpot))
      .pipe(catchError(this.errorHandler));
  }
}
