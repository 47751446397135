import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html'
})
export class InvoiceDetailComponent {
  invoiceInfo = {
    invoiceId: '',
    invoiceLineItemId: ''
  };

  constructor(
    private route: ActivatedRoute) {
      this.invoiceInfo.invoiceId = this.route.snapshot.paramMap.get('invoiceId')!;
      this.invoiceInfo.invoiceLineItemId = this.route.snapshot.paramMap.get('invoiceLineItemId')!;
  }
}
