<widget id="financial-balance-vcc-widget" title="Accumulative Available Funds " [subTitle]="'USD'+(totalFundsUSD| currency:'USD') + ' - ' + (totalFundsCAD| currency:'CAD') + (dateFilterText | async) ">
    <mat-table widgetContent id="financial-balance-vcc-table" [dataSource]="items" style="height: 400px; overflow: auto;">
        <ng-container matColumnDef="merchantName">
            <mat-header-cell id="merchant-name-header" class="mat-header-cell" *matHeaderCellDef>
                Merchant name
            </mat-header-cell>
            <mat-cell id="merchant-name-cell" *matCellDef="let element">
                {{element?.merchantName}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalBalance">
            <mat-header-cell id="total-balance-header" class="mat-header-cell" *matHeaderCellDef>
                Total Available to Issue
            </mat-header-cell>
            <mat-cell id="total-balance-cell" *matCellDef="let element">
                {{element?.totalAti === 0 ?  (element?.totalAta | currency:element?.currencyCode) : (element?.totalAti  | currency:element?.currencyCode) }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="outstandingLiability">
            <mat-header-cell id="outstanding-liability-header" class="mat-header-cell" *matHeaderCellDef>
                Outstanding Liability
            </mat-header-cell>
            <mat-cell id="outstanding-liability-cell" *matCellDef="let element">
                {{element?.totalAti === 0 ?  (element?.outstandingAuthorization | currency:element?.currencyCode) : (element?.outstandingLiability  | currency:element?.currencyCode) }}
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

        <mat-row *matRowDef="let row; columns: displayedColumns; let i = index">
        </mat-row>
    </mat-table>
</widget>