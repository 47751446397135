<div class="grid-title-event">
  <h2 data-testid="vendor-list-title">Vendors</h2>

  <div class="events-container">
      @if(dataSource.filteredData.length){
          <button  data-testid="vendor-list-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="replayGrid()">
              <mat-icon fontIcon="replay"></mat-icon>
          </button>
          <button color="primary" data-testid="download-vendor-list-button" aria-label="Download Adx Vcc File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
              <mat-icon>download</mat-icon>
          </button>
      }
  </div>
</div>
<div class="vendors-container mat-elevation-z8" *ngIf="!this.isLoading" id="vendor-list-vendors-container">
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="handleSortChange($event)"
    id="vendor-list-mat-table">
    <ng-container matColumnDef="id" id="vendor-list-id-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="vendor-list-id-header-cell">ID</mat-header-cell>
      <mat-cell *matCellDef="let row" id="vendor-list-id-cell"> {{row.id}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="vendorName" id="vendor-list-vendor-name-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="vendor-list-vendor-name-header-cell">Name</mat-header-cell>
      <mat-cell *matCellDef="let row" id="vendor-list-vendor-name-cell">{{row.vendorName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Address.Country.Name" id="vendor-list-country-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="vendor-list-country-header-cell">Country</mat-header-cell>
      <mat-cell *matCellDef="let row" id="vendor-list-country-cell">{{row.vendorCountryName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Address.City" id="vendor-list-city-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="vendor-list-city-header-cell">City</mat-header-cell>
      <mat-cell *matCellDef="let row" id="vendor-list-city-cell"> {{row.vendorCity}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="vendorPaymentTC" id="vendor-list-payment-tc-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header disabled id="vendor-list-payment-tc-header-cell">Vendor Payment TC</mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" id="vendor-list-payment-tc-cell">
        <button mat-icon-button color="primary" aria-label="Vendor Payment" *ngIf="checkVendorPaymentBookingTemplate(row.vendorTemplates)"
          (click)="openDialog(row, vendorViewerType.VendorPayment)" id="vendor-list-payment-tc-button">
          <mat-icon id="vendor-list-payment-tc-icon">checkmark</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="cancellationRefundTC" id="vendor-list-cancellation-refund-tc-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header disabled id="vendor-list-cancellation-refund-tc-header-cell">Cancellation Refund TC</mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" id="vendor-list-cancellation-refund-tc-cell">
        <button mat-icon-button color="primary" aria-label="Cancellation Refund" *ngIf="checkCancellationRefundBookingTemplate(row.vendorTemplates)"
          (click)="openDialog(row, vendorViewerType.CancellationRefund)" id="vendor-list-cancellation-refund-tc-button">
          <mat-icon id="vendor-list-cancellation-refund-tc-icon">checkmark</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" data-testid="vendor-list-header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="getRecord(row)" id="vendor-list-mat-row">
    </mat-row>
    </mat-table>
    <mat-paginator
    [length]="pagination.totalItemCount"
    [hidePageSize]="true"
    [pageSize]="pagination.pageSize"
    (page)="searchVendors($event)"
    id="vendor-list-mat-paginator">
  </mat-paginator>
  </div>
