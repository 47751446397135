import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  private resourcePath1: string = 'nav-branch';
  private resourcePath2: string = 'nav-report';
  private resourcePath3: string = 'nav-mapping';


  constructor(
    private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public getNavBranches(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.resourcePath1}`)
      .pipe(catchError(this.errorHandler));
  }

  public getNavReport(uploadGuid: string, dataSourceId: number, batchId: number): Observable<any> {
    let httpParams = new HttpParams({
      fromObject: {
        UploadGuid: uploadGuid,
        SourceId: dataSourceId,
        NavBatchId: batchId
      }
    });
    return this.httpClient
      .get<any>(`${this.resourcePath2}`, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  public getNavGlMapping(navBranchId: number): Observable<any> {
    let httpParams = new HttpParams({fromObject: {NavBranchId: navBranchId}});
    return this.httpClient
      .get<any>(`${this.resourcePath3}`, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  public GetLastUploadUdid(dataSourceId: number, navBranchId: number): Observable<any> {
    let httpParams = new HttpParams({fromObject: {SourceId: dataSourceId, NavBranchId: navBranchId}});
    return this.httpClient
      .get<any>(`${this.resourcePath2}/lastupload`, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }
}
