import {Component, OnInit} from '@angular/core';
import {FileUploadService} from '../service/file-upload.service';
import * as XLSX from 'xlsx';
import {UploadNavReportFileModel} from '../model/upload-nav-report-file-model';
import {NavService} from '../service/nav.service';
import {LoadingService} from '../../../shared/service/loading.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LoadNavReportModel} from '../model/load-nav-report-model';
import {FileUploadResult} from '../model/file-upload-result';
import {ErrorStateMatcher} from '@angular/material/core';
import {NavBranch} from '../model/nav-branch';
import {FileService} from '../../../shared/service/files/file.service';
import {NavReport} from '../model/nav-report';
import {UploadNavMappingFileRecord} from '../model/upload-nav-mapping-file-record';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html'
})
export class FileUploadComponent implements OnInit {
  loading: boolean = false;
  file1!: File;
  file2!: File;
  file3!: File;
  datasources: NavBranch[] = [];
  navUploadForm: FormGroup = this._formBuilder.group({});
  loadNavReportModel: LoadNavReportModel = new LoadNavReportModel();
  loadNavMappingModel: UploadNavMappingFileRecord[] = [];
  fileUploadResult: FileUploadResult = new FileUploadResult();
  dataSourceFormControl: FormControl = new FormControl('', [Validators.required, Validators.min(1)]);
  dataSourceErrorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();
  file1FormControl: FormControl = new FormControl('', [Validators.required]);;
  file2FormControl: FormControl = new FormControl('', [Validators.required]);;
  fileMappingFormControl: FormControl = new FormControl('', [Validators.required]);;
  IsSubmit: boolean = false;
  IsMappingSubmit: boolean = false;
  IsMappingDownload: boolean = false;
  IsReportDownload: boolean = false;
  IsEmptyMappingData: boolean = false;

  constructor(
    private fileUploadService: FileUploadService,
    private navService: NavService,
    public loader: LoadingService,
    private fileService: FileService,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar) {
    this.loading = false;
  }
 
  ngOnInit(): void {
    this.loading = false;
    this.navUploadForm.reset();
    this.dataSourceFormControl = new FormControl('', [Validators.required, Validators.min(1)]);
    this.file1FormControl = new FormControl('', [Validators.required]);
    this.file2FormControl = new FormControl('', [Validators.required]);
    this.fileMappingFormControl = new FormControl('', [Validators.required]);
    this.getDataSources();
    this.loadNavReportModel.NavBranchId = -1;
    this.loadNavReportModel.DataSourceId = -1;
    this.fileUploadResult.uploadGuid = '';
  }

  File1Change(event: any) {
    this.file1 = event.target.files[0];
  }

  File2Change(event: any) {
    this.file2 = event.target.files[0];
  }

  FileMappingChange(event: any) {
    this.file3 = event.target.files[0];
  }

  onUpload(): void {
    this.loading = true;
    this.IsSubmit = true;
    if (this.dataSourceFormControl.value != undefined && this.file1 != undefined && this.file2 != undefined) {
      this.readFileContent(this.file1, 1);
    }
  }

  onMappingUpload(): void {
    this.IsMappingSubmit = true;
    if (this.file3) {
      this.readMappingFileContent(this.file3);
    }
  }

  onReportDownload(): void {
    this.loading = true;
    this.IsReportDownload = true;
    console.log(this.dataSourceFormControl.value);
    if (this.dataSourceFormControl.value != undefined && this.dataSourceFormControl.value > 0) {
      this.loadNavReportModel.DataSourceId = this.datasources.find(s => s.id === this.loadNavReportModel.NavBranchId)!.sourceId;
      this.navService.GetLastUploadUdid(this.loadNavReportModel.DataSourceId, this.loadNavReportModel.NavBranchId).subscribe(
        (udid: string) => {
          if (udid) {
            this.fileUploadResult.uploadGuid = udid;
            this.navService.getNavReport(this.fileUploadResult.uploadGuid, this.loadNavReportModel.DataSourceId, this.loadNavReportModel.NavBranchId).subscribe(
              (data: any) => {
                if (data) {
                  let navReports: NavReport[] = [];
                  data.forEach((s: any) => {
                    let item = new NavReport(s);
                    navReports.push(item);
                  });
                  this.fileService.exportExcel(navReports, 'report_branch' + this.loadNavReportModel.NavBranchId);
                }
              });
          } else {
            this.fileUploadResult.uploadGuid = '';
          }
          this.loading = false;
        });
    }
    else {
      this.loading = false;
    }
  }

  onMappingDownload(): void {
    this.loading = true;
    this.IsMappingDownload = true;
    this.loadNavMappingModel = [];
    if (this.dataSourceFormControl.value != undefined && this.dataSourceFormControl.value > 0) {
      this.navService.getNavGlMapping(this.loadNavReportModel.NavBranchId).subscribe(
        (data: any) => {
          if (data) {
            data.forEach((s: any) => {
              let item = new UploadNavMappingFileRecord(s);
              this.loadNavMappingModel.push(item);
            });
            this.fileService.exportExcel(this.loadNavMappingModel, 'mapping_branch' + this.loadNavReportModel.NavBranchId);
          } else {
            this.IsEmptyMappingData = true;
          }
          this.loading = false;
        });
    }
  }

  public readFileContent(file: File, order: number) {
    let workBook: XLSX.WorkBook;
    let jsonData = null;
    const reader = new FileReader();
    this.loadNavReportModel.Records = [];
    reader.onload = () => {
      const data = reader.result;
      workBook = XLSX.read(data, {type: 'binary'});
      jsonData = workBook.SheetNames.reduce((initial: any, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      let listOfRecordsSheet1 = jsonData.Sheet1 as Array<object>;
      listOfRecordsSheet1.forEach((s: any) => {
        let item = new UploadNavReportFileModel(s);
        this.loadNavReportModel.Records.push(item);
      });
      this.loadNavReportModel.BatchId = order;
      this.loadNavReportModel.DataSourceId = this.datasources.find(s => s.id === this.loadNavReportModel.NavBranchId)!.sourceId;
      if (this.fileUploadResult.uploadGuid != undefined && this.fileUploadResult.uploadGuid.length == 36) {
        this.loadNavReportModel.UploadGuid = this.fileUploadResult.uploadGuid.toUpperCase();
      }
      this.fileUploadService.upload(this.loadNavReportModel).subscribe(
        (data: any) => {
          if (data) {
            this.fileUploadResult = data as FileUploadResult;
            if (this.fileUploadResult.uploadGuid.length == 36 && order === 1) {
              this.readFileContent(this.file2, 2);
              this.loading = false;
            }
          }        
        }
      );
    }
    reader.readAsBinaryString(file);
  }

  public readMappingFileContent(file: File) {
    this.loading = true;
    this.loadNavMappingModel = [];
    let workBook: XLSX.WorkBook;
    let jsonData = null;
    const reader = new FileReader();
    reader.onload = () => {
      const data = reader.result;
      workBook = XLSX.read(data, {type: 'binary'});
      jsonData = workBook.SheetNames.reduce((initial: any, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      let listOfRecordsSheet1 = jsonData.Sheet1 as Array<object>;
      this.fileUploadService.uploadMapping(listOfRecordsSheet1).subscribe(
        (data: any) => {
          if (data) {
            this._snackBar.open('Successfully uploaded mapping', 'Dismiss');
          } else {
            this._snackBar.open('Upload of mapping was not succesfull', 'Dismiss');
          }
          this.loading = false;
        }
      );
    }
    reader.readAsBinaryString(file);
  }

  public getDataSources() {
    this.loading = true;
    this.navService.getNavBranches().subscribe(
      (data: any) => {
        if (data?.items) {
          this.datasources = data.items as NavBranch[];
        }
        this.loading = false;
      }
    );
  }

  public onDataSourceSelected() {
    this.IsEmptyMappingData = false;
    this.IsSubmit = false;
    this.IsMappingSubmit = false;
    this.IsMappingDownload = false;
    this.IsReportDownload = false;
    this.IsEmptyMappingData = false;
    this.loading = false;
  }

  public IsNotLoading(): boolean {
    return !this.loading;
  }
}
