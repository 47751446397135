import {Component, Input} from '@angular/core';
import {SideNavMenu} from 'src/app/shared/model/nav.model';
import {Router} from '@angular/router';

declare const $: any;

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  @Input() sidenavMenu: SideNavMenu = [];

  constructor(private router: Router) { }

  get hasMenuItems() {
    return this.sidenavMenu && this.sidenavMenu.length > 0;
  }

  get isNoAccessPage() {
    return this.router.url.includes('/NoAccess');
  }
}
