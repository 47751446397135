import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {TransactionBooking} from '../model/transaction-booking';
import {TransactionBookingFilterResult} from '../model/transaction-booking-filter-result';

@Injectable({
  providedIn: 'root'
})
export class TransactionBookingService {
  private resourcePath: string = 'transaction-booking';
  private _bookingFilterSubject: BehaviorSubject<TransactionBookingFilterResult> = new BehaviorSubject<TransactionBookingFilterResult>({});
  
  constructor(
    private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public getBookingById(id: number): Observable<TransactionBooking> {
    return this.httpClient
      .get<any>(`${this.resourcePath}/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  public getAllBookings(): Observable<TransactionBooking[]> {
    return this.httpClient
      .get<any>(this.resourcePath, {params: new HttpParams()})
      .pipe(catchError(this.errorHandler));
  }

  public getBookingByKey(httpParams: HttpParams): Observable<any> {
    return this.httpClient
      .get<any>(`${this.resourcePath}/search`, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  public searchBookings(bookingFilter: any): Observable<any> {
    let httpParams = new HttpParams();
    Object
      .keys(bookingFilter)
      .forEach((key) => {
        if (bookingFilter[key]) {
          httpParams = httpParams.append(key, bookingFilter[key]);
        }
      });

    return this.httpClient
      .get<any>(`${this.resourcePath}/search`, {params: httpParams})
  }

  getBookingFilter(): Observable<TransactionBookingFilterResult> {
    return this._bookingFilterSubject.asObservable();
  }

  setBookingFilter(value: TransactionBookingFilterResult): void {
    this._bookingFilterSubject.next(value);
  }
}
