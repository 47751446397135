import {Component, OnInit} from '@angular/core';
import {FinancialAlertsModalComponent} from '../financial-alerts/financial-alerts-modal.component';
import {ModalService} from '../../../shared/service/modal/modal.service';
import {FinancialAlertsService} from '../../service/financial-alerts.service';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {AlertItem} from '../../../shared/model/financial-alerts-result';

@Component({
  selector: 'alerts-grid',
  templateUrl: './alerts.component.html',
})
export class AlertsGridComponent implements OnInit {

    items:AlertItem[] = [];
    sort?:Partial<Sort>;
    page?:Partial<PageEvent & {totalItemCount?: number}>;

    isTogglePending = false;

    displayedColumns = [
        'alert',
        'enabled',
        'criteria',
        'notificationType',
        'action',
    ]

    constructor(
        private modal: ModalService,
        private financialAlertsService: FinancialAlertsService,
    ){}

    ngOnInit(): void {
        this.page = {
            pageSize: 25,
            pageIndex: 0,
            totalItemCount: 0,
        };

        this.getItems();
    }

    getItems(){
        this.financialAlertsService.getAlerts({
            page: this.page,
            sort: this.sort,
        }).subscribe((data)=>{
          this.items = data?.items;
          this.page = {
            ... this.page,
            pageIndex: data.pagination.pageNumber - 1,
            totalItemCount: data.pagination.totalItemCount,
          };
        });
      }

    handleSortChange(sort:Sort){
        this.sort = sort;

        if(!this.sort?.active || !this.sort?.direction) {
          this.sort = {};
        }
      
        this.getItems();
    }

    handlePageChanged(page:PageEvent){
        this.page = {
            ...this.page,
            ...page,
        };
      
        this.getItems();
    }

    handleFinancialAlerts(alertId?:string){
        const modalRef = this.modal.open(FinancialAlertsModalComponent, { 
                width: '35%',
                height: '100%',
                position: {right: '0'},
                panelClass: 'overflowAuto',
                data: alertId,
        });
        modalRef.afterClosed().subscribe((completed)=>{
            if(completed){
                this.getItems();
            }
        });
        modalRef.componentInstance.title = 'Financial alerts and Thresholds';
    }

    handleToggleAlert(alertItem: AlertItem){
        this.isTogglePending = true;
        this.financialAlertsService.getAlertDetails(`${alertItem.workflowId}`).subscribe({
            next:(data)=>{
                this.financialAlertsService.updateAlertState(data, alertItem.isEnabled).subscribe({
                    next: ()=>{
                        this.isTogglePending = false;
                    },
                    error: ()=>{
                        this.getItems();
                        this.isTogglePending = false;
                    },
                });
            },
            error: ()=>{
                alertItem.isEnabled = !alertItem.isEnabled;
                this.isTogglePending = false;
            }
        });
    }
}