import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {VersionComponent} from './version/version.component';
import {MatMenuModule} from '@angular/material/menu';
import {SharedModule} from '../shared/shared.module';
import {AngularMaterialDesignModule} from '../angular-material-design/module/angular-material-design.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialDesignModule,
    MatMenuModule,
    SharedModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    VersionComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    VersionComponent
  ]
})
export class ComponentsModule {
}
