<div class="row">
  <div class="flex-center">
    @if (dateFilterText) {
      <widget-info id="vcc-date-text-widget-info" text="{{dateFilterText}}" [title]="titleWidget" textSub="Total days: {{totalDays}}"
        color="primary-300" icon="calendar_month">
      </widget-info>
    } @else {
      <widget-info id="vcc-date-text-widget-info" text="{{releasedDateFrom}} - {{formattedTodayDate}}" [title]="titleWidget" textSub="Total days: {{totalDays}}"
        color="primary-300" icon="calendar_month">
      </widget-info>
    }
  </div>
  <div class="row">
    <div class="col-md-6 mt-24">
      <funding-vcc-chart></funding-vcc-chart>
    </div>
    <div class="col-md-6 mt-24 ml-2">
      <financial-balances-vcc-list></financial-balances-vcc-list>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mt-24">
      <matching-vcc-chart></matching-vcc-chart>
    </div>
    <div class="col-md-6 mt-24">
      <aggregated-vcc-chart></aggregated-vcc-chart>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mt-24">
      <circulation-vcc-chart></circulation-vcc-chart>
    </div>
  </div>
</div>
  