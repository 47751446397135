import {Injectable} from '@angular/core';
import {HttpClient, HttpContext, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, catchError, throwError} from 'rxjs';
import {GetAdxVccResponse} from '../model/get-adx-vcc-response';
import { BYPASS_ERROR_INTERCEPTOR } from 'src/app/shared/model/problem-details.response';

@Injectable({
  providedIn: 'root'
})
export class AdxVccService {
    private resourcePath: string = 'dbov2/adx-virtual-credit-card';

    constructor(private httpClient: HttpClient) {
    }

    errorHandler(error: HttpErrorResponse) {
      return throwError(error.message || 'server Error');
    }

    public getAdxVccByQuery(transactionBookingId: number[], isDeleted: boolean, bypassErrorInterceptor: boolean = false): Observable<GetAdxVccResponse> {
      const context = new HttpContext().set(BYPASS_ERROR_INTERCEPTOR, bypassErrorInterceptor);
      let httpParams = new HttpParams({fromObject: {TravelServiceBookingId: transactionBookingId, IsDeleted: isDeleted}});
      return this.httpClient
        .get<any>(this.resourcePath, {params: httpParams, context})
        .pipe(catchError(this.errorHandler));
    }
}
