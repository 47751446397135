<div id="booking-template-layout-template-form" class="template-form">
  <div id="booking-template-layout-bookinginfo">
    <div id="booking-template-layout-search-area" class="col-md srch-area">
      <search-template
        id="booking-template-layout-search-template"
        [data]="flag"
        (selectedTemplate)="getSelectedTemplateInfo($event)">
      </search-template>
      <app-template-viewer
        id="booking-template-layout-template-viewer"
        [data]="templateData"
        [update]="update">
      </app-template-viewer>
    </div>
  </div>
</div>

