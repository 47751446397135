import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class ApiVersionInterceptor implements HttpInterceptor {

  firstVersion: number = 1;

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.featureIntegrationCheckAppConfig(request)) {
      return next.handle(request);
    }

    if (this.featureIntegrationCheckHealthCheck(request)) {
      return next.handle(request);
    }

    if (this.featureIntegrationCheckDboV2HealthCheck(request)) {
      return next.handle(request);
    }

    if (this.featureIntegrationCheckGraphApi(request)) {
      return next.handle(request);
    }

    if(this.CheckRequestBelongsToDboV2Api(request)){
      return next.handle(request.clone({
        url: `dbov2/api/v${this.firstVersion}/${request.url.replace('dbov2/', '')}`
      }));
    }

    return next.handle(request.clone({
      url: `api/v${this.firstVersion}/${request.url}`
    }));
  }

  private featureIntegrationCheckAppConfig(request: HttpRequest<unknown>): boolean {
    return request.url.includes('appconfig');
  }

  private featureIntegrationCheckHealthCheck(request: HttpRequest<unknown>): boolean {
    return request.url.includes('trx/health');
  }

  private featureIntegrationCheckDboV2HealthCheck(request: HttpRequest<unknown>): boolean {
    return request.url.includes('dbov2/health');
  }

  private featureIntegrationCheckGraphApi(request: HttpRequest<unknown>): boolean {
    return request.url.includes('graph');
  }

  private CheckRequestBelongsToDboV2Api(request: HttpRequest<unknown>): boolean {
    return request.url.includes('dbov2/');
  }
}
