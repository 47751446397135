import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {TermsConditionsTemplate} from 'src/app/terms-conditions/model/terms-conditions-template';
import {CreateBookingTemplate} from 'src/app/booking-template/model/create-booking-template';
import {BookingTemplateService} from 'src/app/booking-template/service/booking-template.service';
import {
  ConfirmationDialogBookingTemplateComponent
} from 'src/app/booking-template/component/popup/confirmation-dialog/confirmation-dialog.component';
import {TermsConditionsService} from '../../../../terms-conditions/service/terms-conditions.service';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {AutoUnsubscribe} from '../../../../../infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-booking-template-addedit',
  templateUrl: './booking-template-addedit.component.html'
})
export class BookingTemplateAddEditComponent implements OnInit {
  @Input() public data: any;
  bookingTemplateErrorStateMatcher!: ErrorStateMatcher;
  termConditionsTemplateValidator!: FormControl;
  notesValidator!: FormControl;
  public termsConditionsTemplates: TermsConditionsTemplate[] = [];
  isPageLoadingSpinnerVisible: boolean = false;
  isInputDisabled: boolean = false;
  loading$: any;
  title: string = '';
  bookingTemplateSelected: number = 0;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();
  existingSavedBookingTemplateId: number = 0;
  
  public _createBookingTemplate: CreateBookingTemplate = {
    id: 0,
    transactionBookingId: 0,
    templateId: 0,
    termsConditionsTypeId: 0,
    bookingTemplateNotes: '',
    termsConditionsType: '',
    template: '',
    description: '',
    bookingTemplateExists: false,
    bookingTemplateNoteExists: false
  };

  constructor(
    private bookingTemplateService: BookingTemplateService,
    private termsConditionsService: TermsConditionsService,
    public dialog: MatDialog,
    private buildr: FormBuilder,
    public loader: LoadingService) {
  }

  ngOnInit(): void {
    this.loading$ = this.loader.loading$;
    this.setFormControls();
    this.setData();
  }

  setFormControls() {
    this.termConditionsTemplateValidator = new FormControl(
      {value: '', disabled: this.isInputDisabled}, [
        Validators.required
      ]);
    this.notesValidator = new FormControl(
      {value: '', disabled: this.isInputDisabled}, [
        Validators.maxLength(1000),
      ]);
    this.bookingTemplateErrorStateMatcher = new BookingTemplateErrorStateMatcher();
  }

  setData() {
    if (this.data != undefined) {
      if (this.data.termsConditionsTypeId == 1) {
        this.getPaymentTermsTemplate();
        this._createBookingTemplate.termsConditionsType = 'Vendor Payment';
      } else if (this.data.termsConditionsTypeId == 2) {
        this.getCancellationTermsTemplate();
        this._createBookingTemplate.termsConditionsType = 'Cancellation Refund';
      }
      if (this.data.title != undefined) {
        this.title = this.data.title;
      }
      this._createBookingTemplate.termsConditionsTypeId = this.data.termsConditionsTypeId;
      this._createBookingTemplate.transactionBookingId = this.data.transactionBookingId;

      this.bookingTemplateService.getBookingTemplateByTCType(this.data.termsConditionsTypeId, this.data.transactionBookingId)
        .subscribe({
          next: (item) => {
            const bookingName = this.data.bookingName;
            if (item) {
              this._createBookingTemplate.templateId = parseInt(item.template.id);
              this._createBookingTemplate.id = parseInt(item.id);
              this.bookingTemplateSelected = parseInt(item.template.id);
              this._createBookingTemplate.bookingTemplateNotes = item.bookingTemplateNotes;
              this._createBookingTemplate.template = item.templateName;
              this._createBookingTemplate.description = item.template.templateDescription;
              this._createBookingTemplate.bookingTemplateExists = true;
              this._createBookingTemplate.bookingTemplateNoteExists = !!item.bookingTemplateNotes;
              this.title = 'Edit terms and conditions for booking \'' + bookingName + '\'';
              this.existingSavedBookingTemplateId = parseInt(item.template.id);
            }else{
              this.title = 'Add terms and conditions for booking \'' + bookingName + '\'';
            }
          }
        });
    }
  }

  openBookingConfirmationDialog(): void {
    if (this.termConditionsTemplateValidator.value !== '') {
      //If there is a template change since 'bookingTemplateNoteExists' boolean used in 'ConfirmationDialogBookingTemplateComponent' to identify update or 
      //(delete & create) operation based upon empty notes, therefore making sure to trigger delete and create section instead of update notes when template changed.
      if(this.existingSavedBookingTemplateId != this._createBookingTemplate.templateId){
        this.existingSavedBookingTemplateId = this._createBookingTemplate.templateId;
        this._createBookingTemplate.bookingTemplateNoteExists = true;
      }
      this.dialog.open(ConfirmationDialogBookingTemplateComponent, {
        data: {
          bookingTemplate: this._createBookingTemplate
        },
      });
    }
  }

  onSelectTermConditionTemplate(termConditionTemplateId: number) {
    let termConditionTemplate = this.termsConditionsTemplates.find(x => x.id == termConditionTemplateId);
    if (termConditionTemplate !== undefined) {
      this._createBookingTemplate.description = termConditionTemplate.templateDescription;
      this._createBookingTemplate.templateId = termConditionTemplateId;
    }
  }

  onNotesChange(event: Event): void {
    this._createBookingTemplate.bookingTemplateNotes = (event.target as any).value;
  }

  bookingTemplateForm = this.buildr.group({
    TermsConditionsType: this.buildr.control(''),
    Template: this.buildr.control(''),
    Description: this.buildr.control(''),
    Notes: this.buildr.control('')
  });

  public getPaymentTermsTemplate() {
    this.termsConditionsService.GetPaymentTermsTemplate()
      .subscribe((data: any) => {
        this.termsConditionsTemplates = data as TermsConditionsTemplate[];
      });
  }

  public getCancellationTermsTemplate() {
    this.termsConditionsService.GetCancellationTermsTemplate()
      .subscribe((data: any) => {
        this.termsConditionsTemplates = data as TermsConditionsTemplate[];
      });
  }
}

export class BookingTemplateErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form?.submitted;
    return !!(control?.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
