import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {Rule} from '../../../terms-conditions/model/rule';
import {TermsConditionsService} from '../../../terms-conditions/service/terms-conditions.service';

@Component({
  selector: 'app-template-confirmation-dialog',
  templateUrl: './template-confirmation-dialog.component.html'
})
export class TemplateConfirmationDialogComponent {
  isLoadingSpinner: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  public _createTemplate: any = {
    templateName: '',
    templateDescription: '',
    termsConditionsTypeId: -1,
    templateNotes: '',
    termsConditionsRule: []
  };

  constructor(
    public dialogRef: MatDialogRef<TemplateConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private termsConditionsService: TermsConditionsService,
    private _snackBar: MatSnackBar
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  okClick(): void {
    this.isLoadingSpinner = true;
    if (this.data.template?.rules) {
      this.populateTemplate(this.data.template);
      this.termsConditionsService.AddTemplateFormDetails(this._createTemplate)
        .subscribe({
          next: (response) => {
            this.isLoadingSpinner = false;
            this.dialogRef.close();
            this._snackBar.open('Successfully new template saved in the system with the template id: ' + response.termsConditionsTemplateId, 'Dismiss', {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['message-position', 'app-notification-success']
            });
            this.isLoadingSpinner = false;
          },
          error: () => {
            this.isLoadingSpinner = false;
            this.dialogRef.close();
            this.dialogRef.close();
          }
        });
    }
  }

  populateRules() {
    let arrayOfRules: any[] = [];
    this.data.template.rules.forEach((rule: Rule) => {
      let ruleObj = {
        ruleAmt: rule.ruleAmt,
        isAmt: rule.isAmt,
        isPrc: rule.isPrc,
        termsConditionsRuleTypeId: rule.termsConditionsRuleTypeId,
        termsConditionsRuleTypeNumber: rule.termsConditionsRuleTypeNumber,
        ruleConditionId: rule.ruleConditionId
      }
      arrayOfRules.push(ruleObj);
    });
    return arrayOfRules;
  }

  populateTemplate(template: any) {
    this._createTemplate.templateName = template.templateName;
    this._createTemplate.templateDescription = template.templateDescription;
    this._createTemplate.termsConditionsTypeId = template.termsConditionsTypeId;
    this._createTemplate.templateNotes = template.templateNotes;
    this._createTemplate.termsConditionsRule = this.populateRules();
  }
}
