<form [formGroup]="bookingTCViewerForm" id="booking-tc-viewer-form" class="template-form">
  <div id="booking-main-container" class="container">
    <div id="booking-error-div" *ngIf="bookingTCViewerForm.invalid">
      <mat-error>Form is invalid</mat-error>
    </div>
    <mat-form-field id="booking-number-mat-form-field" *ngIf="this._booking.transactionBookingId != undefined && this._booking.transactionBookingId != null">
      <mat-label id="booking-number-field-title">Service number: </mat-label>
      <input id="booking-number-input-field" disabled matInput value="{{this._booking.transactionBookingId}}">
    </mat-form-field>
    <mat-form-field id="booking-terms-and-conditions-mat-form-field" *ngIf="_bookingTermsAndConditions != undefined && _bookingTermsAndConditions != null">
      <mat-label id="booking-terms-and-conditions-field-title">Name: </mat-label>
      <input id="booking-terms-and-conditions-input-field" disabled matInput value="{{_bookingTermsAndConditions.template.templateName}}">
    </mat-form-field>
    <mat-form-field id="booking-description-field-mat-form-field">
      <mat-label id="booking-description-field-title">Description: </mat-label>
      <input id="booking-description-input-field" disabled matInput value="{{_bookingTermsAndConditions.template.templateDescription}}">
    </mat-form-field>
    <mat-form-field id="booking-template-notes-field-mat-form-field">
      <mat-label id="booking-template-notes-field-title">Service Template Notes: </mat-label>
      <input id="booking-template-notes-input-field" disabled matInput value="{{_bookingTermsAndConditions.bookingTemplateNotes}}">
    </mat-form-field>
  </div>
  <br>
  <div class="row">
    <ng-container #container></ng-container>
  </div>
</form>
