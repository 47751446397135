import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-vendor-template-addedit-modal',
  templateUrl: './vendor-template-addedit-modal.component.html'
})
export class VendorTemplateAddEditModalComponent {
  vendorTemplateData: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VendorTemplateAddEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnChanges() {
    this.vendorTemplateData = this.data;
  }

  ngOnInit(): void {
    this.vendorTemplateData = this.data;
  }

  closeVendorPopUpDialog() {
    this.dialogRef.close();
  }
}
