import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BookingStatus} from '../model/booking-status';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CacheableQuery} from '../../../infrastructure/abstractrions/cacheable-query';
import {HttpParamHelper} from '../../../infrastructure/helper/http-param-helper';

@Injectable({
  providedIn: 'root'
})
export class BookingStatusService {

  constructor(private httpClient: HttpClient) {
  }

  public getBookingStatuses(query: CacheableQuery): Observable<Array<string>> {
    return this.httpClient
      .get<Array<BookingStatus>>('booking-status', {params: HttpParamHelper.toQuery(query)})
      .pipe(map(bss => bss.map(bs => bs.bookingStatusName)));
  }
}
