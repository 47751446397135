import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {repeat, tap} from 'rxjs';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {
  constructor(private httpClient: HttpClient) {
  }

  public init(): void {
    this.checkFmsApiHealthCheck();
    this.checkDboV2ApiHealthCheck();
  }

  public checkFmsApiHealthCheck(): void{
    if (environment.healthCheckInterval) {
      this.httpClient
        .get('trx/health', {
          responseType: 'text'
        })
        .pipe(
          tap(
            {
              next: (status) => {
                const localTime = moment();
                console.log(`FMS Transaction API - UTC Time: ${localTime.utc()}\n`, `Time: ${localTime}\n`, `Status: Transaction API ${status}\n`);
              }
            }
          ),
          repeat({
            delay: Number(environment.healthCheckInterval)
          })
        ).subscribe();
    }
  }

  public checkDboV2ApiHealthCheck(): void {
    if (environment.healthCheckInterval) {
      this.httpClient
        .get('dbov2/health', {
          responseType: 'text'
        })
        .pipe(
          tap(
            {
              next: (status) => {
                const localTime = moment();
                console.log(`FMS DboV2 API - UTC Time: ${localTime.utc()}\n`, `Time: ${localTime}\n`, `Status: DboV2 API ${status}\n`);
              }
            }
          ),
          repeat({
            delay: Number(environment.healthCheckInterval)
          })
        ).subscribe();
    }
  }
}
