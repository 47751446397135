<div>
    <h2 data-testid="adx-booking-details-title" style="text-align: center;">Booking {{this.data.id}}</h2>
</div>

<mat-tab-group dynamicHeight disablePagination="true" (selectedTabChange)="tabChanged($event)">
    <mat-tab label="General info">
        <app-adx-booking-info [data]="data"></app-adx-booking-info>
    </mat-tab>
    <mat-tab label="Agents">
        <br>
        @for (adxAgentBooking of data.adxAgentBookings; track adxAgentBooking) {
            <app-adx-agent-booking-info [data]="adxAgentBooking"></app-adx-agent-booking-info>
        }
    </mat-tab>
    <mat-tab label="Udids">
        @for (adxBookingUdid of data.adxBookingUdids; track adxBookingUdid) {
            <app-adx-booking-udid-info [data]="adxBookingUdid"></app-adx-booking-udid-info>
        }
    </mat-tab>
</mat-tab-group>
