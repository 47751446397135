import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, catchError, throwError} from 'rxjs';
import {VccPaymentGateway} from '../model/vcc-payment-gateway';
import {UpsertVccRequest} from '../model/upsert-vcc-request';
import {UpsertVccResponse} from '../model/upsert-vcc-response';
import {DeleteVccRequest} from '../model/delete-vcc-request';

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {
    private resourcePath: string = 'payment-gateway';

    constructor(private httpClient: HttpClient) {
    }

    errorHandler(error: HttpErrorResponse) {
      return throwError(error.message || 'server Error');
    }

    public getVccPaymentGatewayInfoById(id: number, purchasedCardGuid: string, appId: string): Observable<VccPaymentGateway> {
      let httpParams = new HttpParams();
      if (id != null) {
        httpParams = httpParams.set('VccId', id);
      }
      if (purchasedCardGuid != null) {
        httpParams = httpParams.set('PurchasedCardGuid', purchasedCardGuid);
      }
      httpParams = httpParams.set('ApplicationId', appId);
      return this.httpClient
        .get<VccPaymentGateway>(this.resourcePath, {params: httpParams})
        .pipe(catchError(this.errorHandler));
    }

    public getVccsPaymentGatewayInfoByQuery(transactionBookingId: number, isDeleted: boolean): Observable<VccPaymentGateway[]> {
      let httpParams = new HttpParams({fromObject: {TransactionBookingId: transactionBookingId, IsDeleted: isDeleted}});
      return this.httpClient
        .get<any>(this.resourcePath, {params: httpParams})
        .pipe(catchError(this.errorHandler));
    }

    public createPaymentGatewayVccService(issueVcc: UpsertVccRequest): Observable<UpsertVccResponse> {
      return this.httpClient
        .post<UpsertVccResponse>(this.resourcePath, JSON.stringify(issueVcc));
    }

    public updatePaymentGatewayVccService(updateVcc: UpsertVccRequest): Observable<UpsertVccResponse> {
      return this.httpClient
        .put<UpsertVccResponse>(this.resourcePath, JSON.stringify(updateVcc));
    }

    public deletePaymentGatewayVccService(deleteVcc: DeleteVccRequest){
      return this.httpClient
        .delete<any>(this.resourcePath, { body: JSON.stringify(deleteVcc)});
    }
}
