import {Component} from "@angular/core";
import {ActivatedRoute} from '@angular/router';
import {MatchingVccService} from "../../service/matching-vcc.service";
import {MatchingVccDetails} from "../../model/match-vcc";
import {AzureAppConfigurationService } from "src/infrastructure/services/azure-app-configuration.service";
import {Merchant} from "src/app/dashboard/model/merchant-guid";
import {from} from "rxjs";

@Component({
    selector: 'matching-vcc-detail',
    templateUrl: './matching-vcc-detail.component.html',
})
export class MatchingVccDetailComponent {

    id = '';

    details?:MatchingVccDetails;
    
    constructor(
        private route: ActivatedRoute,
        private matchingVccService: MatchingVccService,
        private azureAppConfigService: AzureAppConfigurationService,
    ){
        this.id = this.route.snapshot.paramMap.get('id')!;
        this.matchingVccService.getMatchingDetails(this.id).subscribe((data)=>{
            this.details = data;
            this.getMerchantGuidData();
        })
    }

    getMerchantGuidData(){
        const subscription$ = from(this.azureAppConfigService.getByKey('MerchantGuid')).subscribe((result)=>{
            const merchantGuidsString = result?.value;
            if (merchantGuidsString) {
                const parsedMerchantGuids = JSON.parse(merchantGuidsString);
                const merchantGuids: Merchant[] = parsedMerchantGuids.merchantGuid;

                const matchedMerchant = merchantGuids.filter((merchant)=>{
                    return merchant.id === this.details?.merchantGuid;
                });

                this.details!.connexPayCurrency = matchedMerchant[0].currencyCode;
                this.details!.vccCurrency = matchedMerchant[0].currencyCode;
            }
            subscription$.unsubscribe();
        });    
    }
}
