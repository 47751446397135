import {Component} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {environment} from 'src/environments/environment';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

export interface PeriodicElement {
  fullName: string;
  jobTitle: string;
  email: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {fullName: 'Katryn Persaud', jobTitle: 'FMS, Project Manager', email: 'katryn.persaud@trip-arc.com'}
];

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html'
})

@AutoUnsubscribe()
export class NoAccessComponent {
  displayedColumns: string[] = ['fullName', 'jobTitle', 'email'];
  dataSource = ELEMENT_DATA;

  constructor(private authService: MsalService) {
  }

  azureAccountLogout() {
    localStorage.clear();
    this.authService.logoutRedirect({postLogoutRedirectUri: environment.postLogoutUrl});
  }
}
