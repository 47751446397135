<modal-panel id="vendor-filter-modal-panel" class="vendor-filter-modal-panel-cls filter-panel" [title]="'Filter'" (close)="handleVendorFiltersFormCloseEvent()">
    <div id="vendor-filter-modal-div-1" class="filter" [formGroup]="form">
      <div id="vendor-filter-modal-div-2" class="filter__fields">
        <mat-grid-list cols="1" gutterSize="20px" rowHeight="85" id="vendor-list-grid-list">
            <mat-grid-tile id="vendor-filter-name-grid-tile">
              <mat-form-field id="vendor-filter-name-form-field">
                <mat-label id="vendor-filter-name-label">Vendor Name</mat-label>
                <input
                  matInput
                  [(ngModel)]="config.vendorName"
                  formControlName="vendorName"
                  id="vendor-filter-name-input">
                  @if(form.controls['vendorName'].value){
                    <button 
                        id="vendor-filter-name-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearVendorFilters('vendorName')">
                            <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile id="vendor-filter-city-grid-tile">
              <mat-form-field id="vendor-filter-form-field">
                <mat-label id="vendor-filter-city-label">Vendor City</mat-label>
                <input
                  matInput
                  [(ngModel)]="config.vendorCity"
                  formControlName="vendorCity"
                  id="vendor-filter-city-input">
                  @if(form.controls['vendorCity'].value){
                    <button 
                        id="vendor-filter-city-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearVendorFilters('vendorCity')">
                            <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile id="vendor-filter-country-grid-tile">
              <mat-form-field id="vendor-filter-country-form-field">
                <mat-label id="vendor-filter-country-label">Vendor Country</mat-label>
                <input
                  matInput
                  [(ngModel)]="config.vendorCountry"
                  formControlName="vendorCountry"
                  id="vendor-filter-country-input">
                  @if(form.controls['vendorCountry'].value){
                    <button 
                        id="vendor-filter-country-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearVendorFilters('vendorCountry')">
                            <mat-icon>close</mat-icon>
                    </button>
                  }
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
      </div>
    </div>
    <ng-container id="vendor-filter-modal-footer-container" modalPanelFooter>
      <div class="modal-footer">
        <button id="vendor-filter-modal-button-clear-all" mat-stroked-button color="primary" (click)="clearVendorFilters('all')">Clear All
          <mat-icon id="vendor-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
        </button>
        <button id="vendor-filter-modal-button" mat-raised-button color="primary" (click)="applyVendorFilters()">Apply</button>
      </div>
    </ng-container>
  </modal-panel>
  