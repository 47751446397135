
<ng-container>
    <form data-testdata-testid="payment-tab-detail-form" class="template-form payment-tab-detail-form" *ngIf="!(loading$ | async)">  
      <div data-testdata-testid="payment-tab-detail-main-container" class="container">
        <mat-tab-group dynamicHeight disablePagination="true" (selectedTabChange)="tabChanged($event)">
            @for (payment of payments; track payment) {
            <mat-tab label="Payment {{payment.id}}">
                <mat-card class="mat-elevation-z8">
                    <mat-card-content>
                        <mat-form-field data-testdata-testid="payment-tab-detail-ckCcNo-field" *ngIf="payment.ckCcNo != undefined && payment.ckCcNo != null">
                            <mat-label data-testdata-testid="payment-tab-detail-ckCcNo-field-title">Check No: </mat-label>
                            <input data-testdata-testid="payment-tab-detail-ckCcNo-input-field" matInput value="{{payment.ckCcNo}}">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="payment-tab-detail-amount-field" *ngIf="payment.amount != undefined && payment.amount != null">
                            <mat-label data-testdata-testid="payment-tab-detail-amount-field-title">Amount: </mat-label>
                            <input data-testdata-testid="payment-tab-detail-amount-input-field" matInput value="{{payment.amount}}">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="payment-tab-detail-remarks-field" *ngIf="payment.remarks != undefined && payment.remarks != null">
                            <mat-label data-testdata-testid="payment-tab-detail-remarks-field-title">Remarks: </mat-label>
                            <input data-testdata-testid="payment-tab-detail-remarks-input-field" matInput value="{{payment.remarks}}">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="payment-tab-detail-payment-date-field" *ngIf="payment.paymentDate != undefined && payment.paymentDate != null">
                            <mat-label data-testdata-testid="payment-tab-detail-payment-date-field-title">Payment Date: </mat-label>
                            <input data-testdata-testid="payment-tab-detail-payment-date-input-field" matInput [value]="formatDate(payment.paymentDate)">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="payment-tab-detail-method-field" *ngIf="payment.paymentMethod != undefined && payment.paymentMethod != null">
                            <mat-label data-testdata-testid="payment-tab-detail-method-field-title">Payment Method: </mat-label>
                            <input data-testdata-testid="payment-tab-detail-method-input-field" matInput value="{{payment.paymentMethod}}">
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
            }
        </mat-tab-group>
      </div>
    </form>
  </ng-container>
