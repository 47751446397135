import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterResult } from '../../modals/filter-modal/filter-modal.component';
import { FinanceReportFilterResult } from '../../model/finance-report-filter-result';
import { VccDailySummaryReportFilterResult } from '../../model/vcc-daily-summary-report-filter-result';
import { ClientFilterResult } from 'src/app/client/service/client.model';
import { AgentFilterResult } from 'src/app/agent/component/service/agent.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private _filterSubject: BehaviorSubject<FilterResult> = new BehaviorSubject<FilterResult>({});
  private _financeReportFilterSubject: BehaviorSubject<FinanceReportFilterResult> = new BehaviorSubject<FinanceReportFilterResult>({});
  private _VccDailySummaryReportFilterSubject: BehaviorSubject<VccDailySummaryReportFilterResult> = new BehaviorSubject<VccDailySummaryReportFilterResult>({});
  private clientFilterSubject = new BehaviorSubject<ClientFilterResult>({});
  clientFilter$ = this.clientFilterSubject.asObservable();
  private agentFilterSubject = new BehaviorSubject<AgentFilterResult>({});
  agentFilter$ = this.agentFilterSubject.asObservable();

  getClientFilter(): ClientFilterResult {
    return this.clientFilterSubject.getValue();
  }

  setClientFilter(filter: ClientFilterResult): void {
    this.clientFilterSubject.next(filter);
  }

  getAgentFilter(): AgentFilterResult {
    return this.agentFilterSubject.getValue();
  }

  setAgentFilter(filter: AgentFilterResult): void {
    this.agentFilterSubject.next(filter);
  }

  setFilter(value: FilterResult): void {
    this._filterSubject.next(value);
  }

  getFilter(): Observable<FilterResult> {
    return this._filterSubject.asObservable();
  }

  getFinanceReportFilter(): Observable<FinanceReportFilterResult> {
    return this._financeReportFilterSubject.asObservable();
  }

  setFinanceReportFilter(value: FinanceReportFilterResult): void {
    this._financeReportFilterSubject.next(value);
  }

  getVccDailySummaryReportFilter(): Observable<VccDailySummaryReportFilterResult> {
    return this._VccDailySummaryReportFilterSubject.asObservable();
  }

  setVccDailySummaryReportFilter(value: VccDailySummaryReportFilterResult): void {
    this._VccDailySummaryReportFilterSubject.next(value);
  }

  subtractDays(date: Date, days: number) {
    const customFilterDate = new Date(date);
    customFilterDate.setDate(customFilterDate.getDate() - days);
    return customFilterDate;
  }
}
