export enum MerchantGuidShortName {
    '10929c6a-042c-4ba2-b69a-701bc7127c9e' = 'TENUSD USD',
    'd37a9fc4-0a89-4b49-85b5-bf427684ab3c' = 'KTAIR USD',
    '6437e178-1a57-4169-abc2-c700fb00e4ce' = 'TENAFUS USD',
    '395f558f-ea4f-48a9-9b4b-df3e0c5f1188' = 'ENSUS USD',
    'fc81f962-4604-4d7f-b1ef-f31bde60cf31' = 'HSBC CAD',
    '5c3a0842-d82c-47d7-91ca-7718e9b98044' = 'TECORP CAD',
    '0454aa42-885b-4931-8f0d-e1eb021af6a9' = 'TERTL USD',
    '3537728c-cf32-4abf-a5d2-96ba6772335c' = 'TENCA USD',
    '777d0b81-849a-48be-bd7b-45773ec76804' = 'ENSCA USD',
    '7248ec4f-2f56-4db4-920b-c5833cc762af' = 'TENCA CAD',
    '47bb75e8-3fd8-44dd-9258-05a4ffc77318' = 'TERTL CAD',
    '22f02214-c11d-4b55-a5df-2e41d7ea06c2' = 'TECOR CAD',
    '864b6b89-994c-4aa2-a6a9-b7d75f319879' = 'TENAFCA CAD',
    'af9d80ad-2522-4a91-b11c-7274e0324ccd' = 'ENSCA CAD',
    '3fa85f64-5717-4562-b3fc-2c963f66afa6' = 'TENAFCA USD',
    '2f5c172a-2e0e-4a63-a9d3-ea0ccb30a48c' = 'Nav CAD',
    '68a79556-5818-47fa-9448-e18089e49132' = 'TESTAF FAM USD',
    '97f67599-4f3b-4d53-8c6e-4270ec153c50' = 'KT USD',
    '8848d4c3-d9e2-4d3e-94b3-db22b4c9f2ed' = 'TECHS USD',
    '1e5cb005-ecbf-42b9-9e50-50986a6d437a' = 'TAUSH USD',
}