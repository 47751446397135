import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, catchError, throwError} from 'rxjs';
import {SearchPaginatedAdxInvoiceResponse} from '../model/search-paginated-adx-invoice-response';
import {AdxInvoiceFilterResult} from '../model/adx-invoice-filter-result';
import {AdxInvoiceDetailResponse} from '../model/adx-invoice-detail-response';

@Injectable({
  providedIn: 'root'
})

export class AdxInvoiceService {
  private resourcePath: string = 'dbov2/adx-invoice';
  private _adxInvoiceFilterSubject: BehaviorSubject<AdxInvoiceFilterResult> = new BehaviorSubject<AdxInvoiceFilterResult>({});

  constructor(private httpClient: HttpClient) {
  }

  searchPaginatedAdxInvoices(adxInvoiceFilter: any): Observable<SearchPaginatedAdxInvoiceResponse> {
    let httpParams = new HttpParams();
    Object
      .keys(adxInvoiceFilter)
      .forEach((key) => {
        if (adxInvoiceFilter[key]) {
          httpParams = httpParams.append(key, adxInvoiceFilter[key]);
        }
      });
      return this.httpClient
      .get<SearchPaginatedAdxInvoiceResponse>(`${this.resourcePath}`, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  searchByQueryAdxInvoices(query: any): Observable<AdxInvoiceDetailResponse[]> {
    let httpParams = new HttpParams();
    Object
      .keys(query)
      .forEach((key) => {
        if (query[key]) {
          httpParams = httpParams.append(key, query[key]);
        }
      });
      return this.httpClient
      .get<AdxInvoiceDetailResponse[]>(`${this.resourcePath}/search`, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  getAdxInvoiceFilter(): Observable<AdxInvoiceFilterResult> {
    return this._adxInvoiceFilterSubject.asObservable();
  }

  setAdxInvoiceFilter(value: AdxInvoiceFilterResult): void {
    this._adxInvoiceFilterSubject.next(value);
  }
}