<modal-panel id="vcc-chart-filter-modal-panel"  class="vcc-chart-filter-modal-panel-cls" [title]="'Filter'"  (close)="handleVccChartFiltersFormCloseEvent()">
  <div id="vcc-chart-filter-modal-div-1" class="filter" [formGroup]="form">
      <div id="vcc-chart-filter-modal-div-2" class="filter__fields">
        <mat-grid-list [cols]="isSmall ? 1 : 2" gutterSize="20px" rowHeight="85" data-testid="vcc-chart-list-grid-list">
          <mat-grid-tile>
            <mat-form-field>
              <mat-label id="vcc-chart-filter-released-date-from-field-title">Released Date From</mat-label>
              <input
                id="vcc-chart-filter-released-date-from-input-field"
                matInput
                [matDatepicker]="picker1" readonly
                [(ngModel)]="config.releasedDateFrom"
                formControlName="releasedDateFrom">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 id="vcc-chart-filter-released-date-from-datepicker"></mat-datepicker>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <mat-label id="vcc-chart-filter-released-date-to-field-title">Released Date To</mat-label>
              <input
                id="vcc-chart-filter-released-date-to-input-field"
                matInput
                [matDatepicker]="picker2" readonly
                [(ngModel)]="config.releasedDateTo"
                formControlName="releasedDateTo">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 id="vcc-chart-filter-released-date-to-datepicker"></mat-datepicker>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="1" gutterSize="10px" rowHeight="85">
          <mat-grid-tile>
              <mat-form-field>
                  <mat-label>Merchant name</mat-label>
                  <mat-select [disableOptionCentering]="true"  
                  [(ngModel)]="config.merchantGuid"
                  formControlName="merchantGuid">
                      @if(merchantGuids){
                          @for (merchant of merchantGuids; track merchant) {
                              <mat-option [value]="merchant?.id">{{merchant?.name}}</mat-option>
                          }
                      }
                  </mat-select>
                  @if(form.controls['merchantGuid'].value){
                      <button 
                          data-testid="vcc-chart-filter-merchant-guid-clear-filter-button"
                          matSuffix mat-icon-button
                          type="button"
                          (click)="clearVccChartFilters('merchantGuid')">
                              <mat-icon>close</mat-icon>
                      </button>
                  }
              </mat-form-field>
          </mat-grid-tile>

        </mat-grid-list>
      </div>
    </div>
    <ng-container id="vcc-chart-filter-modal-footer-container" modalPanelFooter>
      <button id="vcc-chart-filter-modal-button" mat-raised-button color="primary" (click)="applyVccChartFilters()">Apply</button>
    </ng-container>
</modal-panel>
