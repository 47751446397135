import {Component, Input, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {TripProfitability} from '../../model/trip-profitability';
import {MatSort, Sort} from '@angular/material/sort';
import {FinanceReportsService} from '../../service/finance-reports.service';
import {ChartOptions} from 'chart.js';
import {ChartOptionsService} from 'src/app/shared/components/chart/chart-options.service';
import {ChartDataSet} from 'src/app/shared/components/chart/chart.component';
import {Pagination} from 'src/infrastructure/pagination/model/pagination';
import {TripProfitabilityResponse} from '../../model/trip-profitability-response';
import {FilterService} from 'src/app/shared/service/filter/filter.service';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {FinanceReportFilterResult} from 'src/app/shared/model/finance-report-filter-result';
import * as moment from 'moment';
import {ExportGridService} from '../../../shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-trip-profitability',
  templateUrl: './trip-profitability.component.html'
})
@AutoUnsubscribe()
export class TripProfitabilityComponent {
  @Input() filter!: FinanceReportFilterResult;
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  public pagination: Pagination = {} as Pagination;

  @ViewChild(MatSort, {static: false}) public sort!: MatSort;
  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  public tripProfitabilityReportFilter: TripProfitability = {} as TripProfitability;
  public dataSource: MatTableDataSource<TripProfitability> = new MatTableDataSource<TripProfitability>([]);
  displayedColumns = [
    'tripReference',
    'dateCreated',
    'advisorName',
    'customerPaid',
    'vendorPaid',
    'commission',
    'agentSplit',
    'marginAmount',
    'marginPercentage',
    'adxBookingNumber'
  ];

  tripProfitabilityPieChartOptions: ChartOptions;
  tripProfitabilityPieChartData!: ChartDataSet;
  tripProfitabilityReportData: TripProfitability[] = [];

  constructor(
    private financeReportsService: FinanceReportsService,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe,
    private chartOptions: ChartOptionsService,
    private filters: FilterService
    ) {
      this.tripProfitabilityPieChartOptions = this.chartOptions.getChartOptions('pie', {
        responsive: true,
        plugins: {
          datalabels:{
            display: false
          },
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Number Of Trips'
          },
        },
        elements: {
          arc: {
            borderWidth: 1
          },
        },
        scales: {
          x: {
            display: false
          },
          y: {
            display: false
          },
        },
      });
    }

  ngOnInit(): void {
    this.tripProfitabilityReportFilter.pageSize = 25;
    this.tripProfitabilityReportFilter.orderKey = 'Desc';
    this.tripProfitabilityReportFilter.propertyName = 'dateCreated';
    this.filters.getFinanceReportFilter().subscribe((filter: FinanceReportFilterResult) => {
      this.tripProfitabilityReportFilter.agentName = filter.advisorName;
      this.tripProfitabilityReportFilter.tripReference = filter.tripReference;
      
      this.tripProfitabilityReportFilter.bookingStartFrom = filter.bookingStartFrom ? moment(filter.bookingStartFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.tripProfitabilityReportFilter.bookingStartTo = filter.bookingStartTo ? moment(filter.bookingStartTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";

      this.tripProfitabilityReportFilter.bookingEndFrom = filter.bookingEndFrom ? moment(filter.bookingEndFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.tripProfitabilityReportFilter.bookingEndTo = filter.bookingEndTo ? moment(filter.bookingEndTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      
      this.tripProfitabilityReportFilter.dateCreatedFrom = filter.dateCreatedFrom ? moment(filter.dateCreatedFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.tripProfitabilityReportFilter.dateCreatedTo = filter.dateCreatedTo ? moment(filter.dateCreatedTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";

      this.getTripProfitabilityReport();
      });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getTripProfitabilityReport() {
    this.financeReportsService.getTripProfitabilityReport(this.tripProfitabilityReportFilter)
      .subscribe((data: TripProfitabilityResponse) => {
        if (data) {
          this.pagination = data.pagination;
          this.tripProfitabilityReportData = data.items;
          this.tripProfitabilityReportFilter.pageNumber = data.pagination.pageNumber;
          this.dataSource = new MatTableDataSource(this.tripProfitabilityReportData);
          const below25MarginPercentNumbrOfTripsCount = this.tripProfitabilityReportData.filter(i => i.marginPercentage <= 25).length;
          const above25MarginPercentNumbrOfTripsCount = this.tripProfitabilityReportData.filter(i => i.marginPercentage > 25).length;
          this.tripProfitabilityPieChartData = {
            labels: [
              'Below 25% Margin Percentage',
              'Above 25% Margin Percentage'
            ],
            datasets: [{
              label: 'Trip Profitability Report',
              data: [below25MarginPercentNumbrOfTripsCount, above25MarginPercentNumbrOfTripsCount],
              backgroundColor: [
                '#708090',
                '#a2798f'
              ],
              hoverOffset: 0,
              spacing: 0
            }]
          };
        }
      });
  }

  pageChanged(event: PageEvent): void  {
    this.tripProfitabilityReportFilter.pageNumber = event.pageIndex + 1;
    this.getTripProfitabilityReport();
  }

  handleSortChange(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.tripProfitabilityReportFilter.propertyName = this.getMappedBookingTblPropertyName(sort.active);
    this.tripProfitabilityReportFilter.orderKey = sort.direction.toUpperCase();
    this.getTripProfitabilityReport();
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.financeReportsService.getTripProfitabilityReport(this.tripProfitabilityReportFilter)
      .subscribe((data: TripProfitabilityResponse) => {
        this.exportGridService.getByDataSource(
          'finance-reports/trip-profitability',
          'trip-profitability',
          {
            ... this.tripProfitabilityReportFilter,
            pageSize: data?.pagination?.totalItemCount || 100,
          },
          (data) => {
            const record = data as TripProfitability;
            return {
              'Trip Reference': record.tripReference,
              'Date Created': this.datePipe.transform(record.dateCreated, 'M/d/yyyy'),
              'Advisor Name': record.advisorName,
              'Customer Paid': record.customerPaid,
              'Vendor Paid': record.vendorPaid,
              'Commission': record.commission,
              'Agent Split': record.agentSplit,
              'Margin Amount': record.marginAmount,
              'Margin Percentage': record.marginPercentage,
              'ADX Booking Number': record.adxBookingNumber
            };
          }
        );
        downloadSubs$?.unsubscribe();
      });
  }
  
  getMappedBookingTblPropertyName(propertyName: string): string {
    switch (propertyName) {
      case 'tripReference':
        return propertyName;
      case 'dateCreated':
        return propertyName;
      case 'advisorName':
        return 'agent.agentName';
      case 'customerPaid':
        return 'clientTotal';
      case 'vendorPaid':
        return 'bookingTotal';
      case 'commission':
        return 'commissionTotal';
      case 'agentSplit':
        return 'agent.splitRate';
      case 'adxBookingNumber':
        return 'externalBookingNumber';
      default:
        return '';
    }
  }
}
