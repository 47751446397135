import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'capitalize',
  standalone: true
})
export class CapitalizePipe implements PipeTransform {

  transform(text: string, all?:boolean): string {
    if(all){
        return text.split(' ').map((word)=>{
            return word ? `${word[0].toUpperCase()}${word?.slice(1)}` : '';
            
        }).join(' ')
    } else {
        return `${text[0].toUpperCase()}${text.slice(1)}`
    }
  }
}
