import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {TransactionBooking} from '../../model/transaction-booking';
import {TransactionBookingService} from '../../service/transaction-booking.service';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'search-transaction-booking',
  templateUrl: './search-transaction-booking.component.html',
})
@AutoUnsubscribe()
export class SearchTransactionBookingComponent implements OnInit {
  @Input() public data: any;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;

  bookingForm!: FormGroup;
  bookings: TransactionBooking[] = [];
  bookingGroupOptions!: Observable<TransactionBooking[]>;
  inputControl = new FormControl();
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  loading$: any;
  @Output() selectedBooking: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _formBuilder: FormBuilder,
              private transactionBookingService: TransactionBookingService,
              public loader: LoadingService) {
  }

  public ngOnInit(): void {
    this.loading$ = this.loader.loading$;
    this.bookingForm = this._formBuilder.group({});
    this.LoadForm();
  }

  LoadForm() {
    this.bookingForm.reset();
    this.getBookings();

  }

  getBookings() {
    this.transactionBookingService.getAllBookings()
      .subscribe((data: any) => {
        if (data.items) {
          this.bookings = data.items as TransactionBooking[];
          this.filterGroups();
        }
      });
  }

  filterGroups() {
    this.bookingGroupOptions = this.inputControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.VendorName),
        map(name => (name
          ? this.bookings.filter(booking => booking.bookingName.toLowerCase().startsWith(name.toLowerCase()))
          : this.bookings.slice()))
      );
  }

  setSelectedBooking(transactionBookingId: number, event: any) {
    if (event.isUserInput) {
      this.emitData(transactionBookingId);
    }
  }

  emitData(transactionBookingId: number) {
    const data = this.bookings.find(s => s.transactionBookingId === transactionBookingId);
    this.selectedBooking.emit(data);
  }

  clearInput(evt: any): void {
    evt.stopPropagation();
    this.inputControl?.reset();
    this.inputAutoComplete?.nativeElement.focus();
  }

  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }
}
