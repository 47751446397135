<modal-panel [title]="title" (close)="handleClose()">
  <div class="view-form">
    <ng-container *ngIf="viewerType == bookingViewerType.Booking; else tcOrVccViewer">
      <app-transaction-booking-viewer [data]="bookingData"></app-transaction-booking-viewer>
    </ng-container>
    <ng-template #tcOrVccViewer>
      <ng-container *ngIf="viewerType == bookingViewerType.VirtualCreditCard else adxVccOrTcViewer">
      <app-vcc-viewer [data]="bookingData" [type]="vccViewerType" [isLoaderVisible]="true" ></app-vcc-viewer>
      </ng-container>
      <ng-template #adxVccOrTcViewer>
        <ng-container *ngIf="viewerType == bookingViewerType.AdxVirtualCreditCard else tcViewer">
          <app-adx-vcc-viewer [data]="bookingData" [isLoaderVisible]="true" ></app-adx-vcc-viewer>
          </ng-container>
          <ng-template #tcViewer>
            <app-transaction-booking-tc-viewer [data]="bookingData" [tcType]="viewerType"></app-transaction-booking-tc-viewer>
          </ng-template>
      </ng-template>
    </ng-template>
  </div>
  <ng-container modalPanelFooter>
    <button id="transation-booking-viewer-modal-close-button" mat-raised-button color="primary" (click)="handleClose()">Close</button>
  </ng-container>
</modal-panel>