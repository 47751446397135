<form data-testid="adx-booking-udid-info-form" class="template-form adx-booking-info-form">  
    <div data-testid="adx-booking-udid-info-main-div" class="filter__fields">
        <mat-card class="mat-elevation-z8">
            <mat-card-content>        
                <mat-form-field data-testid="adx-booking-udid-info-value-field">
                    <mat-label data-testid="adx-booking-udid-info-value-field-title">Number: {{_adxBookingUdid.udidNo}} </mat-label>
                    <input data-testid="adx-booking-udid-info-value-input-field" matInput value="{{_adxBookingUdid.udidValue}}">
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
</form>