import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {Action} from 'src/app/request-action/model/action';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {PaymentGatewayService} from 'src/app/virtual-credit-card/service/payment-gateway-service';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-confirmation-dialog-issue-vcc',
  templateUrl: './confirmation-dialog-issue-vcc.component.html',
})
export class ConfirmationDialogIssueVccComponent implements OnInit {
  transactionBookingName: string;
  isLoadingSpinner: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  actionsList: Action[] = [];
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogIssueVccComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentGatewayService: PaymentGatewayService,
    private _snackBar: MatSnackBar,
    public loader: LoadingService
  ) {
    this.transactionBookingName = this.data.transactionBookingName;
  }
  ngOnInit(): void {
    this.loader.hide();
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  okClick(): void {
    this.isLoadingSpinner = true;
    this.paymentGatewayService.createPaymentGatewayVccService(this.data)
      .subscribe({
        next: (_) => {
          this.isLoadingSpinner = false;
          this.dialogRef.close(true);
          this._snackBar.open(`Successfully virtual credit card is issued for the '${this.data.transactionBookingName}.'\n`, 'Dismiss', {
            duration: 0, // Set duration to 0 for indefinite duration
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['message-position', 'app-notification-success']
          });
        },
        error: () => {
          this.isLoadingSpinner = false;
          this.dialogRef.close(false);
        }
      });
  }
}