import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {LoadNavReportModel} from '../model/load-nav-report-model';

@Injectable({
  providedIn: 'root'
})

export class FileUploadService {

  private resourcePath1: string = 'nav-report-upload';
  private resourcePath2: string = 'nav-mapping-upload';

  constructor(
    private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public upload(UploadNavReportData: LoadNavReportModel): Observable<any> {
    return this.httpClient
      .post<any>(`${this.resourcePath1}`, UploadNavReportData)
      .pipe(catchError(this.errorHandler));
  }

  public uploadMapping(UploadNavMappingData: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.resourcePath2}`, UploadNavMappingData)
      .pipe(catchError(this.errorHandler));
  }
}
