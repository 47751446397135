import {HttpParams} from '@angular/common/http';

export class HttpParamHelper {
  public static toQuery(query: any): HttpParams{
    let httpParams = new HttpParams();
    Object
      .keys(query)
      .forEach((key: any) => {
        const dynamicCacheQueryElement = query[key];
        if (dynamicCacheQueryElement) {
          httpParams = httpParams.append(key, dynamicCacheQueryElement);
        }
      });

    return httpParams;
  }
}
