<div class="grid-title-event">
    <h2 data-testid="fms-vcc-list-title">FMS VCC</h2>

    <div class="events-container">
        @if(dataSource.filteredData.length){
            <button  data-testid="fms-vcc-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="replayGrid()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" data-testid="download-fms-vcc-button" aria-label="Download Adx Vcc File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
                <mat-icon>download</mat-icon>
            </button>
        }
    </div>
</div>
<div class="mat-elevation-z8" *ngIf="!isLoading" id="fms-vcc-list-container">
    <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="handleSortChange($event)"
    id="fms-vcc-list-mat-table">
    <ng-container matColumnDef="TripReference" id="fms-vcc-list-central-adx-reference-column">
        <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="fms-vcc-list-adx-reference-header-cell">Adx Reference</mat-header-cell>
        <mat-cell *matCellDef="let row" id="fms-vcc-list-adx-reference-cell">{{row.tripReference}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="VccNumber" id="fms-vcc-list-card-number-column">
        <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="fms-vcc-list-card-number-header-cell">Card Number</mat-header-cell>
        <mat-cell id="fms-vcc-list-card-number-cell" class="mat-cell" *matCellDef="let row">
          <a id="fms-vcc-list-card-number-link" href={{row.virtualCreditCardUrl}} target="_blank" rel="noopener">
            <div id="fms-vcc-list-card-number-div">
                <p [style.line-break]="'anywhere'">{{row.vccNumber}}</p>
            </div>
          </a>
       </mat-cell>
      </ng-container>
    <ng-container matColumnDef="VirtualCreditCardIssueDate" id="fms-vcc-list-central-issue-date-column">
        <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="fms-vcc-list-issue-date-header-cell">Issue Date</mat-header-cell>
        <mat-cell *matCellDef="let row" id="fms-vcc-list-issue-date-cell">{{row.virtualCreditCardIssueDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="VirtualCreditCardTerminateDate" id="fms-vcc-list-central-terminate-date-column">
        <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="fms-vcc-list-terminate-date-header-cell">Terminate Date</mat-header-cell>
        <mat-cell *matCellDef="let row" id="fms-vcc-list-terminate-date-cell">{{row.virtualCreditCardTerminateDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="VirtualCreditCardStartActiveDate" id="fms-vcc-list-central-start-active-date-column">
        <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="fms-vcc-list-start-active-date-header-cell">Activation Start Date</mat-header-cell>
        <mat-cell *matCellDef="let row" id="fms-vcc-list-start-active-date-cell">{{row.virtualCreditCardStartActiveDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="VirtualCreditCardEndActiveDate" id="fms-vcc-list-central-end-active-date-column">
        <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="fms-vcc-list-end-active-date-header-cell">Activation End Date</mat-header-cell>
        <mat-cell *matCellDef="let row" id="fms-vcc-list-end-active-date-cell">{{row.virtualCreditCardEndActiveDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="VirtualCreditCardCurrentAmt" id="fms-vcc-list-central-current-amt-column">
        <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="fms-vcc-list-current-amt-header-cell">Available Balance</mat-header-cell>
        <mat-cell *matCellDef="let row" id="fms-vcc-list-current-amt-cell">{{row.virtualCreditCardCurrentAmt}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="virtualCreditCardProvider.paymentProviderName" id="fms-vcc-list-central-provider-name-column">
        <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="fms-vcc-list-provider-name-header-cell">Provider Name</mat-header-cell>
        <mat-cell *matCellDef="let row" id="fms-vcc-list-provider-name-cell">{{row.virtualCreditCardProvider.paymentProviderName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="currency.code" id="fms-vcc-list-central-currency-name-column">
        <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="fms-vcc-list-currency-name-header-cell">Currency Name</mat-header-cell>
        <mat-cell *matCellDef="let row" id="fms-vcc-list-currency-name-cell">{{row.currency.code}}</mat-cell>
    </ng-container>
    @if(showPay){
        <ng-container matColumnDef="Pay" id="fms-vcc-list-central-pay-column">
            <mat-header-cell class="mat-header-cell" *matHeaderCellDef id="fms-vcc-list-pay-header-cell">Pay</mat-header-cell>
            <mat-cell *matCellDef="let row" id="fms-vcc-list-pay-cell">
                <button mat-raised-button (click)="$event.stopPropagation(); openAmountModal(row)">Pay</button>
            </mat-cell>
        </ng-container>
    }
    
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" data-testid="fms-vcc-list-header-row"></mat-header-row>
    
    <mat-row *matRowDef="let row; columns: displayedColumns;" id="fms-vcc-mat-row"
    (click)="openDialogShowMoreDetailsOfFmsVcc(row)" (keypress)="openDialogShowMoreDetailsOfFmsVcc(row)">
    </mat-row>
    </mat-table>

    <mat-paginator
        [length]="pagination.totalItemCount"
        [hidePageSize]="true"
        [pageSize]="pagination.pageSize"
        (page)="getFmsVcc($event)"
        id="fms-vcc-list-mat-paginator">
    </mat-paginator>
</div>