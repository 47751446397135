import {Component, ViewChild, OnInit, AfterViewInit, Input} from '@angular/core';
import {Pagination} from '../../../../infrastructure/pagination/model/pagination';
import {MatTableDataSource} from '@angular/material/table';
import {TramsInvoiceModel} from '../../model/trams-invoice-model';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {TramsInvoiceFilter} from '../../model/trams-invoice-filter';
import {InvoiceService} from '../../service/invoice.service';
import {SearchTramsInvoiceResponse} from '../../model/search-trams-invoice-response';
import {FilterResult} from '../../../shared/modals/filter-modal/filter-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-trams-invoices',
  templateUrl: './trams-invoices.component.html'
})
export class TramsInvoicesComponent implements OnInit, AfterViewInit {
  @Input() filter!: FilterResult;
  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort, {static: false})
  public sort!: MatSort;

  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  
  public dataSource: MatTableDataSource<TramsInvoiceModel> = new MatTableDataSource<TramsInvoiceModel>([]);

  public displayedColumns: Array<string> = [
    // Data from service Response
    'InvoiceId',
    'TramsCentralDocumentId',
    'TramsInvoiceNumber',
    'RecordLocator',
    'IssueDate',
    'DateCreated',
    'InvoiceTypeCode',
    'ClientName',
    'DataSourceId',
  ];

  public isLoading: boolean = false;

  public pagination: Pagination = {} as Pagination;
  public tramsInvoiceFilter: TramsInvoiceFilter = {} as TramsInvoiceFilter;

  constructor(
    private  invoiceService: InvoiceService
  ){
  }

  
  ngOnInit(): void {
    this.tramsInvoiceFilter.propertyName = 'Id';
    this.tramsInvoiceFilter.orderKey = 'ASC';
    this.invoiceService.getTramsInvoicesFilter().subscribe((filter) => {
    if (this.paginator !== undefined) {
      this.paginator.pageIndex = 0;
      this.tramsInvoiceFilter.pageNumber = 1;
    }
    this.tramsInvoiceFilter.tramsInvoiceNo = filter.tramsInvoiceNo;
    this.tramsInvoiceFilter.invoiceRecordLocator = filter.invoiceRecordLocator;
    this.tramsInvoiceFilter.issueStartDate = filter.issueStartDate ? moment(filter.issueStartDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
    this.tramsInvoiceFilter.issueEndDate = filter.issueEndDate ? moment(filter.issueEndDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
    this.tramsInvoiceFilter.createdStartDate = filter.createdStartDate ? moment(filter.createdStartDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
    this.tramsInvoiceFilter.createdEndDate = filter.createdEndDate ? moment(filter.createdEndDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
    this.getTramsInvoices(new PageEvent());
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  clearFilters(propertyName: string): void {
    let temp: any = this.tramsInvoiceFilter;
    temp[propertyName] = undefined;
    this.tramsInvoiceFilter = temp;

    if (propertyName === 'all') {
      this.tramsInvoiceFilter = {} as TramsInvoiceFilter;
    }

    this.getTramsInvoices(new PageEvent());
  }

  getTramsInvoices( pageEvent: PageEvent ): void{
    // Set Pagination After page changed
    this.setPagination(pageEvent);

    this.invoiceService
      .searchTramsInvoices(this.tramsInvoiceFilter)
      .subscribe((data: SearchTramsInvoiceResponse) => {
        if (data){
          this.pagination = data.pagination;
          this.tramsInvoiceFilter.pageNumber= data.pagination.pageNumber;
          this.dataSource = new MatTableDataSource<TramsInvoiceModel>(data.items);
        }
      });

  }

  public handleSortChange(sort: any): void {
    this.tramsInvoiceFilter.propertyName = this.getMappedTramsInvoicesTblPropertyName(sort.active);
    this.tramsInvoiceFilter.orderKey = sort.direction.toUpperCase();

    this.getTramsInvoices(new PageEvent());
  }

  getMappedTramsInvoicesTblPropertyName(propertyName: string): string {
    switch (propertyName) {
      case 'InvoiceId':
        return 'Id';
      case 'TramsInvoiceNo':
        return propertyName;
      case 'TramsCentralDocumentId':
        return propertyName;
      case 'TramsInvoiceNumber':
        return propertyName;
      case 'RecordLocator':
        return propertyName;
      case 'IssueDate':
        return propertyName;
      case 'DateCreated':
        return propertyName;
      case 'InvoiceTypeCode':
        return propertyName;
      case 'ClientName':
        return 'Client.ProfileName';
      case 'DataSourceId':
        return propertyName;
      default:
        return '';
    }
  }

  setPagination(pageEvent: PageEvent):void {
    this.tramsInvoiceFilter.pageSize = 25;
    const isStepForward = pageEvent.pageIndex > pageEvent.previousPageIndex!;
    
    if (isStepForward) {
      this.tramsInvoiceFilter.pageNumber += 1;
    } else {
      this.tramsInvoiceFilter.pageNumber = this.tramsInvoiceFilter.pageNumber !== undefined && this.tramsInvoiceFilter.pageNumber > 1 ? this.tramsInvoiceFilter.pageNumber-1 : 1;
    }
  }
}
