import { Component, Inject, Optional, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Agent } from 'src/app/agent/component/service/agent.model';

@Component({
  selector: 'agent-info-modal',
  templateUrl: './agent-info-modal.component.html',
  styleUrls: []
})
export class AgentInfoModalComponent implements OnInit {
  title: string;
  agentData: Agent;

  constructor(
    @Optional() public dialogRef: MatDialogRef<AgentInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, agentData: Agent }
  ) {
    this.title = data.title;
    this.agentData = data.agentData;
  }

  ngOnInit(): void {
    if (!this.agentData) {
      console.error('Agent data is missing');
    }
  }

  public closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public formatAddress(address: any): string {
    if (!address) {
      return '';
    }
    const addressLine1 = address.addressLine1 || '';
    const city = address.city || '';
    const zipCode = address.zipCode || '';
    return `${addressLine1}, ${city}, ${zipCode}`.trim();
  }
}
