import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

const headers = {
  'Content-Type': 'application/json'
}

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  private resourcePath: string = 'dashboard';

  constructor(private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public searchAhtWeeklyCalculation(httpParams: HttpParams): Observable<any> {
    return this.httpClient
      .get<any>(`${this.resourcePath}/aht-weekly-calculation/search`,
        {
          params: this.removeNullValuesFromQueryParams(httpParams),
          headers: headers
        })
      .pipe(catchError(this.errorHandler));
  }

  public searchUtilizationWeeklyCalculation(httpParams: HttpParams): Observable<any> {
    return this.httpClient
      .get<any>(`${this.resourcePath}/utilization-weekly-calculation/search`,
        {
          params: this.removeNullValuesFromQueryParams(httpParams),
          headers: headers
        })
      .pipe(catchError(this.errorHandler));
  }

  removeNullValuesFromQueryParams(params: HttpParams) {
    const paramsKeysAux = params.keys();
    paramsKeysAux.forEach((key) => {
      const value = params.get(key);
      if (value === null || value === undefined || value === '' || value === 'undefined' || value === 'null') {
        params['map'].delete(key);
      }
    });

    return params;
  }
}
