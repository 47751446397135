<form [formGroup]="vendorTCViewerForm" id="vendor-tc-viewer-form">
  <div class="container" id="vendor-tc-viewer-container">
    <div *ngIf="vendorTCViewerForm.invalid" id="vendor-tc-viewer-invalid-div">
      <mat-error id="vendor-tc-viewer-mat-error">Form is invalid</mat-error>
    </div>
    <div *ngIf="this._vendor.vendorId != undefined && this._vendor.vendorId != null" id="vendor-tc-viewer-vendor-id-div">
      <mat-label id="vendor-tc-viewer-vendor-number-label">Vendor number: </mat-label>
      <mat-form-field id="vendor-tc-viewer-vendor-id-form-field">
        <input matInput disabled value="{{this._vendor.vendorId}}" id="vendor-tc-viewer-vendor-id-input">
      </mat-form-field>
    </div>
    <div *ngIf="_vendorTermsAndConditions != undefined && _vendorTermsAndConditions != null" id="vendor-tc-viewer-terms-and-conditions-div">
      <mat-form-field id="vendor-tc-viewer-name-form-field">
        <mat-label id="vendor-tc-viewer-name-label">Name: </mat-label>
        <input matInput disabled value="{{_vendorTermsAndConditions.template.templateName}}" id="vendor-tc-viewer-name-input">
      </mat-form-field>
      <mat-form-field id="vendor-tc-viewer-description-form-field">
        <mat-label id="vendor-tc-viewer-description-label">Description: </mat-label>
        <input matInput disabled value="{{_vendorTermsAndConditions.template.templateDescription}}" id="vendor-tc-viewer-description-input">
      </mat-form-field>
      <mat-form-field id="vendor-tc-viewer-notes-form-field">
        <mat-label id="vendor-tc-viewer-notes-label">Vendor Template Notes: </mat-label>
        <input matInput disabled value="{{_vendorTermsAndConditions.vendorTemplateNotes}}" id="vendor-tc-viewer-notes-input">
      </mat-form-field>
    </div>
    <br>
    <div class="row" id="vendor-tc-viewer-row">
      <ng-container #container id="vendor-tc-viewer-container"></ng-container>
    </div>
  </div>
</form>
