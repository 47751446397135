<div id="fms-all-template-form" class="template-form">
  <div id="fms-all-template">
    <mat-button-toggle-group id="fms-all-vendor-actions" class="vendorActions" name="vendorActions" aria-label="Vendor Actions">
      <mat-button-toggle id="fms-all-get-vendor-button" (click)="GetVendor(1)" mat-raised-button color="primary" color="accent">Get Vendor</mat-button-toggle>
      <mat-button-toggle id="fms-all-add-vendor-button" (click)="AddVendor()" mat-raised-button color="primary" color="accent">Add Vendor</mat-button-toggle>
      <mat-button-toggle id="fms-all-edit-vendor-button" (click)="EditVendor(1)" mat-raised-button color="primary" color="accent">Edit Vendor</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <mat-button-toggle-group id="fms-all-vendor-template-actions" class="vendorTemplateActions" name="vendorTemplateActions" aria-label="Vendor Template Actions">
    <mat-button-toggle id="fms-all-addVendor-template-vp-button" (click)="AddVendorTemplate(1,6)" mat-raised-button color="primary" color="accent">Add Vendor Template (Vendor Payment)</mat-button-toggle>
    <mat-button-toggle id="fms-all-addVendor-template-cr-button" (click)="AddVendorTemplate(2,6)" mat-raised-button color="primary" color="accent">Add Vendor Template (Cancellation Refund)</mat-button-toggle>
  </mat-button-toggle-group>
</div>
