<form data-testid="adx-invoice-info-form" class="template-form adx-invoice-info-form">  
    <div data-testid="adx-invoice-info-main-div" class="filter__fields">
        <mat-card class="mat-elevation-z8">
            <mat-card-content>
                <mat-grid-list data-testid="adx-invoice-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile data-testid="adx-invoice-info-client-id-grid-tile">
                        <mat-form-field data-testid="adx-invoice-info-client-id-field">
                            <mat-label data-testid="adx-invoice-info-client-id-field-title">Client Id: </mat-label>
                            <input data-testid="adx-invoice-info-client-id-input-field" matInput value="{{_adxInvoice.client?.id}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-profile-name-grid-tile">
                        <mat-form-field data-testid="adx-invoice-info-profile-name-field">
                            <mat-label data-testid="adx-invoice-info-profile-name-field-title">Profile Name: </mat-label>
                            <input data-testid="adx-invoice-info-profile-name-input-field" matInput value="{{_adxInvoice.client?.profileName}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-record-locator-grid-tile">
                        <mat-form-field data-testid="adx-invoice-info-record-locator-field">
                            <mat-label data-testid="adx-invoice-info-record-locator-field-title">Invoice Record Locator: </mat-label>
                            <input data-testid="adx-invoice-info-record-locator-input-field" matInput value="{{_adxInvoice.recordLocator}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-invoice-type-code-grid-tile">
                        <mat-form-field data-testid="adx-invoice-info-invoice-type-code-field">
                            <mat-label data-testid="adx-invoice-info-invoice-type-code-field-title">Invoice Type: </mat-label>
                            <input data-testid="adx-invoice-info-invoice-type-code-input-field" matInput value="{{_adxInvoice.invoiceTypeCode}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-invoice-no-grid-tile">
                        <mat-form-field data-testid="adx-invoice-info-invoice-no-field">
                            <mat-label data-testid="adx-invoice-info-invoice-no-field-title">Invoice Number: </mat-label>
                            <input data-testid="adx-invoice-info-invoice-no-input-field" matInput value="{{_adxInvoice.tramsInvoiceNumber}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-issue-date-grid-tile">
                        <mat-form-field data-testid="adx-invoice-info-issue-date-field">
                            <mat-label data-testid="adx-invoice-info-issue-date-field-title">Issue Date: </mat-label>
                            <input data-testid="adx-invoice-info-issue-date-input-field" matInput [value]="formatDate(_adxInvoice.issueDate)">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-branch-id-grid-tile">
                        <mat-form-field data-testid="adx-invoice-info-branch-id-field">
                            <mat-label data-testid="adx-invoice-info-branch-id-field-title">Branch Id: </mat-label>
                            <input data-testid="adx-invoice-info-branch-id-input-field" matInput value="{{_adxInvoice.branch?.id}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-branch-name-grid-tile">
                        <mat-form-field data-testid="adx-invoice-info-branch-name-field">
                            <mat-label data-testid="adx-invoice-info-branch-name-field-title">Branch: </mat-label>
                            <input data-testid="adx-invoice-info-branch-name-input-field" matInput value="{{_adxInvoice.branch?.name}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-data-source-id-grid-tile">
                        <mat-form-field data-testid="adx-invoice-info-data-source-id-field">
                            <mat-label data-testid="adx-invoice-info-data-source-id-field-title">Data Source Id: </mat-label>
                            <input data-testid="adx-invoice-info-data-source-id-input-field" matInput value="{{_adxInvoice.tramsDataSource?.id}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-data-source-name-grid-tile">
                        <mat-form-field data-testid="adx-invoice-info-data-source-name-field">
                            <mat-label data-testid="adx-invoice-info-data-source-name-field-title">Data Source Name: </mat-label>
                            <input data-testid="adx-invoice-info-data-source-name-input-field" matInput value="{{_adxInvoice.tramsDataSource?.sourceName}}">
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-card-content>
        </mat-card>
    </div>
</form>