import {Component, Input} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {TripService} from '../../service/trip.service';
import {Trip} from '../../model/trip';

@Component({
  selector: 'app-trip-info',
  templateUrl: './trip-info.component.html'
})

@AutoUnsubscribe()
export class TripInfoComponent {
  @Input() public data: any;
  _trip: any;

  constructor(
    private buildr: FormBuilder,
    private tripService: TripService) {
  }

  ngOnInit() {
    this.getTripInformation();
  }

  getTripInformation() {
    if (this.data != null) {
      this.tripService
        .getTripById(this.data.tripId)
        .subscribe((data: Trip) => {
          if (data) {
            this._trip = data;
          }
        });
    }
  }

  tripInfoForm = this.buildr.group({
    tripName: this.buildr.control(''),
    tripDescription: this.buildr.control(''),
    templateNotes: this.buildr.control(''),
  })
}

