<div *ngIf="IsNotLoading()">
  <form [formGroup]="navUploadForm" id="file-upload-nav-upload-form" class="template-form" *ngIf="!loading">
    <div id="file-upload-row" layout="row">
      <mat-form-field id="file-upload-form-field" [hideRequiredMarker]="true">
        <mat-label id="file-upload-label">Trams Branch Group</mat-label>
        <mat-select id="file-upload-select" matInput name="dataSource" [formControl]="dataSourceFormControl" (selectionChange)="onDataSourceSelected()" [errorStateMatcher]="dataSourceErrorStateMatcher" [(ngModel)]="loadNavReportModel.NavBranchId" required>
          <mat-option id="file-upload-option-{{i}}" *ngFor="let item of datasources; let i = index" [value]="item.id">
            {{ item.branchName }}
          </mat-option>
        </mat-select>
        <mat-error id="file-upload-error" *ngIf="(IsSubmit || IsMappingDownload || IsReportDownload) && dataSourceFormControl.hasError('min')">Data Source is required</mat-error>
      </mat-form-field>
    </div>
    <div id="file-upload-row" layout="row">
      <div id="file-upload-center" class="center"><h3>Upload files for Nav report</h3></div>
      <br />
      <div flex="">
        <div flex="">
          <label id="file-upload-file1-label" for="file1Input">Previous period</label>
        </div>
        <input id="file-upload-file1-input" class="form-control" type="file" (change)="File1Change($event)" [formControl]="file1FormControl" required>
        <mat-error id="file-upload-file1-error" *ngIf="IsSubmit && file1FormControl.hasError('required')">File is required</mat-error>
      </div>
      <br />
      <div flex="">
        <div flex="">
          <label id="file-upload-file2-label" for="file2Input">Current period</label>
        </div>
        <input id="file-upload-file2-input" class="form-control" type="file" (change)="File2Change($event)" [formControl]="file2FormControl" required>
        <mat-error id="file-upload-file2-Error" *ngIf="IsSubmit && file2FormControl.hasError('required')">File is required</mat-error>
      </div>
      <br />
      <div flex="">
        <button id="file-upload-upload-button" mat-flat-button color="primary" (click)="onUpload()" class="btn btn-success"> Upload </button>
      </div>
    </div>
    <br />
    <div flex="">
      <button id="file-upload-downloadButton" mat-flat-button color="primary" (click)="onReportDownload()" class="btn btn-success"> Download current report </button>
      <mat-error id="file-upload-downloadError" *ngIf="IsReportDownload && !dataSourceFormControl.hasError('min') && fileUploadResult.uploadGuid.length < 36">No data found</mat-error>
    </div>
    <br />
    <hr />
    <br />
    <div id="file-upload-row" layout="row">
      <div id="file-upload-center" class="center"><h3>Update mappings</h3></div>
      <div id="file-upload-flex1" flex="">
        <label id="file-upload-label" for="file1Input">Nav Mapping by selected Trams Branch Group</label>
      </div>
      <br />
      <div flex="">
        <button id="file-upload-download-button" mat-flat-button color="primary" (click)="onMappingDownload()" class="btn btn-success"> Download current mapping </button>
        <mat-error id="file-upload-error" *ngIf="IsMappingDownload && IsEmptyMappingData">No data found</mat-error>
      </div>
      <br />
      <div flex="">
        <input id="file-upload-file-mapping-input" class="form-control" type="file" (change)="FileMappingChange($event)" [formControl]="fileMappingFormControl" required>
        <mat-error id="file-upload-file-error" *ngIf="IsMappingSubmit && fileMappingFormControl.hasError('required')">File is required</mat-error>
      </div>
      <br />
      <div flex="">
        <button id="file-upload-upload-button" mat-flat-button color="primary" (click)="onMappingUpload()" class="btn btn-success"> Upload </button>
      </div>
    </div>
  </form>
</div>
