export class Rule {
  id: number;
  templateId: number;
  ruleAmt?: number;
  isAmt: boolean;
  isPrc: boolean;
  termsConditionsRuleTypeId: number;
  termsConditionsRuleTypeNumber: number;
  ruleConditionId: number

  constructor(obj: any) {
    this.id = obj.id;
    this.templateId = obj.id;
    this.ruleAmt = obj.ruleAmt;
    this.isAmt = obj.isAmt;
    this.isPrc = obj.isPrc;
    this.termsConditionsRuleTypeId = obj.termsConditionsRuleTypeId;
    this.termsConditionsRuleTypeNumber = obj.termsConditionsRuleTypeNumber;
    this.ruleConditionId = obj.ruleConditionId;
  }
}
