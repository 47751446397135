import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {DatePipe} from '@angular/common';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {PaymentService} from '../../service/payment-detail.service';
import {PaymentDetail} from '../../models/payment-detail';

@Component({
  selector: 'app-payment-tab-detail',
  templateUrl: './payment-tab-detail.component.html'
})
export class PaymentTabDetailComponent {
  @Input() public data: any;
  public payments :  any = [];
  public selectedTab: string = "";
  loading$: any;

  constructor(
    private paymentService: PaymentService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public loader: LoadingService) {
  }

  ngOnInit(){
    this.loading$ = this.loader.loading$;
    if (this.data != undefined || this.data.invoiceId != null) {
      this.getPaymentByInvoiceId();
    }
  }

  getPaymentByInvoiceId() {
    this.paymentService.getPaymentByInvoiceId(this.data.invoiceId)
      .subscribe((data: PaymentDetail[]) => {
          this.payments = data.map(details => details.payment);
      });
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM/dd/yyyy') || '';
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTab = event.tab.textLabel;
  }
}