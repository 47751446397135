<h2 mat-dialog-title>Booking {{bookingNo}} UDID's</h2>
<hr>
<mat-dialog-content style="margin-left:5%;">
    <ul>
        <mat-list>
            @for (udid of bookingUdids; track udid) {
                <li style="list-style-type: initial;">
                    <mat-list-item>{{udid.udidNo}}: {{udid.udidValue}}</mat-list-item>
                </li>
            }
        </mat-list>
    </ul>
</mat-dialog-content>
<hr>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>