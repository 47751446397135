import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FilterResult} from 'src/app/shared/modals/filter-modal/filter-modal.component';
import {AdxInvoiceLinePayload} from '../../model/adx-invoice-line-payload';
import {MatSort} from '@angular/material/sort';
import {Pagination} from 'src/infrastructure/pagination/model/pagination';
import {AdxInvoiceLineItemFilter} from '../../model/adx-invoice-line-item-filter';
import {SearchAdxInvoiceLineItemResponse} from '../../model/search-adx-invoice-line-item-response';
import {InvoiceLineItemService} from '../../service/invoice-line-item.service';
import * as moment from 'moment';
import {AdxInvoiceLineItemService} from '../../service/adx-invoice-line-item.service';

@Component({
  selector: 'app-adx-invoice-line-items',
  templateUrl: './adx-invoice-line-items.component.html'
})
export class AdxInvoiceLineItemsComponent implements OnInit {
  @Input() filter!: FilterResult;
  @ViewChild(MatPaginator) 
  paginator!: MatPaginator;
  public pagination: Pagination = {} as Pagination;
  public adxInvoiceLineItemFilter: AdxInvoiceLineItemFilter = {} as AdxInvoiceLineItemFilter;

  @ViewChild(MatSort, {static: false})
  public sort!: MatSort;

  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  public hasRecords: boolean = true;
  dataSource!: MatTableDataSource<AdxInvoiceLinePayload>;
  displayedColumns = ['fmsAdxInvoiceLineItemId', 'clientProfileNo', 'clientName', 'quoteID', 'invoiceRecordLocator','invoiceType', 'issueDate', 'dateCreated'];

  constructor(
    private invoiceLineItemService: InvoiceLineItemService,
    private adxInvoiceLineItemService: AdxInvoiceLineItemService
  ) {
  }

  ngOnInit(): void {
    this.adxInvoiceLineItemFilter.propertyName = 'FmsAdxInvoiceLineItemId';
    this.adxInvoiceLineItemFilter.orderKey = 'ASC';
    this.invoiceLineItemService.getAdxInvoiceLineItemsFilter().subscribe((filter) => {
      this.hasRecords = true;
      if (this.paginator !== undefined) {
        this.paginator.pageIndex = 0;
        this.adxInvoiceLineItemFilter.pageNumber = 1;
      }
      this.adxInvoiceLineItemFilter.id = filter.id;
      this.adxInvoiceLineItemFilter.quoteId = filter.quoteId;
      this.adxInvoiceLineItemFilter.invoiceRecordLocator = filter.invoiceRecordLocator;
      this.adxInvoiceLineItemFilter.invoiceType = filter.invoiceType;
      this.adxInvoiceLineItemFilter.clientProfileNo = filter.clientProfileNo;
      this.adxInvoiceLineItemFilter.userCreated = filter.userCreated;
      this.adxInvoiceLineItemFilter.issueStartDate = filter.issueStartDate ? moment(filter.issueStartDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.adxInvoiceLineItemFilter.issueEndDate = filter.issueEndDate ? moment(filter.issueEndDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.adxInvoiceLineItemFilter.createdStartDate = filter.createdStartDate ? moment(filter.createdStartDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.adxInvoiceLineItemFilter.createdEndDate = filter.createdEndDate ? moment(filter.createdEndDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.getAdxInvoiceLineItems(new PageEvent());
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public clearFilters(propertyName: string): void {
    let temp: any = this.adxInvoiceLineItemFilter;
    temp[propertyName] = undefined;
    this.adxInvoiceLineItemFilter = temp;

    if (propertyName === 'all') {
      this.adxInvoiceLineItemFilter = {} as AdxInvoiceLineItemFilter;
    }

    this.getAdxInvoiceLineItems(new PageEvent());
  }

    getAdxInvoiceLineItems(pageEvent: PageEvent): void {
      this.setPaginationAfterPageChanged(pageEvent);
    this.adxInvoiceLineItemService
      .searchAdxInvoiceLineItems(this.adxInvoiceLineItemFilter)
      .subscribe((data: SearchAdxInvoiceLineItemResponse) => {
        if (data) {
          this.pagination = data.pagination;
          this.adxInvoiceLineItemFilter.pageNumber = data.pagination.pageNumber;
          this.dataSource = new MatTableDataSource<AdxInvoiceLinePayload>(data.items);
          this.hasRecords = data.items.length > 0;
        }
      });
  }

  public handleSortChange(sort: any): void {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.adxInvoiceLineItemFilter.propertyName = sort.active;
    this.adxInvoiceLineItemFilter.orderKey = sort.direction.toUpperCase();

    this.getAdxInvoiceLineItems(new PageEvent());
  }

  public setPaginationAfterPageChanged(pageEvent: PageEvent): void {
    this.adxInvoiceLineItemFilter.pageSize = 25;
    const isStepForward = pageEvent.pageIndex > pageEvent.previousPageIndex!;

    if (isStepForward) {
      this.adxInvoiceLineItemFilter.pageNumber += 1;
    } else {
      this.adxInvoiceLineItemFilter.pageNumber = this.adxInvoiceLineItemFilter.pageNumber !== undefined && this.adxInvoiceLineItemFilter.pageNumber > 1 ? this.adxInvoiceLineItemFilter.pageNumber-1 : 1;
    }
  }
}
