<form [formGroup]="tripInfoForm" class="template-form" *ngIf="_trip != undefined && _trip != null">
  <div data-testid="trip-info-modal-div" class="filter__fields">
    <mat-card class="mat-elevation-z8">
      <mat-card-content>
        <mat-grid-list data-testid="trip-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="85">
          <mat-grid-tile data-testid="trip-name-grid-tile">
            <mat-form-field data-testid="trip-name-field">
              <mat-label data-testid="trip-name-field-title">Trip name: </mat-label>
              <input readonly data-testid="trip-name-input-field" matInput value="{{_trip.tripName}}">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile data-testid="trip-description-grid-tile">
            <mat-form-field data-testid="trip-description-field">
              <mat-label data-testid="trip-description-field-title">Trip description: </mat-label>
              <input readonly data-testid="trip-description-input-field" matInput value="{{_trip.tripDescription}}">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile data-testid="trip-external-number-grid-tile">
            <mat-form-field data-testid="trip-external-number-field">
              <mat-label data-testid="trip-external-number-field-title">External number: </mat-label>
              <input readonly data-testid="trip-external-number-input-field" matInput value="{{_trip.externalNumber}}">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile data-testid="trip-external-reference-grid-tile">
            <mat-form-field data-testid="trip-external-reference-field">
              <mat-label data-testid="trip-external-reference-field-title">External reference: </mat-label>
              <input readonly data-testid="trip-external-reference-input-field" matInput value="{{_trip.externalReference}}">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field data-testid="trip-start-date-field">
              <mat-label data-testid="trip-start-date-field-title">Trip start date: </mat-label>
              <input readonly data-testid="trip-start-date-input-field" matInput value="{{_trip.tripStartDate  | date: 'MM/dd/yyyy'}}">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field data-testid="trip-end-date-fiel">
              <mat-label data-testid="trip-end-date-field-title">Trip end date: </mat-label>
              <input readonly data-testid="trip-end-date-input-field" matInput value="{{_trip.tripEndDate  | date: 'MM/dd/yyyy'}}">
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card> 
  </div>
</form>