import {Component, Input, OnInit} from '@angular/core';
import {AhtType} from '../../components/aht-weekly-calculation/aht-weekly-calculation.component';
import {FilterService} from 'src/app/shared/service/filter/filter.service';
import {BreakpointService, Breakpoints} from 'src/app/shared/service/breakpoint/breakpoint.service';
import {map} from 'rxjs';
import {DatePipe} from '@angular/common';
import {HttpParams} from '@angular/common/http';
import {WidgetInfoService} from 'src/app/shared/service/widget-info/widget-info.service';
import {DateService} from 'src/app/shared/service/date/date.service';
import {FilterResult} from 'src/app/shared/modals/filter-modal/filter-modal.component';
import {ApplicationConstants} from 'src/app/shared/constants/application.constants';
import {AzureAuthService} from '../../../../infrastructure/services/azure-auth.service';
import { WidgetInfoItem } from '../../model/widget-info-item';

@Component({
  selector: 'transactions',
  templateUrl: './transactions.component.html',
  providers: [DatePipe]
})
export class TransactionsComponent implements OnInit {
  AhtTypeEnum = AhtType;
  ahtChartType: AhtType = AhtType.Minutes;
  @Input() filter!: FilterResult;
  private appConstants: ApplicationConstants = new ApplicationConstants;
  filterEmail: string = this.appConstants.AllUser;
  private readonly allUsers: string = this.appConstants.AllUser;
  private readonly assignedTickets: string = 'Assigned Tickets';
  private readonly totalUsers: string = 'Total users';
  private readonly closedTickets: string = 'Closed Tickets';
  private readonly closed: string = 'Closed';
  infoData: WidgetInfoItem[] = [
    {
      'text': '',
      'title': '',
      'overLine': '',
      'icon': 'confirmation_number',
      color: 'indigo'
    },
    {
      'text': '',
      'title': '',
      'overLine': '',
      'icon': 'confirmation_number',
      color: 'danger'
    },
    {
      'text': '',
      'title': '',
      'overLine': '',
      'icon': 'thumb_up',
      color: 'info'
    },
    {
      'text': '',
      'title': '',
      'overLine': '',
      'icon': 'shopping_cart',
      color: 'success'
    }
  ]
  fromDate: Date | null = null;
  toDate: Date | null = null;
  appliedFilterUserFullName: string = '';

  constructor(
    private filters: FilterService,
    private bp: BreakpointService,
    private datePipe: DatePipe,
    private widgetInfoService: WidgetInfoService,
    private date: DateService,
    private azureAuthService: AzureAuthService) {
  }

  ngOnInit() {
    const isDirector = this.azureAuthService.checkUserAzureTokenHasDirectorRole();
    if (!isDirector) {
      this.azureAuthService.loggedInUserEmail.subscribe(x => {
        this.filterEmail = x;
      });
    }
    if (this.fromDate === null || this.toDate === null) {
      this.fromDate = this.date.subtractDays(new Date(), 30);
      this.toDate = new Date();
    }

    this.getVolume(
      this.fromDate.toDateString(),
      this.toDate.toDateString(),
      this.filterEmail
    );

    this.filters.getFilter().subscribe((filter) => {
      if (filter.from && filter.to) {
        this.fromDate = filter.from;
        this.toDate = filter.to;
        this.appliedFilterUserFullName = filter.name ? filter.name : '';
        this.getVolume(
          this.fromDate.toDateString(),
          this.toDate.toDateString(),
          filter.email
            ? filter.email.toString()
            : this.filterEmail
        );
      }
    });
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'EEE MMM dd yyyy');
  }

  get filters$() {
    return this.filters.getFilter();
  }

  get isMobile$() {
    return this.bp.isActive([Breakpoints.SM])
  }

  get dateFilterText$() {
    return this.filters.getFilter()
      .pipe(map(f => f.from && f.to ? ` (${this.formatDate(f.from)} - ${this.formatDate(f.to)})` : ''))
  }

  getVolume(fromDate: string, toDate: string, email: string) {
    const paramsObject: { dateFrom: string; dateTo: string; email?: string } = {
      dateFrom: fromDate,
      dateTo: toDate,
    };

    if (email !== this.allUsers) {
      paramsObject['email'] = email;
    }

    const httpParams = new HttpParams({fromObject: paramsObject});

    let totalTickets: string;

    this.widgetInfoService.getRequestVolume(httpParams).subscribe((data: any) => {
      const volume = data || [];

      const tickets = volume.reduce((acc: number, curr: { tickets: number }) => acc + curr.tickets, 0);
      totalTickets = tickets.toString();
      const result = this.countUniqueUsersWithEmail(volume, email);

      if (result.name === '') {
        if (!this.azureAuthService.checkUserAzureTokenHasDirectorRole()) {
          this.azureAuthService.loggedInUserName.subscribe(x => {
            result.name = x;
          });
        } else {
          result.name = this.appliedFilterUserFullName;
        }
      }
      const overLine = email === this.allUsers
        ? `${this.totalUsers}: ${result.usersCount}`
        : result.name;
      this.updateInfoData(0, totalTickets, this.assignedTickets, overLine);

      this.getClosedTickets(httpParams, totalTickets);
    });
  }

  getClosedTickets(httpParams: HttpParams, totalTickets: string | undefined) {
    this.widgetInfoService.getRequestClosedVolume(httpParams).subscribe((data: any) => {
      const volume = data || [];
      const totalClosedTickets = volume.reduce((acc: number, curr: { tickets: number }) => acc + curr.tickets, 0);
      const percentageClosed = (totalClosedTickets / (parseInt(totalTickets ?? '0', 10) || 1)) * 100;
      const overLine = `${this.closed} ${percentageClosed.toFixed(2)}%`;
      this.updateInfoData(1, totalClosedTickets || '0', this.closedTickets, overLine);
    });
  }

  countUniqueUsersWithEmail(volume: Array<{ email: string; name: string }>, email: string) {
    const uniqueEmails = new Set<string>();
    let name = '';

    const usersCount = volume.reduce((count: number, entry: { email: string; name: string }) => {
      if (!uniqueEmails.has(entry.email)) {
        uniqueEmails.add(entry.email);
        if (email === entry.email) {
          name = entry.name;
        }
        return count + 1;
      }
      return count;
    }, 0);

    return {usersCount, name};
  }

  updateInfoData(index: number, text: string, title: string, overLine: string) {
    this.infoData[index] = {
      ...this.infoData[index],
      text: text,
      title: title,
      overLine: overLine,
    };
  }
}
