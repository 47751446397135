import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {VccDailySummaryReportFilterResult} from '../../model/vcc-daily-summary-report-filter-result';
import {VccDailySummaryReportFilterFields} from '../../model/vcc-daily-summary-report-filter-fields';
import {AzureAppConfigurationService} from 'src/infrastructure/services/azure-app-configuration.service';

interface Merchant {
  name: string;
  id: string;
}

@AutoUnsubscribe()
@Component({
  selector: 'vcc-daily-summary-reports-filter-modal',
  templateUrl: './vcc-daily-summary-reports-filter-modal.component.html'
})
export class VccDailySummaryReportFilterModalComponent implements OnInit {
  merchantGuids: Merchant[] = [];
  selectedMerchantGuid: string | undefined = undefined;
  @Input() config!: VccDailySummaryReportFilterResult;

  constructor(
    @Optional() public dialogRef: MatDialogRef<VccDailySummaryReportFilterModalComponent>,
    private azureAppConfigService: AzureAppConfigurationService
  ) { }

  form: FormGroup = new FormGroup({
    releasedDate: new FormControl(null),
    merchantGuid: new FormControl(null)
  });

  handleClose() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  async ngOnInit() {
    const result = await this.azureAppConfigService.getByKey('MerchantGuid');
    const merchantGuidsString = result?.value;
    if (merchantGuidsString) {
      const parsedMerchantGuids = JSON.parse(merchantGuidsString);
      this.merchantGuids = parsedMerchantGuids.merchantGuid;

      const { releasedDate, merchantGuid } = this.config ?? {};
      this.selectedMerchantGuid = merchantGuid ?? this.merchantGuids[0]?.id;

      if (releasedDate === undefined) {
        let previousDate = new Date();
        previousDate.setDate(previousDate.getDate() - 1);
        this.config.releasedDate = previousDate.toJSON();
      }

      this.form.patchValue({
        releasedDate: this.config.releasedDate,
        merchantGuid: this.selectedMerchantGuid
      });
    }
  }

  applyVccDailySummaryReportFilters() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }
    else if (this.dialogRef) {
      this.dialogRef.close(this.config);
    }
  }

  public handleVccDailySummaryReportFilterChange(event: any, fieldName: string): void {
    if (!event) {
      return;
    }
    switch (fieldName) {
      case (VccDailySummaryReportFilterFields.MerchantGuid): {
        let selectedMerchant: Merchant | undefined;
        const filteredMerchants = this.merchantGuids.filter((merchant) => merchant.id === event);
        selectedMerchant = filteredMerchants?.length > 0 ? filteredMerchants[0] : undefined;
        if (selectedMerchant) {
          this.selectedMerchantGuid = selectedMerchant.id;
          this.config.merchantGuid = selectedMerchant.id;
          this.form.controls['merchantGuid'].setValue(selectedMerchant.name);
        }
        break;
      }
      case (VccDailySummaryReportFilterFields.ReleasedDate):
        this.config.releasedDate = event.value.toJSON();
        break;
    }
  }

  getMerchantName() {
    this.selectedMerchantGuid = this.form.controls['merchantGuid'].value;
    const selectedMerchant = this.merchantGuids.find(merchant => merchant.id === this.selectedMerchantGuid);
    return selectedMerchant ? selectedMerchant.name : this.merchantGuids[0].name;
  }
}
