import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  VendorTemplateAddEditModalComponent
} from '../../vendor-template/component/addedit/vendor-template-addedit-modal/vendor-template-addedit-modal.component';
import {
  AddEditVendorModalComponent
} from '../../vendor/component/addedit/addedit-vendor-modal/addedit-vendor-modal.component';
import {
  VendorViewerModalComponent
} from '../../vendor/component/viewer/vendor-viewer-modal/vendor-viewer-modal.component';

@Component({
  selector: 'app-fms-all',
  templateUrl: './fms-all.component.html'
})
export class FmsAllComponent {
  constructor(public dialog: MatDialog) {
  }

  GetVendor(vendorId: number) {
    let data = {
      title: 'Vendor Information',
      vendorId: vendorId
    };
    const dialogRef = this.dialog.open(VendorViewerModalComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  EditVendor(vendorId: number) {
    let data = {
      title: 'Edit Vendor',
      vendorId: vendorId
    };
    const dialogRef = this.dialog.open(AddEditVendorModalComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  AddVendor() {
    let data = {
      title: 'Add Vendor',
      vendorId: -1
    };
    const dialogRef = this.dialog.open(AddEditVendorModalComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  AddVendorTemplate(termsConditionsTypeId: number, vendorId: number) {
    let data = {
      vendorId: vendorId,
      termsConditionsTypeId: termsConditionsTypeId
    };
    const dialogRef = this.dialog.open(VendorTemplateAddEditModalComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((_: any) => {

    });
  }
}
