<div class="grid-title-event">
  <h2 data-testid="client-list-title">Clients</h2>

  <div class="events-container">
    <ng-container *ngIf="dataSource.filteredData.length">
      <button data-testid="client-list-refresh-button" aria-label="Refresh grid" mat-icon-button (click)="replayGrid()">
        <mat-icon fontIcon="replay"></mat-icon>
      </button>
      <button color="primary" data-testid="download-client-list-button" aria-label="Download Client List" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
        <mat-icon>download</mat-icon>
      </button>
    </ng-container>
  </div>
</div>

<div class="clients-container mat-elevation-z8" *ngIf="!this.isLoading" id="client-list-clients-container">
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="handleSortChange($event)" id="client-list-mat-table">
    <ng-container matColumnDef="firstName">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>First Name</mat-header-cell>
      <mat-cell *matCellDef="let client">{{ client.firstName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="middleName">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Middle Name</mat-header-cell>
      <mat-cell *matCellDef="let client">{{ client.middleName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
      <mat-cell *matCellDef="let client">{{ client.lastName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="primaryAgentName">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Primary Agent Name</mat-header-cell>
      <mat-cell *matCellDef="let client">{{ client.primaryAgentName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="dataSourceName">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Data Source Name</mat-header-cell>
      <mat-cell *matCellDef="let client">{{ client.dataSourceName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="tramsProfileNumber">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Trams Profile Number</mat-header-cell>
      <mat-cell *matCellDef="let client">{{ client.tramsProfileNumber }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator [length]="pagination.totalItemCount"
                 [hidePageSize]="true"
                 [pageSize]="pagination.pageSize"
                 [pageIndex]="pagination.pageIndex"
                 (page)="searchClients($event)"
                 id="client-list-mat-paginator">
  </mat-paginator>
</div>
