import {APP_INITIALIZER, Provider} from '@angular/core';
import {HealthCheckService} from '../service/health-check.service';

export const HEALTH_CHECK_PROVIDER = (): Provider => {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (healthCheckService: HealthCheckService) => {
      return () => {
        healthCheckService.init();
      }
    },
    deps: [HealthCheckService]
  };
}
