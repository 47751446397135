import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {TermsConditionsTemplate} from 'src/app/terms-conditions/model/terms-conditions-template';
import {CreateVendorTemplate} from 'src/app/vendor-template/model/create-vendor-template';
import {VendorTemplateService} from 'src/app/vendor-template/service/vendor-template.service';
import {
  ConfirmationDialogVendorTemplateComponent
} from 'src/app/vendor-template/component/popup/confirmation-dialog/confirmation-dialog.component';
import {TermsConditionsService} from '../../../../terms-conditions/service/terms-conditions.service';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {AutoUnsubscribe} from '../../../../../infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-vendor-template-addedit',
  templateUrl: './vendor-template-addedit.component.html'
})
export class VendorTemplateAddEditComponent implements OnInit {
  @Input() public data: any;
  vendorTemplateErrorStateMatcher!: ErrorStateMatcher;
  termConditionsTemplateValidator!: FormControl;
  notesValidator!: FormControl;
  public termsConditionsTemplates: TermsConditionsTemplate[] = [];
  isPageLoadingSpinnerVisible: boolean = false;
  isInputDisabled: boolean = false;
  loading$: any;
  title: string = '';
  vendorTemplateSelected: number = 0;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();
  existingSavedVendorTemplateId: number = 0;

  public _createVendorTemplate: CreateVendorTemplate = {
    id: 0,
    vendorId: 0,
    templateId: 0,
    termsConditionsTypeId: 0,
    vendorTemplateNotes: '',
    termsConditionsType: '',
    template: '',
    description: '',
    vendorTemplateExists: false,
    vendorTemplateNoteExists: false
  };

  constructor(
    private vendorTemplateService: VendorTemplateService,
    private termsConditionsService: TermsConditionsService,
    public dialog: MatDialog,
    private buildr: FormBuilder,
    public loader: LoadingService
  ) {

  }

  ngOnInit(): void {
    this.loading$ = this.loader.loading$;
    this.setFormControls();
    this.setData();
  }

  setFormControls() {
    this.termConditionsTemplateValidator = new FormControl(
      {value: '', disabled: this.isInputDisabled}, [
        Validators.required
      ]);
    this.notesValidator = new FormControl(
      {value: '', disabled: this.isInputDisabled}, [
        Validators.maxLength(1000),
      ]);
    this.vendorTemplateErrorStateMatcher = new VendorTemplateErrorStateMatcher();
  }

  setData() {
    if (this.data != undefined) {
      if (this.data.termsConditionsTypeId == 1) {
        this.getPaymentTermsTemplate();
        this._createVendorTemplate.termsConditionsType = 'Vendor Payment';
      } else if (this.data.termsConditionsTypeId == 2) {
        this.getCancellationTermsTemplate();
        this._createVendorTemplate.termsConditionsType = 'Cancellation Refund';
      }
      if (this.data.title != undefined) {
        this.title = this.data.title;
      }
      this._createVendorTemplate.termsConditionsTypeId = this.data.termsConditionsTypeId;
      this._createVendorTemplate.vendorId = this.data.vendorId;

      this.vendorTemplateService.getVendorTemplateByTCType(this.data.termsConditionsTypeId, this.data.vendorId)
        .subscribe({
          next: (item) => {
            const vendorName = this.data.vendorName;
            if (item) {
              this._createVendorTemplate.templateId = parseInt(item.template.id);
              this._createVendorTemplate.id = parseInt(item.id);
              this.vendorTemplateSelected = parseInt(item.template.id);
              this._createVendorTemplate.vendorTemplateNotes = item.vendorTemplateNotes;
              this._createVendorTemplate.template = item.template.templateName;
              this._createVendorTemplate.description = item.template.templateDescription;
              this._createVendorTemplate.vendorTemplateExists = true;
              this._createVendorTemplate.vendorTemplateNoteExists = !!item.vendorTemplateNotes;
              this.title = 'Edit terms and conditions for vendor \'' + vendorName + '\'';
              this.existingSavedVendorTemplateId = parseInt(item.template.id);
            }else{
              this.title = 'Add terms and conditions for vendor \'' + vendorName + "'";
            }
          }
        });
    }
  }

  openVendorConfirmationDialog(): void {
    if (this.termConditionsTemplateValidator.value !== '') {
      //If there is a template change since 'vendorTemplateNoteExists' boolean used in 'ConfirmationDialogVendorTemplateComponent' to identify update or 
      //(delete & create) operation based upon empty notes, therefore making sure to trigger delete and create section instead of update notes when template changed.
      if(this.existingSavedVendorTemplateId != this._createVendorTemplate.templateId){
        this.existingSavedVendorTemplateId = this._createVendorTemplate.templateId;
        this._createVendorTemplate.vendorTemplateNoteExists = true;
      }
      this.dialog.open(ConfirmationDialogVendorTemplateComponent, {
        data: {
          vendorTemplate: this._createVendorTemplate
        },
      });
    }
  }

  onSelectTermConditionTemplate(termConditionTemplateId: number) {
    let termConditionTemplate = this.termsConditionsTemplates.find(x => x.id == termConditionTemplateId);
    if (termConditionTemplate !== undefined) {
      this._createVendorTemplate.description = termConditionTemplate.templateDescription;
      this._createVendorTemplate.templateId = termConditionTemplateId;
    }
  }

  onNotesChange(event: Event): void {
    this._createVendorTemplate.vendorTemplateNotes = (event.target as any).value;
  }

  vendorTemplateForm = this.buildr.group({
    TermsConditionsType: this.buildr.control(''),
    Template: this.buildr.control(''),
    Description: this.buildr.control(''),
    Notes: this.buildr.control('')
  });

  public getPaymentTermsTemplate() {
    this.termsConditionsService.GetPaymentTermsTemplate()
      .subscribe((data: any) => {
        this.termsConditionsTemplates = data as TermsConditionsTemplate[];
      });
  }

  public getCancellationTermsTemplate() {
    this.termsConditionsService.GetCancellationTermsTemplate()
      .subscribe((data: any) => {
        this.termsConditionsTemplates = data as TermsConditionsTemplate[];
      });
  }
}

export class VendorTemplateErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form?.submitted;
    return !!(control?.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
