import {ErrorHandler, Injectable} from '@angular/core';
import {AppInsightsService} from './app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor(private appInsightsService: AppInsightsService) {
    super();
  }

  override handleError(error: Error): void {
    this.appInsightsService.logException(error);
  }
}
