<h2 mat-dialog-title>Assigned Agents for {{bookingNo}}:</h2>
<hr>
<mat-dialog-content style="margin-left:5%;">
    <ul>
        <mat-list>
            @for (agent of agents; track agent) {
                <li style="list-style-type: initial;">
                    <mat-list-item>{{agent.agentId}}{{agent.agentName != null ? ": "+ agent.agentName : ""}}</mat-list-item>
                </li>
            }
        </mat-list>
    </ul>
</mat-dialog-content>
<hr>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>