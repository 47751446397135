import {Component, Input, OnInit} from '@angular/core';
import {AutoUnsubscribe} from '../../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {AzureAuthService} from '../../../../../infrastructure/services/azure-auth.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-vendor-template.layout',
  templateUrl: './vendor-template.layout.component.html'
})
export class VendorTemplateLayoutComponent implements OnInit {
  @Input() public data: any;
  templateData: any;
  flag: number = 1;
  update: number = 0;
  isUserLoggedIn: boolean = false;

  constructor(private azureAuthService: AzureAuthService) {
  }

  ngOnInit(): void {
    this.azureAuthService.isUserLoggedIn.subscribe(x => {
      this.isUserLoggedIn = x;
    })
  }

  getSelectedTemplateInfo(data: any) {
    this.templateData = data;
  }

  Refresh() {
    this.flag = Math.floor(Math.random() * (1 - 100 + 1)) + 1;
    this.update = 1;
  }
}
