import {Component, Input, OnInit, Optional} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {AutoUnsubscribe} from "src/infrastructure/decorators/auto-unsubscribe.decorator";
import {Subscription} from "rxjs";
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {HsProcessingFilter} from '../../models/hs-process.component';
import {HsProcessingService} from '../../services/hs-processing.service';

@AutoUnsubscribe()
@Component({
    selector: 'hs-processing-filter',
    templateUrl: './hs-processing-filter.component.html',
})
export class HsProcessingFilterComponent implements OnInit {
    @Input() config!:HsProcessingFilter;

    hsProcessingFilterForm!:FormGroup;

    breakPoint$?:Subscription;
    isSmall: boolean = false;

    constructor(
        @Optional() public matchingDialogRef: MatDialogRef<HsProcessingFilterComponent>,
        private formBuilder:FormBuilder,
        private hsProcessingService: HsProcessingService,
        breakpointObserver: BreakpointObserver,
    ){
        this.hsProcessingFilterForm = this.formBuilder.group({
            createdDateFrom: null,
            createdDateTo: null,
            adxReference: null,
            hsTicketNumber: null,
            invalid: null,
        });

        this.breakPoint$ = breakpointObserver.observe([
            Breakpoints.Medium,
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            this.isSmall = result.matches;
        });
        
     }

    ngOnInit(): void {
        this.hsProcessingService.getHsProcessingFilter().subscribe((filter: HsProcessingFilter) => {
            this.config = filter;
            this.hsProcessingFilterForm.patchValue(this.config || {});
        });
    }

    applyMatchingFilters(){
        this.config = this.hsProcessingFilterForm.value;
        this.config.adxReference = this.config.adxReference?.trim();
        this.config.hsTicketNumber = this.config.hsTicketNumber?.trim();
        this.matchingDialogRef.close(this.config);
    }

    handleBookingFiltersFormCloseEvent() {
        if (this.matchingDialogRef) {
          this.matchingDialogRef.close();
        }
    }

    clearMatchingFields(...args:string[]){
        if(!args.length) {
            this.hsProcessingFilterForm.reset();
            return;
        }
        args.forEach((field)=>{
            this.hsProcessingFilterForm.controls[field].reset()
        })
    }
}
