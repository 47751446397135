<div class="template-form" *ngIf="_vendor.vendorName" id="vendor-viewer-template-form">
  <div id="vendor-viewer-title-div">
    <label *ngIf="data != undefined" class="title" id="vendor-viewer-title-label">{{data.title}}</label>
  </div>
  <hr>
  <div id="vendor-viewer-inputs-div">
    <mat-form-field id="vendor-viewer-name-form-field">
      <mat-label id="vendor-viewer-name-label">Vendor Name</mat-label>
      <input type="text" #vendorName matInput [(ngModel)]="_vendor.vendorName" readonly id="vendor-viewer-name-input">
    </mat-form-field>
    <mat-form-field id="vendor-viewer-contact-person-form-field">
      <mat-label id="vendor-viewer-contact-person-label">Vendor Contact Person</mat-label>
      <input type="text" matInput #vendorContactPerson [(ngModel)]="_vendor.vendorContactPerson" readonly id="vendor-viewer-contact-person-input">
    </mat-form-field>
    <mat-form-field id="vendor-viewer-contact-email-form-field">
      <mat-label id="vendor-viewer-contact-email-label">Vendor Contact Email</mat-label>
      <input type="text" matInput #vendorContactEmail [(ngModel)]="_vendor.vendorContactEmail" readonly id="vendor-viewer-contact-email-input">
    </mat-form-field>
    <mat-form-field id="vendor-viewer-contact-phone-form-field">
      <mat-label id="vendor-viewer-contact-phone-label">Vendor Contact Phone</mat-label>
      <input type="text" #vendorContactPhone matInput [(ngModel)]="_vendor.vendorContactPhone" readonly id="vendor-viewer-contact-phone-input">
    </mat-form-field>
    <mat-form-field id="vendor-viewer-full-address-form-field">
      <mat-label id="vendor-viewer-full-address-label">Vendor Full Address</mat-label>
      <input type="text" #vendorFullAddress matInput [(ngModel)]="_vendor.vendorFullAddress" readonly id="vendor-viewer-full-address-input">
    </mat-form-field>
    <mat-form-field id="vendor-viewer-website-form-field">
      <mat-label id="vendor-viewer-website-label">Website</mat-label>
      <input type="text" matInput [(ngModel)]="_vendor.vendorWebSite" readonly id="vendor-viewer-website-input">
    </mat-form-field>
  </div>
</div>

