
<ng-container>
    <form data-testdata-testid="adx-invoice-detail-form" class="template-form adx-invoice-detail-form" *ngIf="!(loading$ | async)">  
      <div data-testdata-testid="adx-invoice-detail-main-container" class="container">
        <mat-tab-group dynamicHeight disablePagination="true" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Invoice">
                <mat-card class="mat-elevation-z8">
                    <mat-card-content>
                        <mat-form-field data-testdata-testid="adx-invoice-detail-profile-name-field" *ngIf="adxInvoice.client?.profileName != undefined && adxInvoice.client?.profileName != null">
                            <mat-label data-testdata-testid="adx-invoice-detail-profile-name-field-title">Profile Name: </mat-label>
                            <input data-testdata-testid="adx-invoice-detail-profile-name-input-field" matInput value="{{adxInvoice.client.profileName}}">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="adx-invoice-detail-client-id-field" *ngIf="adxInvoice.client?.id != undefined && adxInvoice.client?.id != null">
                            <mat-label data-testdata-testid="adx-invoice-detail-client-id-field-title">Client Id: </mat-label>
                            <input data-testdata-testid="adx-invoice-detail-client-id-input-field" matInput value="{{adxInvoice.client.id}}">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="adx-invoice-detail-record-locator-field" *ngIf="adxInvoice.recordLocator != undefined && adxInvoice.recordLocator != null">
                            <mat-label data-testdata-testid="adx-invoice-detail-record-locator-field-title">Invoice Record Locator: </mat-label>
                            <input data-testdata-testid="adx-invoice-detail-record-locator-input-field" matInput value="{{adxInvoice.recordLocator}}">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="adx-invoice-detail-invoice-type-code-field" *ngIf="adxInvoice.invoiceTypeCode != undefined && adxInvoice.invoiceTypeCode != null">
                            <mat-label data-testdata-testid="adx-invoice-detail-invoice-type-code-field-title">Invoice Type: </mat-label>
                            <input data-testdata-testid="adx-invoice-detail-invoice-type-code-input-field" matInput value="{{adxInvoice.invoiceTypeCode}}">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="adx-invoice-detail-invoice-no-field" *ngIf="adxInvoice.tramsInvoiceNumber != undefined && adxInvoice.tramsInvoiceNumber != null  && adxInvoice.tramsInvoiceNumber != 0">
                            <mat-label data-testdata-testid="adx-invoice-detail-invoice-no-field-title">Invoice Number: </mat-label>
                            <input data-testdata-testid="adx-invoice-detail-invoice-no-input-field" matInput value="{{adxInvoice.tramsInvoiceNumber}}">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="adx-invoice-detail-branch-name-field" *ngIf="adxInvoice.branch?.name != undefined && adxInvoice.branch?.name != null">
                            <mat-label data-testdata-testid="adx-invoice-detail-branch-name-field-title">Branch: </mat-label>
                            <input data-testdata-testid="adx-invoice-detail-branch-name-input-field" matInput value="{{adxInvoice.branch.name}}">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="adx-invoice-detail-issue-date-field" *ngIf="adxInvoice.issueDate != undefined && adxInvoice.issueDate != null">
                            <mat-label data-testdata-testid="adx-invoice-detail-issue-date-field-title">Issue Date: </mat-label>
                            <input data-testdata-testid="adx-invoice-detail-issue-date-input-field" matInput [value]="formatDate(adxInvoice.issueDate)">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="adx-invoice-detail-data-source-id-field" *ngIf="adxInvoice.tramsDataSource?.id != undefined && adxInvoice.tramsDataSource?.id != null">
                            <mat-label data-testdata-testid="adx-invoice-detail-data-source-id-field-title">Data Source Id: </mat-label>
                            <input data-testdata-testid="adx-invoice-detail-data-source-id-input-field" matInput value="{{adxInvoice.tramsDataSource.id}}">
                        </mat-form-field>
                        <mat-form-field data-testdata-testid="adx-invoice-detail-data-source-name-field" *ngIf="adxInvoice.tramsDataSource?.sourceName != undefined && adxInvoice.tramsDataSource?.sourceName != null">
                            <mat-label data-testdata-testid="adx-invoice-detail-data-source-name-field-title">Data Source Name: </mat-label>
                            <input data-testdata-testid="adx-invoice-detail-data-source-name-input-field" matInput value="{{adxInvoice.tramsDataSource.sourceName}}">
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
            @for (adxBooking of adxInvoice.adxBookings; track adxBooking) {
                <mat-tab [label]="adxBooking.id">
                    <mat-form-field data-testid="adx-invoice-detail-vendor-id-field" *ngIf="adxBooking.vendor?.id != undefined && adxBooking.vendor?.id != null">
                        <mat-label data-testid="adx-invoice-detail-vendor-id-field-title">Vendor Id: </mat-label>
                        <input data-testid="adx-invoice-detail-vendor-id-input-field" matInput value="{{adxBooking.vendor.id}}">
                    </mat-form-field>
                    <mat-form-field data-testid="adx-invoice-detail-vendor-name-field" *ngIf="adxBooking.vendor?.vendorName != undefined && adxBooking.vendor?.vendorName != null">
                        <mat-label data-testid="adx-invoice-detail-vendor-name-field-title">Vendor Name: </mat-label>
                        <input data-testid="adx-invoice-detail-vendor-name-input-field" matInput value="{{adxBooking.vendor.vendorName}}">
                    </mat-form-field>
                    <mat-form-field data-testid="adx-invoice-detail-depart-date-field" *ngIf="adxBooking.departDate != undefined && adxBooking.departDate != null">
                        <mat-label data-testid="adx-invoice-detail-depart-date-field-title">Depart Date: </mat-label>
                        <input data-testid="adx-invoice-detail-depart-date-input-field" matInput [value]="formatDate(adxBooking?.departDate)">
                    </mat-form-field>
                    <mat-form-field data-testid="adx-invoice-detail-return-date-field" *ngIf="adxBooking.returnDate != undefined && adxBooking.returnDate != null">
                        <mat-label data-testid="adx-invoice-detail-return-date-field-title">Return Date: </mat-label>
                        <input data-testid="adx-invoice-detail-return-date-input-field" matInput [value]="formatDate(adxBooking.returnDate)">
                    </mat-form-field>
                    <mat-form-field data-testid="adx-invoice-detail-total-fare-field" *ngIf="adxBooking.totalFare != undefined && adxBooking.totalFare != null">
                        <mat-label data-testid="adx-invoice-detail-total-fare-field-title">Booking Total: </mat-label>
                        <input data-testid="adx-invoice-detail-total-fare-input-field" matInput value="{{adxBooking.totalFare}}">
                    </mat-form-field>
                    <mat-form-field data-testid="adx-invoice-detail-confirmation-number-field" *ngIf="adxBooking.confirmationNumber != undefined && adxBooking.confirmationNumber != null">
                        <mat-label data-testid="adx-invoice-detail-confirmation-number-field-title">Confirmation Number: </mat-label>
                        <input data-testid="adx-invoice-detail-confirmation-number-input-field" matInput value="{{adxBooking.confirmationNumber}}">
                    </mat-form-field>
                    <mat-form-field data-testid="adx-invoice-detail-currency-field" *ngIf="adxBooking.currency?.currencyName != undefined && adxBooking.currency?.currencyName != null && adxBooking.currency?.currencyName != ''">
                        <mat-label data-testid="adx-invoice-detail-currency-field-title">Currency: </mat-label>
                        <input data-testid="adx-invoice-detail-currency-input-field" matInput value="{{adxBooking.currency.currencyName}}">
                    </mat-form-field>
                    <mat-form-field data-testid="adx-invoice-detail-record-locator-field" *ngIf="adxBooking.recordLocator != undefined && adxBooking.recordLocator != null">
                        <mat-label data-testid="adx-invoice-detail-record-locator-field-title">Record Locator: </mat-label>
                        <input data-testid="adx-invoice-detail-record-locator-input-field" matInput value="{{adxBooking.recordLocator}}">
                    </mat-form-field>
                    <mat-form-field data-testid="adx-invoice-detail-reservation-no-field" *ngIf="adxBooking.reservationNo != undefined && adxBooking.reservationNo != null">
                        <mat-label data-testid="adx-invoice-detail-reservation-no-field-title">Reservation Number: </mat-label>
                        <input data-testid="adx-invoice-detail-reservation-no-input-field" matInput value="{{adxBooking.reservationNo}}">
                    </mat-form-field>
                    <mat-form-field data-testid="adx-invoice-detail-booking-udids-field" *ngIf="adxBooking.adxBookingUdids?.length >= 1" (keypress)="showMoreAboutAdxBookingUdidsInfo()" (click)="showMoreAboutAdxBookingUdidsInfo()">
                        <mat-label data-testid="adx-invoice-detail-booking-udids-field-title">List of UDIDs: </mat-label>
                        <input data-testid="adx-invoice-detail-booking-udids-input-field" matInput [value]="getUdidValues(adxBooking.adxBookingUdids)">
                    </mat-form-field>
                    <mat-form-field data-testid="adx-invoice-detail-agent-ids-field" *ngIf="adxBooking.adxAgentBookings?.length >= 1" (keypress)="showMoreAboutAdxAgentsInfo()" (click)="showMoreAboutAdxAgentsInfo()">
                        <mat-label data-testid="adx-invoice-detail-agent-ids-field-title">List of agents: </mat-label>
                        <input data-testid="adx-invoice-detail-agent-ids-input-field" matInput [value]="getAgentValues(adxBooking.adxAgentBookings)">
                    </mat-form-field>
                </mat-tab>
            }
        </mat-tab-group>
      </div>
    </form>
  </ng-container>
