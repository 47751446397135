<div id="update-matching-vcc-dialog-confirmpopup" class="confirmpopup">
    <div id="update-matchingvcc-dialog-dialog-title" mat-dialog-title>
      <label id="update-matching-vcc-dialog-title-label" class="title">Apply Update VCC: Confirmation</label>
    </div>
    <hr id="update-matching-vcc-dialog-first-hr">
    <div id="update-matching-vcc-dialog-dialog-content" mat-dialog-content>
      <p id="update-matching-vcc-dialog-content-p1">Are you sure you want to update virtual credit card with current information?</p>
      <p id="update-matching-vcc-dialog-content-p2">Adjusted commission items to save: {{dialogData.toSave.length - resolvedElements}}</p>
      <p id="update-matching-vcc-dialog-content-p3">Resolved items to save: {{resolvedElements}}</p>
      <p id="update-matching-vcc-dialog-content-p4">Items won't save: {{dialogData.noSave.length}}</p>
    </div>
    <hr id="update-matching-vcc-dialog-second-hr">
    <div id="update-matching-vcc-dialog-dialog-actions" mat-dialog-actions style="float: right;">
      <button
        id="update-matching-vcc-dialog-ok-button"
        type="submit" mat-raised-button
        (click)="okClick()"
        color="primary"
        cdkFocusInitial matTooltip="Update Matching Virtual Credit Card"
        aria-label="Update Matching Virtual Credit Card"
      >
        @if(isLoadingSpinner){
          <mat-spinner
            id="update-matching-vcc-dialog-spinner"
            color="accent"
            diameter="30">
          </mat-spinner>
        } @else {
          <span id="update-matching-vcc-dialog-ok-span">Ok</span>
        }
      </button>
      <button
        id="updte-matching-vcc-dialog-no-button"
        mat-raised-button
        (click)="onNoClick()"
        color="warn"
        [disabled]="isLoadingSpinner">
          No
      </button>
    </div>
  </div>
  