<ng-container id="aht-weekly-calculation-minutes-container" *ngIf="isTypeMinutes; else ahtPercentage">
  <chart id="aht-weekly-calculation-minutes-chart" *ngIf="minutesLineChartData"
    [dataSet]="minutesLineChartData"
    [title]="'AHT minutes'"
    [options]="minutesLineChartOptions"
    [chartType]="'line'">
  </chart>
</ng-container>
<ng-template id="aht-weekly-calculation-percentage-template" #ahtPercentage>
  <chart id="aht-weekly-calculation-percentage-chart" *ngIf="percentageLineChartData"
    [dataSet]="percentageLineChartData"
    [title]="'AHT percentage'"
    [options]="percentageLineChartOptions"
    [chartType]="'line'">
  </chart>
</ng-template>
