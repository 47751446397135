import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, catchError, throwError} from 'rxjs';
import {AdxInvoiceLineItemFilterResult} from '../model/adx-invoice-line-item-filter-result';
import {InvoiceDetailResponse} from '../model/invoice-detail-model';

@Injectable({
  providedIn: 'root'
})

export class InvoiceLineItemService {
  private resourcePath: string = 'dbov2/invoice-line-item';
  private _adxInvoiceLineItemFilterSubject: BehaviorSubject<AdxInvoiceLineItemFilterResult> = new BehaviorSubject<AdxInvoiceLineItemFilterResult>({});
  constructor(private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  getAdxInvoiceLineItemsFilter(): Observable<AdxInvoiceLineItemFilterResult> {
    return this._adxInvoiceLineItemFilterSubject.asObservable();
  }

  setAdxInvoiceLineItemsFilter(value: AdxInvoiceLineItemFilterResult): void {
    this._adxInvoiceLineItemFilterSubject.next(value);
  }

  getInvoiceLineItemById(id: string): Observable<InvoiceDetailResponse> {
    return this.httpClient
      .get<InvoiceDetailResponse>(`${this.resourcePath}/${id}`)
      .pipe(catchError(this.errorHandler));
  }
}