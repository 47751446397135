import {Component, ContentChild, Directive, Input} from '@angular/core';

@Directive({
  selector: '[widgetActions]',
})
export class WidgetActionsDirective {
}

@Component({
  selector: 'widget',
  templateUrl: './widget.component.html'
})
export class WidgetComponent {

  @Input() title!: string;
  @Input() subTitle!: string;
  @ContentChild(WidgetActionsDirective) actions!: WidgetActionsDirective;
}
