import {AfterViewInit, Directive, Input, inject, OnInit} from '@angular/core';
import {MatOption, MatSelect} from '@angular/material/select';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Directive({
    selector: 'mat-option[selectAll]',
})
export class SelectAllDirective implements OnInit, AfterViewInit {
    @Input({ required: true }) allValues: unknown[] = [];
  
    private _matSelect = inject(MatSelect);
    private _matOption = inject(MatOption);
  
    optionSubs$?:Subscription;
    selectSubs$?:Subscription;
    changeDetect$?: Subscription;

    ngOnInit(): void {
        this._matSelect.ngControl.control?.valueChanges.subscribe((value)=>{
            this.validateSelectAll(value);
        });
    }

    ngAfterViewInit(): void {
      const parentFormControl = this._matSelect.ngControl.control;

        this.optionSubs$ = this._matOption.onSelectionChange.subscribe((optionEvent) => {
            if (optionEvent.isUserInput) {
                if (optionEvent.source.selected) {
                    parentFormControl?.setValue(this.allValues);
                    this._matOption.select(false);
                } else {
                    parentFormControl?.setValue([]);
                    this._matOption.deselect(false);
                }
            }
        });
  
        this.selectSubs$ = this._matSelect.optionSelectionChanges.subscribe((selectEvent) => {
            if (selectEvent.isUserInput && selectEvent.source.value !== this._matOption.value) {
                if (!selectEvent.source.selected) {
                    this._matOption.deselect(false);
                } else {
                    if (parentFormControl?.value.length === this.allValues.length) {
                        this._matOption.select(false);
                    }
                }
            }
        });

        this.validateSelectAll(this._matSelect.ngControl.control?.value);
    }

    validateSelectAll(value: unknown[]): void {
        if (value?.length === this.allValues.length) {
            this._matOption.select(false);
        }
    }
}
  