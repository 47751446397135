<div id="home-container" class="container">
  <h1 id="home-title">Finance Management System</h1>
  <br>
  <p id="home-description">Finance Management System is designed and implemented as Financial Platform for Trip-Arc.
    <br>FMS goal is to provide all tools Finance might need to manage transactions
    <br>across the company and monitor financial activities.
  </p>
  <br>
  <br>
  <div id="home-row" class="row">
    <div id="home-card-container-{{i}}" *ngFor="let card of cards; let i = index" (click)="toggleText(card)" class="col-12 col-md-6 col-lg-3">
      <mat-card id="home-card-{{i}}" class="card-rotate" [ngClass]="{'flip': card.showText}">
        <mat-card-content id="home-card-content-{{i}}" [ngClass]="{'unflip-content': card.showText}">
          <img
            id="home-card-image-{{i}}"
            mat-card-image
            [ngSrc]="card.image"
            alt="Image"
            width="360"
            height="200"
            [hidden]="card.showText"
            class="card-image"
            priority="true">
          <p id="home-card-text-{{i}}" [hidden]="!card.showText">{{ card.text }}</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
