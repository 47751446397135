import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {BookingStatusService} from 'src/app/booking-status/service/booking-status.service';
import {ServiceStatusService} from 'src/app/service-status/service/service-status.service';
import {CacheableQuery} from 'src/infrastructure/abstractrions/cacheable-query';
import {TransactionBookingFilterResult} from '../../model/transaction-booking-filter-result';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'transaction-bookings-filter-modal',
  templateUrl: './transaction-bookings-filter-modal.component.html'
})
export class TransactionBookingsFilterModalComponent implements OnInit {

  @Input() config!: TransactionBookingFilterResult;

  public serviceStatuses: string[] = [];

  public bookingStatuses: string[] = [];

  form: FormGroup = new FormGroup({
    agentName: new FormControl(null),
    bookingName: new FormControl(null),
    bookingStatusName: new FormControl(null),
    serviceStatusName: new FormControl(null),
    externalNumber: new FormControl(null, [Validators.pattern(/^[1-9]\d*$/), Validators.min(1)]),
    bookingStartFrom: new FormControl(null),
    bookingStartTo: new FormControl(null),
    bookingEndFrom: new FormControl(null),
    bookingEndTo: new FormControl(null),
    dateCreatedFrom: new FormControl(null),
    dateCreatedTo: new FormControl(null),
    tripReference: new FormControl(null),
  });

  breakPoint$?:Subscription;
  isSmall: boolean = false;

  constructor(
    @Optional() public bookingFilterDialogRef: MatDialogRef<TransactionBookingsFilterModalComponent>,
    private bookingStatusService: BookingStatusService,
    private serviceStatusService: ServiceStatusService,
    breakpointObserver: BreakpointObserver,
  ){
      this.breakPoint$ = breakpointObserver.observe([
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall
      ]).subscribe(result => {
          this.isSmall = result.matches;
      });
  }

  ngOnInit() {
    this.bookingStatusService.getBookingStatuses({useCache: true, slidingExpiration: 1, cacheKey: 'BOOKING_STATUSES'} as CacheableQuery)
      .subscribe((next: Array<string>) => this.bookingStatuses = next);

    this.serviceStatusService.getServiceStatuses({useCache: true, slidingExpiration: 1, cacheKey: 'SERVICE_STATUSES'} as CacheableQuery)
      .subscribe((next: Array<string>) => this.serviceStatuses = next);
    
    const {
      agentName,
      bookingName,
      bookingStatusName,
      serviceStatusName,
      externalNumber,
      bookingStartFrom,
      bookingStartTo,
      bookingEndFrom,
      bookingEndTo,
      dateCreatedFrom,
      dateCreatedTo,
      tripReference} = this.config ?? {};

    this.form.patchValue({
      agentName,
      bookingName,
      bookingStatusName,
      serviceStatusName,
      externalNumber,
      bookingStartFrom,
      bookingStartTo,
      bookingEndFrom,
      bookingEndTo,
      dateCreatedFrom,
      dateCreatedTo,
      tripReference
    });
  }

  handleBookingFiltersFormCloseEvent() {
    if (this.bookingFilterDialogRef) {
      this.bookingFilterDialogRef.close();
    }
  }

  applyBookingFilters() {
    this.config.agentName = this.config.agentName?.trim();
    this.config.bookingName = this.config.bookingName?.trim();
    this.config.tripReference = this.config.tripReference?.trim();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else if (this.bookingFilterDialogRef) {
      this.bookingFilterDialogRef.close(this.config);
    }
  }

  public clearBookingFilters(propertyName: string): void {
    if (propertyName === 'all') {
      this.config = {} as TransactionBookingFilterResult;
    }else{
      this.config[propertyName] = undefined;
    }
  }
}
