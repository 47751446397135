import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup, Validators,} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {TramsInvoiceFilterResult} from '../../model/trams-invoice-filter-result';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'trams-invoices-filter-modal',
  templateUrl: './trams-invoices-filter-modal.component.html'
})
export class TramsInvoiceFilterModalComponent implements OnInit {
  @Input() config!: TramsInvoiceFilterResult;

  breakPoint$?:Subscription;
  isSmall: boolean = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<TramsInvoiceFilterModalComponent>,
    breakpointObserver: BreakpointObserver,
  ) {  
      this.breakPoint$ = breakpointObserver.observe([
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall
      ]).subscribe(result => {
          this.isSmall = result.matches;
      });

  }

  form: FormGroup = new FormGroup({
    tramsInvoiceNo: new FormControl('',[Validators.pattern(/^[1-9]\d*$/), Validators.min(1)]),
    invoiceRecordLocator: new FormControl('', Validators.pattern(/^\S*$/)),
    issueStartDate: new FormControl(null),
    issueEndDate: new FormControl(null),
    createdStartDate: new FormControl(null),
    createdEndDate: new FormControl(null)
  });

  handleTramsInvoiceFiltersFormCloseEvent() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  ngOnInit() {
    const {tramsInvoiceNo, invoiceRecordLocator, issueStartDate, issueEndDate, createdStartDate, createdEndDate} = this.config ?? {};
      this.form.patchValue({
        tramsInvoiceNo, invoiceRecordLocator, issueStartDate, issueEndDate, createdStartDate, createdEndDate
      });
  }

  applyTramsInvoiceFilters() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }
    else if (this.dialogRef) {
      this.dialogRef.close(this.config);
    }
  }

  public clearTramsInvoiceFilters(propertyName: string): void {
    if (propertyName === 'all') {
      this.config = {} as TramsInvoiceFilterResult;
    }else{
      let temp: any = this.config;
      temp[propertyName] = undefined;
      this.config = temp;
    }
  }
}
