import {Component, Input, OnInit, Optional} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {AutoUnsubscribe} from "src/infrastructure/decorators/auto-unsubscribe.decorator";
import {AdxVccFilter, PaymentProvider} from "../../model/adx-data";
import {AdxVccPaymentProviderService} from "../../service/adx-vcc-payment-providers.service";
import {Observable, Subscription} from "rxjs";
import {Merchant} from "src/app/dashboard/model/merchant-guid";
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MerchantsService} from "src/infrastructure/merchants/service/merchants.service";
import {AdxVccService} from '../../service/adx-vcc.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-adx-vcc-filter',
    templateUrl: './adx-vcc-filter.component.html',
})
export class AdxVccFilterComponent implements OnInit {
    @Input() config!:AdxVccFilter

    adxFilterForm!:FormGroup;
    disabled = false;
    paymentProviders$?: Observable<PaymentProvider[]>;
    merchantGuids: Merchant[] = [];

    breakPoint$?:Subscription;
    isSmall: boolean = false;

    constructor(
        @Optional() public adxDialogRef: MatDialogRef<AdxVccFilterComponent>,
        private adxVccPaymentProviders: AdxVccPaymentProviderService,
        private formBuilder:FormBuilder,
        private merchantsService: MerchantsService,
        private adxService: AdxVccService,
        breakpointObserver: BreakpointObserver,
    ){
        this.adxFilterForm = this.formBuilder.group<AdxVccFilter>({
            dateCreatedFrom:null,
            dateCreatedTo:null,
            paymentProvider:null,
            vccLastFourDigits:null,
            cardGuid:null,
            tripReference:null,
            cardStatus:null,
            merchantGuid: null,
        });

        this.breakPoint$ = breakpointObserver.observe([
            Breakpoints.Medium,
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            this.isSmall = result.matches;
        });

        this.paymentProviders$ = this.adxVccPaymentProviders.getProviers();

     }

    ngOnInit(): void {
        this.adxService.getAdxFilter().subscribe((filter: AdxVccFilter) => {
            this.config = filter;
            this.adxFilterForm.patchValue(this.config || {});
        });
        this.merchantsService.merchants$.subscribe((data)=>{
            this.merchantGuids = data;
        });
    }

    applyAdxFilters(){
        this.config = this.adxFilterForm.value;
        this.config.vccLastFourDigits = this.config.vccLastFourDigits?.trim();
        this.config.cardGuid = this.config.cardGuid?.trim();
        this.config.tripReference = this.config.tripReference?.trim();
        this.adxDialogRef.close(this.config);
    }

    handleBookingFiltersFormCloseEvent() {
        if (this.adxDialogRef) {
          this.adxDialogRef.close();
        }
      }

    clearAdxFields(...args:string[]){
        if(!args.length) {
            this.adxFilterForm.reset();
            return;
        }
        args.forEach((field)=>{
            this.adxFilterForm.controls[field].reset()
        })
    }
}
