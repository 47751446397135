import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, catchError, throwError} from 'rxjs';
import {SearchPaginatedAdxBookingResponse} from '../model/search-paginated-adx-booking-response';
import {AdxBookingFilterResult} from '../model/adx-booking-filter-result';
import {AdxBookingResponse} from '../model/adx-booking-response';

@Injectable({
  providedIn: 'root'
})

export class AdxBookingService {
  private resourcePath: string = 'dbov2/adx-booking';
  private _adxBookingFilterSubject: BehaviorSubject<AdxBookingFilterResult> = new BehaviorSubject<AdxBookingFilterResult>({});

  constructor(private httpClient: HttpClient) {
  }

  public getAdxBookingById(id: number): Observable<AdxBookingResponse> {
    return this.httpClient
      .get<AdxBookingResponse>(`${this.resourcePath}/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  searchPaginatedAdxBookings(adxBookingFilter: any): Observable<SearchPaginatedAdxBookingResponse> {
    let httpParams = new HttpParams();
    Object
      .keys(adxBookingFilter)
      .forEach((key) => {
        if (adxBookingFilter[key]) {
          httpParams = httpParams.append(key, adxBookingFilter[key]);
        }
      });
      return this.httpClient
        .get<SearchPaginatedAdxBookingResponse>(`${this.resourcePath}`, {params: httpParams})
        .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  getAdxBookingFilter(): Observable<AdxBookingFilterResult> {
    return this._adxBookingFilterSubject.asObservable();
  }

  setAdxBookingFilter(value: AdxBookingFilterResult): void {
    this._adxBookingFilterSubject.next(value);
  }
}