import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {DatePipe} from '@angular/common';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {AdxInvoiceService} from '../../service/adx-invoice.service';
import {BookingUdidInfoComponent} from 'src/app/invoices/component/popup/booking-udid-info/booking-udid-info.component';
import {AgentInfoComponent} from 'src/app/invoices/component/popup/agent-info/agent-info.component';
import {AdxInvoiceDetailResponse} from '../../model/adx-invoice-detail-response';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-adx-invoice-detail',
  templateUrl: './adx-invoice-detail.component.html'
})
export class AdxInvoiceDetailComponent {
  @Input() public data: any;
  public adxInvoice :  any = [];
  public selectedTab: string = "Invoice";
  loading$: any;

  constructor(
    private adxInvoiceService: AdxInvoiceService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public loader: LoadingService,
    private route: ActivatedRoute) {
  }

  ngOnInit(){
    this.loading$ = this.loader.loading$;
    if (this.data == undefined || this.data.id == null) {
      this.route.paramMap.subscribe(params => {
        this.data = {
          id: params.get('id')
        }
      });
    }
    this.getAdxInvoiceDetail();
  }

  getAdxInvoiceDetail() {
    this.adxInvoiceService.searchByQueryAdxInvoices(this.data)
      .subscribe((data: AdxInvoiceDetailResponse[]) => {
        if (data.length > 0){
          this.adxInvoice = data[0];
        }
      });
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM/dd/yyyy') || '';
  }

  getUdidValues(adxBookingUdids: any[]): string {
    return adxBookingUdids.map(udid => udid.udidValue).join(', ');
  }

  getAgentValues(adxAgentBookings: any[]): string {
    return adxAgentBookings.map(agent => agent.agent.id).join(', ');
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTab = event.tab.textLabel;
  }

  showMoreAboutAdxBookingUdidsInfo(){
    const currentTabBooking = this.adxInvoice.adxBookings.find((booking: any) => booking.id === this.selectedTab);
    let dialogConfirmationRef = 
      this.dialog.open(BookingUdidInfoComponent, {
        width: '30%',
        data: {
          bookingNo: this.selectedTab,
          bookingUdids: currentTabBooking.adxBookingUdids
        }
     })
     dialogConfirmationRef.afterClosed().subscribe(
      (result: boolean) => {
        if (result != null && result){
          console.log(result);
        }
      }
    );
  }

  showMoreAboutAdxAgentsInfo(){
    const currentTabBooking = this.adxInvoice.adxBookings.find((booking: any) => booking.id === this.selectedTab);
    let dialogConfirmationRef = 
      this.dialog.open(AgentInfoComponent, {
        width: '30%',
        data: {
          bookingNo: this.selectedTab,
          agents: currentTabBooking.adxAgentBookings.map((agent: any) => ({agentId: agent.agent.id, agentName: agent.agent.agentName}))
        }
     })
     dialogConfirmationRef.afterClosed().subscribe(
      (result: boolean) => {
        if (result != null && result){
          console.log(result);
        }
      }
    );
  }
}