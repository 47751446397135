<div *ngIf="!(loading$ | async)">
  <form [formGroup]="bookingForm" id="booking-search-form" class="flex flex-wrap">
    <div>
      <mat-label id="booking-search-field-title" class="title">Bookings:</mat-label>
    </div>
    <mat-form-field>
      <input
            id="booking-search-input-field"
            #inputAutoComplete
            placeholder="Search..."
            [formControl]="inputControl"
            [matAutocomplete]="auto"
            #trigger="matAutocompleteTrigger"
            matInput
            type="text" />
      <div matSuffix style="display:flex">
        <button id="booking-search-clear-button"
                (click)="clearInput($event)"
                *ngIf="inputControl?.value!==null && inputControl?.value!==''"
                aria-label="Clear"
                mat-icon-button
                type="button">
          <mat-icon>clear</mat-icon>
        </button>
        <button id="booking-search-arrow-button"
                (click)="openOrClosePanel($event, trigger)"
                aria-label="Clear"
                mat-icon-button
                type="button">
          <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
        </button>
      </div>
      <mat-autocomplete id="booking-auto-complete-option"
                        #auto="matAutocomplete"
                        (closed)="arrowIconSubject.next('arrow_drop_down')"
                        (opened)="arrowIconSubject.next('arrow_drop_up')"
                        (optionSelected)="arrowIconSubject.next('arrow_drop_down')">
        <mat-option id="booking-group-option-{{i}}"  *ngFor="let booking of bookingGroupOptions | async; let i = index "
                    [value]="booking.bookingName" (onSelectionChange)="setSelectedBooking(booking.transactionBookingId,$event)">
          {{booking.bookingName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
