<app-vcc-payment-gateway-viewer
  [title]="title"
  [data]="data"
  (innerLoadState)="handleLoadState($event)"
></app-vcc-payment-gateway-viewer>

@if(!isLoaderVisible){
  <transaction-history-list
    [guid]="guid"
  ></transaction-history-list>
}
