import {Component, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {Subscription} from 'rxjs';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {MatchingVccService} from '../../service/matching-vcc.service';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {ApplyVccData, MatchedVccFilter, MatchedVccModel} from '../../model/match-vcc';
import {ModalService} from 'src/app/shared/service/modal/modal.service';
import {ConfirmationDialogMatchingVccComnponent} from '../confirmation-dialog-matching-vcc/confirmation-dialog-matching-vcc.component';
import {ConfirmationDialogRevertMatchingVccComnponent} from '../confirmation-dialog-revert-matching-vcc/confirmation-dialog-revert-matching-vcc.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ComponentCanDeactivate} from 'src/infrastructure/guards/unsaved-changes.guard';
import {DatePipe} from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'matching-vcc',
  templateUrl: './matching-vcc.component.html',
})
export class MatchingVccComponent implements OnInit, ComponentCanDeactivate {

  displayedColumns: Array<string> = [
    'clientName',
    'orderNumber',
    'cardLastFour',
    'merchantName',
    'releasedDate',
    'connexPayAccountingDailyDetailAmount',
    'adxCCAmount',
    'tramsBookingTotalFare',
    'tramsBookingCommAmt',
    'cogs',
    'adxTripReference',
    'tramsInvoiceNumber',
    'agentName',
    'adxCardIssueDate',
    'travelerName',
    'tramsBookingStartDate',
    'tramsBookingEndDate',
    'userApproved',
    'dateApproved',
    'suggestedAdjustmentToCommAmt',
    'revertButton',
  ];
  matchingFilter$?: Subscription;

  items: MatchedVccModel[] = [];
  filters: MatchedVccFilter = {};
  pagination: Partial<PageEvent & {totalItemCount?: number}> = {};
  sorting: Partial<Sort> = {};
  itemsSave: ApplyVccData[] = [];
  itemsNoSave: ApplyVccData[] = [];

  allowNavigate = true;

  constructor(
    private matchingVccService: MatchingVccService,
    private exportGridService: ExportGridService,
    private modalService: ModalService,
    private _snackBar: MatSnackBar,
    private datePipe: DatePipe,
  ){}

  canDeactivate() {
    return this.allowNavigate;
  }

  ngOnInit(): void {
    this.matchingFilter$ = this.matchingVccService.getMatchingFilter().subscribe((filter:MatchedVccFilter)=>{
      this.filters = filter;
      this.pagination = {
        pageSize: this.pagination.pageSize || 25,
        pageIndex: 0,
        totalItemCount: 0,
      };
      this.sorting = {active: 'releasedDate', direction: 'desc'} as Sort;
        this.getItems();
    });
  }

  handleSortChange(sort: Sort): void { 
    this.sorting = sort;

    if(!this.sorting?.active || !this.sorting?.direction) {
      this.sorting = {};
    }
  
    this.getItems();
  }

  handlePageChanged(page: PageEvent): void {
    this.pagination = {
      ...this.pagination,
      ...page,
    };

    this.getItems();
  }

  getItems(){
    this.matchingVccService.searchMatching({
      filters: this.filters,
      page: this.pagination,
      sort: this.sorting,
    }).subscribe((data)=>{
      this.items = data?.items;
      this.pagination = {
        ... this.pagination,
        pageIndex: data.pagination.pageNumber - 1,
        totalItemCount: data.pagination.totalItemCount,
      };

     this.setItemsSave();
    });
  }

  downloadGridToFile(): void {

    this.exportGridService.getByDataSource(
      'dbov2/connex-pay-accounting-daily-detail',
      'matching-vcc',
      {
        ... this.matchingVccService.getOrganizedFilters(this.filters),
        pageSize: this.pagination?.totalItemCount || 100,
        propertyName: 'releasedDate',
        orderKey: 'desc',
      },
      (data) => {
        const record = data as MatchedVccModel;
        return {
          'Merchant Name': record?.clientName,
          'VCC Provider Reference': record?.orderNumber,
          'VCC Number': record?.cardLastFour,
          'VCC Provider Vendor Name': record?.merchantName,
          'VCC Provider Released Date': this.datePipe.transform(record?.releasedDate, 'M/d/yyyy'),
          'VCC Provider Total': record?.connexPayAccountingDailyDetailAmount,
          'Adx Total': record?.adxCCAmount,
          'Trams Total': record?.tramsBookingTotalFare,
          'Agent Commission': record?.tramsBookingCommAmt,
          'COGS': record?.cogs,
          'Adx Trip Reference': record?.adxTripReference,
          'Trams Invoice Number': record?.tramsInvoiceNumber,
          'Agent Name': record?.agentName,
          'Adx Issued Date':  this.datePipe.transform(record?.adxCardIssueDate, 'M/d/yyyy'),
          'Traveler Name': record?.travelerName,
          'Booking Start Date': this.datePipe.transform(record?.tramsBookingStartDate, 'M/d/yyyy'),
          'Booking End Date': this.datePipe.transform(record?.tramsBookingEndDate, 'M/d/yyyy'),
          'User Approved': record?.userApproved,
          'Date Approved': this.datePipe.transform(record?.dateApproved, 'M/d/yyyy'),
        }
      }
    );
  }

  applyUpdate(){
    this.allowNavigate = false;

    if (this.itemsSave.length) {
      this.modalService.open(
        ConfirmationDialogMatchingVccComnponent,
        {
          data: { toSave: this.itemsSave, noSave: this.itemsNoSave},
        }
      ).afterClosed().subscribe((confirmation:boolean)=>{
        if(confirmation){
          this.getItems();
        }
      });
    } else {
      this._snackBar.open('No items to save', 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });    
    }

  }

  applyRevert(element:MatchedVccModel){
    this.allowNavigate = false;

    if (element?.revert) {
      delete element.revert;
      this.setItemsSave();
      return;
    }

    this.modalService.open(ConfirmationDialogRevertMatchingVccComnponent)
    .afterClosed().subscribe((confirmation:boolean)=>{
      if (confirmation) {
        element.revert = true;
        this.setItemsSave();
      }
    });
  }

  setItemsSave(){
    this.itemsSave = [];
    this.itemsNoSave = [];

    this.items?.forEach((item)=>{
      if (item?.isAmountMatches && !item?.userApproved && !item?.dateApproved && !item?.revert) {
        this.itemsSave.push({
          id: item?.connexPayAccountingDailyDetailId,
          adjustedCommission: item?.suggestedAdjustmentToCommAmt,
          isResolved: item?.isResolved,
        });
      } else {
        if(item?.shouldResolve){
          this.itemsSave.push({
            id: item?.connexPayAccountingDailyDetailId,
            isResolved: item?.shouldResolve,
          });
        } else {
          this.itemsNoSave.push({
            id: item?.connexPayAccountingDailyDetailId,
            adjustedCommission: item?.suggestedAdjustmentToCommAmt,
          });
        }
      }
    });
  }

  itemColorStyle(item:MatchedVccModel){
    if (item?.isAmountMatches && !item?.userApproved && !item?.dateApproved && !item?.revert) {
      return '#deffde';
    }

    if (item?.revert) {
      return '#d4daff';
    }

    if (item?.userApproved && item?.dateApproved) {
      return '';
    }

    return '#ffd8d8';
  }

  setResolve(item:MatchedVccModel){
    this.allowNavigate = false;

    if(item.shouldResolve){
      delete item.shouldResolve;
    } else {
      item.shouldResolve = true;
    }

    this.setItemsSave();
  }
}
