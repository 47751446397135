import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, from, Observable} from 'rxjs';
import {AlertCriteria, AlertCondition, AlertFormValue, AlertsResponse, SearchAlertsParams, AlertDetail, WorkflowRules} from '../../shared/model/financial-alerts-result';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';
import {Merchant} from 'src/app/shared/model/merchant';
import {AzureAppConfigurationService} from 'src/infrastructure/services/azure-app-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialAlertsService {
    private readonly conditions: Record<number,string> = {
        1: '>',
        2: '>=',
        3: '<',
        4: '<=',
        5: '==',
    };

    merchantGuids: Merchant[] = [];

    constructor(
      private httpClient: HttpClient, 
      private azureAppConfigService: AzureAppConfigurationService,
    ){
      this.setMerchantNameFilter();
    }


    setMerchantNameFilter(){
      const subscription$ = from(this.azureAppConfigService.getByKey('MerchantGuid')).subscribe((result)=>{
          const merchantGuidsString = result?.value;
          if (merchantGuidsString) {
              const parsedMerchantGuids = JSON.parse(merchantGuidsString);
              this.merchantGuids = parsedMerchantGuids.merchantGuid;
          }

          subscription$.unsubscribe();
      });    
  }

    getAlerts({page,sort}:SearchAlertsParams): Observable<AlertsResponse>{
      const params:Record<string, string | number> = {
        pageSize:  page?.pageSize || 25,
        pageNumber : (page?.pageIndex || 0) + 1,
        propertyName: sort?.active || '',
        orderKey: sort?.direction || '',
      };

      removeEmptyParams(params);

      return this.httpClient.get<AlertsResponse>('dbov2/alert-rule', {
        params: {...params}
      })
      .pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error.message || 'Server error')
      }));
    }

    getAlertDetails(alertId:string): Observable<AlertDetail>{
      return this.httpClient.get<AlertDetail>(`dbov2/alert-rule/${alertId}`)
      .pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error.message || 'Server error')
      }));
    }

    setAlert(form:AlertFormValue): Observable<unknown>{
        const request = {
          name: form.alertName,
          criteriaId: form.alertCriteria,
          description: form.description,
          isEnabled: form.enabled,
          workflowNotification: {
            notificationValue: form.emailsForm.map((element) => element.email).join(";"),
          },
          workflowRules: form.merchantGuid.map((merchant)=>{
            const merchantDetails = this.merchantGuids.find((merchantData)=> merchantData.id === merchant);
            return {
              ruleName: `${form.alertName} for ${merchantDetails?.name}`,
              description: `${form.description} for ${merchantDetails?.name}`,
              expression: `input1.merchantGuid == "${merchantDetails?.id}" and input2.Amount ${this.conditions[form.alertCondition as number]} ${form.amount}`,
              isEnabled: true,
              workflowRuleConditions: [
                {
                  workflowLogicOperatorId: 5,
                  workflowRuleConditionValue: merchantDetails?.id,
                  entityName: "ConnexPayEventDetail",
                  entityColumn: "MerchantIdentifier",
                  orderNumber: 1,
                },
                {
                  workflowLogicOperatorId: form.alertCondition,
                  workflowRuleConditionValue: form.amount,
                  entityName: "ConnexPayEventCard",
                  entityColumn: "AmountLimit",
                  orderNumber: 2,
                },
              ],
              workflowRuleActions: [
                {
                  typeId: 1,
                },
                {
                  typeId: 2,
                },
              ],
            }
          }),
        };

        return this.httpClient.post('dbov2/alert-rule', request)
        .pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error.message || 'Server error')
        }));
    }

    updateAlert(form:AlertFormValue, alert:AlertDetail): Observable<unknown> {
      const request = {
        id: alert.workflow.id,
        name: form.alertName,
        criteriaId: form.alertCriteria,
        description: form.description,
        isEnabled: form.enabled,
        workflowNotification: {
          id: alert.workflow.workflowNotification.id,
          workflowId: alert.workflow.workflowNotification.workflowId,
          notificationTypeId: alert.workflow.workflowNotification.notificationTypeId,
          notificationValue: form.emailsForm.map((element) => element.email).join(";"),
        },
        workflowRules: {},
      }

      const updatedRules: any = alert?.workflow?.workflowRules.map((rule)=>{
        const merchantDetails = this.merchantGuids.find(
          (merchantData)=> merchantData.id === rule.workflowRuleConditions[0].workflowRuleConditionValue
        );
        return {
          id: rule.id,
          ruleName: `${form.alertName} for ${merchantDetails?.name}`,
          description: `${form.description} for ${merchantDetails?.name}`,
          workflowId: rule.workflowId,
          expression: `input1.merchantGuid == "${merchantDetails?.id}" and input2.Amount ${this.conditions[form.alertCondition as number]} ${form.amount}`,
          successEvent: rule.successEvent,
          operatorId: rule.operatorId,
          errorMessage: rule.errorMessage,
          isEnabled: form.merchantGuid.includes(rule.workflowRuleConditions[0].workflowRuleConditionValue),
          workflowRuleConditions: [
            {
              ...rule.workflowRuleConditions[0],
            },
            {
              ...rule.workflowRuleConditions[1],
              workflowLogicOperatorId: form.alertCondition,
              workflowRuleConditionValue: `${form.amount}`,
            }
          ],
          workflowRuleActions: rule.workflowRuleActions,
        }
      });

      form.merchantGuid.forEach((merchantId)=>{
        if(!updatedRules?.find((rule: WorkflowRules)=> rule.workflowRuleConditions[0].workflowRuleConditionValue === merchantId)){
          const merchantDetails = this.merchantGuids.find((merchantData)=> merchantData.id === merchantId);
            updatedRules?.push({
              ruleName: `${form.alertName} for ${merchantDetails?.name}`,
              description: `${form.description} for ${merchantDetails?.name}`,
              expression: `input1.merchantGuid == "${merchantDetails?.id}" and input2.Amount ${this.conditions[form.alertCondition as number]} ${form.amount}`,
              isEnabled: true,
              workflowRuleConditions: [
                {
                  workflowLogicOperatorId: 5,
                  workflowRuleConditionValue: merchantDetails?.id,
                  entityName: "ConnexPayEventDetail",
                  entityColumn: "MerchantIdentifier",
                  orderNumber: 1,
                },
                {
                  workflowLogicOperatorId: form.alertCondition,
                  workflowRuleConditionValue: form.amount,
                  entityName: "ConnexPayEventCard",
                  entityColumn: "AmountLimit",
                  orderNumber: 2,
                },
              ],
              workflowRuleActions: [
                {
                  typeId: 1,
                },
                {
                  typeId: 2,
                },
              ]
            })

        }
      });

      request.workflowRules = updatedRules;

      return this.httpClient.post('dbov2/alert-rule', request)
      .pipe(catchError((error:HttpErrorResponse)=>{
          throw new Error(error.message || 'Server error')
      }));
    }

    updateAlertState(alert:AlertDetail, state:boolean) {
      alert.workflow.isEnabled = state;

      return this.httpClient.post('dbov2/alert-rule', alert.workflow)
      .pipe(catchError((error:HttpErrorResponse)=>{
          throw new Error(error.message || 'Server error')
      }));
    }

    getAlertCriteria(): Observable<AlertCriteria[]> {
        return this.httpClient.get<AlertCriteria[]>('dbov2/workflow-target-criteria')
        .pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error')
        }));
    }

    getAlertConditions(): Observable<AlertCondition[]>{
        return this.httpClient.get<AlertCondition[]>('dbov2/workflow-logic-operator')
        .pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error')
        }));
    }
}

