import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {TripExternalStatusType} from '../model/trip-external-status-type';

@Injectable({
  providedIn: 'root'
})
export class TripExternalStatusTypeService {
  private resourcePath: string = 'dbov2/trip-external-status-type';
  
  constructor(
    private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public getAllTripExternalStatusTypes(): Observable<TripExternalStatusType[]> {
    return this.httpClient
      .get<any>(this.resourcePath, {params: new HttpParams()})
      .pipe(catchError(this.errorHandler));
  }
}
