import {BreakpointObserver} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';

export const Breakpoints = {
  XS: '(max-width: 479.99px)',
  SM: '(min-width: 480px) and (max-width: 767.99px)',
  MD: '(min-width: 768px) and (max-width: 1023.99px)',
  LG: '(min-width: 1024px)',
};

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  constructor(private breakpointObserver: BreakpointObserver,) {
  }

  isActive(breakpoints: string[]): Observable<boolean> {
    return this.breakpointObserver.observe(breakpoints).pipe(
      map(result => {
        for (const query of breakpoints) {
          if (result.breakpoints[query]) {
            return true;
          }
        }
        return false;
      })
    );
  }
}
