<div class="grid-title-event">
  <h2 data-testid="adx-booking-list-title">Bookings</h2>
  <div class="events-container">
      @if(dataSource.filteredData.length){
          <button data-testid="adx-booking-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="replayGrid()">
              <mat-icon fontIcon="replay"></mat-icon>
          </button>
          <button color="primary" data-testid="adx-booking-download-button" aria-label="Download Adx Booking File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
              <mat-icon>download</mat-icon>
          </button>
      }
  </div>
</div>
<div class="adx-booking-container mat-elevation-z8" *ngIf="!this.isLoading">
  <mat-table
    data-testid="adx-booking-table"
    [dataSource]="dataSource"
    (matSortChange)="handleSortChange($event)"
    matSort>

    <ng-container matColumnDef="id">
      <mat-header-cell data-testid="adx-booking-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
      <mat-cell data-testid="adx-booking-id-cell" class="mat-cell" *matCellDef="let element">
        <span class="adx-booking-id-cell-text" matTooltip={{element.id}}  [matTooltipPosition]="'above'">
          <a data-testid="adx-booking-id-cell-link" href="AdxBookings/{{element?.id}}" target="_blank">{{element.id}}</a>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="recordLocator">
      <mat-header-cell data-testid="adx-booking-record-locator-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Record Locator</mat-header-cell>
      <mat-cell data-testid="adx-booking-record-locator-cell" *matCellDef="let element">{{element.recordLocator}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="reservationNo">
      <mat-header-cell data-testid="adx-booking-branch-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Reservation Number</mat-header-cell>
      <mat-cell data-testid="adx-booking-branch-name-cell" *matCellDef="let element">{{element.reservationNo}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="confirmationNumber">
      <mat-header-cell data-testid="adx-booking-client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Confirmation Number</mat-header-cell>
      <mat-cell data-testid="adx-booking-client-name-cell" *matCellDef="let element">{{element.confirmationNumber}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalFare">
      <mat-header-cell  data-testid="adx-booking-type-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Total Fare</mat-header-cell>
      <mat-cell data-testid="adx-booking-type-cell" *matCellDef="let element">{{element.totalFare}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="departDate">
      <mat-header-cell data-testid="adx-booking-issue-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Departure Date</mat-header-cell>
      <mat-cell  data-testid="adx-booking-issue-date-cell" *matCellDef="let element">{{element.departDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="returnDate">
      <mat-header-cell data-testid="adx-booking-issue-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Return Date</mat-header-cell>
      <mat-cell  data-testid="adx-booking-issue-date-cell" *matCellDef="let element">{{element.returnDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>

    <mat-header-row data-testid="adx-booking-list-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row data-testid="adx-booking-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-paginator
    data-testid="adx-booking-paginator"
    [length]="pagination.totalItemCount"
    [hidePageSize]="true"
    [pageSize]="pagination.pageSize"
    (page)="pageChanged($event)">
  </mat-paginator>
</div>
