<ng-container id="summary-list-main-container" *ngIf="isTableView">
  <div id="summary-list-table-feed" class="table-feed" *ngIf="!hasSummaries">
    <div id="summary-list-table-feed-text" class="table-feed-text  m-t-8">
      There are no records to display.
    </div>
  </div>
  <div class="grid-title-event">
    <h2 data-testid="summary-list-title">Trasaction list</h2>

    <div class="events-container">
        @if(dataSource.filteredData.length){
            <button  data-testid="summary-list-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="replayGrid()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" data-testid="download-summary-list-button" aria-label="Download Adx Vcc File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
                <mat-icon>download</mat-icon>
            </button>
        }
    </div>
  </div>

  <mat-table id="summary-list-mat-table" [dataSource]="dataSource" matSort>
    <ng-container id="summary-list-no-data-row" *matNoDataRow>
      <mat-cell id="summary-list-empty-data-cell" [attr.colspan]="displayedColumns.length"> Empty data </mat-cell>
    </ng-container>
    <ng-container id="summary-list-name-column" matColumnDef="name">
      <mat-header-cell id="summary-list-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell id="summary-list-name-cell" class="mat-cell" *matCellDef="let row"> {{row.name}} </mat-cell>
    </ng-container>

    <ng-container id="summary-list-driver-column" *ngFor="let driver of drivers; let i = index" matColumnDef="driver{{i}}">
      <mat-header-cell id="summary-list-driver-header-{{i}}" class="mat-header-cell" *matHeaderCellDef mat-sort-header> {{driver.driverName}} </mat-header-cell>
      <mat-cell id="summary-list-driver-cell-{{i}}" class="mat-cell" *matCellDef="let row">
        <ng-container id="summary-list-driver-container" *ngIf="getDriverIndex(driver, row) !== -1; else showNAContentBlock">
            <p id="summary-list-driver-time-{{i}}">{{ row.drivers[getDriverIndex(driver, row)]?.timeInMins }}</p>
        </ng-container>
        <div id="summary-list-na-template-{{i}}">
          <ng-template #showNAContentBlock><p id="summary-list-na-time-{{i}}">0</p></ng-template>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container id="summary-list-total-tickets-column" matColumnDef="totalTickets">
      <mat-header-cell id="summary-list-total-tickets-header" class="mat-header-cell" *matHeaderCellDef>Total tickets</mat-header-cell>
      <mat-cell id="summary-list-total-tickets-cell" class="mat-cell" *matCellDef="let row"> {{row.totalTickets}} </mat-cell>
    </ng-container>

    <mat-header-row data-testid="summary-list-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row id="summary-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</ng-container>
<ng-container id="summary-list-chart-container" *ngIf="isChartView">
  <chart id="summary-list-chart" *ngIf="summaryBarChartData" [dataSet]="summaryBarChartData" [options]="summaryBarChartOptions"
    [chartType]="'bar'"></chart>
</ng-container>
<mat-paginator
  id="summary-list-paginator"
  *ngIf="isTableView"
  [length]="totalPages"
  [hidePageSize]="true"
  [pageSize]="pageSize"
  (page)="PageChanged($event)">
</mat-paginator>
