<h2 style="text-align:center" id="trams-invoices-list-title">ADX Invoice Line Items</h2>
<div class="vendors-container mat-elevation-z8" id="adx-invoice-line-items-list-main-div">
    <div id="adx-invoice-line-items-list-table-feed" class="table-feed" *ngIf="!hasRecords">
      <div id="adx-invoice-line-items-list-table-feed-text" class="table-feed-text" style="margin-top: 2%;">
        There are no records to display for selected filters.
      </div>
    </div>
    <mat-table id="adx-invoice-line-items-list-mat-table" [dataSource]="dataSource" matSort (matSortChange)="handleSortChange($event)">
      <ng-container id="adx-invoice-line-items-list-no-data-row" *matNoDataRow>
        <mat-cell id="adx-invoice-line-items-list-empty-data-cell" [attr.colspan]="displayedColumns.length"> Empty data </mat-cell>
      </ng-container>

      <ng-container id="adx-invoice-line-items-list-fms-adx-invoice-id-column" matColumnDef="fmsAdxInvoiceLineItemId">
        <mat-header-cell id="adx-invoice-line-items-list-fms-adx-invoice-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header style="width: 7%;flex: inherit;"> Fms Id </mat-header-cell>
        <mat-cell id="adx-invoice-line-items-list-fms-adx-invoice-id-cell" class="mat-cell" *matCellDef="let row" style="width: 7%;flex: inherit;">
          <a id="adx-invoice-line-items-list-fms-adx-invoice-id-link" href={{row.linkForInvoiceDetails}} target="_blank" rel="noopener">
            <div id="adx-invoice-line-items-list-fms-adx-invoice-id-div">
              {{row.fmsAdxInvoiceLineItemId}}
            </div>
          </a>
        </mat-cell>
      </ng-container>

      <ng-container id="adx-invoice-line-items-list-client-profile-no-column" matColumnDef="clientProfileNo">
        <mat-header-cell id="adx-invoice-line-items-list-client-profile-no-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Client Profile No </mat-header-cell>
        <mat-cell id="adx-invoice-line-items-list-client-profile-no-cell" class="mat-cell" *matCellDef="let row">{{row.clientProfileNo}} </mat-cell>
      </ng-container>

      <ng-container id="adx-invoice-line-items-list-client-name-column" matColumnDef="clientName">
        <mat-header-cell id="adx-invoice-line-items-list-client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Client Name </mat-header-cell>
        <mat-cell id="adx-invoice-line-items-list-client-name-cell" class="mat-cell" *matCellDef="let row">{{row.clientName}} </mat-cell>
      </ng-container>

      <ng-container id="adx-invoice-line-items-list-adx-quote-id-column" matColumnDef="quoteID">
        <mat-header-cell id="adx-invoice-line-items-list-adx-quote-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Itinerary </mat-header-cell>
        <mat-cell id="adx-invoice-line-items-list-adx-quote-id-cell" class="mat-cell" *matCellDef="let row"> {{row.quoteID}} </mat-cell>
      </ng-container>

      <ng-container id="adx-invoice-line-items-list-invoice-record-locator-column" matColumnDef="invoiceRecordLocator">
        <mat-header-cell id="adx-invoice-line-items-list-invoice-record-locator-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Invoice Record Locator </mat-header-cell>
        <mat-cell id="adx-invoice-line-items-list-invoice-record-locator-cell" class="mat-cell" *matCellDef="let row"> {{row.invoiceRecordLocator}} </mat-cell>
      </ng-container>

      <ng-container id="adx-invoice-line-items-list-invoice-type-column" matColumnDef="invoiceType">
        <mat-header-cell id="adx-invoice-line-items-list-invoice-type-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Invoice Type </mat-header-cell>
        <mat-cell id="adx-invoice-line-items-list-invoice-type-cell" class="mat-cell" *matCellDef="let row">{{row.invoiceType}} </mat-cell>
      </ng-container>

      <ng-container id="adx-invoice-line-items-list-invoice-group-column" matColumnDef="invoiceGroup">
        <mat-header-cell id="adx-invoice-line-items-list-invoice-group-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Invoice Group </mat-header-cell>
        <mat-cell id="adx-invoice-line-items-list-invoice-group-cell" class="mat-cell" *matCellDef="let row">{{row.invoiceGroup}} </mat-cell>
      </ng-container>

      <ng-container id="adx-invoice-line-items-list-issue-date-column" matColumnDef="issueDate">
        <mat-header-cell id="adx-invoice-line-items-list-issue-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Issue Date </mat-header-cell>
        <mat-cell id="adx-invoice-line-items-list-issue-date-cell" class="mat-cell" *matCellDef="let row">{{row.issueDate | date: 'M/d/yyyy'}} </mat-cell>
      </ng-container>

      <ng-container id="adx-invoice-line-items-list-created-date-column" matColumnDef="dateCreated">
        <mat-header-cell id="adx-invoice-line-items-list-created-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Date Created </mat-header-cell>
        <mat-cell id="adx-invoice-line-items-list-created-date-cell" class="mat-cell" *matCellDef="let row">{{row.dateCreated | date: 'M/d/yyyy'}} </mat-cell>
      </ng-container>

      <mat-header-row data-testid="adx-invoice-line-items-list-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row id="adx-invoice-line-items-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
  <mat-paginator
    [length]="pagination.totalItemCount"
    [hidePageSize]="true"
    [pageSize]="pagination.pageSize"
    (page)="getAdxInvoiceLineItems($event)"
    id="adx-invoice-line-items-list-paginator">
  </mat-paginator>
