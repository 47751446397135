import {Component, Input, OnInit, Optional} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {AutoUnsubscribe} from "src/infrastructure/decorators/auto-unsubscribe.decorator";
import {MatchedVccFilter} from "../../model/match-vcc";
import {AzureAppConfigurationService } from "src/infrastructure/services/azure-app-configuration.service";
import {Merchant} from "src/app/dashboard/model/merchant-guid";
import {from, Subscription} from "rxjs";
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MatchingVccService} from '../../service/matching-vcc.service';

@AutoUnsubscribe()
@Component({
    selector: 'matching-vcc-filter',
    templateUrl: './matching-vcc-filter.component.html',
})
export class MatchingVccFilterComponent implements OnInit {
    @Input() config!:MatchedVccFilter;

    matchingFilterForm!:FormGroup;

    merchantGuids: Merchant[] = [];

    breakPoint$?:Subscription;
    isSmall: boolean = false;

    constructor(
        @Optional() public matchingDialogRef: MatDialogRef<MatchingVccFilterComponent>,
        private formBuilder:FormBuilder,
        private azureAppConfigService: AzureAppConfigurationService,
        private matchingVccService: MatchingVccService,
        breakpointObserver: BreakpointObserver,
    ){
        this.matchingFilterForm = this.formBuilder.group({
            releasedDateFrom: null,
            releasedDateTo: null,
            tramsBookingStartDateFrom: null,
            tramsBookingStartDateTo: null,
            tramsBookingEndDateFrom: null,
            tramsBookingEndDateTo: null,
            orderNumber: null,
            tramsInvoiceNumber: null,
            merchantName: null,
            cardLastFour: null,
            merchantGuid: null,
            isMapped: null,
            isAmountMatches: null,
            isResolved: null,
        });

        this.breakPoint$ = breakpointObserver.observe([
            Breakpoints.Medium,
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            this.isSmall = result.matches;
        });
        
     }

    ngOnInit(): void {
        this.matchingVccService.getMatchingFilter().subscribe((filter: MatchedVccFilter) => {
            this.config = filter;
            this.matchingFilterForm.patchValue(this.config || {});
        });
        this.setMerchantNameFilter();
    }

    applyMatchingFilters(){
        this.config = this.matchingFilterForm.value;
        this.config.tramsInvoiceNumber = this.config.tramsInvoiceNumber?.trim();
        this.config.orderNumber = this.config.orderNumber?.trim();
        this.config.merchantName = this.config.merchantName?.trim();
        this.config.cardLastFour = this.config.cardLastFour?.trim();
        this.matchingDialogRef.close(this.config);
    }

    handleBookingFiltersFormCloseEvent() {
        if (this.matchingDialogRef) {
          this.matchingDialogRef.close();
        }
      }

    clearMatchingFields(...args:string[]){
        if(!args.length) {
            this.matchingFilterForm.reset();
            return;
        }
        args.forEach((field)=>{
            this.matchingFilterForm.controls[field].reset()
        })
    }

    setMerchantNameFilter(){
        const subscription$ = from(this.azureAppConfigService.getByKey('MerchantGuid')).subscribe((result)=>{
            const merchantGuidsString = result?.value;
            if (merchantGuidsString) {
                const parsedMerchantGuids = JSON.parse(merchantGuidsString);
                this.merchantGuids = parsedMerchantGuids.merchantGuid;
            }

            subscription$.unsubscribe();
        });    
    }
}
