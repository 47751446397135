<div id="tickets-summary-list-main-div" *ngIf="!(loading$ | async)">
  <div id="tickets-summary-list-table-feed" class="table-feed" *ngIf="this.ticketsSummaries.length == 0">
    <div id="tickets-summary-list-table-feed-text" class="table-feed-text m-t-8">
      There are no records to display.
    </div>
  </div>
  <div class="grid-title-event">
    <h2 data-testid="tickets-summary-list-title">Tickets list</h2>

    <div class="events-container">
        @if(dataSource.filteredData.length){
            <button  data-testid="tickets-summary-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="replayGrid()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" data-testid="download-tickets-summary-button" aria-label="Download Adx Vcc File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
                <mat-icon>download</mat-icon>
            </button>
        }
    </div>
  </div>
  <mat-table id="tickets-summary-list-mat-table" [dataSource]="dataSource" matSort>
    <ng-container id="tickets-summary-list-no-data-row" *matNoDataRow>
      <mat-cell id="tickets-summary-list-empty-data-cell" [attr.colspan]="displayedColumns.length"> Empty data </mat-cell>
    </ng-container>
    <ng-container id="tickets-summary-list-adx-ref-number-column" matColumnDef="adxRefNumber">
      <mat-header-cell id="tickets-summary-list-adx-ref-number-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Ticket List </mat-header-cell>
      <mat-cell id="tickets-summary-list-adx-ref-number-cell" class="mat-cell" *matCellDef="let row">
          <a id="tickets-summary-list-adx-ref-number-link" href={{row.linkToHsTicket}} target="_blank">
              <div id="tickets-summary-list-adx-ref-number-div">
                  {{row.adxRefNumber}}
              </div>
          </a>
      </mat-cell>
    </ng-container>

    <ng-container id="tickets-summary-list-assignee-name-column" matColumnDef="assigneeName">
      <mat-header-cell id="tickets-summary-list-assignee-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Assignee Name </mat-header-cell>
      <mat-cell id="tickets-summary-list-assignee-name-cell" class="mat-cell" *matCellDef="let row">{{row.assigneeName}} </mat-cell>
    </ng-container>

    <ng-container id="tickets-summary-list-transactions-column" matColumnDef="transactions">
      <mat-header-cell id="tickets-summary-list-transactions-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Transaction Types </mat-header-cell>
      <mat-cell id="tickets-summary-list-transactions-cell" class="mat-cell" *matCellDef="let row"> {{row.transactions.join(', ')}} </mat-cell>
    </ng-container>

    <ng-container id="tickets-summary-list-is-open-column" matColumnDef="isOpen">
      <mat-header-cell id="tickets-summary-list-is-open-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
      <mat-cell id="tickets-summary-list-is-open-cell" class="mat-cell" *matCellDef="let row">{{row.isOpen == 1 ? 'Open': 'Closed' }} </mat-cell>
    </ng-container>

    <ng-container id="tickets-summary-list-service-type-column" matColumnDef="serviceType">
      <mat-header-cell id="tickets-summary-list-service-type-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Service Type </mat-header-cell>
      <mat-cell id="tickets-summary-list-service-type-cell" class="mat-cell" *matCellDef="let row">{{row.serviceType}} </mat-cell>
    </ng-container>

    <ng-container id="tickets-summary-list-handling-time-column" matColumnDef="handlingTime">
      <mat-header-cell id="tickets-summary-list-handling-time-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Handling Time </mat-header-cell>
      <mat-cell id="tickets-summary-list-handling-time-cell" class="mat-cell" *matCellDef="let row"> {{row.handlingTime}} </mat-cell>
    </ng-container>

    <mat-header-row data-testid="tickets-summary-list-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row id="tickets-summary-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
<mat-paginator
  id="tickets-summary-list-paginator"
  [length]="totalPages"
  [hidePageSize]="true"
  [pageSize]="pageSize"
  (page)="PageChanged($event)">
</mat-paginator>
