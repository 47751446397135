<ng-container *ngIf="!isLoading; else loadingView" class="template-form">
  <mat-dialog-content *ngIf="title != undefined && title != null" class="flex-space-between">
    <mat-label id="vcc-payment-gateway-label-title" class="modal-title-bold">{{this.title}}</mat-label>
    <mat-dialog-actions align="end">
      <button id="vcc-payment-gateway-cancel-button" mat-icon-button color="primary" aria-label="Close Modal"
        (click)="handleVccPaymentGatewayViewerCloseBtnClick()">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
  <form [formGroup]="vccPaymentGatewayViewerForm" id="vcc-payment-gateway-viewer-form" class="template-form">
    <div id="vcc-payment-gateway-main-container" class="container">
        <mat-form-field id="vcc-payment-gateway-status-mat-form-field" *ngIf="_vcc.status != undefined && _vcc.status != null">
          <mat-label id="vcc-payment-gateway-status-field-title">Status: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.status}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-provider-id-field" *ngIf="_vcc.providerId != undefined && _vcc.providerId != null">
          <mat-label id="vcc-payment-gateway-provider-id-field-title">Provider ID: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.providerId}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-purchase-log-unique-id-field" *ngIf="_vcc.purchaseLogUniqueID != undefined && _vcc.purchaseLogUniqueID != null">
          <mat-label id="vcc-payment-gateway-purchase-log-unique-id-field-title">Purchase Log Unique ID: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.purchaseLogUniqueID}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-card-number-field" *ngIf="_vcc.creditCard.cardNumber != undefined && _vcc.creditCard.cardNumber != null">
          <mat-label id="vcc-payment-gateway-card-number-field-title">Card Number: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.creditCard.cardNumber}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-card-holder-name-field" *ngIf="_vcc.creditCard.cardHolderName != undefined && _vcc.creditCard.cardHolderName != null">
          <mat-label id="vcc-payment-gateway-card-holder-name-field-title">Card Holder Name: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.creditCard.cardHolderName}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-types-field" *ngIf="_vcc.creditCard.creditCardTypes != undefined && _vcc.creditCard.creditCardTypes != null">
          <mat-label id="vcc-payment-gateway-types-field-title">Type: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.creditCard.creditCardTypes}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-amount-limit-field" *ngIf="_vcc.creditCard.amountLimit != undefined && _vcc.creditCard.amountLimit != null">
          <mat-label id="vcc-payment-gateway-amount-limit-field-title">Amount of charges allowed: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.creditCard.amountLimit}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-issued-amount-field" *ngIf="_vcc.creditCard.issuedAmount != undefined && _vcc.creditCard.issuedAmount != null">
          <mat-label id="vcc-payment-gateway-issued-amount-field-title">Issued Amount: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.creditCard.issuedAmount}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-currency-code-field" *ngIf="_vcc.creditCard.currencyCode != undefined && _vcc.creditCard.currencyCode != null">
          <mat-label id="vcc-payment-gateway-currency-code-field-title">Currency Code: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.creditCard.currencyCode}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-available-balance-field" *ngIf=" _vcc.creditCard.availableBalance != undefined && _vcc.creditCard.availableBalance != null">
          <mat-label id="vcc-payment-gateway-available-balance-field-title">Available Balance: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.creditCard.availableBalance}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-customer-id" *ngIf="_vcc.customerId != undefined && _vcc.customerId != null">
          <mat-label id="vcc-payment-gateway-customer-id-title">Customer ID: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.customerId}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-usage-limit" *ngIf="_vcc.usageLimit != undefined && _vcc.usageLimit != null">
          <mat-label id="vcc-payment-gateway-usage-limit-title">Usage Limit: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.usageLimit}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-expiration-date" *ngIf="_vcc.expirationDate != undefined && _vcc.expirationDate != null">
          <mat-label id="vcc-payment-gateway-expiration-date-title">Expiration Date: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.expirationDate | date: 'M/d/yyyy'}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-termination-date" *ngIf="_vcc.terminateDate != undefined && _vcc.terminateDate != null">
          <mat-label id="vcc-payment-gateway-termination-date-title">Termination Date: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.terminateDate | date: 'M/d/yyyy'}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-active-from-date" *ngIf="_vcc.activeFromDate != undefined && _vcc.activeFromDate != null">
          <mat-label id="vcc-payment-gateway-active-from-date-title">Active From Date: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.activeFromDate | date: 'M/d/yyyy'}}">
        </mat-form-field>
        <mat-form-field id="vcc-payment-gateway-active-to-date" *ngIf="_vcc.activeToDate != undefined && _vcc.activeToDate != null">
          <mat-label id="vcc-payment-gateway-active-to-date-title">Active To Date: </mat-label>
          <input id="vcc-payment-gateway-input-field" matInput value="{{_vcc.activeToDate | date: 'M/d/yyyy'}}">
        </mat-form-field>
    </div>
  </form>
</ng-container>
<ng-template #loadingView>
  <div class="loading-spinner">
    <mat-spinner *ngIf="isLoaderVisible"></mat-spinner>
  </div>
</ng-template>
