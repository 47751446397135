@if(!isLoading)
{
  @if(title)
  {
    <mat-dialog-content  class="flex-space-between">
      <mat-label id="trip-reference-label-title" class="modal-title-bold">{{this.title}}</mat-label>
      <mat-dialog-actions align="end">
        <button id="trip-reference-cancel-button" mat-icon-button color="primary" aria-label="Close Modal"
          (click)="handleTripReferenceCloseBtnClick()">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-dialog-actions>
    </mat-dialog-content>
  }
  <form [formGroup]="tripReferenceForm" id="trip-reference-viewer-form" class="template-form">
    <div id="trip-reference-main-container" class="container">
      @if(_vcc.tripReference)
      {
        <mat-form-field id="trip-reference-trip-id-field">
          <mat-label id="trip-reference-trip-id-title">Trip Id: </mat-label>
          <input id="trip-reference-tripReference-input-field" matInput value="{{_vcc.tripReference}}">
        </mat-form-field>
      }
      @if(_vcc.bookingStartDate)
      {
        <mat-form-field id="trip-reference-service-start-date">
          <mat-label id="trip-reference-service-start-date-title">Service Start Date: </mat-label>
          <input id="trip-reference-service-start-date-input-field" matInput value="{{_vcc.bookingStartDate | date: 'M/d/yyyy'}}">
        </mat-form-field>
      }
      @if(_vcc.bookingEndDate)
      {
        <mat-form-field id="trip-reference-service-end-date">
          <mat-label id="trip-reference-service-end-date-title">Service End Date: </mat-label>
          <input id="trip-reference-service-end-date-input-field" matInput value="{{_vcc.bookingEndDate | date: 'M/d/yyyy'}}">
        </mat-form-field>
      }
      @if(_vcc.serviceStatusName)
      {
        <mat-form-field id="trip-reference-service-status-field">
          <mat-label id="trip-reference-service-status-field-title">Service Status: </mat-label>
          <input id="trip-reference-service-status-input-field" matInput value="{{_vcc.serviceStatusName}}">
        </mat-form-field>
      }
      @if( _vcc.vendor && _vcc.vendor.vendorName)
      {
        <mat-form-field id="trip-reference-vendor-name-field">
          <mat-label id="trip-reference-vendor-name-field-title">Vendor Name: </mat-label>
          <input id="trip-reference-vendor-name-input-field" matInput value="{{_vcc.vendor.vendorName}}">
        </mat-form-field>
      }
      @if(_vcc.bookingTotal)
      {
        <mat-form-field id="trip-reference-service-cost">
          <mat-label id="trip-reference-service-cost-title">Service Cost: </mat-label>
          <input id="trip-reference-service-cost-input-field" matInput value="{{_vcc.bookingTotal }}">
        </mat-form-field>
      }
      @if(_vcc.bookingTotalCurrency)
      {
        <mat-form-field id="trip-reference-service-cost-currency-code-field">
          <mat-label id="trip-reference-service-cost-currency-code-title">Service Cost Currency: </mat-label>
          <input id="trip-reference-service-cost-currency-input-field" matInput value="{{_vcc.bookingTotalCurrency}}">
        </mat-form-field>
      }
      @if(_vcc.clientTotal)
      {
        <mat-form-field id="trip-reference-client-total-field">
          <mat-label id="trip-reference-client-total-field-title">Client Total: </mat-label>
          <input id="trip-reference-client-total-input-field" matInput value="{{_vcc.clientTotal}}">
        </mat-form-field>
      }
      @if(_vcc.clientTotalCurrency)
      {
        <mat-form-field id="trip-reference-client-total-currency-code-field">
          <mat-label id="trip-reference-client-total-currency-code-field-title">Client Total Currency: </mat-label>
          <input id="trip-reference-client-total-currency-input-field" matInput value="{{_vcc.clientTotalCurrency}}">
        </mat-form-field>
      }
    </div>
  </form>
 }
 @else{
  <div class="loading-spinner">
    <mat-spinner *ngIf="isLoaderVisible"></mat-spinner>
  </div>
 }
