<form [formGroup]="myform" id="addedit-vendor-myform-form" class="template-form">
  <div id="addedit-vendor-title-div">
    <label *ngIf="data != undefined" id="addedit-vendor-title-label" class="title">{{data.title}}</label>
  </div>
  <div>
    <mat-spinner id="addedit-vendor-loading-spinner" *ngIf="isPageLoadingSpinnerVisible" diameter="50" mode="indeterminate"></mat-spinner>
    <mat-form-field id="addedit-vendor-vendor-name-field">
      <mat-label id="addedit-vendor-vendor-name-label">Vendor Name</mat-label>
      <input type="text" #vendorName maxlength="250" [formControl]="vendorNameValidator" [errorStateMatcher]="vendorErrorStateMatcher" matInput [(ngModel)]="_vendor.vendorName" required id="addedit-vendor-vendor-name-input">
      <mat-hint align="end" id="addedit-vendor-vendor-name-hint">{{vendorName.value.length}} / 250</mat-hint>
      <mat-error *ngIf="vendorNameValidator.hasError('required')" id="addedit-vendor-vendor-name-required-error">Vendor Name is required</mat-error>
      <mat-error *ngIf="vendorNameValidator.hasError('minlength')" id="addedit-vendor-vendor-name-minlength-error">Vendor name must be 3 characters or longer</mat-error>
      <mat-error *ngIf="vendorNameValidator.hasError('maxlength')" id="addedit-vendor-vendor-name-maxlength-error">Vendor Name cannot exceed 250 characters</mat-error>
      <mat-error *ngIf="vendorNameValidator.hasError('pattern')" id="addedit-vendor-vendor-name-pattern-error">Invalid vendor name format. Only letters, numbers, dashes are allowed.</mat-error>
    </mat-form-field>
    <mat-form-field id="addedit-vendor-vendor-full-address-field">
      <mat-label id="addedit-vendor-vendor-full-address-label">Vendor Address</mat-label>
      <input type="text" #vendorFullAddress maxlength="250" [formControl]="vendorFullAddressValidator" matInput [(ngModel)]="_vendor.vendorFullAddress" id="addedit-vendor-vendor-full-address-input">
      <mat-hint align="end" id="addedit-vendor-full-address-hint">{{vendorFullAddress.value.length}} / 250</mat-hint>
    </mat-form-field>
    <mat-form-field id="addedit-vendor-vendor-city-field">
      <mat-label id="addedit-vendor-vendor-city-label">Vendor City</mat-label>
      <input type="text" #vendorCity maxlength="250" [formControl]="vendorCityValidator" matInput [(ngModel)]="_vendor.vendorCity" id="addedit-vendor-vendor-city-input">
      <mat-hint align="end" id="addedit-vendor-vendor-city-hint">{{vendorCity.value.length}} / 250</mat-hint>
    </mat-form-field>
    <mat-form-field id="addedit-vendor-province-field">
      <mat-label id="addedit-vendor-province-label">Province</mat-label>
      <input type="text" #vendorProvince maxlength="250" [formControl]="vendorProvinceValidator" matInput [(ngModel)]="_vendor.vendorProvince" id="addedit-vendor-province-input">
      <mat-hint align="end" id="addedit-vendor-province-hint">{{vendorProvince.value.length}} / 250</mat-hint>
    </mat-form-field>
    <mat-form-field id="addedit-vendor-zip-code-field">
      <mat-label id="addedit-vendor-zip-code-label">Vendor Zip Code</mat-label>
      <input type="text" #vendorZipCode maxlength="10" [formControl]="vendorZipCodeValidator" [errorStateMatcher]="vendorErrorStateMatcher" matInput [(ngModel)]="_vendor.vendorZipCode" required id="addedit-vendor-zip-code-input">
      <mat-hint align="end" id="addedit-vendor-zip-code-hint">{{vendorZipCode.value.length}} / 10</mat-hint>
      <mat-error *ngIf="vendorZipCodeValidator.hasError('required')" id="addedit-vendor-zip-code-required-error">Vendor Zip Code is required</mat-error>
      <mat-error *ngIf="vendorZipCodeValidator.hasError('maxlength')" id="addedit-vendor-zip-code-maxlength-error">Vendor Zip Code cannot exceed 250 characters</mat-error>
      <mat-error *ngIf="vendorZipCodeValidator.hasError('pattern')" id="addedit-vendor-zip-code-pattern-error">Invalid Vendor Zip Code format. Only numbers and letters are allowed.</mat-error>
    </mat-form-field>
    <mat-form-field id="addedit-vendor-country-field">
      <mat-label id="addedit-vendor-country-label">Select Vendor Country</mat-label>
      <mat-select name="vendorCountry" [(value)]="vendorCountrySelected" [formControl]="vendorCountryValidator" [errorStateMatcher]="vendorErrorStateMatcher" matInput [(ngModel)]="_vendor.vendorCountry" required id="addedit-vendor-country-select">
        <mat-option *ngFor="let country of this.countries; let i = index" [value]="country.Id" id="addedit-vendor-country-option-{{i}}">
          {{country.Name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="vendorCountryValidator.hasError('required')" id="addedit-vendor-country-required-error">Vendor Country is required</mat-error>
    </mat-form-field>
    <mat-form-field id="addedit-vendor-contact-person-field">
      <mat-label id="addedit-vendor-contact-person-label">Vendor Contact Person</mat-label>
      <input type="text" formControlName="VendorContactPerson" matInput [(ngModel)]="_vendor.vendorContactPerson" id="addedit-vendor-contact-person-input">
    </mat-form-field>
    <mat-form-field id="addedit-vendor-contact-email-field">
      <mat-label id="addedit-vendor-contact-email-label">Vendor Contact Email</mat-label>
      <input type="email" matInput #vendorContactEmail maxlength="50" [formControl]="vendorContactEmailValidator" [errorStateMatcher]="vendorErrorStateMatcher" [(ngModel)]="_vendor.vendorContactEmail" id="addedit-vendor-contact-email-input">
      <mat-hint align="end" id="addedit-vendor-contact-email-hint">{{vendorContactEmail.value.length}} / 50</mat-hint>
      <mat-error *ngIf="vendorContactEmailValidator.hasError('email')" id="addedit-vendor-contact-email-error">
        Please enter a valid email address
      </mat-error>
    </mat-form-field>
    <mat-form-field id="addedit-vendor-contact-phone-field">
      <mat-label id="addedit-vendor-contact-phone-label">Vendor Contact Phone</mat-label>
      <input type="tel" #vendorContactPhone maxlength="50" matInput placeholder="555-555-1234" [formControl]="vendorContactPhoneValidator" [errorStateMatcher]="vendorErrorStateMatcher" [(ngModel)]="_vendor.vendorContactPhone" id="addedit-vendor-contact-phone-input">
      <mat-error *ngIf="vendorContactPhoneValidator.hasError('maxlength')" id="addedit-vendor-contact-phone-maxlength-error">Vendor Contact Phone number cannot exceed 50 characters</mat-error>
      <mat-error *ngIf="vendorContactPhoneValidator.hasError('pattern')" id="addedit-vendor-contact-phone-pattern-error">Invalid phone number format. Only numbers, ( , ) , - , one whitespace is allowed.</mat-error>
      <mat-hint align="end" id="addedit-vendor-contact-phone-hint">{{vendorContactPhone.value.length}} / 50</mat-hint>
    </mat-form-field>
    <mat-form-field id="addedit-vendor-web-site-field">
      <mat-label id="addedit-vendor-web-site-label">Website</mat-label>
      <input type="text" formControlName="vendorWebSite" matInput [(ngModel)]="_vendor.vendorWebSite" id="addedit-vendor-web-site-input">
    </mat-form-field>
  </div>
  <div>
    <button id="addedit-vendor-submit-button" (click)="openVendorConfirmationDialog()" mat-raised-button color="primary" class="pull-right">Submit</button>
  </div>
</form>
