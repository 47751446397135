import {Rule} from './rule';

export class RuleModel {
  rule: Rule | null;
  internalId: number | null;
  isvalid: boolean = true;

  constructor(obj: any) {
    this.rule = new Rule(obj);
    this.internalId = -1;
    this.isvalid = true;
  }
}
