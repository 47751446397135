import {Component, Input} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {VendorViewerType} from '../vendor-viewer-modal/vendor-viewer-modal.component';

@Component({
  selector: 'app-vendor-tc-viewer',
  templateUrl: './vendor-tc-viewer.component.html'
})

@AutoUnsubscribe()
export class VendorTCViewerComponent {
  @Input() public data: any;
  @Input() public tcType!: VendorViewerType;
  _vendor: any;
  _vendorTermsAndConditions: any;

  constructor(private buildr: FormBuilder) {
  }

  ngOnChanges() {
    this.getVendorTCInformation();
  }

  getVendorTCInformation() {
    if (this.data) {
      this._vendor = this.data;

      if (this._vendor.vendorTemplates.length > 0) {
        if (this.tcType == VendorViewerType.VendorPayment) {
          this._vendorTermsAndConditions = this._vendor.vendorTemplates.find((x: any) => !x.isDeleted &&
            x.termsConditionsTypeId == Number(VendorViewerType.VendorPayment));
        } else {
          this._vendorTermsAndConditions = this._vendor.vendorTemplates.find((x: any) => !x.isDeleted &&
            x.termsConditionsTypeId == Number(VendorViewerType.CancellationRefund));
        }
      }
    }
  }

  vendorTCViewerForm = this.buildr.group({
    action: this.buildr.control(''),
    templateName: this.buildr.control(''),
    templateNotes: this.buildr.control(''),
  })
}

