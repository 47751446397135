import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {MsalAzureTokenConstants} from '../../app/shared/model/msal-azure-token.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthRoleGuard {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
    const userRoles = this.getAssignedUserRolesFromFmsAzureToken();

    // Define the roles that are allowed to access this component
    const requiredRoles: string[] = route.data['roles'];

    // Check if any of the required roles exist in the user's roles
    const hasRequiredRole = requiredRoles.some(requiredRole => userRoles.includes(requiredRole));

    if (hasRequiredRole) {
      return true; // Allow access
    } else {
      // Redirect to a different route or display an error message
      this.router.navigate(['/NoAccess']);
      return false; // Deny access
    }
  }
  getAssignedUserRolesFromFmsAzureToken(): any {
    const jwt = localStorage.getItem(MsalAzureTokenConstants.FmsApiToken);
    if (jwt) {
      const jwtPayload = JSON.parse(window.atob(jwt.split('.')[1]))
      return jwtPayload.roles? jwtPayload.roles : [];
    }
  }
}
