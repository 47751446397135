import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {VendorTemplateService} from '../../../service/vendor-template.service';
import {AutoUnsubscribe} from '../../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {UpdateVendorTemplate} from 'src/app/vendor-template/model/update-vendor-template';

@AutoUnsubscribe()
@Component({
  selector: 'app-confirmation-dialog-vendor-template',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogVendorTemplateComponent {
  isLoadingSpinner: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  public updateVendorTemplate: UpdateVendorTemplate = {
    id: 0,
    vendorId: 0,
    templateId: 0,
    termsConditionsTypeId: 0,
    vendorTemplateNotes: '',
  };
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogVendorTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private vendorTemplateService: VendorTemplateService,
    private _snackBar: MatSnackBar
  ) {
      this.updateVendorTemplate.vendorId = this.data.vendorTemplate.vendorId;
      this.updateVendorTemplate.templateId = this.data.vendorTemplate.templateId;
      this.updateVendorTemplate.termsConditionsTypeId = this.data.vendorTemplate.termsConditionsTypeId;
      this.updateVendorTemplate.vendorTemplateNotes = this.data.vendorTemplate.vendorTemplateNotes;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  okClick(): void {
    this.isLoadingSpinner = true;
    if (!this.data.vendorTemplate.vendorTemplateExists) {
      this.CreateVendorTemplate();
      this.data.vendorTemplate.vendorTemplateExists = true;
    } else {
      this.updateVendorTemplate.id = this.data.vendorTemplate.id;
      this.UpdateVendorTemplatefn();
    }
  }

  public CreateVendorTemplate() {
    this.vendorTemplateService.createVendorTemplate(this.data.vendorTemplate)
      .subscribe({
        next: (_) => {
          this.data.vendorTemplate.id = _.vendorTemplateId;
          if(this.data.vendorTemplate.vendorTemplateNotes !== '')
            this.data.vendorTemplate.vendorTemplateNoteExists = true;
          this.isLoadingSpinner = false;
          this.dialogRef.close();
          this._snackBar.open('Successfully vendor template information updated!!', 'Dismiss', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['message-position', 'app-notification-success']
          });
          this.data.vendorTemplateAddEditDialogRef.close();
        },
        error: () => {
          this.isLoadingSpinner = false;
          this.dialogRef.close();
          this.data.vendorTemplateAddEditDialogRef.close();
        }
      });
  }

  public UpdateVendorTemplatefn() {
    if(!this.data.vendorTemplate.vendorTemplateNoteExists){
      this.vendorTemplateService.updateVendorTemplateService(this.updateVendorTemplate)
      .subscribe({
        next: () => {
          if(this.data.vendorTemplate.vendorTemplateNotes !== '')
            this.data.vendorTemplate.vendorTemplateNoteExists = true;
          this.isLoadingSpinner = false;
          this.dialogRef.close();
          this._snackBar.open('Successfully vendor template information updated!!', 'Dismiss', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['message-position', 'app-notification-success']
          });
          this.data.vendorTemplateAddEditDialogRef.close();
        },
        error: () => {
          this.isLoadingSpinner = false;
          this.dialogRef.close();
          this.data.vendorTemplateAddEditDialogRef.close();
        }
      });
    }
    else {
      this.vendorTemplateService.deleteVendorTemplate(this.data.vendorTemplate)
        .subscribe({
          next: () => {
            this.CreateVendorTemplate();
          },
          error: () => {
            this.isLoadingSpinner = false;
            this.dialogRef.close();
            this.data.vendorTemplateAddEditDialogRef.close();
          }
        });
    }
  }
}
