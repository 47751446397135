<ng-container id="sidebar-menu-container-1" *ngIf="item.subMenu">
  <mat-expansion-panel id="{{item.idTag}}-menu-expansion-panel" class="sidebar-menu-expansion-panel" [expanded]="item.isExpanded">
    <mat-expansion-panel-header id="{{item.idTag}}-menu-expansion-panel-header" expandedHeight="48px" collapsedHeight="48px">
      <span id="{{item.idTag}}-menu-span" class="sidebar-menu-item sidebar-menu-item-btn-expansion">
        <mat-icon id="{{item.idTag}}-menu-icon">{{ item.icon }}</mat-icon>
        {{ item.title }}
      </span>
    </mat-expansion-panel-header>
    <sidebar-menu
      id="{{sub.idTag}}-sub-tab"
      *ngFor="let sub of item.subMenu ?? []; let i = index"
      [isSubMenu]="true"
      [item]="sub"
      [isSubMenu]="true">
    </sidebar-menu>
  </mat-expansion-panel>
</ng-container>

<ng-container id="sidebar-menu-container-2" *ngIf="!item.subMenu">
  <a id="{{item.idTag}}-menu-link" *ngIf="!item?.isExternal; else externalLink" matRipple class="sidebar-menu-item sidebar-menu-item-btn"
    routerLinkActive="sidebar-menu-item-active" [routerLinkActiveOptions]="{exact:
      true}" [routerLink]="[item.path]">
    <ng-container id="sidebar-menu-container-3" *ngTemplateOutlet="menuItemContent; context: { $implicit: item }">
    </ng-container>
  </a>
  <ng-template id="sidebar-menu-external-link" #externalLink>
    <a id="{{item.idTag}}-menu-link" matRipple class="sidebar-menu-item sidebar-menu-item-btn" [attr.href]="[item.path]" [attr.target]="'_blank'">
      <ng-container id="sidebar-menu-container-4" *ngTemplateOutlet="menuItemContent; context: { $implicit: item }">
      </ng-container>
    </a>
  </ng-template>
</ng-container>

<ng-template id="sidebar-menu-menu-item-content" #menuItemContent let-item>
  <mat-icon id="{{item.idTag}}-menu-icon" *ngIf="!isSubMenu">{{
    item?.icon
    }}</mat-icon>
  <div id="sidebar-menu" class="sidebar-menu-item-text">{{ item?.title }}</div>
</ng-template>
