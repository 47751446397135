<modal-panel id="filter-modal-panel" [title]="'Filter'" (close)="handleClose()">
  <div id="filter-modal-div-1" class="filter" [formGroup]="form">
    <div id="filter-modal-div-2" class="filter__fields">
      <mat-form-field id="filter-modal-fromdp-form-field" appearance="outline">
        <mat-label id="filter-modal-fromdp-label">From</mat-label>
        <input id="filter-modal-fromdp-input" matInput [matDatepicker]="fromDp" readonly formControlName="from" />
        <mat-datepicker-toggle id="filter-modal-datepicker-fromdp-toggle"
          matIconSuffix
          [for]="fromDp"
        ></mat-datepicker-toggle>
        <mat-datepicker id="filter-modal-from-date-datepicker" #fromDp></mat-datepicker>
        <mat-error id="filter-modal-from-date-error" *ngIf="fromDateControl.hasError('required')">
          From date is required
        </mat-error>
      </mat-form-field>

      <mat-form-field id="filter-modal-to-form-field" appearance="outline">
        <mat-label id="filter-modal-to-label">To</mat-label>
        <input id="filter-modal-to-input"
          matInput
          [matDatepicker]="toDp" readonly
          [min]="fromDateControl.value"
          formControlName="to"
        />
        <mat-datepicker-toggle id="filter-modal-todp-datepickertoggle"
          matIconSuffix
          [for]="toDp"
        ></mat-datepicker-toggle>
        <mat-datepicker id="filter-modal-todp-datepicker" #toDp></mat-datepicker>
        <mat-error id="filter-modal-todp-error" *ngIf="toDateControl.hasError('required')">
          To date is required
        </mat-error>
      </mat-form-field>
    </div>
    <mat-divider id="filter-modal-divider"></mat-divider>
    <mat-form-field id="filter-modal-selected-person-email-form-field" appearance="outline" *ngIf="this.isLoggedInUserDirector">
      <mat-label id="filter-modal-selected-person-email-label">Select Person</mat-label>
      <input type="text" 
        placeholder="Select Person" 
        aria-label="Select Person" 
        matInput 
        [formControl]="personEmailControl" 
        [matAutocomplete]="auto">

      <mat-autocomplete 
        id="filter-modal-select"
        #auto="matAutocomplete"
        (optionSelected)="onPersonSelection($event.option.value)"
        [(ngModel)]="selectedPersonEmail"
        [displayWith]="displaySelectedPersonFullName"
        name="filterPerson"
        formControlName="email"
        required>

        @for (option of personListFilteredOptions | async; track option) {
          <mat-option 
            id="filter-modal-option-{{option.personId}}"
            [ngClass]="(personEmailControl.value.email===option.email)?'mat-selected-transactions-filter-default':''"
            [value]="option">
            {{option.firstName + ' ' + option.lastName}}
          </mat-option>
        }
      </mat-autocomplete>
      <mat-error id="filter-modal-person-error" *ngIf="personEmailControl.hasError('required')">
        Person is required
      </mat-error>
    </mat-form-field>
  </div>
  <ng-container id="filter-modal-container" modalPanelFooter>
    <button id="filter-modal-button" mat-raised-button color="primary" (click)="apply()">Apply</button>
  </ng-container>
</modal-panel>
