import {Component, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ActivatedRoute} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AdxBookingService} from '../../service/adx-booking.service';
import {AdxBookingResponse} from '../../model/adx-booking-response';

@Component({
  selector: 'app-adx-booking-details',
  templateUrl: './adx-booking-details.component.html'
})

@AutoUnsubscribe()
export class AdxBookingDetailsComponent implements OnInit {
  data: any;
  adxBookingId: any;
  public selectedTab: string = "General info";

  constructor(private adxBookingService: AdxBookingService,
    private route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.adxBookingId = params.get('id');
    });

    this.getAdxBooking();
  }

  getAdxBooking() {
    this.adxBookingService
      .getAdxBookingById(this.adxBookingId)
      .subscribe((data: AdxBookingResponse) => {
          this.data = data;
      });
    }
  
  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTab = event.tab.textLabel;
  }
}