import {Injectable} from '@angular/core';
import {FileService} from './file.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';

@Injectable({
  providedIn: 'root'
})
export class ExportGridService {

    constructor(
        private http: HttpClient,
        private _snackBar: MatSnackBar,
        private fileService: FileService
    ){}

    getByDataSource(
        url: string,
        name: string,
        filters: Record<string, unknown>,
        mapFunction?: (data:unknown) => Object,
    ) {

        removeEmptyParams(filters);
        
        this.http.get(url, {
            params: {...filters as Record<string, string | number | boolean>}
        })
        .pipe(
            catchError((error:HttpErrorResponse) => {
                this._snackBar.open('The data is too large to generate excel file', 'Dismiss');
                throw new Error(error?.message || 'Server error');
            })
        )
        .subscribe((data: {items?:unknown[]}) => {
            this._snackBar.open('File exported successfully!', 'Dismiss');
            if(mapFunction){
                data.items = data?.items ?? (Array.isArray(data) ? data : [data])
                data.items = data.items?.map(mapFunction)
            }
            this.fileService.exportExcel(
                data?.items ?? (Array.isArray(data) ? data : [data]),
                name,
            );
        });
    }

    downloadDataSource(
        data: any,
        name: string,
        mapFunction?: (data:unknown) => Object,
    ){
        
        this._snackBar.open('File exported successfully!', 'Dismiss');
        if(mapFunction){
            data.items = data?.items ?? (Array.isArray(data) ? data : [data])
            data.items = data.items?.map(mapFunction)
        }
        this.fileService.exportExcel(
            data?.items ?? (Array.isArray(data) ? data : [data]),
            name,
        );
    }
}

