import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ISummary} from '../../model/summary';
import {MatTableDataSource} from '@angular/material/table';
import {ChartDataSet, ChartOptions} from 'src/app/shared/components/chart/chart.component';
import {ChartOptionsService} from 'src/app/shared/components/chart/chart-options.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Driver} from '../../model/driver';
import {HttpParams} from '@angular/common/http';
import {SummaryService} from '../../service/summary.service';
import {DateService} from 'src/app/shared/service/date/date.service';
import {FilterResult} from 'src/app/shared/modals/filter-modal/filter-modal.component';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {ApplicationConstants} from 'src/app/shared/constants/application.constants';
import {AzureAuthService} from '../../../../infrastructure/services/azure-auth.service';
import {ChartService} from '../../../shared/service/chart/chart.service';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';

@Component({
  selector: 'summary-list',
  templateUrl: './summary-list.component.html'
})

@AutoUnsubscribe()
export class SummaryListComponent implements OnInit {
  @Input() type: 'chart' | 'table' = 'table';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() filter!: FilterResult;
  summaries: ISummary[] = [];
  drivers: Driver[] = [];
  summaryChartsFilterFromDate: Date;
  summaryChartsFilterToDate: Date;
  summaryBarChartData!: ChartDataSet;
  summaryBarChartOptions: ChartOptions;
  dataSource = new MatTableDataSource<ISummary>([]);
  displayedColumns: string[] = ['name', 'totalTickets'];
  totalPages: number = 0;
  pageSize = 5;
  pageNumber: number = 1;
  private appConstants: ApplicationConstants = new ApplicationConstants;
  filterEmail: string = this.appConstants.AllUser;
  loading$: any;
  _httpParams: HttpParams = new HttpParams();

  constructor(
    private summaryService: SummaryService,
    private exportGridService: ExportGridService,
    private date: DateService,
    private chartOptions: ChartOptionsService,
    public loader: LoadingService,
    private azureAuthService: AzureAuthService,
    private chartService: ChartService,
  ) {
    this.summaryBarChartOptions = this.chartOptions.getChartOptions('bar', {
      plugins: {
        legend: {
          display: true,
          align: 'center'
        },
        datalabels: {
          color: '#ffffff',
          align: 'center',
          anchor: 'center',
          offset: 1,
          borderWidth: 1,
          borderRadius: 5,
          font: {
            weight: 'bold'
          },
          padding: 1,
          display: 'auto',
          formatter: function (value, _) {
            if (value === 0) {
              value = '';
              return value;
            }
          }
        }
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      }
    });
    this.summaryChartsFilterFromDate = this.date.subtractDays(new Date(), 30);
    this.summaryChartsFilterToDate = new Date();
  }

  ngOnInit() {
    const isDirector = this.azureAuthService.checkUserAzureTokenHasDirectorRole();
    if (!isDirector) {
      this.azureAuthService.loggedInUserEmail.subscribe(x => {
        this.filterEmail = x;
      });
    }
    this.getSummary(this.summaryChartsFilterFromDate.toDateString(), this.summaryChartsFilterToDate.toDateString(), this.filterEmail);
    this.loading$ = this.loader.loading$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter'] && !changes['filter'].firstChange) {
      this.pageNumber = 1;
      this.summaryChartsFilterFromDate = this.filter.from ? this.filter.from : this.summaryChartsFilterFromDate;
      this.summaryChartsFilterToDate = this.filter.to ? this.filter.to : this.summaryChartsFilterToDate;
      this.filterEmail = this.filter.email?.toString() ?? this.filterEmail;
      this.getSummary(this.summaryChartsFilterFromDate.toDateString(), this.summaryChartsFilterToDate.toDateString(), this.filterEmail);
    }
  }

  getSummary(fromDate: string, toDate: string, email: string) {
    this.summaryService.getHsDrivers()
        .subscribe((data: any) => {
          this.drivers = data ?? [];
          this.displayedColumns = [];
          const dynamicColumns = this.drivers.map((_, index) => 'driver' + index);
          this.displayedColumns.push(...["name", ...dynamicColumns, "totalTickets"]);
          if(email == this.appConstants.AllUser){
            email = '';
          }
          if (this.isTableView) {
            this._httpParams = new HttpParams({ fromObject: { dateFrom: fromDate, dateTo: toDate, email: email, PageSize: this.pageSize, PageNumber: this.pageNumber == 0 ? 1 : this.pageNumber} });
            this.summaryService.getHsRequestDrivers(this._httpParams)
              .subscribe((data: any) => {
                let summData: ISummary[] = data.items ?? [];
                this.paginator.pageIndex = this.pageNumber - 1;
                this.paginator.length = data.pagination.totalItemCount;
                this.dataSource = new MatTableDataSource(summData);
                this.summaries = summData;
              });
          } else if (this.isChartView) {
            let httpParams = new HttpParams({ fromObject: { dateFrom: fromDate, dateTo: toDate, email: email, All: true} });
            this.summaryService.getHsRequestDrivers(httpParams)
              .subscribe((data: any) => {
                let summData: ISummary[] = data.items ?? [];
                this.summaries = summData;
                this.createChartOptionsData(this.summaries);
              });
            }
          });
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.summaryService.getHsRequestDrivers(this._httpParams)
      .subscribe(() => {
        this.exportGridService.getByDataSource(
          'help-spot/request/driver/summary',
          'transaction-summary',
          {
            dateFrom: this.summaryChartsFilterFromDate.toDateString(),
            dateTo: this.summaryChartsFilterToDate.toDateString(),
            email: this.filterEmail == this.appConstants.AllUser ? '' : this.filterEmail,
            PageSize: this.pageSize || 100,
            PageNumber: this.pageNumber == 0 ? 1 : this.pageNumber
          },
          (data) => {
            const record = data as ISummary;
              const allDrivers = [
              "ADX Upload",
              "Check Availability",
              "Commission Collection",
              "Issue Resolution",
              "Payment",
              "Reservation",
              "ReConfirmation",
              "TripArc Cruise",
              "VCC Creation",
              "Wire Transfer"
            ];
  
            const driversMapped = allDrivers.reduce((acc:any, driverName) => {
              acc[driverName] = 0;
              return acc;
            }, {});

            record?.drivers?.forEach(driver => {
              driversMapped[driver.driverName] = driver.timeInMins || 0;
            });
  
            return {
              'Name': record?.name,
              ...driversMapped,
              'Total tickets': record?.totalTickets,
            };
          }
        );
        downloadSubs$?.unsubscribe();
    });
  }

  replayGrid (){
    this.getSummary(this.summaryChartsFilterFromDate.toDateString(), this.summaryChartsFilterToDate.toDateString(), this.filterEmail);
  }

  createChartOptionsData(data: ISummary[] = []) {
    this.summaryBarChartData = this.chartService.createBarChartOptions(data, this.drivers);
  }

  get summaryListLength() {
    return this.summaries?.length ?? 0
  }

  get hasSummaries() {
    return this.summaries && this.summaries.length > 0
  }

  get isTableView() {
    return this.type === 'table';
  }

  get isChartView() {
    return this.type === 'chart';
  }

  PageChanged(event: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.getSummary(this.summaryChartsFilterFromDate.toDateString(), this.summaryChartsFilterToDate.toDateString(), this.filterEmail);
  }

  getDriverIndex(driverItem: Driver, summaryItem: ISummary) {
    const index = summaryItem.drivers.findIndex(item => item.driverId == driverItem.driverId);
    return index !== -1 ? index : -1;
  }
}
