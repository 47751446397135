import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RuleCondition} from '../model/rule-condition';
import {RulePeriodType} from '../model/rule-period-type';
import {Rule} from '../model/rule';
import {RuleModel} from '../model/rule-model';
import {TermsConditionsService} from '../service/terms-conditions.service';
import {AutoUnsubscribe} from '../../../infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-terms-conditions-rule',
  templateUrl: './terms-conditions-rule.component.html'
})
export class TermsConditionsRuleComponent implements OnInit, OnChanges {
  @Input() index!: number;
  rule: Rule = {
    id: -1,
    templateId: -1,
    ruleAmt: 100,
    isAmt: false,
    isPrc: true,
    termsConditionsRuleTypeId: 1,
    termsConditionsRuleTypeNumber: 0,
    ruleConditionId: 1
  }
  templatePrcAmtSelected: string = 'isPrc';
  isPrOrAmt: any = [{id: 1, name: 'Amt', value: 'isAmt'}, {id: 2, name: '%', value: 'isPrc'}];
  ruleForm!: FormGroup;
  public rulePeriodTypes!: RulePeriodType[];
  public ruleConditions!: RuleCondition[];
  @Output() emitter = new EventEmitter<any>();

  constructor(
    private buildr: FormBuilder,
    private termsConditionsService: TermsConditionsService) {
  }

  ngOnChanges(_: SimpleChanges): void {
    this.emmitRule();
  }

  ngOnInit(): void {
    this.setFormBuilder();
    this.emmitRule();
  }

  setFormBuilder() {
    this.ruleForm = this.buildr
      .group(
        {
          ruleAmt: new FormControl(
            'ruleAmt',
            {
              validators: [
                Validators.required,
                Validators.pattern(/^[+]?\d*\.?\d*$/),
                Validators.min(0),
                Validators.max(1000000)
              ],
              updateOn: 'blur'
            }),
          isPrOrAmt: new FormControl('isPrOrAmt', {updateOn: 'blur'}),
          rulePeriod: new FormControl(
            'rulePeriod',
            {
              validators: [
                Validators.required,
                Validators.pattern(/^\d*$/),
                Validators.min(0),
                Validators.max(100)
              ],
              updateOn: 'blur'
            }),
          rulePeriodType: new FormControl('rulePeriodType', {updateOn: `blur`}),
          ruleCondition: new FormControl('ruleCondition', {'updateOn': 'blur'})
        });

    this.ruleForm.controls['isPrOrAmt'].setValue(this.templatePrcAmtSelected);

    this.termsConditionsService.GetTermsConditionsRuleTypes().subscribe(data => {
      if (data) {
        this.rulePeriodTypes = data;
      }
    })

    this.termsConditionsService.GetTermsConditionsRuleConditionTypes().subscribe(data => {
      if (data) {
        this.ruleConditions = data;
      }
    })
  }

  emmitRule() {
    let ruleModel = new RuleModel(this.rule);
    ruleModel.isvalid = this.ruleForm.errors == null && this.findInvalidControls();
    ruleModel.internalId = this.index;
    this.emitter.emit(ruleModel);
  }

  findInvalidControls() {
    console.log(this.ruleForm);
    const invalid = [];
    const controls = this.ruleForm.controls;
    for (const name in controls) {
      if (controls[name].status == 'INVALID') {
        invalid.push(name);
      }
    }
    return invalid.length == 0;
  }

  ruleAmtChanged(event: any) {
    this.rule.ruleAmt = event.target.value;
    this.emmitRule();
  }

  rulePeriodChanged(event: any) {
    this.rule.termsConditionsRuleTypeNumber = event.target.value;
    this.emmitRule();
  }

  onPrOrAmtChange(selection: any) {
    this.rule.isAmt = selection == 'isAmt';
    this.rule.isPrc = selection == 'isPrc';
    this.emmitRule();
  }

  onRulePeriodType(value: any) {
    this.rule.termsConditionsRuleTypeId = value;
    this.emmitRule();
  }

  onRuleCondition(value: any) {
    this.rule.ruleConditionId = value;
    this.emmitRule();
  }
}

