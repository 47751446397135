import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {Template} from '../../model/template';
import {TemplateService} from '../../service/template.service';
import {TemplateInputModel} from '../../model/template-input-model';
import {TermsConditionsService} from '../../../terms-conditions/service/terms-conditions.service';
import {TemplateType} from '../../../terms-conditions/model/template-type';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'search-template',
  templateUrl: './search-template.component.html',
})
export class SearchTemplateComponent implements OnInit {
  @Input() public data: any;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;

  templateForm!: FormGroup;
  paymentTermsTemplates: Template[] = [];
  cancellationTermsTemplates: Template[] = [];
  templateGroupOptions!: Observable<Template[]>;
  inputControl = new FormControl();
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  termsContitionsTypes: TemplateType[] = [];
  loading$: any;
  @Output() selectedTemplate: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _formBuilder: FormBuilder,
    private templateService: TemplateService,
    private termsConditionsService: TermsConditionsService,
    public loader: LoadingService) {
  }

  ngOnInit() {
    this.loading$ = this.loader.loading$;
    this.templateForm = this._formBuilder.group({
      templateType: ['1', Validators.required]
    });
    this.getTemplateTypes();
    this.LoadForm();
    this.templateForm.patchValue({
      templateType: 1
    });
  }

  LoadForm() {
    this.templateForm.reset();
    this.getTemplates();
  }

  getTemplates() {
    this.templateService.GetAllTermsConditionsTemplates()
      .subscribe((data: any) => {
        if (data) {
          data.forEach((myObject: any) => {
            const item = new Template(myObject);
            if (item.termsConditionsTypeId === 1) {
              this.paymentTermsTemplates.push(item);
            }
            if (item.termsConditionsTypeId === 2) {
              this.cancellationTermsTemplates.push(item);
            }
          });
          this.filterGroups();
        }
      });
  }

  filterGroups() {
    const type = this.templateForm.controls['templateType'].value;
    if (type === 1) {
      this.templateGroupOptions = this.inputControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string'
            ? value
            : value?.VendorName),
          map(name => (name
            ? this.paymentTermsTemplates.filter(templates => templates.templateName.toLowerCase().startsWith(name.toLowerCase()))
            : this.paymentTermsTemplates.slice())
          )
        );
    }
    if (type === 2) {
      this.templateGroupOptions = this.inputControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.VendorName),
          map(name => (name
            ? this.cancellationTermsTemplates.filter(templates => templates.templateName.toLowerCase().startsWith(name.toLowerCase()))
            : this.cancellationTermsTemplates.slice()))
        );
    }
  }

  setSelectedTemplate(templateId: number, event: any) {
    if (event.isUserInput) {
      this.emitData(templateId);
    }
  }

  emitData(templateId: number) {
    const data = new TemplateInputModel();
    data.title = '';
    data.templateId = templateId;
    this.selectedTemplate.emit(data);
  }

  clearInput(evt: any): void {
    evt.stopPropagation();
    this.inputControl?.reset();
    this.inputAutoComplete?.nativeElement.focus();
  }

  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  getTemplateTypes() {
    this.termsConditionsService.GetTermsConditionsTypes().subscribe(data => {
      if (data) {
        this.termsContitionsTypes = data;
      }
    });
  }

  onTemplateTypeId() {
    this.inputControl?.reset();
    this.filterGroups();
    this.emitData(-1);
  }
}
