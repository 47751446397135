<div id="booking-template-addedit-template-form" class ="template-form" *ngIf="!(loading$ | async)">
    <form id="booking-template-addedit-bookingTemplateForm"
          [formGroup]="bookingTemplateForm">
        <div id="booking-template-addedit-title"
            class="title">
            <label id="booking-template-addedit-title-label">
              {{this.title}}
            </label>
        </div>
        <div id="booking-template-addedit-spinner-container">
            <mat-spinner
              id="booking-template-addedit-spinner"
              *ngIf="isPageLoadingSpinnerVisible"
              diameter="50"
              mode="indeterminate">
            </mat-spinner>
            <mat-form-field id="booking-template-addedit-termsConditionsType-field">
                <mat-label
                  id="booking-template-addedit-termsConditionsType-label">
                  T&C type
                </mat-label>
                <input
                  id="booking-template-addedit-termsConditionsType-input"
                  matInput disabled value="{{_createBookingTemplate.termsConditionsType}}">
            </mat-form-field>
            <mat-form-field id="booking-template-addedit-template-field">
                <mat-label
                  id="booking-template-addedit-template-label">
                  Select one template
                </mat-label>
                <mat-select
                  id="booking-template-addedit-template-select"
                  name="template"
                  [(value)]="this.bookingTemplateSelected" [formControl]="termConditionsTemplateValidator"
                  [errorStateMatcher]="bookingTemplateErrorStateMatcher" matInput [(ngModel)]="_createBookingTemplate.templateId" required
                  (selectionChange)="onSelectTermConditionTemplate($event.value)">
                    <mat-option
                    id="booking-template-addedit-template-option-{{i}}"
                    *ngFor="let termConditionTemplate of this.termsConditionsTemplates; let i = index"
                    [value]="termConditionTemplate.id">
                    {{termConditionTemplate.templateName}}
                  </mat-option>
                </mat-select>
                <mat-error
                  id="booking-template-addedit-template-error"
                  *ngIf="termConditionsTemplateValidator.hasError('required')">
                  Template is required
                </mat-error>
              </mat-form-field>
            <mat-form-field id="booking-template-addedit-description-field">
                <mat-label
                  id="booking-template-addedit-description-label">
                  Description
                </mat-label>
                <input
                  id="booking-template-addedit-description-input"
                  matInput disabled value="{{_createBookingTemplate.description}}">
            </mat-form-field>
            <mat-form-field id="booking-template-addedit-notes-field" class="example-full-width">
                <mat-label id="booking-template-addedit-notes-label">Notes</mat-label>
                <textarea
                  id="booking-template-addedit-notes-textarea"
                  matInput value="{{_createBookingTemplate.bookingTemplateNotes}}"
                  (input)="onNotesChange($event)"
                  cdkTextareaAutosize cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"
                  #message maxlength="1000"
                  placeholder="Type here your note.">
                </textarea>
                <mat-hint
                  id="booking-template-addedit-notes-hint"
                  align="end">{{message.value.length}} / 1000
                </mat-hint>
                <mat-error
                  id="booking-template-addedit-notes-error"
                  *ngIf="notesValidator.hasError('maxlength')">
                  Notes cannot exceed 1000 characters
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <button id="booking-template-addedit-submit-button-container"
              (click)="openBookingConfirmationDialog()"
              mat-raised-button
              color="primary"
              class="pull-right">
              Submit
            </button>
        </div>
    </form>
</div>
